import React from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Stack,
  Chip,
  IconButton,
  Paper,
} from '@mui/material';
import {
  X,
  Home,
  User,
  Calendar,
  Clock,
  Users,
  DollarSign,
  MapPin,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

const EventPopup = ({ event, open, onClose }) => {
  const { t } = useTranslation('common');
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const formatTime = (time) => {
    return new Date(`2024-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const renderInfoSection = (icon, title, content, secondaryContent = null) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        p: 2,
        borderRadius: 2,
        backgroundColor: 'rgba(0,0,0,0.03)',
      }}
    >
      {icon}
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body1" fontWeight="medium" className="!text-sm">
          {content}
        </Typography>
      </Box>
      {secondaryContent}
    </Box>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent sx={{ p: 3, position: 'relative' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'text.secondary',
          }}
        >
          <X size={20} />
        </IconButton>

        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Home size={24} color="#2196f3" />
          {t('Reservation Details')}
        </Typography>

        <Stack spacing={2}>
          {renderInfoSection(
            <User size={20} />,
            t('Guest'),
            event?.guestName || 'John Doe',
            <Chip
              label={event?.channel || 'Airbnb'}
              size="small"
              color="primary"
              variant="outlined"
            />,
          )}

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            {renderInfoSection(
              <Calendar size={20} />,
              t('Checkin'),
              formatDate(event?.startDate || '2024-01-01'),
            )}
            {renderInfoSection(
              <Calendar size={20} />,
              t('Checkout'),
              formatDate(event?.endDate || '2024-01-03'),
            )}
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            {renderInfoSection(
              <Clock size={20} />,
              t('Check-In Time'),
              formatTime(event?.checkInTime || '15:00'),
            )}
            {renderInfoSection(
              <Users size={20} />,
              t('Guests'),
              `${event?.numberOfGuests || '2'} guests`,
            )}
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            {renderInfoSection(
              <DollarSign size={20} />,
              t('Total Price'),
              `${event?.price || '150'}`,
              <Chip
                label={event?.paid ? t('Paid') : t('Unpaid')}
                color={event?.paid ? 'success' : 'error'}
                size="small"
              />,
            )}
            {renderInfoSection(
              <MapPin size={20} />,
              t('Reservation Number'),
              event?.reservationNumber || 'ABC123',
            )}
          </Box>

          <Paper
            elevation={0}
            sx={{
              backgroundColor: 'background.default',
              p: 3,
              borderRadius: 3,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Home size={24} color="#2196f3" />
              <Typography variant="h6" fontWeight="bold">
                {t('Property Details')}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {event?.propertyName || '6251 Cahuilla Avenue'}
            </Typography>
          </Paper>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EventPopup;
