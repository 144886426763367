import React, { useCallback, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)({
  width: '100%',
  margin: '0',
  marginLeft: '0',
  '& .MuiOutlinedInput-root': {
    height: '40px',
    '& fieldset': {
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      borderColor: '#b3b3b3',
    },
  },
  '& .MuiInputBase-input': {
    padding: '9px 14px',
    height: '22px',
    alignItems: 'center',
  },
});

const ReservationNumberFilter = ({
  reservationNumber,
  setReservationNumber,
}) => {
  const { t } = useTranslation('common');

  const [inputValue, setInputValue] = useState(reservationNumber || '');

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      // Convert to lowercase and trim for consistent searching
      const normalizedValue = searchValue.toLowerCase().trim();
      console.log('Searching for:', normalizedValue);
      setReservationNumber(normalizedValue);
    }, 300),
    [setReservationNumber],
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue); // Update local state immediately
    debouncedSearch(newValue); // Debounce the actual search
  };

  return (
    <StyledTextField
      placeholder={t('Search by reservation number')}
      value={inputValue}
      onChange={handleChange}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ReservationNumberFilter;
