import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from '../../redux/slices/AuthSlice';
import { AUTH_CONFIG } from '../../config/auth.config';
import Loading from 'components/loading/Loading';

const AuthRoute = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={AUTH_CONFIG.LOGOUT_REDIRECT} replace />;
};

export default AuthRoute;