import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Admin from "layouts/admin";
import AuthRoute from './components/wrappers/AuthRoute';
import LoginPage from 'features/auth/page/LoginPage';
import InvitationPage from 'features/auth/page/InvitationPage';
import NotFoundPage from 'components/notFoundPage/NotFoundPage';
import { AUTH_CONFIG } from './config/auth.config';

function App() {
  return (
    <Routes>
      <Route path={AUTH_CONFIG.LOGOUT_REDIRECT} element={<LoginPage />} />
      <Route path="/invit" element={<InvitationPage />} />
      
      <Route element={<AuthRoute />}>
        <Route path="/admin" element={<Navigate to="/admin/overview" replace />} />
        <Route path="/" element={<Navigate to="/admin/overview" replace />} />
        {/* <Route path="/404" element={<NotFoundPage />} /> */}
        <Route path="*" element={<Admin />} />
      </Route>
      
      {/* <Route path="/404" element={<NotFoundPage />} /> */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;