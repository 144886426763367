import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasRouteAccess } from '../../routes';

const RoutePermissionWrapper = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  
  let currentPath = location.pathname.substring(1); 
  
  if (currentPath === '') {
    currentPath = 'admin/overview';
  }
  
//   const bypassRoutes = ['/404', 'admin/overview'];
  const bypassRoutes = ['admin/overview'];

  if (bypassRoutes.includes(location.pathname)) {
    return children;
  }
  
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  
  if (!hasRouteAccess(user, currentPath)) {
    console.log(`User ${user.email} with role ${user.role} denied access to: ${currentPath}`);
    return <Navigate to="/404" replace />;
  }
  
  return children;
}

export default RoutePermissionWrapper;