import React from 'react';
import {
    Typography,
    Switch,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    styled
} from '@mui/material';
import GlobalTable from '../../../../../components/GlobalTable/GlobalTable';

// Custom styled components
const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#00b4b4',
        '&:hover': {
            backgroundColor: 'rgba(0, 180, 180, 0.08)',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#00b4b4',
    },
}));

const CustomRadio = styled(Radio)(() => ({
    padding: '4px',
    '&': {
        color: '#00b4b4',
    },
    '&.Mui-checked': {
        color: '#00b4b4 !important',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '20px',
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 180, 180, 0.04) !important',
    },
}));

const TasksTable = ({ tasks, handleTaskChange, shouldShowNA }) => {
    const columns = [
        {
            field: 'taskName',
            header: 'Task Name',
            width: '150px'
        },
        {
            field: 'taskCreatedAutomatically',
            header: 'Created Auto',
            width: '150px',
            body: (rowData) => (
                shouldShowNA(rowData.taskName, 'taskCreatedAutomatically') ? 'N/A' : (
                    <CustomSwitch
                        checked={rowData.taskCreatedAutomatically}
                        onChange={(e) => handleTaskChange(rowData.taskName, 'taskCreatedAutomatically', e.target.checked)}
                    />
                )
            )
        },
        {
            field: 'taskAssignedAutomatically',
            header: 'Assigned Auto',
            width: '150px',
            body: (rowData) => (
                shouldShowNA(rowData.taskName, 'taskAssignedAutomatically') ? 'N/A' : (
                    <CustomSwitch
                        checked={rowData.taskAssignedAutomatically}
                        onChange={(e) => handleTaskChange(rowData.taskName, 'taskAssignedAutomatically', e.target.checked)}
                    />
                )
            )
        },
        {
            field: 'duration',
            header: 'Duration',
            width: '150px',
            body: (rowData) => (
                shouldShowNA(rowData.taskName, 'duration') ? 'N/A' : (
                    <Select
                        value={rowData.duration}
                        onChange={(e) => handleTaskChange(rowData.taskName, 'duration', e.target.value)}
                        size="small"
                        displayEmpty
                    >
                        <MenuItem value="">Select duration</MenuItem>
                        <MenuItem value="1">1h</MenuItem>
                        <MenuItem value="2">2h</MenuItem>
                        <MenuItem value="3">3h</MenuItem>
                    </Select>
                )
            )
        },
        {
            field: 'daysBetweenCheckInOut',
            header: 'Days Between Check In/Out',
            width: '200px',
            body: (rowData) => (
                shouldShowNA(rowData.taskName, 'daysBetweenCheckInOut') ? 'N/A' : (
                    <Select
                        value={rowData.daysBetweenCheckInOut}
                        onChange={(e) => handleTaskChange(rowData.taskName, 'daysBetweenCheckInOut', e.target.value)}
                        size="small"
                        displayEmpty
                    >
                        <MenuItem value="">Select days</MenuItem>
                        {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                            <MenuItem key={day} value={day.toString()}>
                                {`${day}d`}
                            </MenuItem>
                        ))}
                    </Select>
                )
            )
        },
        {
            field: 'daysBeforeAssignment',
            header: 'Days Before Assignment',
            width: '300px',
            body: (rowData) => (
                shouldShowNA(rowData.taskName, 'daysBeforeAssignment') ? <div className="text-center">N/A</div> : (
                    <RadioGroup
                        row
                        value={rowData.daysBeforeAssignment}
                        onChange={(e) => handleTaskChange(rowData.taskName, 'daysBeforeAssignment', e.target.value)}
                        sx={{ display: 'flex', gap: 0, justifyContent: 'center' }}
                    >
                        {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                            <FormControlLabel
                                key={day}
                                value={day.toString()}
                                control={<CustomRadio size="small" />}
                                label={<Typography variant="body2">{`${day}d`}</Typography>}
                                labelPlacement="top"
                                sx={{
                                    margin: 0,
                                    flexDirection: 'column',
                                    '.MuiFormControlLabel-label': {
                                        marginBottom: '4px'
                                    }
                                }}
                            />
                        ))}
                    </RadioGroup>
                )
            )
        },
        {
            field: 'notificationHours',
            header: 'Notification Hours',
            width: '300px',
            body: (rowData) => (
                shouldShowNA(rowData.taskName, 'notificationHours') ? <div className="text-center">N/A</div> : (
                    <RadioGroup
                        row
                        value={rowData.notificationHours}
                        onChange={(e) => handleTaskChange(rowData.taskName, 'notificationHours', e.target.value)}
                        sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}
                    >
                        {[9, 13, 17].map((hour) => (
                            <FormControlLabel
                                key={hour}
                                value={hour.toString()}
                                control={<CustomRadio size="small" />}
                                label={<Typography variant="body2">{`${hour}h`}</Typography>}
                                labelPlacement="top"
                                sx={{
                                    margin: 0,
                                    flexDirection: 'column',
                                    '.MuiFormControlLabel-label': {
                                        marginBottom: '4px'
                                    }
                                }}
                            />
                        ))}
                    </RadioGroup>
                )
            )
        }
    ];

    return (
        <GlobalTable
            data={tasks}
            columns={columns}
            hasPagination={false}
        />
    );
};

export default TasksTable;