import React from 'react';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const ListingCard = ({ title, count, subtitle, isCurrent, next7Days, last30Days, percentage }) => {
    const textColorClass =
        title === "Inactive" || title === "Active" ? '!text-teal-500 !cursor-pointer' : '!bg-white';
    return (
        <Card className="!rounded-none flex flex-col justify-between h-full !shadow-xs">
        {/* <Card className="!rounded-none flex flex-col justify-between m-2 h-full !shadow-xs"> */}

            <CardContent className="p-0 flex flex-col h-full">
                <Box className="!p-[10px] flex justify-between items-center border-b-[1px] border-gray-300">
                    <Typography variant="h6" component="div" className={`flex gap-1 items-center !text-base !font-normal !text-[#676A6C ${textColorClass}`}>
                        <FormatListBulletedIcon className="!text-base !font-normal" />
                        {title}
                    </Typography>

                </Box>
                <Box className="!px-4 !py-4">
                    <div>
                        <Typography variant="h3" component="div" className={`mt-2 text-3xl !font-light !text-[#676A6C] ${textColorClass}`}>
                            {count}
                        </Typography>
                        <div className="flex items-center justify-between">
                            <Typography variant="body2" className={`!text-xs !font-light !text-[#676A6C] ${textColorClass}`}>
                                {subtitle}
                            </Typography>
                            {percentage && (
                                <Typography className={`!text-sm ${percentage.startsWith('-') ? 'text-red-500' : 'text-green-500'}`}>
                                    {percentage}
                                </Typography>
                            )}
                        </div>
                    </div>
                    {title === "Unread conversation(s)" && unreadConversations && (
                        <List className="mt-4 max-h-full overflow-y-auto">
                            {unreadConversations.map((conversation, index) => (
                                <ListItem key={index} className={`py-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} !p-4`}>
                                    <ListItemText
                                        primary={
                                            <Typography className="!text-sm text-teal-500 flex items-center gap-2">
                                                {conversation.image}
                                                {conversation.name} ({conversation.date})
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </CardContent>
        </Card>

    );

};

export default ListingCard;