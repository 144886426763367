import React, { useState } from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const CityFilter = ({
  cities = [],
  selectedCities = [],
  setSelectedCities,
}) => {
  const { t } = useTranslation('common');
  return (
    <FilterSearch
      options={cities}
      selectedItems={selectedCities}
      onItemsChange={setSelectedCities}
      placeholder={t('Select Cities')}
      idKey="_id"
      labelKey="name"
    />
  );
};

export default CityFilter;
