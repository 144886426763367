import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import { X } from 'lucide-react';
import { toast } from 'react-toastify';
import { updateReservation } from '../services/serverApi.reservation';
import GuestDetails from '../pages/components/componentsInfo/guestInfoDetails/GuestDetails';
import ListingDetails from '../pages/components/componentsInfo/guestInfoDetails/ListingDetails';
import ReservationDetails from '../pages/components/componentsInfo/guestInfoDetails/ReservationDetails';
import { getListings } from '../services/serverApi.reservation';
import { useTranslation } from 'react-i18next';

const UpdateReservationDialog = ({ open, onClose, reservation, onUpdate }) => {
  const { t } = useTranslation('common');
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fullReservation, setFullReservation] = useState(null);
  const [editableDetails, setEditableDetails] = useState({
    guestFirstName: '',
    guestLastName: '',
    guestEmail: '',
    phone: '',
    guestLanguage: '',
    guestCountry: '',
    guestCity: '',
    arrivalDate: '',
    departureDate: '',
    checkInTime: '',
    checkOutTime: '',
    confirmedCheckInTime: false,
    confirmedCheckOutTime: false,
    status: '',
    paymentStatus: '',
    currency: '',
    adults: 0,
    children: 0,
    infants: 0,
    timeLine: '',
    numberOfGuests: 0,
    checkinStatus: '',
    reservationNumber: '',
    guestRegistration: {
      nbre_guest_registered: 0,
      nbre_guest_to_register: 0,
    },
    sojoriId: '',
    roomTypeId: '',
  });

  useEffect(() => {
    const fetchListings = async () => {
      if (open) {
        setIsLoading(true);
        try {
          const staging =
            JSON.parse(localStorage.getItem('isStaging')) || false;
          const listingsData = await getListings(staging);
          setListings(listingsData);
        } catch (error) {
          console.error('Error fetching listings:', error);
          toast.error('Failed to fetch listings');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchListings();
  }, [open]);

  useEffect(() => {
    if (reservation) {
      setFullReservation(reservation);
      setEditableDetails((prev) => ({
        ...prev,
        ...reservation,
        arrivalDate: formatDate(reservation.arrivalDate),
        departureDate: formatDate(reservation.departureDate),
        guestRegistration: reservation.guestRegistration || {
          nbre_guest_registered: 0,
          nbre_guest_to_register: 0,
        },
      }));
    }
  }, [reservation]);

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      const updatePayload = {
        ...fullReservation,
        ...editableDetails,
        guestName:
          `${editableDetails.guestFirstName} ${editableDetails.guestLastName}`.trim(),
        numberOfGuests:
          parseInt(editableDetails.adults) +
          parseInt(editableDetails.children) +
          parseInt(editableDetails.infants),
      };

      const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
      const response = await updateReservation(
        reservation._id,
        updatePayload,
        staging,
      );

      if (response.success) {
        toast.success('Reservation updated successfully');
        onUpdate(response.data);
        onClose();
      }
    } catch (error) {
      console.error('Error updating reservation:', error);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        'Error updating reservation';
      toast.error(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '90vw',
          maxWidth: '1400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {t('Update Reservation')}
        <IconButton onClick={onClose}>
          <X size={20} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row gap-4">
            <div className="w-full">
              <GuestDetails
                editableDetails={editableDetails}
                isEditMode={true}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="w-full">
              <ListingDetails
                listings={listings} // Update this prop
                editableDetails={editableDetails}
                isEditMode={true}
                handleInputChange={handleInputChange}
              />
              <ReservationDetails
                editableDetails={editableDetails}
                isEditMode={true}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>

          <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 2 }}>
            <Button
              variant="outlined"
              className="!border-gray-500 !text-gray-500"
              onClick={onClose}
              fullWidth
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              className="!bg-medium-aquamarine !text-white"
              type="submit"
              disabled={isSaving}
              fullWidth
            >
              {isSaving ? (
                <CircularProgress size={24} />
              ) : (
                t('Update Reservation')
              )}
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateReservationDialog;
