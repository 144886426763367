import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Typography } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { getStaff } from '../services/serverApi.task';
import ModifyStaff from './ModifyStaff';
import { ToastContainer } from 'react-toastify';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SearchIcon from '@mui/icons-material/Search';
import CreateStaffDialog from './AddStaff';
import EditOffIcon from '@mui/icons-material/EditOff';
import {
  getcities,
  getcountries,
} from '../../setting/services/serverApi.adminConfig';
import {
  getListingsTa,
  getTaskConfigs,
} from '../../tasks/services/serverApi.task';
import ListingPopup from './ListingPopup';
import { toast } from 'react-toastify';
import { getLanguage } from '../services/serverApi.task';

const PublicStaff = () => {
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [listings, setListings] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [staffListing, setStaffListing] = useState(null);
  // const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const [listingPopupOpen, setListingPopupOpen] = useState(false);
  const [selectedListingIds, setSelectedListingIds] = useState([]);
  const [languages, setLanguages] = useState([]);

  const fetchLanguages = async () => {
    try {
      const response = await getLanguage();
      if (response.data) {
        setLanguages(response.data);
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
      toast.error('Error loading languages');
    }
  };

  const fetchCities = async () => {
    try {
      const response = await getcities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getcountries();
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchListings = async () => {
    try {
      const response = await getListingsTa();
      console.log('response====>:', response);

      setListings(response);
    } catch (error) {
      console.error('Error fetching staff:', error);
    }
  };

  const fetchTaskTypes = async () => {
    setIsLoading(true);
    try {
      const response = await getTaskConfigs();
      if (response && Array.isArray(response)) {
        console.log({ response });
        const updatedTaskTypes = response.flatMap((item) => {
          if (item.task === 'CONCIERGE' && Array.isArray(item?.subs)) {
            return item.subs.map((sub) => ({
              ...sub,
              task: sub.type,
            }));
          }
          return item;
        });

        setTaskTypes(updatedTaskTypes);
      }
    } catch (error) {
      console.error('Error fetching task config:', error);
      toast.error('Error loading task types');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskTypes();
    fetchCities();
    fetchCountries();
    fetchListings();
    fetchLanguages();
  }, []);

  useEffect(() => {
    fetchStaff();
  }, [page, limit, searchText]);

  const fetchStaff = async () => {
    setIsLoading(true);
    try {
      const response = await getStaff({ page, limit, search_text: searchText });
      if (response && Array.isArray(response.data)) {
        setStaff(response.data);
        setTotalCount(response.total || 0);
        setIsNextDisabled((page + 1) * limit >= response.total);
      } else {
        setStaff([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching staff:', error);
      setStaff([]);
      setTotalCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = (staffMember) => {
    setSelectedStaff(staffMember);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStaff(null);
  };

  const handleStaffUpdate = (updatedStaff) => {
    setStaff((prevStaff) =>
      prevStaff.map((member) =>
        member._id === updatedStaff._id
          ? {
              ...member,
              ...updatedStaff,
              public_metadata: {
                ...member.public_metadata,
                ...updatedStaff.public_metadata,
              },
              email_addresses:
                updatedStaff.email_addresses || member.email_addresses,
            }
          : member,
      ),
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <button
        className="px-2 py-1 bg-medium-aquamarine !rounded-md"
        onClick={() => handleUpdate(rowData)}
      >
        <EditOffIcon className="text-white" />
      </button>
    );
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const onStaffCreated = (newStaff) => {
    const formattedNewStaff = {
      _id: newStaff.id,
      username: newStaff.username,
      email_addresses: newStaff.emailAddresses.map((email) => ({
        email_address: email.emailAddress,
      })),
      public_metadata: {
        subType: newStaff.publicMetadata.subType,
        callPhone: newStaff.publicMetadata.callPhone,
        whatsappPhone: newStaff.publicMetadata.whatsappPhone,
        language: newStaff.publicMetadata.language,
      },
      clerkId: newStaff.id,
      createdAt: new Date(newStaff.createdAt).toISOString(),
      updatedAt: new Date(newStaff.updatedAt).toISOString(),
    };

    setStaff((prevStaff) => {
      const updatedStaff = [formattedNewStaff, ...prevStaff];
      if (updatedStaff.length > limit) {
        updatedStaff.pop();
      }
      return updatedStaff;
    });
    setTotalCount((prevCount) => prevCount + 1);
    setIsNextDisabled(false);
  };

  const columns = [
    { field: 'username', header: 'Username' },
    {
      field: 'email_addresses',
      header: 'Email Address',
      body: (rowData) => rowData.email_addresses[0]?.email_address || '',
    },
    {
      field: 'public_metadata.subType',
      header: 'Types',
      body: (rowData) => {
        const types = Array.isArray(rowData.public_metadata?.subType)
          ? rowData.public_metadata.subType
          : [];
        return (
          <div title={types.join('\n')} className="max-w-[200px] truncate">
            {types.join(', ')}
          </div>
        );
      },
    },
    {
      field: 'public_metadata.cityIds',
      header: 'Cities',
      body: (rowData) => {
        const cityIds = rowData.public_metadata?.cityIds || [];
        if (cityIds.includes('All')) {
          return <div className="max-w-[200px]">All Cities</div>;
        }
        const cityNames = cityIds
          .map(
            (cityId) => cities.find((city) => city._id === cityId)?.name || '',
          )
          .filter(Boolean);

        return (
          <div title={cityNames.join('\n')} className="max-w-[200px] truncate">
            {cityNames.join(', ') || ''}
          </div>
        );
      },
    },
    {
      field: 'public_metadata.countryIds',
      header: 'Countries',
      body: (rowData) => {
        const countryIds = rowData.public_metadata?.countryIds || [];
        if (countryIds.includes('All')) {
          return <div className="max-w-[200px]">All Countries</div>;
        }
        const countryNames = countryIds
          .map(
            (countryId) =>
              countries.find((country) => country._id === countryId)?.name ||
              '',
          )
          .filter(Boolean);

        return (
          <div
            title={countryNames.join('\n')}
            className="max-w-[200px] truncate"
          >
            {countryNames.join(', ') || ''}
          </div>
        );
      },
    },
    {
      field: 'public_metadata.listingIds',
      header: 'Listings',
      body: (rowData) => {
        const listingIds = rowData.public_metadata?.listingIds || [];
        const isAllListings = listingIds.includes('All');
        const listingCount = isAllListings ? 'All' : listingIds.length;

        const handleListingClick = () => {
          if (listingCount > 0 || isAllListings) {
            setSelectedListingIds(listingIds);
            setListingPopupOpen(true);
          }
        };

        return (
          <div className="text-center">
            <button
              type="button"
              onClick={handleListingClick}
              className={`${
                listingCount > 0 || isAllListings
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 text-gray-500 !cursor-default'
              } px-3 py-1 !rounded-full !text-sm hover:opacity-90 transition-opacity`}
            >
              {isAllListings
                ? 'All listings'
                : `${listingCount} listing${listingCount !== 1 ? 's' : ''}`}
            </button>
          </div>
        );
      },
    },
    {
      field: 'public_metadata.callPhone',
      header: 'Call Phone',
      body: (rowData) => rowData.public_metadata?.callPhone || '-',
    },
    {
      field: 'public_metadata.whatsappPhone',
      header: 'WhatsApp Phone',
      body: (rowData) => rowData.public_metadata?.whatsappPhone || '-',
    },
    {
      field: 'public_metadata.language',
      header: 'Language',
      body: (rowData) => rowData.public_metadata?.language || '',
    },
    { field: 'action', header: 'Action', body: actionBodyTemplate },
  ];

  if (error) {
    return (
      <div className="w-full h-64 flex justify-center items-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="card p-4">
      <Typography
        variant="h4"
        component="h1"
        className="mb-4 flex items-center gap-1"
        gutterBottom
      >
        <Diversity3Icon /> Staff Management
      </Typography>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
        <div className="w-full md:flex-grow md:mr-4">
          <div className="flex w-full bg-white border rounded-md h-8 md:h-10">
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search staff..."
              className="p-2 text-xs md:text-xs outline-none rounded-md w-full"
            />
            <div className="px-2">
              <SearchIcon className="!w-3 !h-3 md:!w-4 md:!h-4" />
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={handleOpenCreateDialog}
          className="w-full md:w-auto px-2 md:px-2.5 py-1 md:py-1.5 !text-xs !bg-medium-aquamarine text-white !rounded-md"
        >
          Create Staff
        </Button>
      </div>
      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center h-64">
            <CircularProgress style={{ color: '#66cdaa' }} />
          </div>
        ) : staff.length > 0 ? (
          <GlobalTable
            data={staff}
            columns={columns}
            page={page}
            hasPagination={true}
            onPageChange={handlePageChange}
            isNextDisabled={isNextDisabled}
            limit={limit}
            onLimitChange={handleLimitChange}
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalCount={totalCount}
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            <CircularProgress sx={{ color: '#00b4b4' }} />
          </div>
        )}
      </div>
      <ModifyStaff
        open={openModal}
        handleClose={handleCloseModal}
        staff={selectedStaff}
        onStaffUpdate={handleStaffUpdate}
        cities={cities}
        countries={countries}
        listings={listings}
        taskTypes={taskTypes}
        languages={languages}
      />
      <CreateStaffDialog
        open={openCreateDialog}
        handleClose={handleCloseCreateDialog}
        onStaffCreated={onStaffCreated}
        cities={cities}
        countries={countries}
        listings={listings}
        taskTypes={taskTypes}
        languages={languages}
      />
      <ListingPopup
        open={listingPopupOpen}
        onClose={() => setListingPopupOpen(false)}
        listingIds={selectedListingIds}
        // staging={staging}
      />
    </div>
  );
};

export default PublicStaff;
