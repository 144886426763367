import React from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PublicOwner from './components/PublicOwner';

function Staff() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="Task">
                <PublicOwner />
            </div>
        </DashboardLayout>
    );
}

export default Staff;