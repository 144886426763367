import React from 'react';
import { Box, Typography, Button, Paper, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import HomeIcon from '@mui/icons-material/Home';

const NotFoundPage = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Paper
        elevation={0}
        sx={{
          background: 'white',
          overflow: 'hidden',
          mt: 4,
          mb: 4,
          height: 'calc(100vh - 64px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center',
            padding: 5,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 30,
              right: 30,
              width: '80px',
              height: '80px',
              opacity: 0.1,
              borderRadius: '50%',
              background: '#FE9E19',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 40,
              left: 40,
              width: '120px',
              height: '120px',
              opacity: 0.1,
              borderRadius: '50%',
              background: '#FE9E19',
            }}
          />
          
          <Box
            sx={{
              position: 'relative',
              mb: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: { xs: '6rem', sm: '10rem' },
                fontWeight: 700,
                color: '#FE9E19',
                opacity: 0.2,
                lineHeight: 1,
                position: 'relative',
              }}
            >
              404
            </Typography>
            
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ErrorOutlineIcon
                sx={{
                  fontSize: 36,
                  color: '#FE9E19',
                  mr: 1,
                }}
              />
              <Typography
                variant="h5"
                component="span"
                sx={{
                  fontWeight: 600,
                  color: '#FE9E19',
                }}
              >
                {t('pageNotFound', 'Page Not Found')}
              </Typography>
            </Box>
          </Box>

          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 5,
              backgroundColor: 'rgba(254, 158, 25, 0.05)',
              padding: '16px 24px',
              borderRadius: 2,
              maxWidth: '500px',
            }}
          >
            <SentimentVeryDissatisfiedIcon 
              sx={{ 
                color: '#FE9E19', 
                fontSize: 28, 
                mr: 2 
              }} 
            />
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ 
                fontSize: '1.1rem',
                textAlign: 'left',
              }}
            >
              {t('pageNotFoundMessage', "The page you're looking for doesn't exist or you don't have permission to view it.")}
            </Typography>
          </Box>

          <Button
            component={Link}
            to="/admin/overview"
            variant="contained"
            className="!bg-white"
            startIcon={<HomeIcon />}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              backgroundColor: '#FE9E19',
              color: 'white',
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '1rem',
              boxShadow: '0 4px 10px rgba(254, 158, 25, 0.3)',
              '&:hover': {
                backgroundColor: '#F4762A',
                boxShadow: '0 6px 15px rgba(254, 158, 25, 0.4)',
                color: '#FE9E19',
              },
              transition: 'all 0.3s ease',
            }}
          >
            {t('returnToDashboard', 'Return to Dashboard')}
          </Button>
        </Box>
      </Paper>
    </DashboardLayout>
  );
};

export default NotFoundPage;