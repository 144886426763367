import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Typography } from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import { toast } from 'react-toastify';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import WorkIcon from '@mui/icons-material/Work';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdminFilter from './AdminFilter';
import CreateWorkerDialog from './CreateWorkerDialog';
import UpdateWorkerDialog from './UpdateWorkerDialog';
import TableLoading from 'components/TableLoading/TableLoadign';
import axios from 'axios';
import { getWorkers } from '../services/serverApi.task';
import { Chip, Stack, IconButton, Popover, Tooltip, Avatar, Box } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer } from 'react-toastify';
import RoleBasedRenderer from 'components/wrappers/RoleBasedRenderer';
import { useSelector } from 'react-redux';
import { hasAdminAccess, ROLES } from 'utils/rbac.utils';

const ALL_MODULES = {
    PMS: { label: 'PMS', price: 100 },
    WhGuest: { label: 'WhatsApp Guest', price: 100 },
    WhStaff: { label: 'WhatsApp Staff', price: 100 },
    WhPMS: { label: 'WhatsApp PMS', price: 100 },
    RMS: { label: 'Dynamic Pricing', price: 100 },
    MessageAndReview: { label: 'Message & Review', price: 100 },
};

const PublicWorker = () => {
    const [workers, setWorkers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [deletedFilter, setDeletedFilter] = useState('false');
    const [bannedFilter, setBannedFilter] = useState('false');
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [permissionsAnchorEl, setPermissionsAnchorEl] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState(null);
    const [searchText, setSearchText] = useState('');
    const { user } = useSelector((state) => state.auth);
    const isAdmin = user && hasAdminAccess(user.role);

    useEffect(() => {
        fetchWorkers();
    }, [page, limit, deletedFilter, bannedFilter, searchText]);

    const fetchWorkers = async () => {
        setIsLoading(true);
        try {
            const response = await getWorkers({
                page,
                limit,
                deleted: deletedFilter === 'true',
                banned: bannedFilter === 'true',
                search_text: searchText
            });

            if (response && response.data) {
                setWorkers(response.data);
                setTotalCount(response.total || 0);
                setIsNextDisabled((page + 1) * limit >= response.total);
            } else {
                setWorkers([]);
                setTotalCount(0);
                setIsNextDisabled(true);
            }
        } catch (error) {
            console.error('Error fetching workers:', error);
            setWorkers([]);
            setTotalCount(0);
            setIsNextDisabled(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onWorkerCreated = async (newWorker) => {
        toast.success('Worker invited successfully');
        await fetchWorkers();
    };

    const onWorkerUpdated = (updatedWorker) => {
        setWorkers(prevWorkers =>
            prevWorkers.map(worker =>
                worker._id === updatedWorker._id ? {
                    ...worker,
                    ...updatedWorker,
                    subscriptionModules: updatedWorker.subscriptionModules,
                    permissions: updatedWorker.permissions
                } : worker
            )
        );
        toast.success('Worker updated successfully');
    };

    const handleUpdate = (worker) => {
        setSelectedWorker(worker);
        setOpenUpdateDialog(true);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const handleStatusClick = (event, rowData) => {
        setStatusAnchorEl(event.currentTarget);
        setSelectedStatus(rowData);
    };

    const handleStatusClose = () => {
        setStatusAnchorEl(null);
        setSelectedStatus(null);
    };

    const handlePermissionsClick = (event, rowData) => {
        setPermissionsAnchorEl(event.currentTarget);
        setSelectedPermissions(rowData);
    };

    const handlePermissionsClose = () => {
        setPermissionsAnchorEl(null);
        setSelectedPermissions(null);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    };

    const baseColumns = [
        {
            field: 'firstName',
            header: 'First Name',
            body: (rowData) => <div className="font-medium">{rowData.firstName}</div>,
        },
        {
            field: 'lastName',
            header: 'Last Name',
            body: (rowData) => <div>{rowData.lastName}</div>,
        },
        {
            field: 'email',
            header: 'Email',
            body: (rowData) => <div>{rowData.email}</div>,
        },
        {
            field: 'phone',
            header: 'Phone',
            body: (rowData) => <div>{rowData.phone}</div>,
        },
        {
            field: 'whatsapp',
            header: 'WhatsApp',
            body: (rowData) => <div>{rowData.whatsapp || '-'}</div>,
        },
    ];

    const ownerColumn = {
        field: 'owner',
        header: 'Owner',
        body: (rowData) => {
            if (!rowData.owner) return <div className="text-gray-400">-</div>;
            
            return (
                <div className="flex items-center">
                    <div className="flex flex-col">
                        <span className="text-sm font-medium">
                            {rowData.owner?.firstName} {rowData.owner?.lastName}
                        </span>
                        {rowData.owner?.email && (
                            <span className="text-xs text-gray-500">
                                {rowData.owner.email}
                            </span>
                        )}
                    </div>
                </div>
            );
        },
    };

    const remainingColumns = [
        {
            field: 'permissions',
            header: 'Permissions',
            body: (rowData) => (
                <div className="flex items-center">
                    {rowData.permissions?.length > 0 ? (
                        <div className="flex flex-wrap gap-1 items-center">
                            <div className="flex -space-x-1">
                                {rowData.permissions.slice(0, 2).map((perm, index) => (
                                    <Avatar
                                        key={perm._id || index}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            fontSize: '0.75rem',
                                            bgcolor: 'rgba(0, 180, 180, 0.1)',
                                            color: '#00b4b4',
                                            fontWeight: 600,
                                            border: '1px solid rgba(0, 180, 180, 0.2)'
                                        }}
                                    >
                                        {perm.module?.charAt(0)}
                                    </Avatar>
                                ))}
                            </div>
                            
                            {rowData.permissions.length > 2 && (
                                <Typography 
                                    variant="caption" 
                                    className="text-gray-500 font-medium ml-1"
                                >
                                    +{rowData.permissions.length - 2} more
                                </Typography>
                            )}
                            
                            <IconButton
                                size="small"
                                onClick={(e) => handlePermissionsClick(e, rowData)}
                                className="!text-medium-aquamarine ml-1"
                            >
                                <RemoveRedEye fontSize="small" />
                            </IconButton>
                        </div>
                    ) : (
                        <span className="text-gray-400 text-sm">No permissions</span>
                    )}
                </div>
            ),
        },
        {
            field: 'status',
            header: 'Status',
            body: (rowData) => (
                <div className="flex items-center">
                    <IconButton
                        size="small"
                        onClick={(e) => handleStatusClick(e, rowData)}
                        className="!text-medium-aquamarine"
                    >
                        <RemoveRedEye fontSize="small" />
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'action',
            header: 'Action',
            body: (rowData) => (
                <button
                    className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                    onClick={() => handleUpdate(rowData)}
                >
                    <EditOffIcon className="text-white" />
                </button>
            ),
        },
    ];

    const columns = isAdmin 
        ? [...baseColumns, ownerColumn, ...remainingColumns]
        : [...baseColumns, ...remainingColumns];

    return (
        <div className="card p-4">
            <ToastContainer position="top-right" autoClose={3000} />
            <div className="flex items-center justify-between mb-4">
                <Typography
                    variant="h4"
                    component="h1"
                    className="!flex !items-center !gap-1 !md:text-2xl !text-lg"
                >
                    <WorkIcon className="!md:text-3xl !text-xl" />
                    Worker Management
                </Typography>
                <AdminFilter
                    deletedFilter={deletedFilter}
                    bannedFilter={bannedFilter}
                    onDeletedChange={setDeletedFilter}
                    onBannedChange={setBannedFilter}
                />
            </div>

            <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
                <div className="w-full md:flex-grow md:mr-4">
                    <div className="flex w-full bg-white border rounded-md h-8 md:h-10">
                        <input
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search by name or email..."
                            className="p-2 text-xs md:text-xs outline-none rounded-md w-full"
                        />
                        <div className="px-2 flex items-center">
                            <SearchIcon className="!w-3 !h-3 md:!w-4 md:!h-4" />
                        </div>
                    </div>
                </div>

                <Button
                    variant="contained"
                    onClick={() => setOpenCreateDialog(true)}
                    className="w-full md:w-auto px-2 md:px-2.5 py-1 md:py-1.5 !text-xs !bg-medium-aquamarine text-white !rounded-md"
                >
                    Invite Worker
                </Button>
            </div>

            <div className="bg-white">
                {isLoading ? (
                    <TableLoading />
                ) : workers.length > 0 ? (
                    <GlobalTable
                        data={workers}
                        columns={columns}
                        page={page}
                        hasPagination={true}
                        onPageChange={handlePageChange}
                        isNextDisabled={isNextDisabled}
                        limit={limit}
                        onLimitChange={handleLimitChange}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        totalCount={totalCount}
                    />
                ) : (
                    <div className="flex flex-col justify-center items-center h-64">
                        <Typography variant="body1" color="textSecondary">
                            No workers found
                        </Typography>
                    </div>
                )}
            </div>

            <CreateWorkerDialog
                open={openCreateDialog}
                onClose={() => setOpenCreateDialog(false)}
                onWorkerCreated={onWorkerCreated}
            />

            {selectedWorker && (
                <UpdateWorkerDialog
                    open={openUpdateDialog}
                    onClose={() => setOpenUpdateDialog(false)}
                    worker={selectedWorker}
                    onWorkerUpdated={onWorkerUpdated}
                    modules={ALL_MODULES}
                />
            )}

            <Popover
                open={Boolean(statusAnchorEl)}
                anchorEl={statusAnchorEl}
                onClose={handleStatusClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {selectedStatus && (
                    <div className="p-4 space-y-2 !bg-white">
                        <Stack direction="column" spacing={1}>
                            <Chip
                                size="small"
                                label={selectedStatus.status}
                                color={selectedStatus.status === 'active' ? 'success' : 'default'}
                                variant="outlined"
                            />
                            <Chip
                                size="small"
                                label={selectedStatus.banned ? 'Banned' : 'Not Banned'}
                                color={selectedStatus.banned ? 'error' : 'success'}
                                variant="outlined"
                            />
                            <Chip
                                size="small"
                                label={selectedStatus.deleted ? 'Deleted' : 'Not Deleted'}
                                color={selectedStatus.deleted ? 'default' : 'success'}
                                variant="outlined"
                            />
                        </Stack>
                    </div>
                )}
            </Popover>
            
            <Popover 
                open={Boolean(permissionsAnchorEl)}
                anchorEl={permissionsAnchorEl}
                onClose={handlePermissionsClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',

                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        p: 2,
                        minWidth: 250,
                        maxWidth: 320,
                        boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
                        borderRadius: '8px',
                        background: 'white'

                    }
                }}
            >
                {selectedPermissions && (
                    <div>
                        <Typography 
                            variant="subtitle2" 
                            className="!text-gray-700 !font-semibold !mb-2"
                        >
                            {selectedPermissions.firstName} s Permissions
                        </Typography>
                        
                        {selectedPermissions.permissions?.length > 0 ? (
                            <Stack 
                                direction="row" 
                                spacing={1} 
                                sx={{ 
                                    flexWrap: 'wrap', 
                                    gap: '8px !important',
                                    mt: 1
                                }}
                            >
                                {selectedPermissions.permissions.map((perm) => (
                                    <Box
                                        key={perm._id}
                                        sx={{
                                            position: 'relative',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            backgroundColor: 'white',
                                            borderRadius: '8px',
                                            py: 0.75,
                                            px: 1.5,
                                            transition: 'all 0.2s ease',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
                                            border: '1px solid rgba(0, 180, 180, 0.15)',
                                            '&:hover': {
                                                transform: 'translateY(-1px)',
                                                boxShadow: '0 4px 8px rgba(0,180,180,0.1)',
                                            }
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                fontSize: '0.75rem',
                                                bgcolor: 'rgba(0, 180, 180, 0.1)',
                                                color: '#00b4b4',
                                                fontWeight: 600
                                            }}
                                        >
                                            {perm.module?.charAt(0)}
                                        </Avatar>
                                        <Typography
                                            sx={{
                                                fontSize: '0.75rem',
                                                fontWeight: 500,
                                                color: '#2d3748',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {perm.module}
                                        </Typography>
                                        
                                        {perm.actions?.length > 0 && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    width: 3,
                                                    height: 3,
                                                    borderRadius: '50%',
                                                    backgroundColor: '#00b4b4',
                                                    right: 6,
                                                    top: 6
                                                }}
                                            />
                                        )}
                                    </Box>
                                ))}
                            </Stack>
                        ) : (
                            <Typography className="text-sm text-gray-500">
                                No permissions assigned
                            </Typography>
                        )}
                        
                        {selectedPermissions.permissions?.length > 0 && (
                            <div className="mt-3">
                                <Typography variant="caption" className="text-gray-500 italic">
                                    Click on a permission to see its details
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
            </Popover>
        </div>
    );
};

export default PublicWorker;