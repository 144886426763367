import React, { useState, useEffect } from 'react';
import { 
  TextField, Button, Accordion, AccordionSummary, AccordionDetails, 
  Typography, IconButton, Switch, Select, MenuItem, FormControl, InputLabel,
  Divider 
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageUpload from 'components/CustomUpload/UploadV2';
import { saveAs } from 'file-saver';
import PdfViewerModal from '../PdfViewerModal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { getAccessTypes } from 'features/listing/services/serverApi.listing';
import defaultImage from 'assets/images/image_placeholder.jpg';

const handleImageError = (e) => {
  e.target.src = defaultImage;
};

const AccessDetails = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [accessTypes, setAccessTypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');

  useEffect(() => {
    fetchAccessTypes();
  }, []);

  const fetchAccessTypes = async () => {
    try {
      const response = await getAccessTypes();
      setAccessTypes(response.data.data);
    } catch (error) {
      console.error('Error fetching access types:', error);
    }
  };
  const handleAddAccessType = (accessType) => {
    let currentAccessDetails = values.accessDetails || [];
    
    if (!Array.isArray(currentAccessDetails)) {
      currentAccessDetails = [];
    }
  
    const newAccessDetail = {
      accessTypeId: accessType._id,
      enable: true,
      name: '', 
      access: [
        {
          type: 'Name',
          txt: ''
        },
        {
          type: 'Description',
          txt: ''
        },
        {
          type: 'Url',
          txt: ''
        },
        {
          type: 'Access Code',
          name: '',
          code: '',
          url: ''
        },
        {
          type: 'Instruction',
          txt: ['']
        }
      ]
    };
  
    setFieldValue('accessDetails', [...currentAccessDetails, newAccessDetail]);
  };

  const handleAddNewEntry = (index, type) => {
    const newAccessDetails = [...values.accessDetails];
    const currentAccess = newAccessDetails[index].access;
    
    const newEntry = type === 'Access Code' 
      ? { type, name: '', code: '', url: '' }
      : type === 'Instruction'
      ? { type, txt: [''] } 
      : { type, txt: '' };
    
    const lastIndexOfType = currentAccess.map(item => item.type).lastIndexOf(type);
    
    if (lastIndexOfType === -1) {
      currentAccess.push(newEntry);
    } else {
      currentAccess.splice(lastIndexOfType + 1, 0, newEntry);
    }
    
    setFieldValue('accessDetails', newAccessDetails);
  };

  const handleDeleteAccessType = (indexToDelete) => {
    const newAccessDetails = values.accessDetails.filter((_, index) => index !== indexToDelete);
    setFieldValue('accessDetails', newAccessDetails);
  };

  const handleUrlField = (index, accessIndex, fileUrl) => {
    const newAccessDetails = [...values.accessDetails];
    newAccessDetails[index].access[accessIndex].txt = fileUrl;
    setFieldValue('accessDetails', newAccessDetails);
  };

  const fieldTypes = [
    { type: 'Name', label: 'Name Field' },
    { type: 'Description', label: 'Description Field' },
    { type: 'Url', label: 'URL Field' },
    { type: 'Access Code', label: 'Access Code' },
    { type: 'Instruction', label: 'Instruction Field' }
  ];

  const downloadPDF = (url) => {
    saveAs(url, 'access_file.pdf');
  };

  const openPdfModal = (url) => {
    setCurrentPdfUrl(url);
    setModalOpen(true);
  };

  const renderAccessDetailPanel = (accessDetail, index) => {
    const accessType = accessTypes.find(type => type._id === accessDetail.accessTypeId);
    
    const handleDeleteSection = (accessIndex) => {
      const newAccessDetails = [...values.accessDetails];
      newAccessDetails[index].access.splice(accessIndex, 1);
      setFieldValue('accessDetails', newAccessDetails);
    };
    
    return (
      <Accordion
        key={index}
        expanded={expandedPanel === index}
        onChange={(event, isExpanded) => setExpandedPanel(isExpanded ? index : null)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="!text-white" />}
          className="!bg-[#6fd1bd] !text-white !min-h-[48px]"
        >
          <div className="flex justify-between items-center w-full">
            <Typography variant="subtitle2">{accessType?.name || accessType?.type}</Typography>
            <IconButton 
              size="small" 
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteAccessType(index);
              }}
              className="!text-white"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails className="!p-2">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 mt-3">
              <TextField
                size="small"
                label="Access Type Name"
                value={accessDetail.name || ''}
                onChange={(e) => {
                  const newAccessDetails = [...values.accessDetails];
                  newAccessDetails[index].name = e.target.value;
                  setFieldValue('accessDetails', newAccessDetails);
                }}
              />
              <Switch
                size="small"
                checked={accessDetail.enable}
                onChange={(e) => {
                  const newAccessDetails = [...values.accessDetails];
                  newAccessDetails[index].enable = e.target.checked;
                  setFieldValue('accessDetails', newAccessDetails);
                }}
              />
            </div>

            <FormControl size="small" className="mb-2 w-[9.5rem]">
              <InputLabel>Add Field</InputLabel>
              <Select
                value=""
                label="Add Field"
                onChange={(e) => handleAddNewEntry(index, e.target.value)}
              >
                {fieldTypes.map((field) => (
                  <MenuItem key={field.type} value={field.type}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {accessDetail.access.map((access, accessIndex) => (
              <div key={accessIndex} className="border-b pb-2 mb-2 last:border-b-0">
                <div className="flex justify-between items-center mb-1">
                  <Typography variant="caption" className="text-gray-600">{access.type}</Typography>
                  <IconButton 
                    size="small" 
                    onClick={() => handleDeleteSection(accessIndex)}
                    className="!text-red-500"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
                {access.type === 'Access Code' ? (
                  <div className="grid grid-cols-2 gap-2">
                    <TextField size="small" label="Name" value={access.name}
                      onChange={(e) => {
                        const newAccessDetails = [...values.accessDetails];
                        newAccessDetails[index].access[accessIndex].name = e.target.value;
                        setFieldValue('accessDetails', newAccessDetails);
                      }}
                    />
                    <TextField size="small" label="Code" value={access.code}
                      onChange={(e) => {
                        const newAccessDetails = [...values.accessDetails];
                        newAccessDetails[index].access[accessIndex].code = e.target.value;
                        setFieldValue('accessDetails', newAccessDetails);
                      }}
                    />
                    <div className="col-span-2">
                      <TextField
                        size="small"
                        fullWidth
                        label="Enter URL"
                        value={access.url || ''}
                        onChange={(e) => {
                          const newAccessDetails = [...values.accessDetails];
                          newAccessDetails[index].access[accessIndex].url = e.target.value;
                          setFieldValue('accessDetails', newAccessDetails);
                        }}
                      />
                    </div>
                  </div>
                ) : access.type === 'Instruction' ? (
                  <div className="flex flex-col gap-1">
                    {Array.isArray(access.txt) && access.txt.map((instruction, i) => (
                      <div key={i} className="flex gap-2 items-center">
                        <TextField 
                          size="small" 
                          fullWidth 
                          value={instruction || ''} 
                          placeholder={`Instruction ${i + 1}`}
                          onChange={(e) => {
                            const newAccessDetails = [...values.accessDetails];
                            newAccessDetails[index].access[accessIndex].txt[i] = e.target.value;
                            setFieldValue('accessDetails', newAccessDetails);
                          }}
                        />
                        <IconButton 
                          size="small"
                          onClick={() => {
                            const newAccessDetails = [...values.accessDetails];
                            newAccessDetails[index].access[accessIndex].txt.splice(i, 1);
                            setFieldValue('accessDetails', newAccessDetails);
                          }}
                          className="!text-gray-500"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    ))}
                    <Button 
                      size="small" 
                      startIcon={<AddCircleIcon />}
                      onClick={() => {
                        const newAccessDetails = [...values.accessDetails];
                        newAccessDetails[index].access[accessIndex].txt.push('');
                        setFieldValue('accessDetails', newAccessDetails);
                      }}
                    >
                      Add Line
                    </Button>
                  </div>
                ) : access.type === 'Url' ? (
                  <div className="flex flex-col gap-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Enter URL"
                      value={access.txt || access.uploadedFile || ''}
                      onChange={(e) => {
                        const newAccessDetails = [...values.accessDetails];
                        newAccessDetails[index].access[accessIndex].txt = e.target.value;
                        newAccessDetails[index].access[accessIndex].uploadedFile = e.target.value;
                        setFieldValue('accessDetails', newAccessDetails);
                      }}
                    />
                    <Divider className="my-2">OR</Divider>
                    {(access.uploadedFile || access.txt) ? (
                      <div className="w-full">
                        {(access.uploadedFile || access.txt).endsWith('.pdf') ? (
                          <div className="flex gap-4 items-center justify-center mt-2">
                            <Button
                              variant="contained"
                              className="!bg-medium-aquamarine !text-white"
                              onClick={() => openPdfModal(access.uploadedFile || access.txt)}
                            >
                              <RemoveRedEyeIcon />
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => downloadPDF(access.uploadedFile || access.txt)}
                              className="!text-gray-500 !bg-white !border !border-gray-500"
                            >
                              <DownloadingIcon />
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                const newAccessDetails = [...values.accessDetails];
                                newAccessDetails[index].access[accessIndex].uploadedFile = '';
                                newAccessDetails[index].access[accessIndex].txt = '';
                                setFieldValue('accessDetails', newAccessDetails);
                              }}
                              className="!text-red-500 !bg-white !border !border-red-500"
                            >
                              <DeleteIcon />
                            </Button>
                          </div>
                        ) : (
                          <div className="relative w-32 h-20">
                            <img
                              src={access.uploadedFile || access.txt}
                              alt="Access Image"
                              onError={handleImageError}
                              className="w-full h-full object-cover rounded"
                            />
                            <IconButton
                              size="small"
                              className="!absolute !-top-2 !-right-2 !bg-white !p-0.5"
                              onClick={() => {
                                const newAccessDetails = [...values.accessDetails];
                                newAccessDetails[index].access[accessIndex].uploadedFile = '';
                                newAccessDetails[index].access[accessIndex].txt = '';
                                setFieldValue('accessDetails', newAccessDetails);
                              }}
                            >
                              <DeleteIcon fontSize="small" className="!text-red-500" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ) : (
                      <ImageUpload
                        fieldName={`accessDetails.${index}.access.${accessIndex}.uploadedFile`}
                        setFieldValue={(_, value) => {
                          const newAccessDetails = [...values.accessDetails];
                          newAccessDetails[index].access[accessIndex].uploadedFile = value;
                          newAccessDetails[index].access[accessIndex].txt = value;
                          setFieldValue('accessDetails', newAccessDetails);
                        }}
                        folder="listing"
                        accept=".pdf,image/*"
                        label="Upload File"
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex">
                    <TextField size="small" fullWidth value={access.txt}
                      onChange={(e) => {
                        const newAccessDetails = [...values.accessDetails];
                        newAccessDetails[index].access[accessIndex].txt = e.target.value;
                        setFieldValue('accessDetails', newAccessDetails);
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className="w-full p-2">
      <FormControl size="small" fullWidth className="mb-2">
        <InputLabel>Add Access Type</InputLabel>
        <Select
          value=""
          label="Add Access Type"
          onChange={(e) => {
            const selectedType = accessTypes.find(type => type._id === e.target.value);
            if (selectedType) handleAddAccessType(selectedType);
          }}
        >
          {accessTypes.map((type) => (
            <MenuItem key={type._id} value={type._id}>
              {type.type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className="flex flex-col gap-2">
        {Array.isArray(values.accessDetails) && values.accessDetails.map((accessDetail, index) => 
          renderAccessDetailPanel(accessDetail, index)
        )}
      </div>

      <PdfViewerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        pdfUrl={currentPdfUrl}
      />
    </div>
  );
};

export default AccessDetails;