import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import GuestForm from './GuestForm';
import { useTranslation } from 'react-i18next';

function RegisterGuestModal({ open, handleClose, handleFormSubmit }) {
  const { t } = useTranslation('common');
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle className="!text-gray-800">
        {t('Register Guest')}
      </DialogTitle>
      <DialogContent className="p-3">
        <GuestForm
          initialData={{}}
          onSubmit={handleFormSubmit}
          showUpdateButton={false}
          id="register-guest-form"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="!text-gray-500">
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          form="register-guest-form"
          className="text-white !bg-medium-aquamarine"
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RegisterGuestModal;
