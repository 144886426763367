import React, { useState, useEffect } from 'react';
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Switch,
} from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { toast } from 'react-toastify';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import MailTemplateContainer from './MailTemplateContainer';
import {
  getMailTemplate,
  createMailTemplate,
  updateMailTemplate,
  deleteMailTemplate,
} from '../services/serverApi.adminConfig';

const MailTemplates = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [error, setError] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await getMailTemplate();
      if (response.data.success) {
        setTemplates(response.data.data);
      } else {
        throw new Error('Failed to fetch templates');
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleEnabled = async (templateId, currentEnabled) => {
    try {
      const currentTemplate = templates.find(template => template._id === templateId);
      if (!currentTemplate) {
        throw new Error('Template not found');
      }

      const updatedTemplateData = {
        ...currentTemplate,
        enabled: !currentEnabled
      };

      delete updatedTemplateData._id;

      await updateMailTemplate(templateId, updatedTemplateData);

      setTemplates(prevTemplates =>
        prevTemplates.map(template =>
          template._id === templateId
            ? { ...template, enabled: !template.enabled }
            : template
        )
      );

      toast.success(`Template ${!currentEnabled ? 'enabled' : 'disabled'} successfully`);
    } catch (error) {
      console.error('Error updating template status:', error);
      toast.error('Failed to update template status');
    }
  };
  const handleOpenForm = (templateId = null) => {
    setSelectedTemplateId(templateId);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setSelectedTemplateId(null);
    setShowForm(false);
  };

  const handleSubmit = async (formData,finalTemplateId = null) => {
    try {
      const dataToSubmit = {
        ...formData,
        enabled: formData.enabled !== undefined ? formData.enabled : true
      };

      if (finalTemplateId) {
        await updateMailTemplate(finalTemplateId, dataToSubmit);
        toast.success('Template updated successfully');
      } else {
        await createMailTemplate(dataToSubmit);
        toast.success('Template created successfully');
      }
      handleCloseForm();
      await fetchTemplates();
    } catch (error) {
      console.error('Error saving template:', error);
      const errorMessage = error.response?.data?.errors?.[0]?.message || 'Failed to save template';
      toast.error(errorMessage);
    }
  };

  const handleDeleteClick = (templateId) => {
    setTemplateToDelete(templateId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setTemplateToDelete(null);
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteMailTemplate(templateToDelete);
      setTemplates(prevTemplates =>
        prevTemplates.filter(template => template._id !== templateToDelete)
      );
      toast.success('Template deleted successfully');
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('Failed to delete template');
    } finally {
      setDeleteConfirmOpen(false);
      setTemplateToDelete(null);
    }
  };

  const columns = [
    {
      header: "Name",
      body: (rowData) => (
        <Tooltip title={rowData.messageName || 'No Name'} placement="right">
          <span className="cursor-pointer">{rowData.messageName || '-'}</span>
        </Tooltip>
      )
    },
    {
      header: "Status",
      body: (rowData) => (
        <Tooltip title={rowData.enabled ? 'Disable Template' : 'Enable Template'}>
          <Switch
            checked={rowData.enabled}
            onChange={() => handleToggleEnabled(rowData._id, rowData.enabled)}
            color="primary"
            className="!text-medium-aquamarine"
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#00b4b4',
                '&:hover': {
                  backgroundColor: 'rgba(0, 180, 180, 0.08)',
                },
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#00b4b4',
              },
            }}
          />
        </Tooltip>
      )
    },
    {
      header: "Type",
      body: (rowData) => (
        <Tooltip title={rowData.type || 'No Type'} placement="right">
          <span className="cursor-pointer">{rowData.type || '-'}</span>
        </Tooltip>
      )
    },
    {
      header: "Description",
      body: (rowData) => (
        <Tooltip title={rowData.description || 'No Description'} placement="right">
          <span className="cursor-pointer">{rowData.description || '-'}</span>
        </Tooltip>
      )
    },
    {
      header: "Content (French)",
      body: (rowData) => (
        <Tooltip
          title={rowData.content?.trim() || 'No French Content'}
          placement="right"
        >
          <div className="max-w-md truncate cursor-pointer">
            {rowData.content?.trim() ?
              rowData.content :
              <span className="text-red-500 font-bold">No French Content</span>
            }
          </div>
        </Tooltip>
      )
    },
    {
      header: "Content (English)",
      body: (rowData) => (
        <Tooltip
          title={rowData.contentEng?.trim() || 'No English Content'}
          placement="right"
        >
          <div className="max-w-md truncate cursor-pointer">
            {rowData.contentEng?.trim() ?
              rowData.contentEng :
              <span className="text-red-500 font-bold">No English Content</span>
            }
          </div>
        </Tooltip>
      )
    },
    {
      header: "Language",
      body: (rowData) => {
        const content = rowData.content?.trim();
        const contentEng = rowData.contentEng?.trim();

        let langCount = 0;
        if (content && contentEng) {
          langCount = 2;
        } else if (content || contentEng) {
          langCount = 1;
        }

        return (
          <Tooltip title={`${langCount} language(s)`} placement="right">
            <span className="cursor-pointer">{langCount}</span>
          </Tooltip>
        );
      }
    },
    {
      header: 'Action',
      body: (rowData) => (
        <div className="flex gap-1">
          <Tooltip title="Edit Template">
            <button
              className="px-2 py-1 bg-medium-aquamarine !rounded-md cursor-pointer"
              onClick={() => handleOpenForm(rowData._id)}
            >
              <EditOffIcon className="text-white" />
            </button>
          </Tooltip>
          <Tooltip title="Delete Template">
            <button
              className="px-2 py-1 bg-red-500 !rounded-md cursor-pointer"
              onClick={() => handleDeleteClick(rowData._id)}
            >
              <DeleteSweepIcon className="text-white" />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  if (error) {
    return (
      <div className="w-full h-64 flex justify-center items-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="card p-4">
      {showForm ? (

        <MailTemplateContainer
          templateId={selectedTemplateId}
          onSubmit={handleSubmit}
          onCancel={handleCloseForm}
          templates={templates}
        />
      ) : (
        <>
          <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
            Mail Template Management
          </Typography>
          <div className="mb-4">
            <Button
              onClick={() => handleOpenForm()}
              className="float-right !bg-medium-aquamarine text-white"
            >
              Create New Template
            </Button>
          </div>
          <div>
            <div className="w-full">
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <CircularProgress style={{ color: '#00b4b4' }} />
                </div>
              ) : (
                <GlobalTable
                  data={templates}
                  columns={columns}
                  hasPagination={false}
                />
              )}
            </div>
          </div>
        </>
      )}

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Template'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this template? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} className="!text-gray-500">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            autoFocus
            className="!bg-red-500 !text-white"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MailTemplates;
