import React, { useState, useEffect } from 'react';
import {
  getCountries,
  getCities,
} from 'features/reservation/services/serverApi.reservation';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ta } from 'date-fns/locale';

const ReservationDetails = ({ formik, listingOptions }) => {
  const { t } = useTranslation('common');
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getCountries();
        setCountries(countriesData.data);

        const citiesData = await getCities();
        setCities(citiesData.data);

        // const defaultCountry = countriesData.data.find(country => country.name === 'Morocco');
        // const defaultCity = citiesData.data.find(city => city.name === 'Rabat');

        // if (defaultCountry && !formik.values.guestCountry) {
        //     formik.setFieldValue('guestCountry', defaultCountry.name);
        // }
        // if (defaultCity && !formik.values.guestCity) {
        //     formik.setFieldValue('guestCity', defaultCity.name);
        // }
      } catch (error) {
        console.error('Error fetching country and city data:', error);
      }
    };

    fetchData();
  }, []);

  const renderInputField = (
    label,
    name,
    type = 'text',
    additionalProps = {},
  ) => {
    const today = new Date().toISOString().split('T')[0];
    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <input
          type={type}
          name={name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          autoComplete="off"
          {...additionalProps}
          min={type === 'date' ? today : undefined}
          list={
            name === 'guestCountry'
              ? 'countries'
              : name === 'guestCity'
              ? 'cities'
              : undefined
          }
          className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            fontSize: 'small',
          }}
        />
        {name === 'guestCountry' && (
          <datalist id="countries" autoComplete="off">
            {countries.map((country) => (
              <option key={country.id} value={country.name} />
            ))}
          </datalist>
        )}
        {name === 'guestCity' && (
          <datalist id="cities" autoComplete="off">
            {cities.map((city) => (
              <option key={city.id} value={city.name} />
            ))}
          </datalist>
        )}
        {formik.touched[name] && formik.errors[name] && (
          <div className="text-red-500 text-sm">{formik.errors[name]}</div>
        )}
      </div>
    );
  };

  const renderSelectField = (label, name, options, defaultValue) => (
    <div className="flex flex-col">
      <label className="font-bold text-[#676a6c] text-sm mb-2">
        {t(label)}
      </label>
      <select
        name={name}
        onChange={(e) => {
          if (
            name === 'confirmedCheckInTime' ||
            name === 'confirmedCheckOutTime'
          ) {
            formik.setFieldValue(name, e.target.value === 'true');
          } else {
            formik.handleChange(e);
          }
        }}
        onBlur={formik.handleBlur}
        value={
          name === 'confirmedCheckInTime' || name === 'confirmedCheckOutTime'
            ? formik.values[name].toString()
            : formik.values[name] || defaultValue || ''
        }
        className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
        style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          fontSize: 'small',
        }}
      >
        <option value="">
          {t('Select')} {t(label)}
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={[
              'CancelledByAdmin',
              'CancelledByCustomer',
              'CancelledByOta',
              'CancelledAfterFailedPayment',
              'OtherCancellation',
            ].includes(option.value)}
          >
            {t(option.label)}
          </option>
        ))}
      </select>
      {formik.touched[name] && formik.errors[name] && (
        <div className="text-red-500 text-sm">{formik.errors[name]}</div>
      )}
    </div>
  );

  const renderTimeSelectField = (label, name) => {
    const currentHour = new Date().getHours();
    const today = new Date().toISOString().split('T')[0];
    const selectedDate = formik.values.arrivalDate || today;
    const isToday = selectedDate === today;
    const hours = Array.from({ length: 24 }, (_, i) => i);

    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <select
          name={name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            fontSize: 'small',
          }}
        >
          <option value="">{t('Select time')}</option>
          {hours.map((hour) => (
            <option
              key={hour}
              value={hour}
              disabled={isToday && hour < currentHour}
            >
              {`${hour}:00`}
            </option>
          ))}
        </select>
        {formik.touched[name] && formik.errors[name] && (
          <div className="text-red-500 text-sm">{formik.errors[name]}</div>
        )}
      </div>
    );
  };

  const renderLocationField = (label, name, options) => {
    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <Autocomplete
          options={options || []}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option?.name || '';
          }}
          value={formik.values[name] || null}
          onChange={(event, newValue) => {
            formik.setFieldValue(
              name,
              typeof newValue === 'string' ? newValue : newValue?.name || '',
            );
          }}
          onInputChange={(event, newInputValue) => {
            if (event?.type === 'change') {
              formik.setFieldValue(name, newInputValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              error={formik.touched[name] && Boolean(formik.errors[name])}
              helperText={formik.touched[name] && formik.errors[name]}
              value={formik.values[name] || ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 'small',
                  color: '#676a6c',
                },
              }}
            />
          )}
          freeSolo
          isOptionEqualToValue={(option, value) => {
            if (!option || !value) return false;
            if (typeof value === 'string') return option.name === value;
            return option.name === value.name;
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {renderInputField('Check-in date', 'arrivalDate', 'date')}
        {renderInputField('Check-out date', 'departureDate', 'date')}
        {renderTimeSelectField('Check-in Time', 'checkInTime')}
        {renderTimeSelectField('Check-out Time', 'checkOutTime')}
        {renderSelectField('Confirmed Check-in Time', 'confirmedCheckInTime', [
          { value: 'true', label: 'YES' },
          { value: 'false', label: 'NO' },
        ])}
        {renderSelectField(
          'Confirmed Check-out Time',
          'confirmedCheckOutTime',
          [
            { value: 'true', label: 'YES' },
            { value: 'false', label: 'NO' },
          ],
        )}
        {renderSelectField('Currency', 'currency', [
          { value: 'MAD', label: 'MAD' },
          { value: 'USD', label: 'USD' },
          { value: 'EUR', label: 'EUR' },
          { value: 'GBP', label: 'GBP' },
        ])}
        {renderSelectField(
          'Status',
          'status',
          [
            { value: 'Pending', label: 'Pending' },
            { value: 'Confirmed', label: 'Confirmed' },
            { value: 'Completed', label: 'Completed' },
            { value: 'CancelledByAdmin', label: 'CancelledByAdmin' },
            { value: 'CancelledByCustomer', label: 'CancelledByCustomer' },
            { value: 'CancelledByOta', label: 'CancelledByOta' },
            {
              value: 'CancelledAfterFailedPayment',
              label: 'CancelledAfterFailedPayment',
            },
            { value: 'OtherCancellation', label: 'OtherCancellation' },
          ],
          'Confirmed',
        )}
        {renderSelectField(
          'Payment Status',
          'paymentStatus',
          [
            { value: 'Paid', label: 'Paid' },
            { value: 'UnPaid', label: 'UnPaid' },
          ],
          'UnPaid',
        )}
        {renderSelectField(
          'Time Line',
          'timeLine',
          [
            { value: 'Coming-Today', label: 'Coming-Today' },
            { value: 'Coming-Tomorrow', label: 'Coming-Tomorrow' },
            { value: 'Coming-in2days', label: 'Coming-in2days' },
            { value: 'Coming-in3days', label: 'Coming-in3days' },
            { value: 'Normal', label: 'Normal' },
            { value: 'Departure-Today', label: 'Departure-Today' },
            { value: 'Departure-Tomorrow', label: 'Departure-Tomorrow' },
            { value: 'Inside', label: 'Inside' },
            { value: 'Leave', label: 'Leave' },
            { value: 'One-Night', label: 'One-Night' },
          ],
          'Normal',
        )}
        {renderLocationField('Guest Country', 'guestCountry', countries)}
        {renderLocationField('Guest City', 'guestCity', cities)}
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        {/* Commented out fields */}
        {/* {renderInputField('Children', 'children', 'number')}
                {renderInputField('Infants', 'infants', 'number')}
                {renderInputField('Pets', 'pets', 'number')} */}
      </div>
      {/* <div className="grid grid-cols-1 gap-4 mt-4">
                {renderInputField('Guest Note', 'guestNote', 'textarea')}
                {renderInputField('Host Note', 'hostNote', 'textarea')}
            </div> */}
    </>
  );
};

export default ReservationDetails;
