import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import useAxios from 'axios-hooks';
import axios from 'axios';
import PropTypes from 'prop-types';

const optionShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  value: PropTypes.any
});

const defaultProps = {
  multiple: false,
  label: '',
  placeholder: 'Select...',
  defaultValue: null,
  minInputLength: 0,
  onChange: () => {},
  defaultParams: {}
};

const SelectAutocomplete = ({
  endpoint,
  multiple,
  label,
  value,
  onChange,
  placeholder,
  defaultValue,
  minInputLength,
  defaultParams = {}
}) => {
  const [inputValue, setInputValue] = useState('');
  const cancelTokenRef = useRef(null);
  
  const [{ data, loading }, executeSearch] = useAxios(
    {
      url: endpoint,
      method: 'GET'
    },
    { manual: true }
  );

  const debouncedSearch = useCallback(
    async (searchParams) => {
      try {
        if (cancelTokenRef.current) {
          cancelTokenRef.current.cancel('Operation cancelled due to new request.');
        }

        cancelTokenRef.current = axios.CancelToken.source();

        await executeSearch({
          params: searchParams,
          options: {
            cancelToken: cancelTokenRef.current.token
          }
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Search error:', error);
        }
      }
    },
    [executeSearch]
  );

  useEffect(() => {
    debouncedSearch(defaultParams);
    
    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Component unmounted');
      }
    };
  }, []);

  const options = (data?.data || []).map(item => ({
    id: item._id,
    label: `${item.firstName} ${item.lastName}`,
    value: item._id,
    ...item
  }));
  
  const handleInputChange = async (_, newInputValue) => {
    setInputValue(newInputValue);
    
    if (newInputValue.length >= minInputLength) {
      await debouncedSearch({
        ...defaultParams,
        search: newInputValue
      });
    }
  };

  const renderInputAdornment = (params) => (
    <React.Fragment>
      {loading && <CircularProgress color="inherit" size={20} />}
      {params.InputProps.endAdornment}
    </React.Fragment>
  );

  const renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        endAdornment: renderInputAdornment(params),
      }}
      className="bg-white rounded-md"
    />
  );

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={(_, newValue) => onChange?.(newValue)}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={renderInput}
      loading={loading}
      className="w-full"
    />
  );
};

SelectAutocomplete.propTypes = {
  endpoint: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    optionShape,
    PropTypes.arrayOf(optionShape)
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    optionShape,
    PropTypes.arrayOf(optionShape)
  ]),
  minInputLength: PropTypes.number,
  defaultParams: PropTypes.object
};

SelectAutocomplete.defaultProps = defaultProps;

export default SelectAutocomplete;