import axios from 'axios';
import { getToken, getRefreshToken, setTokens, clearTokens } from '../utils/auth.utils';
import { AUTH_CONFIG } from './auth.config';
import store from '../redux/store';
import { logout, updateToken } from '../redux/slices/AuthSlice';
import { navigationRef } from '../utils/navigation';

axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  async (config) => {
    if (config.url.includes('/login') || config.url.includes('/valid-token-check')) {
      return config;
    }

    const token = getToken();
    const refreshToken = getRefreshToken();
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (refreshToken) {
      config.headers['x-refresh-token'] = refreshToken;
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    if (response.data?.newToken) {
      const newToken = response.data.newToken;
      setTokens(newToken, getRefreshToken());
      store.dispatch(updateToken(newToken));
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      
      console.log('Token updated:', {
        newToken,
        currentHeader: axios.defaults.headers.common['Authorization'],
        storedToken: getToken()
      });
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.data?.newToken) {
      const newToken = error.response.data.newToken;
      setTokens(newToken, getRefreshToken());
      store.dispatch(updateToken(newToken));
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return axios(originalRequest);
    }

    if (error.response?.data?.forceLogout || 
        error.response?.data?.error === "Session expired, please login again") {
      clearTokens();
      store.dispatch(logout());
      navigationRef.current?.navigate(AUTH_CONFIG.LOGOUT_REDIRECT);
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const response = await axios.get(`${AUTH_CONFIG.API_URL}/valid-token-check`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
            'x-refresh-token': getRefreshToken()
          }
        });
        
        if (response.data.newToken) {
          setTokens(response.data.newToken, getRefreshToken());
          originalRequest.headers['Authorization'] = `Bearer ${response.data.newToken}`;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        clearTokens();
        store.dispatch(logout());
        navigationRef.current?.navigate(AUTH_CONFIG.LOGOUT_REDIRECT);
        return Promise.reject(refreshError);
      }
    }
    
    return Promise.reject(error);
  }
);