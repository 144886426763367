import React from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PublicWorker from './components/PublicWorker';

function Worker() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="Task">
                <PublicWorker />
            </div>
        </DashboardLayout>
    );
}

export default Worker;