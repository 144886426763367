import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Analytics from "../components/Analytics";
import RentalActivity from "../components/rentalActivity/RentalActivity";
import Occupancy from "../components/occupancy/Occupancy";
import Exprenses from "../components/expensesAndExtras/Exprenses";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

const tabStyles = {
  container: {
    position: 'relative',
    paddingTop: '48px', 
  },
  tabList: {
    position: 'fixed',
    width: '100%',
    top: '64px', 
    zIndex: 1000,
    backgroundColor: 'white !important',
    borderBottom: 1,
    borderColor: 'divider',
    '& .MuiTabs-indicator': {
      backgroundColor: '#00b4b4',
    },
    '& .MuiTab-root': {
      color: 'black',
      fontWeight: 600,
      fontSize: '0.8rem',
      textTransform: 'capitalize',
    },
    '& .Mui-selected': {
      color: '#00b4b4 !important',
    },
  }
};

function Financial() {
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={0} className="!rounded-none">
                <Box sx={{ width: '100%', typography: 'body1' }} className="card !rounded-none !border-none">
                    <TabContext value={value}>
                        <Box sx={tabStyles.container}>
                            <TabList onChange={handleChange} aria-label="financial tabs" sx={tabStyles.tabList}>
                                <Tab label="Analytics" value="1" />
                                <Tab label="Rental Activity" value="2" />
                                {/* <Tab label="Occupancy Report" value="3" /> */}
                                {/* <Tab label="Expenses & Extras" value="4" /> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1"><Analytics /></TabPanel>
                        <TabPanel value="2"><RentalActivity /></TabPanel>
                        {/* <TabPanel value="3"><Occupancy /></TabPanel> */}
                        {/* <TabPanel value="4"><Exprenses /></TabPanel> */}
                    </TabContext>
                </Box>
            </MDBox>
        </DashboardLayout>
    );
}

export default Financial;

