import React, { useState, useEffect } from 'react';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import FilterSection from './FilterSection';
import ViewToggle from './ViewToggle';
import { getRevenuePerLandR, getListingsPagination, getReservationPagination, getCountries, getListingsList, getCities } from 'features/financial/services/serverApi.financialConfig';
import { Alert } from '@mui/material';
import moment from 'moment';
import { CircularProgress, Box } from '@mui/material';
import ColumnVisibility from './ColumnVisibility';

const formatNumber = (value) => {
  if (value === '_' || value === undefined || value === null) return '_';
  return Number(value).toFixed(2);
};

const ReservationTable = () => {
  const [filters, setFilters] = useState({
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
    dateRange: '',
    checkin: 'arrival',
    listingIds: [],
    cityIds: [],
    countryNames: [],
    channelNames: [],
    active: ['true'],
    status: ['Confirmed', 'Pending', 'Completed']

  });
  const [view, setView] = useState('listing');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [revenueData, setRevenueData] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem('columnVisibility');
    if (savedVisibility) {
      return JSON.parse(savedVisibility);
    }
    return {
      // '_id': false,
      'name': true,
      'totalRevenue': true,
      'fees': true,
      'nightsBooked': true,
      'ownerStayNights': false,
      'totalCheckIns': true,
      'nightsAvailable': true,
      'occupancyRate': true,
      'revenuePerNightBooked': false,
      'revenuePerNightAvailable': false,
      'averageNightlyRate': false,
      'averageRevenuePerStay': false,
      'guestName': true,
      'listingName': true,
      'cancelations': false,
      'cancelationsPercentage': false
    };
  });
  const [listings, setListings] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [staging, setStaging] = useState(JSON.parse(localStorage.getItem('isStaging')) || false);

  useEffect(() => {
    console.log('Current staging value:', staging);
    fetchData();
  }, [view, filters, page, limit, staging]);

  useEffect(() => {
    fetchListingOptions();
    fetchCities();
    fetchCountries();
  }, [staging]);

  useEffect(() => {
    const handleStagingToggle = (event) => {
      setStaging(event.detail);
    };
    window.addEventListener('stagingToggle', handleStagingToggle);

    return () => {
      window.removeEventListener('stagingToggle', handleStagingToggle);
    };
  }, []);

  const fetchListingOptions = async () => {
    try {
      const options = await getListingsList(staging);
      setListings(options);
    } catch (err) {
      console.error('Error fetching listing options:', err);
      setError('Failed to fetch listings. Please try again.');
    }
  };

  const fetchCities = async () => {
    try {
      const response = await getCities(staging);
      if (response.data && Array.isArray(response.data)) {
        const formattedCities = response.data.map(city => ({
          _id: city._id,
          name: city.name
        }));
        setCities(formattedCities);
      } else {
        console.error('Unexpected cities data format:', response.data);
        setCities([]);
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCities([]);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries(0, 100, false);
      if (response.data && Array.isArray(response.data)) {
        const formattedCountries = response.data.map(country => ({
          _id: country.name,
          name: country.name
        }));
        setCountries(formattedCountries);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setCountries([]);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      if (view === 'listing') {
        const listingsResponse = await getListingsPagination(
          page,
          limit,
          filters.listingIds,
          filters.cityIds,
          filters.countryNames,
          staging,
          filters.active,
          true
        );
        const listingsData = listingsResponse.data.data;

        if (listingsData.length === 0) {
          setData([]);
          setTotal(0);
          setRevenueData(null);
          setIsNextDisabled(true);
          return;
        }

        try {
          const itemIds = listingsData.map(listing => listing._id).join(',');
          const revenueResponse = await getRevenuePerLandR(
            filters.from,
            filters.to,
            view,
            itemIds,
            filters.channelNames,
            staging,
            filters.checkin
          );

          if (revenueResponse.data.success) {
            const revenueData = revenueResponse.data.byItems;

            const combinedData = listingsData.map(listing => {
              const revenueItem = revenueData.find(item => item.itemId === listing._id) || {};
              return {
                ...listing,
                totalRevenue: formatNumber(revenueItem.totalRevenue),
                fees: formatNumber(revenueItem.fees),
                nightsBooked: revenueItem.nightsBooked || '_',
                ownerStayNights: revenueItem.ownerStayNights || '_',
                totalCheckIns: revenueItem.totalCheckIns || '_',
                nightsAvailable: revenueItem.nightsAvailable || '_',
                occupancyRate: formatNumber(revenueItem.occupancyRate),
                revenuePerNightBooked: formatNumber(revenueItem.revenuePerNightBooked),
                revenuePerNightAvailable: formatNumber(revenueItem.revenuePerNightAvailable),
                averageNightlyRate: formatNumber(revenueItem.averageNightlyRate),
                averageRevenuePerStay: formatNumber(revenueItem.averageRevenuePerStay),
                cancelations: revenueItem?.cancelations || '_',
                cancelationsPercentage: formatNumber(revenueItem?.cancelationsPercentage)
              };
            });

            setData(combinedData);
            setTotal(formatNumber(revenueResponse.data.totals.totalRevenue));
            setRevenueData(revenueResponse.data);
            setIsNextDisabled(listingsData.length < limit);
          } else {
            setData(listingsData.map(listing => ({
              ...listing,
              totalRevenue: '_',
              fees: '_',
              nightsBooked: '_',
              ownerStayNights: '_',
              totalCheckIns: '_',
              nightsAvailable: '_',
              occupancyRate: '_',
              revenuePerNightBooked: '_',
              revenuePerNightAvailable: '_',
              averageNightlyRate: '_',
              averageRevenuePerStay: '_',
              cancelations: '_',
              cancelationsPercentage: '_'
            })));
            setTotal(0);
            setRevenueData(null);
          }
        } catch (revenueError) {
          if (revenueError.response?.status === 400) {
            setData(listingsData.map(listing => ({
              ...listing,
              totalRevenue: '_',
              fees: '_',
              nightsBooked: '_',
              ownerStayNights: '_',
              totalCheckIns: '_',
              nightsAvailable: '_',
              occupancyRate: '_',
              revenuePerNightBooked: '_',
              revenuePerNightAvailable: '_',
              averageNightlyRate: '_',
              averageRevenuePerStay: '_',
              cancelations: '_',
              cancelationsPercentage: '_'
            })));
            setTotal(0);
            setRevenueData(null);
          } else {
            throw revenueError;
          }
        }
      } else {
        const reservationsResponse = await getReservationPagination(
          page,
          limit,
          filters,
          staging,
          true
        );
        const reservationsData = reservationsResponse.data.data;

        if (reservationsData.length === 0) {
          setData([]);
          setTotal(0);
          setIsNextDisabled(true);
          return;
        }

        try {
          const itemIds = reservationsData.map(reservation => reservation._id).join(',');
          const revenueResponse = await getRevenuePerLandR(
            filters.from,
            filters.to,
            view,
            itemIds,
            filters.channelNames,
            staging,
            filters.checkin
          );

          if (revenueResponse.data.success) {
            const revenueData = revenueResponse.data.byItems;

            const combinedData = reservationsData.map(reservation => {
              const revenueItem = revenueData.find(item => item.itemId === reservation._id) || {};
              return {
                ...reservation,
                totalRevenue: formatNumber(revenueItem.totalRevenue),
                fees: formatNumber(revenueItem.fees),
                listingName: reservation.listing?.name
              };
            });

            setData(combinedData);
            setTotal(formatNumber(revenueResponse.data.totals?.totalRevenue));
            setRevenueData({
              ...revenueResponse.data,
              totals: {
                totalRevenue: revenueResponse.data.totals?.totalRevenue,
                totalFees: revenueResponse.data.totals?.totalFees
              }
            });
            setIsNextDisabled(reservationsData.length < limit);
          } else {
            setData(reservationsData.map(reservation => ({
              ...reservation,
              totalRevenue: '_',
              fees: '_',
              listingName: reservation.listing?.name
            })));
            setTotal(0);
          }
        } catch (revenueError) {
          if (revenueError.response?.status === 400) {
            setData(reservationsData.map(reservation => ({
              ...reservation,
              totalRevenue: '_',
              fees: '_',
              listingName: reservation.listing?.name
            })));
            setTotal(0);
          } else {
            throw revenueError;
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response?.status === 400) {
        setData([]);
        setTotal(0);
      } else {
        setError('No data found for the selected criteria.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns = view === 'listing'
    ? [
      columnVisibility['name'] && { field: 'name', header: 'Listing Name' },
      columnVisibility['totalRevenue'] && { field: 'totalRevenue', header: 'Total Revenue' },
      columnVisibility['nightsBooked'] && { field: 'nightsBooked', header: 'Nights Booked' },
      // columnVisibility['ownerStayNights'] && { field: 'ownerStayNights', header: 'Owner Stay Nights' },
      columnVisibility['totalCheckIns'] && { field: 'totalCheckIns', header: 'Total Check-ins' },
      columnVisibility['nightsAvailable'] && { field: 'nightsAvailable', header: 'Nights Available' },
      columnVisibility['occupancyRate'] && { field: 'occupancyRate', header: 'Occupancy Rate' },
      columnVisibility['revenuePerNightBooked'] && { field: 'revenuePerNightBooked', header: 'Revenue Per Night Booked' },
      columnVisibility['revenuePerNightAvailable'] && { field: 'revenuePerNightAvailable', header: 'Revenue Per Night Available' },
      columnVisibility['averageNightlyRate'] && { field: 'averageNightlyRate', header: 'Average Nightly Rate' },
      columnVisibility['averageRevenuePerStay'] && { field: 'averageRevenuePerStay', header: 'Average Revenue Per Stay' },
      columnVisibility['cancelations'] && { field: 'cancelations', header: 'Cancelations' },
      columnVisibility['cancelationsPercentage'] && { field: 'cancelationsPercentage', header: 'Cancelation Percentage' },
      // columnVisibility['fees'] && { field: 'fees', header: 'Fees' }

    ].filter(Boolean)
    : [
      columnVisibility['guestName'] && { field: 'guestName', header: 'Guest Name' },
      columnVisibility['listingName'] && { field: 'listingName', header: 'Listing Default Order' },
      columnVisibility['totalRevenue'] && { field: 'totalRevenue', header: 'Total Revenue' },
      columnVisibility['fees'] && { field: 'fees', header: 'Fees' }
    ].filter(Boolean);

  useEffect(() => {
    localStorage.setItem('columnVisibility', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const handleColumnVisibilityChange = (column) => {
    setColumnVisibility(prev => {
      const newVisibility = {
        ...prev,
        [column]: !prev[column]
      };
      localStorage.setItem('columnVisibility', JSON.stringify(newVisibility));
      return newVisibility;
    });
  };

  const handleFilterChange = (key, value) => {
    if (key === 'reset') {
      resetFilters();
    } else {
      setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    }
  };

  const resetFilters = () => {
    setFilters({
      from: moment().startOf('year').format('YYYY-MM-DD'),
      to: moment().endOf('month').format('YYYY-MM-DD'),
      dateRange: '',
      checkin: 'arrival',
      listingIds: [],
      cityIds: [],
      countryNames: [],
      channelNames: [],
      active: ['true'],
      status: ['Confirmed', 'Pending', 'Completed']
    });
    setPage(0);
    setLimit(10);
    setData([]);
    setTotal(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };
  const toggleFilters = () => {
    setShowFilters(prev => !prev);
  };

  const handleViewChange = (newView) => {
    if (newView !== view) {
      setFilters(prev => ({
        ...prev,
        checkin: newView === 'listing' ? 'arrival' : 'checked',
      }));
      setView(newView);
      setPage(0);
      setLimit(10);
      setData([]);
      setTotal(0);
    }
  };


  return (
    <>
      <div className="flex justify-between">
        <ViewToggle view={view} setView={setView} toggleFilters={toggleFilters} onViewChange={handleViewChange} showFilters={showFilters} handleFilterChange={handleFilterChange} />
      </div>
      {showFilters && (
        <>
          <FilterSection
            filters={filters}
            handleFilterChange={handleFilterChange}
            listings={listings}
            cities={cities}
            countries={countries}
          />
          <ColumnVisibility
            view={view}
            columnVisibility={columnVisibility}
            handleColumnVisibilityChange={handleColumnVisibilityChange}
          />
        </>
      )}
      {error && <Alert severity="error" className="mb-4">{error}</Alert>}
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress sx={{ color: '#00b4b4' }} />
        </Box>
      ) : (
        !error && (
          <>
            <GlobalTable
              data={data}
              columns={columns}
              hasPagination={true}
              page={page}
              onPageChange={handlePageChange}
              isNextDisabled={isNextDisabled}
              limit={limit}
              onLimitChange={handleLimitChange}
              rowsPerPageOptions={[5, 10, 20, 50]}
              totalRevenue={total}
              totals={revenueData?.totals}
            />
          </>
        )
      )}
    </>
  );
};

export default ReservationTable;

