import React from 'react';
import BasicInfo from './BasicInfo';
import Address from './Address';
import Media from './Media';
import Amenities from './Amenities';
import PriceAndFees from './PriceAndFees';
import AdditionalInfo from './AdditionalInfo';
import ChannelSpecific from './ChannelSpecific';
import ContactPerson from './ContactPerson';
import Attachment from './Attachment';
import CustomFields from './CustomFields';
import FinancialSettings from './FinancialSettings';
import GuestPortal from './GuestPortal';
import BedTypes from './BedTypes';
import LicenseInfo from './LicenseInfo';
import PaymentAccounts from './PaymentAccounts';
import ChannelManager from './ChannelManager';
import Cleaning from './Cleaning';
import StandardSojori from './StandardSojori';
import MoreInfo from './MoreInfo';
// import AccessInfo from './AccessInfo';
import DetailAmenities from './DetailAmenities';
import Message from './Message';
import AccessDetails from './AccessDetails';
import MinutListing from './MinutListing';
import RoomType from './RoomType';
import Rooms from './Rooms';
import RoomConfig from './RoomConfig';
import Task from './Task';
import Services from './Services';

const FormContainer = ({
  tab,
  formik,
  listingId,
  types,
  propertyTypes,
  bedTypes,
  currencies,
  conciergeType,
}) => {
  return (
    <div className="w-full h-full bg-white mt-4 !rounded-none">
      {tab && tab === 'Media' && <Media formik={formik} />}
      {tab && tab === 'Basic info' && (
        <BasicInfo
          formik={formik}
          propertyTypes={propertyTypes}
          currencies={currencies}
        />
      )}
      {tab && tab === 'configuration' && (
        <RoomConfig formik={formik} types={types} bedTypes={bedTypes} />
      )}
      {tab && tab === 'Address' && <Address formik={formik} />}
      {tab && tab === 'Amenities' && <Amenities formik={formik} />}
      {tab && tab === 'Standard Sojori' && <StandardSojori formik={formik} />}
      {/* {tab && tab === 'price' && <PriceAndFees formik={formik}/>} */}
      {/* {tab && tab === 'additionalinfo' && <AdditionalInfo formik={formik}/>} */}
      {/* {tab && tab === 'bedtypes' && <BedTypes formik={formik}/>} */}
      {tab && tab === 'Channelmanager' && <ChannelManager formik={formik} />}
      {/* {tab && tab === 'task' && <Cleaning formik={formik}/>} */}
      {/* {tab && tab === 'moreinfo' && (
        <MoreInfo formik={formik} listingId={listingId} />
      )} */}
      {tab && tab === 'roomtype' && (
        <RoomType formik={formik} types={types} bedTypes={bedTypes} />
      )}
      {tab && tab === 'rooms' && <Rooms formik={formik} types={types} />}

      {/* {tab && tab === 'accessinfo' && <AccessInfo formik={formik} />} */}
      {tab && tab === 'Message' && <Message formik={formik} />}
      {tab && tab === 'appartmentinformation' && (
        <DetailAmenities formik={formik} />
      )}
      {tab && tab === 'Access details' && <AccessDetails formik={formik} />}
      {tab && tab === 'Iot' && <MinutListing formik={formik} />}
      {tab && tab === 'Task' && <Task formik={formik} />}
      {tab && tab === 'Concierges' && (
        <Services formik={formik} conciergeType={conciergeType} />
      )}
    </div>
  );
};

export default FormContainer;