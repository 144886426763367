import React, { useState, useEffect } from 'react';
import DashboardCard from '../components/DashboardCard';
import { Typography, CircularProgress } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  getListingsStats,
  getResStats,
} from '../services/serverApi.financialConfig';
import logo from 'assets/images/logo-ct.png';
import booking from 'assets/images/booking.png';
import airbnb from 'assets/images/airbnb.png';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import ListingCard from '../components/ListingCard';
import { useTranslation } from 'react-i18next';

// Add this helper function near the top of the file, after the imports:
const formatNumber = (number) => {
  if (number === null || number === undefined) return '0.00';
  return Number(number).toFixed(2);
};

const ReservationsDashboard = () => {
  const { t } = useTranslation('common');

  const [listingStats, setListingStats] = useState();
  const [reservationStats, setReservationStats] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [staging, setStaging] = useState(
    JSON.parse(localStorage.getItem('isStaging')) || false,
  );

  useEffect(() => {
    const handleStagingToggle = (event) => {
      setStaging(event.detail);
      fetchData(event.detail);
    };

    window.addEventListener('stagingToggle', handleStagingToggle);
    fetchData(staging);

    return () => {
      window.removeEventListener('stagingToggle', handleStagingToggle);
    };
  }, []);

  const fetchData = async (staging) => {
    setIsLoading(true);
    try {
      const [listingData, reservationData] = await Promise.all([
        getListingsStats(staging),
        getResStats(staging),
      ]);
      setListingStats(listingData.data);
      setReservationStats(reservationData.data);
    } catch (error) {
      console.error('Error fetching listing stats:', error);
      setListingStats([]);
      setReservationStats([]);
    } finally {
      setIsLoading(false);
    }
  };
  // const unreadConversations = [
  //   { name: "abdilah eladi", date: "2024-06-18 - 2024-06-19", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "abdilah elaidi", date: "2024-06-24 - 2024-06-26", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "ABDELHAMID AZIZI", date: "2024-06-06 - 2024-06-07", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "Sojori", date: "2024-07-01 - 2024-07-12", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "ABDELHAMID AZIZI", date: "2024-05-23 - 2024-05-25", isNew: false, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "Tawfiq Gouach", date: "2024-05-25 - 2024-05-28", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "Anass Filali", date: "2024-04-03 - 2024-04-10", isNew: false, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  // ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatTime = (time) => (time ? `@${time}:00` : 'N/A');

  const createGuestData = (data) => {
    return (
      data?.map((item) => {
        let guestImage = null;
        if (item.atSojori) {
          guestImage = <img src={logo} alt="" className="w-6 h-6" />;
        } else if (item.channelName?.toLowerCase() === 'bookingcom') {
          guestImage = <img src={booking} alt="" className="w-6 h-6" />;
        } else if (item.channelName?.toLowerCase() === 'airbnb') {
          guestImage = <img src={airbnb} alt="" className="w-6 h-6" />;
        }

        return {
          _id: item._id,
          Listingname: item.listing.name,
          CheckInDate: formatDate(item.arrivalDate),
          CheckinTime: formatTime(item.checkInTime),
          CheckOutDate: formatDate(item.departureDate),
          CheckOutTime: formatTime(item.checkOutTime),
          image: guestImage,
        };
      }) || []
    );
  };

  const StayingGuests = createGuestData(reservationStats?.stayingGuestsData);
  const PendingInquiries = createGuestData(
    reservationStats?.pendingInquiriesData,
  );
  const UpcomingReservations = createGuestData(
    reservationStats?.upcomingReservationsData,
  );
  const PendingReservations = createGuestData(
    reservationStats?.pendingReservationsData,
  );
  const CheckInsToday = createGuestData(reservationStats?.checkInsTodayData);
  const CheckOutsToday = createGuestData(reservationStats?.checkOutsTodayData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress sx={{ color: '#00b4b4' }} />
        </div>
      ) : (
        <>
          <Typography
            variant="h5"
            className="!text-[#676A6C] !my-[3px] bg-white shadow-sm p-3 flex items-center gap-2 shadow-md"
          >
            <EventAvailableIcon className="!text-2xl !font-semibold" />
            {t('Reservations')}
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[3px] sm:grid-cols-1">
            <DashboardCard
              title={t(`Today's check-outs`)}
              count={reservationStats?.checkOutsToday}
              subtitle={t('Check-outs today')}
              isCurrent
              listingList={CheckOutsToday}
            />
            <DashboardCard
              title={t(`Today's check-ins`)}
              count={reservationStats?.checkInsToday}
              subtitle={t('Check-ins today')}
              isCurrent
              listingList={CheckInsToday}
            />
            <DashboardCard
              title={t('Reservations that need your approval')}
              count={reservationStats?.pendingReservations}
              subtitle={t('Pending & unconfirmed reservation(s)')}
              listingList={PendingReservations}
              isCurrent
            />
            <DashboardCard
              title={t('Upcoming reservation(s)')}
              count={reservationStats?.upcomingReservations}
              subtitle={t('Upcoming reservations')}
              next7Days
              listingList={UpcomingReservations}
            />
            <DashboardCard
              title={t('Staying guest(s)')}
              count={reservationStats?.stayingGuests}
              subtitle={t('Guests who are currently staying')}
              isCurrent
              listingList={StayingGuests}
            />
            <DashboardCard
              title={t('Pending inquiries')}
              count={reservationStats?.pendingInquiries}
              subtitle={t('Guest inquiries for reservation')}
              isCurrent
              listingList={PendingInquiries}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[3px] sm:grid-cols-1 !mt-[3px]">
            <DashboardCard
              title={t('Check-in')}
              count={reservationStats?.checkInsLast30Days}
              subtitle={t('Guest Check-ins')}
              last30Days
              percentage={`${reservationStats?.checkInsPercentageChange}`}
            />
            <DashboardCard
              title="Revenue"
              count={formatNumber(reservationStats?.avgRevenueLast30Days)}
              subtitle={t('Avg. revenue per guest')}
              last30Days
              percentage={formatNumber(reservationStats?.avgRevenuePercentageChange)}
            />
            <DashboardCard
              title={t('Revenue')}
              count={formatNumber(reservationStats?.totalRevenueLast30Days)}
              subtitle={t('Total Revenue')}
              last30Days
              percentage={formatNumber(reservationStats?.totalRevenuePercentageChange)}
            />
            <DashboardCard
              title={t('Reservation')}
              count={reservationStats?.reservationLast30Days}
              subtitle={t('reservations')}
              last30Days
              percentage={reservationStats?.reservationPercentageChange}
            />
          </div>
          <Typography
            variant="h5"
            className="!text-[#676A6C] bg-white shadow-sm p-3 !mb-[3px] flex items-center gap-2"
          >
            <FormatListBulletedIcon className="!text-2xl !font-semibold" />
            {t('Listings')}
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[3px] sm:grid-cols-1 !mb-[3px]">
            <ListingCard
              title={t('Total')}
              count={listingStats?.totalListing}
              subtitle={t('Total listing(s)')}
            />
            <ListingCard
              title={t('Active')}
              count={listingStats?.totalActive || 0}
              subtitle={t('Active listing(s)')}
            />
            <ListingCard
              title={t('Inactive')}
              count={listingStats?.totalInactive}
              subtitle={t('Inactive listing(s)')}
            />
          </div>
          {/* <Typography variant="h5" className="!text-[#676A6C] bg-white shadow-sm p-3 flex items-center gap-2"><ForumIcon className="!text-2xl !font-semibold" />Messages</Typography>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <MessageCard title="Sent" count="0" subtitle="Total sent messages" percentage="-100%" last30Days />
          <MessageCard title="Average sent" count="0" subtitle="Average sent messages per day" last30Days />
          <MessageCard title="Received" count="0" subtitle="Received message(s)" percentage="-100%" last30Days />
          <MessageCard title="Average received" count="0" subtitle="Average received messages per day" last30Days />
          <MessageCard title="Automation" count="0" subtitle="Messages sent by automation" percentage="-100%" last30Days />
          <MessageCard title="Automation" count="0" subtitle="Avg. sent by automation per day " last30Days />
        </div>
        <div className="grid grid-cols-1">
          <MessageCard title="Unread conversation(s)" unreadConversations={unreadConversations} last30Days className=""/>
        </div>
      </div> */}
        </>
      )}
    </DashboardLayout>
  );
};

export default ReservationsDashboard;
