import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Box,
  FormControl,
  InputLabel,
  Stack,
  Card,
  CardContent,
  Avatar
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { X, User } from 'lucide-react';
import axios from 'axios';
import { updateOwner } from '../services/serverApi.task';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone is required'),
  whatsapp: Yup.string(),
  selectedModules: Yup.array().min(1, 'Select at least one module'),
  status: Yup.string().oneOf(['active', 'inactive', 'suspended']).required('Status is required'),
  banned: Yup.boolean(),
  deleted: Yup.boolean(),
  settings: Yup.object().shape({
    language: Yup.string().required('Language is required'),
    currency: Yup.string().required('Currency is required')
  }),
  permissions: Yup.array().of(
    Yup.object().shape({
      module: Yup.string().required(),
      actions: Yup.array().of(Yup.string())
    })
  )
});

const UpdateOwnerDialog = ({ open, onClose, owner, onOwnerUpdated, modules }) => {
    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
          const response = await updateOwner(owner._id, {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            whatsapp: values.whatsapp,
            status: values.status,
            banned: values.banned,
            deleted: values.deleted,
            settings: values.settings,
            permissions: values.permissions,
            subscriptionModules: values.selectedModules.map(module => ({
              module,
              label: modules[module].label,
              price: modules[module].price
            }))
          });
      
          if (response.data && response.data.account) {
            onOwnerUpdated(response.data.account);
            onClose();
          }
        } catch (error) {
          console.error('Error updating owner:', error);
          setErrors({ submit: error.response?.data?.message || 'Failed to update owner' });
        } finally {
          setSubmitting(false);
        }
      };

  const initialModules = owner?.subscriptionModules?.map(m => m.module) || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-medium-aquamarine flex justify-between items-center">
        <Typography variant="h6" className="text-white flex items-center gap-2">
          <User className="w-5 h-5" />
          Update Owner
        </Typography>
        <IconButton onClick={onClose} className="text-white">
          <X className="w-5 h-5" />
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={{
          firstName: owner?.firstName || '',
          lastName: owner?.lastName || '',
          phone: owner?.phone || '',
          whatsapp: owner?.whatsapp || '',
          selectedModules: initialModules,
          status: owner?.status || 'active',
          banned: owner?.banned || false,
          deleted: owner?.deleted || false,
          settings: owner?.settings || {
            language: 'en',
            currency: 'USD'
          },
          permissions: owner?.permissions || []
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <Form>
            <DialogContent className="!space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  fullWidth
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <TextField
                  fullWidth
                  name="phone"
                  label="Phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />

                <TextField
                  fullWidth
                  name="whatsapp"
                  label="WhatsApp"
                  value={values.whatsapp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.whatsapp && Boolean(errors.whatsapp)}
                  helperText={touched.whatsapp && errors.whatsapp}
                />
              </div>

              <Typography variant="subtitle1" className="mb-4 font-medium">
                Settings
              </Typography>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  fullWidth
                  size="small"
                  name="settings.language"
                  label="Language"
                  value={values.settings.language}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.settings?.language && Boolean(errors.settings?.language)}
                  helperText={touched.settings?.language && errors.settings?.language}
                />

                <TextField
                  fullWidth
                  size="small"
                  name="settings.currency"
                  label="Currency"
                  value={values.settings.currency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.settings?.currency && Boolean(errors.settings?.currency)}
                  helperText={touched.settings?.currency && errors.settings?.currency}
                />
              </div>

              <Typography variant="subtitle1" className="mb-4 font-medium">
                Status
              </Typography>
              <FormControl fullWidth size="small">
                <InputLabel>Account Status</InputLabel>
                <Select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  label="Account Status"
                >
                  <MenuItem value="active">
                    <Chip label="Active" size="small" color="success" variant="outlined" />
                  </MenuItem>
                  <MenuItem value="inactive">
                    <Chip label="Inactive" size="small" color="warning" variant="outlined" />
                  </MenuItem>
                  <MenuItem value="suspended">
                    <Chip label="Suspended" size="small" color="error" variant="outlined" />
                  </MenuItem>
                </Select>
              </FormControl>

              <Typography variant="subtitle1" className="mb-4 font-medium">
                Account Status
              </Typography>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Ban Status</InputLabel>
                  <Select
                    value={values.banned ? 'banned' : 'active'}
                    onChange={(e) => {
                      setFieldValue('banned', e.target.value === 'banned');
                    }}
                    label="Ban Status"
                  >
                    <MenuItem value="active">
                      <Chip 
                        label="Active" 
                        size="small" 
                        color="success" 
                        variant="outlined"
                      />
                    </MenuItem>
                    <MenuItem value="banned">
                      <Chip 
                        label="Banned" 
                        size="small" 
                        color="error" 
                        variant="outlined"
                      />
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Delete Status</InputLabel>
                  <Select
                    value={values.deleted ? 'deleted' : 'active'}
                    onChange={(e) => {
                      setFieldValue('deleted', e.target.value === 'deleted');
                    }}
                    label="Delete Status"
                  >
                    <MenuItem value="active">
                      <Chip 
                        label="Active" 
                        size="small" 
                        color="success" 
                        variant="outlined"
                      />
                    </MenuItem>
                    <MenuItem value="deleted">
                      <Chip 
                        label="Deleted" 
                        size="small" 
                        color="default" 
                        variant="outlined"
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <Typography variant="subtitle1" className="mb-4 font-medium">
                Subscription Modules
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Selected Modules</InputLabel>
                <Select
                  multiple
                  value={values.selectedModules}
                  onChange={(e) => {
                    const newSelectedModules = e.target.value;
                    setFieldValue('selectedModules', newSelectedModules);
                    
                    const newPermissions = newSelectedModules.map(moduleKey => ({
                      module: moduleKey,
                      actions: ['get', 'create', 'update', 'delete']
                    }));
                    setFieldValue('permissions', newPermissions);
                  }}
                  input={<OutlinedInput label="Selected Modules" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={modules[value].label}
                          size="small"
                          className="!bg-medium-aquamarine !text-white"
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Object.entries(modules).map(([key, { label }]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.selectedModules && errors.selectedModules && (
                  <Typography color="error" variant="caption">
                    {errors.selectedModules}
                  </Typography>
                )}
              </FormControl>

{values.selectedModules.length > 0 && (
  <>
    <Typography variant="subtitle1" className="mb-2 font-medium flex items-center gap-2">
      <Box
        sx={{
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: '#00b4b4'
        }}
      />
      Active Permissions
    </Typography>
    <Stack 
      direction="row" 
      spacing={1} 
      sx={{ 
        flexWrap: 'wrap', 
        gap: '8px !important',
        p: 2,
        borderRadius: 1,
        background: 'linear-gradient(145deg, rgba(0, 180, 180, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%)',
        backdropFilter: 'blur(8px)',
        border: '1px solid rgba(0, 180, 180, 0.1)'
      }}
    >
      {Object.entries(modules)
        .filter(([moduleKey]) => values.selectedModules.includes(moduleKey))
        .map(([moduleKey, moduleData]) => (
          <Box
            key={moduleKey}
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: 'white',
              borderRadius: '8px',
              py: 0.75,
              px: 1.5,
              transition: 'all 0.2s ease',
              boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
              border: '1px solid rgba(0, 180, 180, 0.15)',
              '&:hover': {
                transform: 'translateY(-1px)',
                boxShadow: '0 4px 8px rgba(0,180,180,0.1)',
              }
            }}
          >
            <Avatar
              sx={{
                width: 20,
                height: 20,
                fontSize: '0.75rem',
                bgcolor: 'rgba(0, 180, 180, 0.1)',
                color: '#00b4b4',
                fontWeight: 600
              }}
            >
              {moduleData.label.charAt(0)}
            </Avatar>
            <Typography
              sx={{
                fontSize: '0.75rem',
                fontWeight: 500,
                color: '#2d3748',
                textTransform: 'capitalize'
              }}
            >
              {moduleData.label}
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                width: 3,
                height: 3,
                borderRadius: '50%',
                backgroundColor: '#00b4b4',
                right: 6,
                top: 6
              }}
            />
          </Box>
        ))}
    </Stack>
  </>
)}
            </DialogContent>

            <DialogActions className="p-4">
              <Button onClick={onClose} variant="outlined" className="!text-gray-500 !border-gray-500">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                className="!bg-medium-aquamarine !text-white"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateOwnerDialog;