import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Paper, 
    Tabs, 
    Tab, 
    Typography,
} from '@mui/material';
import { 
    ClipboardList, 
    Brush, 
    LogIn, 
    LogOut, 
    Building2
} from 'lucide-react';
import TasksTable from './taskTabs/TasksTable';
import CleaningTab from './taskTabs/CleaningTab';
import CheckinTab from './taskTabs/CheckinTab';
import CheckoutTab from './taskTabs/CheckoutTab';
import SyndicTab from './taskTabs/SyndicTab';
import { getLanguage } from 'features/listing/services/serverApi.listing';


const Task = ({ formik }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [languages, setLanguages] = useState([]); 

    useEffect(() => {

        fetchLanguages();
    }, []);


      const fetchLanguages = async () => {
        try {
          const [languagesResponse] = await Promise.all([
            getLanguage()
          ]);
          setLanguages(languagesResponse.data);
        } catch (error) {
          console.error('Error while fetching languages:', error);
        }
      };

    const [tasks, setTasks] = useState([
        {
            taskName: 'MidClean',
            taskCreatedAutomatically: null, 
            taskAssignedAutomatically: false, 
            duration: '', 
            daysBeforeAssignment: '',
            notificationHours: '',
            daysBetweenCheckInOut: null 
        },
        {
            taskName: 'Clean',
            taskCreatedAutomatically: false, 
            taskAssignedAutomatically: false,
            duration: '',
            daysBetweenCheckInOut: '',
            daysBeforeAssignment: '',
            notificationHours: ''
        },
        {
            taskName: 'Checkin',
            taskCreatedAutomatically: false,
            taskAssignedAutomatically: false,
            duration: '',
            daysBetweenCheckInOut: null, 
            daysBeforeAssignment: '',
            notificationHours: ''
        },
        {
            taskName: 'Checkout',
            taskCreatedAutomatically: false,
            taskAssignedAutomatically: false,
            duration: '',
            daysBetweenCheckInOut: null, 
            daysBeforeAssignment: '',
            notificationHours: ''
        },
        {
            taskName: 'Assist',
            taskCreatedAutomatically: false, 
            taskAssignedAutomatically: false,
            duration: '',
            daysBetweenCheckInOut: '',
            daysBeforeAssignment: '',
            notificationHours: ''
        },
        {
            taskName: 'Syndic',
            taskCreatedAutomatically: null,
            taskAssignedAutomatically: null,
            duration: null, 
            daysBetweenCheckInOut: null, 
            daysBeforeAssignment: null, 
            notificationHours: '' 
        }
    ]);

    useEffect(() => {
        if (formik.values.tasks?.length > 0) {
            setTasks(formik.values.tasks);
        }
    }, [formik.values.tasks]);

    const categories = [
        { name: 'tasks', icon: <ClipboardList size={20} /> },
        { name: 'cleaning', icon: <Brush size={20} /> },
        { name: 'checkin', icon: <LogIn size={20} /> },
        { name: 'checkout', icon: <LogOut size={20} /> },
        { name: 'syndic', icon: <Building2 size={20} /> },
    ];

    const handleTaskChange = (taskName, field, value) => {
        const updatedTasks = tasks.map(task => 
            task.taskName === taskName 
                ? { ...task, [field]: value }
                : task
        );
        setTasks(updatedTasks);
        formik.setFieldValue('tasks', updatedTasks);
    };

    const shouldShowNA = (taskName, field) => {
        switch (taskName) {
            case 'MidClean':
                return field === 'taskCreatedAutomatically' || field === 'daysBetweenCheckInOut';
            case 'Clean':
                return false;
            case 'Checkin':
            case 'Checkout':
                return field === 'daysBetweenCheckInOut';
            case 'Assist':
                return false;
            case 'Syndic':
                return field !== 'notificationHours';
            default:
                return false;
        }
    };

    const renderTabContent = (category) => (
        <Box sx={{ p: 3 }}>
            {category === 'tasks' ? (
                <TasksTable 
                    tasks={tasks} 
                    handleTaskChange={handleTaskChange}
                    shouldShowNA={shouldShowNA}
                />
            ) : category === 'cleaning' ? (
                <CleaningTab formik={formik} languages={languages} />
            ) : category === 'checkin' ? (
                <CheckinTab 
                    formik={formik}
                    languages={languages}
                />
            ) : category === 'checkout' ? (
                <CheckoutTab 
                    formik={formik}
                    languages={languages}
                />
            ) : category === 'syndic' ? (
                <SyndicTab
                    formik={formik}
                    languages={languages}
                />
            ) : (
                <Typography variant="body2">Coming soon...</Typography>
            )}
        </Box>
    );

    return (
        <div className="!border-none">
            <Paper elevation={3} className="!p-4">
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {categories.map((category, index) => (
                        <Tab
                            key={category.name}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {category.icon}
                                    <Box sx={{ ml: 1, textTransform: 'capitalize' }}>
                                        {category.name}
                                    </Box>
                                </Box>
                            }
                        />
                    ))}
                </Tabs>
                {categories.map((category, index) => (
                    <Box
                        key={category.name}
                        role="tabpanel"
                        hidden={activeTab !== index}
                    >
                        {activeTab === index && renderTabContent(category.name)}
                    </Box>
                ))}
            </Paper>
        </div>
    );
};

export default Task;
