import React, { useState, useEffect } from 'react';
import { 
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Modal
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VerificationItems from './verificationItems/VerificationItems';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import { defaultValues } from '../helper/Options';

const CheckinTab = ({ formik, languages }) => {
  const [editingRows, setEditingRows] = useState({ TS_CHECKIN: null });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [expandedTables, setExpandedTables] = useState({ TS_CHECKIN: true });

  useEffect(() => {
    if (!formik.values.TS_CHECKIN || formik.values.TS_CHECKIN.length === 0) {
      formik.setFieldValue('TS_CHECKIN', defaultValues.TS_CHECKIN || []);
    }
  }, []);

  const getDisplayValues = (type) => {
    return (formik.values[type] && formik.values[type].length > 0)
      ? formik.values[type]
      : [];
  };

  const handleAddSlot = (type) => {
    const newSlot = { 
      id: formik.values[type]?.length || 0, 
      start: '', 
      end: '', 
      type: 'Normal', 
      price: 0, 
      default: false, 
      isNew: true 
    };
    const updatedSlots = [...(formik.values[type] || []), newSlot];
    formik.setFieldValue(type, updatedSlots);
    setEditingRows(prev => ({ ...prev, [type]: newSlot }));
    setExpandedTables(prev => ({ ...prev, [type]: true }));
  };

  const handleStartEditing = (rowData, type) => {
    setEditingRows(prev => ({ ...prev, [type]: { ...rowData } }));
  };

  const handleEditingInputChange = (e, type) => {
    const { name, value } = e.target;
    setEditingRows(prev => ({ 
      ...prev, 
      [type]: { ...prev[type], [name]: value } 
    }));
  };

  const handleDefaultChange = (e, type, id) => {
    const value = e.target.value === "Yes";
    const updatedSlots = formik.values[type].map(slot => ({
      ...slot,
      default: slot.id === id ? value : false
    }));
    formik.setFieldValue(type, updatedSlots);
  };

  const handleSaveNewSlot = (rowData, type) => {
    const updatedSlot = {
      id: rowData.id,
      start: Number(rowData.start),
      end: Number(rowData.end),
      type: rowData.type || 'Normal',
      price: Number(rowData.price) || 0,
      default: rowData.default || false
    };
    const updatedSlots = formik.values[type].map((slot, index) =>
      index === rowData.id ? updatedSlot : slot
    );
    formik.setFieldValue(type, updatedSlots);
    setEditingRows(prev => ({ ...prev, [type]: null }));
  };

  const handleUpdateRow = (rowData, type) => {
    const updatedSlot = {
      ...rowData,
      start: Number(rowData.start),
      end: Number(rowData.end),
      type: rowData.type || 'Normal',
      price: Number(rowData.price) || 0,
      default: rowData.default || false
    };
    const updatedSlots = formik.values[type].map((slot, index) =>
      index === rowData.id ? updatedSlot : slot
    );
    formik.setFieldValue(type, updatedSlots);
    setEditingRows(prev => ({ ...prev, [type]: null }));
  };

  const handleCancelEditing = (type) => {
    if (editingRows[type]?.isNew) {
      const updatedSlots = formik.values[type].filter((_, index) => 
        index !== editingRows[type].id
      );
      formik.setFieldValue(type, updatedSlots);
    }
    setEditingRows(prev => ({ ...prev, [type]: null }));
  };

  const handleDelete = (type) => {
    const updatedSlots = formik.values[type].filter((_, index) => 
      index !== selectedSlot.id
    );
    formik.setFieldValue(type, updatedSlots);
    setSelectedSlot(null);
  };

  const toggleTable = (type) => {
    setExpandedTables(prev => ({ 
      ...prev, 
      [type]: !prev[type] 
    }));
  };

  const columns = [
    {
      header: "Start Time",
      field: "start",
      body: (rowData) => (
        editingRows.TS_CHECKIN?.id === rowData.id ? (
          <TextField
            type="number"
            name="start"
            value={editingRows.TS_CHECKIN?.start}
            onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
            fullWidth
          />
        ) : (
          <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.start}</span>
        )
      ),
    },
    {
      header: "End Time",
      field: "end",
      body: (rowData) => (
        editingRows.TS_CHECKIN?.id === rowData.id ? (
          <TextField
            type="number"
            name="end"
            value={editingRows.TS_CHECKIN?.end}
            onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
            fullWidth
          />
        ) : (
          <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.end}</span>
        )
      ),
    },
    {
      header: "Type",
      field: "type",
      body: (rowData) => (
        editingRows.TS_CHECKIN?.id === rowData.id ? (
          <Select
            name="type"
            value={editingRows.TS_CHECKIN?.type || ''}
            onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
            fullWidth
          >
            <MenuItem value="Normal">Normal</MenuItem>
            <MenuItem value="Early">Early</MenuItem>
            <MenuItem value="Late">Late</MenuItem>
          </Select>
        ) : (
          <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.type}</span>
        )
      ),
    },
    {
      header: "Price",
      field: "price",
      body: (rowData) => (
        editingRows.TS_CHECKIN?.id === rowData.id ? (
          <TextField
            type="number"
            name="price"
            value={editingRows.TS_CHECKIN?.price}
            onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
            fullWidth
          />
        ) : (
          <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.price}</span>
        )
      ),
    },
    {
      header: "Default",
      field: "default",
      body: (rowData) => (
        <Select
          value={rowData.default ? "Yes" : "No"}
          onChange={(e) => handleDefaultChange(e, 'TS_CHECKIN', rowData.id)}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      ),
    },
  ];

  const actionColumn = {
    header: "Actions",
    field: "actions",
    body: (rowData, type) => (
      <div className="flex gap-1">
        {editingRows[type]?.id === rowData.id ? (
          <>
            {rowData.isNew ? (
              <button 
                type='button' 
                className="px-2 py-1 bg-[#6ad1d1] rounded-sm" 
                onClick={() => handleSaveNewSlot(editingRows[type], type)}
              >
                <span className="text-[13px] text-white">Save</span>
              </button>
            ) : (
              <button 
                type='button' 
                className="px-2 py-1 bg-[#6ad1d1] rounded-sm" 
                onClick={() => handleUpdateRow(editingRows[type], type)}
              >
                <span className="text-[13px] text-white">Save</span>
              </button>
            )}
            <button 
              type='button' 
              className="px-2 py-1 bg-gray-400 rounded-sm" 
              onClick={() => handleCancelEditing(type)}
            >
              <span className="text-[13px] text-white">Cancel</span>
            </button>
          </>
        ) : (
          <>
            <button 
              type='button' 
              className="px-2 py-1 bg-[#6ad1d1] rounded-sm" 
              onClick={() => handleStartEditing(rowData, type)}
            >
              <span className="text-[13px] text-white">Update</span>
            </button>
            <button 
              type='button' 
              className="px-2 py-1 bg-[#df5454] rounded-sm" 
              onClick={() => setSelectedSlot({ ...rowData, type })}
            >
              <span className="text-[13px] text-white">Delete</span>
            </button>
          </>
        )}
      </div>
    ),
  };

  const renderTable = (type, title) => {
    const displayValues = getDisplayValues(type);
    const slotsSummary = (displayValues || []).map(slot => `${slot.start}/${slot.end}`).join(', ');

    return (
      <div className="mb-8">
        <div className="flex items-center my-2 w-full">
          {expandedTables[type] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          <span className="text-sm font-semibold ml-2 cursor-pointer" onClick={() => toggleTable(type)}>
            {title}
          </span>
          <span className='text-sm text-gray-400 mx-1'>{slotsSummary}</span>
          <button 
            type="button" 
            className='px-2 py-[10px] mx-1 bg-slate-200 !rounded-md' 
            onClick={() => handleAddSlot(type)}
          >
            <AddIcon className='!text-sm' />
          </button>
        </div>

        {expandedTables[type] && (
          <div className="w-full">
            <GlobalTable
              data={displayValues.map((slot, index) => ({ ...slot, id: index }))}
              columns={[...columns, { ...actionColumn, body: (rowData) => actionColumn.body(rowData, type) }]}
              hasPagination={false}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Box>
      <VerificationItems 
        formik={formik} 
        languages={languages} 
        type="verificationItemsCheckin" 
      />
      {renderTable('TS_CHECKIN', 'Check-in Times')}
      
      <Modal open={Boolean(selectedSlot)} onClose={() => setSelectedSlot(null)}>
        <Box className="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24] bg-[#f8fafb]">
          <Box className="w-full mb-11 p-4">
            <Typography className="!text-[14px] !text-center">
              Are you sure you want to delete this check-in slot?
            </Typography>
          </Box>
          <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">
            <Box className="flex gap-2">
              <button
                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                onClick={() => setSelectedSlot(null)}
              >
                Cancel
              </button>
              <button
                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                onClick={() => handleDelete(selectedSlot.type)}
              >
                Confirm
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CheckinTab;