import React, { useState, useEffect } from 'react';
import { getLeads, deleteLead } from '../../services/serverApi.lead';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import LeadModal from './LeadModal';
import { toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, Typography, Button } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import LeadFilter from './LeadFilter';
import LeadViewModal from './LeadViewModal';

function Leads() {
    const { t } = useTranslation('common');
    const [isLoading, setIsLoading] = useState(true);
    const [leads, setLeads] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [error, setError] = useState(null);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [leadToView, setLeadToView] = useState(null);
    
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    
    const [filters, setFilters] = useState({
        search_text: '',
        type: '',
        properties_number: '',
    });

    useEffect(() => {
        fetchLeads();
    }, [filters, page, limit]);

    const fetchLeads = async () => {
        try {
            setIsLoading(true);
            const queryParams = new URLSearchParams({
                paged: 'true',
                limit: String(limit),
                page: String(page),
                ...(filters.search_text && { search_text: filters.search_text }),
                ...(filters.type && { type: filters.type }),
                ...(filters.properties_number && { properties_number: filters.properties_number })
            }).toString();

            console.log(`Fetching with limit: ${limit}, page: ${page}, URL params: ${queryParams}`);

            const response = await getLeads(queryParams);
            if (response.success) {
                setLeads(Array.isArray(response.data) ? response.data : []);
                setTotalRecords(response.total || 0);
            } else {
                setLeads([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
            setLeads([]);
            setTotalRecords(0);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        console.log(`Changing limit from ${limit} to ${newLimit}`);
        setLimit(Number(newLimit));
        setPage(0); 
    };

    const handleOpenModal = (lead = null) => {
        setSelectedLead(lead);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedLead(null);
        setModalOpen(false);
    };

    const handleViewLead = (lead) => {
        setLeadToView(lead);
        setViewModalOpen(true);
    };

    const handleCloseViewModal = () => {
        setLeadToView(null);
        setViewModalOpen(false);
    };

    const handleDeleteLead = async (leadId) => {
        if (window.confirm(t('Are you sure you want to delete this lead?'))) {
            try {
                await deleteLead(leadId);
                setLeads(prevLeads => prevLeads.filter(lead => lead._id !== leadId));
                toast.success(t('Lead deleted successfully'));
            } catch (error) {
                console.error('Error deleting lead:', error);
                toast.error(t('Failed to delete lead'));
            }
        }
    };

    const handleFilterApply = (newFilters) => {
        setFilters(newFilters);
        setPage(0); 
    };

    const formatDate = (dateString) => {
        try {
            return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
        } catch (error) {
            return t('Invalid date');
        }
    };

    const columns = [
        { header: t("Name"), body: (rowData) => <span>{rowData.name}</span> },
        { header: t("Creation Date"), body: (rowData) => <span>{formatDate(rowData.created_at)}</span> },
        { header: t("Email"), body: (rowData) => <span>{rowData.email}</span> },
        { header: t("Phone"), body: (rowData) => <span>{rowData.phone_number}</span> },
        { header: t("Properties"), body: (rowData) => <span>{rowData.properties_number}</span> },
        { header: t("Country"), body: (rowData) => <span>{rowData.country}</span> },
        { header: t("Type"), body: (rowData) => <span className="capitalize">{rowData.type || '-'}</span> },
        {
            header: t("Action"),
            body: (rowData) => (
                <div className="flex gap-1">
                    <button className="px-2 py-1 bg-blue-500 !rounded-md" onClick={() => handleViewLead(rowData)}>
                        <VisibilityIcon className="text-white" />
                    </button>
                    <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModal(rowData)}>
                        <EditOffIcon className="text-white" />
                    </button>
                    <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteLead(rowData._id)}>
                        <DeleteSweepIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    const isNextDisabled = (page + 1) * limit >= totalRecords;

    return (
        <div className="card p-4 !border-none">
            <div className="flex justify-between items-center mb-4">
                <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                    {t('Leads Management')}
                </Typography>
                <div className="">
                    <Button onClick={() => handleOpenModal()} className="float-right !bg-medium-aquamarine text-white">
                        {t('Create New Lead')}
                    </Button>
                </div>
            </div>

            <LeadFilter onFilterApply={handleFilterApply} initialFilters={filters} />

            <div>
                <div className="w-full">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <GlobalTable
                            data={leads}
                            columns={columns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            total={totalRecords}
                            isNextDisabled={isNextDisabled}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    )}
                </div>
            </div>
            <LeadModal
                open={modalOpen}
                onClose={handleCloseModal}
                setLeads={setLeads}
                lead={selectedLead}
            />
            <LeadViewModal
                open={viewModalOpen}
                onClose={handleCloseViewModal}
                lead={leadToView}
            />
        </div>
    );
}

export default Leads;