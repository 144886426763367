import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  CalendarSearch,
  ChevronRight,
  ChevronLeft,
  ChevronLast,
  ChevronFirst,
} from 'lucide-react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';

export const CalendarHeader = ({
  goToNextPeriod,
  goToPreviousPeriod,
  setViewType,
  viewType,
  goToToday,
  formattedDateRange,
  setCurrentDate,
  currentDate,
}) => {
  const { t } = useTranslation('common');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);
  const triggerRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (triggerRef.current && showDatePicker) {
        const rect = triggerRef.current.getBoundingClientRect();
        const scrollX = window.scrollX || window.pageXOffset;
        const scrollY = window.scrollY || window.pageYOffset;

        setPosition({
          top: rect.bottom + scrollY,
          left: rect.left + scrollX,
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [showDatePicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDatePicker]);

  const handleDateClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (date) => {
    setCurrentDate(date);

    setShowDatePicker(false);
  };
  const handleNextDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const handlePreviousDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNext15Days = () => {
    goToNextPeriod();
  };

  const handlePrevious15Days = () => {
    goToPreviousPeriod();
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap justify-between items-center p-1 border-b border-gray-300">
        <div className="flex items-center gap-1 sm:gap-4">
          <div className="flex items-center gap-1">
            <ChevronFirst
              onClick={handlePrevious15Days}
              className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
            />
            <ChevronLeft
              onClick={handlePreviousDay}
              className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
            />

            <div
              ref={triggerRef}
              className="flex items-center gap-1 cursor-pointer hover:bg-gray-100 p-1 rounded-md"
              onClick={handleDateClick}
            >
              <span className="text-[10px] sm:text-sm font-bold whitespace-nowrap">
                {formattedDateRange}
              </span>
              <CalendarSearch className="text-teal-500 w-5 h-5 sm:w-6 sm:h-6" />
            </div>

            <div
              onClick={goToToday}
              className="px-2 py-1 bg-white border border-teal-500 rounded-md cursor-pointer hover:bg-teal-600 hover:text-white"
            >
              <span className="text-[10px] sm:text-xs text-teal-500 hover:text-white">
                {t('now')}
              </span>
            </div>

            <ChevronRight
              onClick={handleNextDay}
              className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
            />
            <ChevronLast
              onClick={handleNext15Days}
              className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
            />
          </div>
        </div>

        {/* <div className="flex gap-1">
          {['10 Day', '14 Day', '20 Day', '30 Day'].map((type) => (
            <button
              key={type}
              onClick={() => setViewType(type)}
              className={`whitespace-nowrap px-3 py-1 border border-gray-300 rounded-md text-xs
                ${viewType === type ? 'bg-teal-600 text-white' : 'bg-white hover:bg-gray-100'}`}
            >
              {type}
            </button>
          ))}
        </div> */}
      </div>

      {showDatePicker &&
        createPortal(
          <div
            ref={datePickerRef}
            className="fixed shadow-xl bg-white rounded-lg z-[9999]"
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
              maxWidth: '95vw',
            }}
          >
            <Calendar
              date={currentDate}
              onChange={handleDateChange}
              className="rounded-lg overflow-hidden scale-[0.85] sm:scale-100"
            />
          </div>,
          document.body,
        )}
    </div>
  );
};
