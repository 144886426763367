import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  Box,
  FormControlLabel,
} from '@mui/material';
import * as Yup from 'yup';
import { updateStaffPlannig } from '../services/serverApi.task';

import { Calendar, X, Plus, Trash } from 'lucide-react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  schedule: Yup.array().of(
    Yup.object().shape({
      present: Yup.boolean(),
      timings: Yup.array().of(
        Yup.object().shape({
          start: Yup.number().min(0).max(24).required(),
          end: Yup.number().min(0).max(24).required(),
        }),
      ),
    }),
  ),
});
const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const ModifyStaffPlanning = ({ open, handleClose, staff, onStaffUpdate }) => {
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    updateStaffPlannig({
      staffId: staff._id,
      schedule: values.schedule,
    })
      .then(({ data }) => {
        onStaffUpdate(staff._id, data.planning);
        handleClose();
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          const serverErrors = {};
          error.response.data.errors.forEach((err) => {
            serverErrors[err.path[0]] = err.message;
          });
          setErrors(serverErrors);
          toast.error(serverErrors[Object.keys(serverErrors)[0]]);
        } else {
          toast.error('Error updating planning');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const defaultSchedule = daysOfWeek.map((day) => ({
    day,
    present: false,
    timings: [],
  }));

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-medium-aquamarine flex justify-between items-center">
        <Typography variant="h6" className="text-white flex items-center gap-2">
          <Calendar className="w-5 h-5" />
          Update Staff Planning
        </Typography>
        <IconButton onClick={handleClose} className="text-white">
          <X className="w-5 h-5" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="pt-6">
        <Formik
          initialValues={{
            schedule: staff?.staffPlanning?.schedule
              ? daysOfWeek.map(
                  (day) =>
                    staff.staffPlanning.schedule.find((s) => s.day === day) || {
                      day,
                      present: false,
                      timings: [],
                    },
                )
              : defaultSchedule,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="space-y-4">
              <FieldArray name="schedule">
                {() => (
                  <Box className="space-y-4">
                    {values.schedule.map((day, index) => (
                      <Box key={index} className="border p-4 rounded-md">
                        <Box className="flex justify-between items-center">
                          <Typography variant="body1" className="font-semibold">
                            {day.day}
                          </Typography>

                          <FormControlLabel
                            control={
                              <Field
                                as={Checkbox}
                                type="checkbox"
                                name={`schedule.${index}.present`}
                                checked={day.present}
                                onChange={(e) =>
                                  setFieldValue(
                                    `schedule.${index}.present`,
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label="Present"
                          />
                        </Box>

                        {day.present && (
                          <FieldArray name={`schedule.${index}.timings`}>
                            {({ push, remove }) => (
                              <Box>
                                {day.timings.map((time, timeIndex) => (
                                  <Box
                                    key={timeIndex}
                                    className="flex gap-2 mt-2"
                                  >
                                    <Field
                                      as={TextField}
                                      type="number"
                                      name={`schedule.${index}.timings.${timeIndex}.start`}
                                      label="Start"
                                      variant="outlined"
                                      className="w-20"
                                    />
                                    <Field
                                      as={TextField}
                                      type="number"
                                      name={`schedule.${index}.timings.${timeIndex}.end`}
                                      label="End"
                                      variant="outlined"
                                      className="w-20"
                                    />
                                    <IconButton
                                      onClick={() => remove(timeIndex)}
                                      color="error"
                                    >
                                      <Trash className="w-4 h-4" />
                                    </IconButton>
                                  </Box>
                                ))}
                                <Button
                                  onClick={() => push({ start: '', end: '' })}
                                  startIcon={<Plus className="w-4 h-4" />}
                                  className="mt-2"
                                >
                                  Add Timing
                                </Button>
                              </Box>
                            )}
                          </FieldArray>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </FieldArray>

              <DialogActions>
                <Button
                  className="!text-red-500"
                  onClick={handleClose}
                  variant="outlined"
                  color="error"
                  startIcon={<X className="w-4 h-4" />}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="text-white !bg-medium-aquamarine !hover:bg-medium-aquamarine/90"
                  variant="contained"
                >
                  {isSubmitting ? 'Updating...' : 'Update Planning'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyStaffPlanning;
