import React, { useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const LeadFilter = ({ onFilterApply, initialFilters = {} }) => {
  const [filters, setFilters] = useState({
    search_text: initialFilters.search_text || '',
    type: initialFilters.type || '',
    properties_number: initialFilters.properties_number || '',
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    
    const updatedFilters = {
      ...filters,
      [name]: value
    };
    
    setFilters(updatedFilters);
    
    onFilterApply(updatedFilters);
  };

  const handleResetFilters = () => {
    const resetFilters = {
      search_text: '',
      type: '',
      properties_number: '',
    };
    setFilters(resetFilters);
    onFilterApply(resetFilters);
  };

  return (
    <div className="w-full flex flex-col gap-4 mb-4 sm:flex-row sm:flex-wrap sm:items-center">
      <div className="w-full sm:w-auto">
        <TextField
          label="Search (Name, Email, Phone, Country)"
          name="search_text"
          value={filters.search_text}
          onChange={handleFilterChange}
          variant="outlined"
          className="w-full sm:w-64"
        />
      </div>

      <div className="w-full sm:w-auto">
        <FormControl className="w-full sm:w-52" variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            name="type"
            value={filters.type}
            label="Type"
            onChange={handleFilterChange}
          >
            <MenuItem value="">All Types</MenuItem>
            <MenuItem value="pricing">Pricing</MenuItem>
            <MenuItem value="demo">Demo</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="w-full sm:w-auto">
        <TextField
          label="Min Properties"
          name="properties_number"
          type="number"
          value={filters.properties_number}
          onChange={handleFilterChange}
          variant="outlined"
          className="w-full sm:w-40"
        />
      </div>

      <div className="w-full sm:w-auto">
        <div className="flex gap-2 w-full">
          <Button onClick={handleResetFilters} className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !justify-center">
            <RotateLeftIcon className="!text-white !h-7 !w-7" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeadFilter;