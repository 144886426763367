import React, { useEffect, useState } from 'react';
import { Select, MenuItem, TextField, Button, FormLabel, CircularProgress, Switch } from '@mui/material';
import Charts from './Charts';
import { getcities, getListings, getCheckinBychannel, getRentalRevenue, getBookedNights, getoccupancyRate, getAverageDailyRate, getAverageRevenuePerStay, getReservationStats, getChannelStats, getBookedNightsByChannel, getRevenueBychannel, getReservationPercentagePerChannel, getRentalRevenuePerReservation } from '../services/serverApi.financialConfig';
import moment from 'moment';
import Upcoming from './Upcoming';
import { getDateRange } from './dateUtils';
import { scrollbarStyle } from '../financialStyles/scrollBar';
import useFetchData from '../services/useFetchData';
import FilterSearch from 'components/FilterSearch/FilterSearch';
const Analytics = () => {
    const [cities, setCities] = useState([]);
    const [listings, setListings] = useState([]);
    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);
    const [channels] = useState([
        { label: 'Sojori', value: 'sojori' },
        { label: 'Booking', value: 'BookingCom' },
        { label: 'AirBNB', value: 'AirBNB' }
    ]);
    const formattedStartOfMonthDate = moment().startOf('month').format('YYYY-MM-DD');
    const formattedEndOfMonthDate = moment().endOf('month').format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(formattedStartOfMonthDate);
    const [endDate, setEndDate] = useState(formattedEndOfMonthDate);
    const [dateRangeOption, setDateRangeOption] = useState('Current month');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedListing, setSelectedListing] = useState('');
    const [staging, setStaging] = useState(JSON.parse(localStorage.getItem('isStaging')) || false);
    const [selectedCityIds, setSelectedCityIds] = useState([]);
    const [selectedListingIds, setSelectedListingIds] = useState([]);
    const [selectedChannelNames, setSelectedChannelNames] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState('day');
    const [active, setActive] = useState(['true']); 

    const activeStatusOptions = [
        { id: 'true', name: 'Active' },
        { id: 'false', name: 'Inactive' }
    ];


    useEffect(() => {
        const handleStagingToggle = (event) => {
            setStaging(event.detail);
        };
        window.addEventListener('stagingToggle', handleStagingToggle);
        fetchData();
        return () => {
            window.removeEventListener('stagingToggle', handleStagingToggle);
        };
    }, [staging]);

    useEffect(() => {
        setCurrentPeriodIndex(0);
    }, [startDate, endDate]);


    const fetchData = async () => {
        try {
            const [citiesData, listingsResponse] = await Promise.all([
                getcities(),
                getListings(staging)
            ]);
            setCities(citiesData.data);
            setListings(listingsResponse.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setListings([]);
        }
    };

    // const handleDateRangeOptionChange = (event) => {
    //     const option = event.target.value;
    //     setDateRangeOption(option);
    //     const { start, end } = getDateRange(option);
    //     setStartDate(start);
    //     setEndDate(end);
    // };

    const handleDateRangeOptionChange = (event) => {
        const option = event.target.value;
        setDateRangeOption(option);
        const { start, end } = getDateRange(option);
        setStartDate(start);
        setEndDate(end);
        setCurrentPeriodIndex(0);
    };


    const { data: rentalRevenueData, isLoading: isLoadingRentalRevenue } = useFetchData(
        () => getRentalRevenue(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: bookedNightsData, isLoading: isLoadingBookedNights } = useFetchData(
        () => getBookedNights(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: occupancyRateData, isLoading: isLoadingOccupancyRate } = useFetchData(
        () => getoccupancyRate(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: averageDailyRateData, isLoading: isLoadingAverageDailyRate } = useFetchData(
        () => getAverageDailyRate(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: averageRevenuePerStayData, isLoading: isLoadingAverageRevenuePerStay } = useFetchData(
        () => getAverageRevenuePerStay(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: reservationStatsData, isLoading: isLoadingReservationStats } = useFetchData(
        () => getReservationStats(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, selectedPeriod, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, selectedPeriod, active]
    );

    const { data: channelStatsData, isLoading: isLoadingChannelStats } = useFetchData(
        () => getChannelStats(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: checkinByChannelData, isLoading: isLoadingCheckinByChannel } = useFetchData(
        () => getCheckinBychannel(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, selectedPeriod, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, selectedPeriod, active]
    );

    const { data: revenueByChannelData, isLoading: isLoadingRevenueByChannel } = useFetchData(
        () => getRevenueBychannel(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: bookedNightsByChannelData, isLoading: isLoadingBookedNightsByChannel } = useFetchData(
        () => getBookedNightsByChannel(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: reservationPerChannelData, isLoading: isLoadingReservationPerChannel } = useFetchData(
        () => getReservationPercentagePerChannel(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: rentalRevenuePerReservationData, isLoading: isLoadingRentalRevenuePerReservation } = useFetchData(
        () => getRentalRevenuePerReservation(startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [startDate, endDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );


    const handleDateChange = (event, dateType) => {
        if (dateType === 'start') {
            setStartDate(event.target.value);
        } else {
            setEndDate(event.target.value);
        }
    };


    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
        setCurrentPeriodIndex(0); 
    };

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
                <h5 className="text-2xl font-bold">Financial Reporting</h5>

            </div>


            <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-end mb-4">
                <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center w-full sm:w-auto">

                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Cities</FormLabel>

                        <FilterSearch
                            selectedItems={selectedCityIds}
                            options={cities}
                            onItemsChange={setSelectedCityIds}
                            placeholder="Select Cities"
                            labelKey="name"
                        />
                    </div>

                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Listings</FormLabel>
                        <FilterSearch
                            selectedItems={selectedListingIds}
                            options={listings}
                            onItemsChange={setSelectedListingIds}
                            placeholder="Select Listings"
                            labelKey="name"
                            idKey="_id"

                        />
                    </div>


                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Listing Status</FormLabel>
                        <FilterSearch
                            selectedItems={active}
                            options={activeStatusOptions}
                            onItemsChange={setActive}
                            placeholder="Select Status"
                            idKey="id"
                            labelKey="name"
                            width={200}
                        />
                    </div>

                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Channels</FormLabel>

                        <FilterSearch
                            selectedItems={selectedChannelNames}
                            options={channels}
                            onItemsChange={setSelectedChannelNames}
                            placeholder="Select Channels"
                            labelKey="label"
                            idKey="value"
                        />
                    </div>

                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Start Date</FormLabel>
                        <TextField
                            type="date"
                            value={startDate}
                            className="w-full"
                            InputProps={{ className: "h-9 !rounded-sm" }}
                            onChange={(e) => handleDateChange(e, 'start')}
                        />
                    </div>

                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">End Date</FormLabel>
                        <TextField
                            type="date"
                            value={endDate}
                            className="w-full"
                            InputProps={{ className: "h-9 !rounded-sm" }}
                            onChange={(e) => handleDateChange(e, 'end')}
                        />
                    </div>

                    <div className="">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Date Range</FormLabel>
                        <Select
                            className="w-full h-9 !rounded-sm"
                            value={dateRangeOption}
                            onChange={handleDateRangeOptionChange}
                            MenuProps={{ PaperProps: { style: { maxHeight: 250, ...scrollbarStyle, }, }, }}
                        >
                            <MenuItem value="Current month">Current month</MenuItem>
                            <MenuItem value="Last month">Last month</MenuItem>
                            <MenuItem value="Next Month">Next month</MenuItem>
                            <MenuItem value="Today">Today</MenuItem>
                            <MenuItem value="Yesterday">Yesterday</MenuItem>
                            <MenuItem value="Tomorrow">Tomorrow</MenuItem>
                            <MenuItem value="Current Year">Current Year</MenuItem>
                            <MenuItem value="Last Year">Last Year</MenuItem>
                            {/* <MenuItem value="Last 12 months">Last 12 months</MenuItem>
                            <MenuItem value="Last 3 months">Last 3 months</MenuItem>
                            <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                            <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                            <MenuItem value="YTD">YTD</MenuItem>
                            <MenuItem value="MTD">MTD</MenuItem>
                            <MenuItem value="QTD">QTD</MenuItem>
                            <MenuItem value="WTD">WTD</MenuItem> */}
                        </Select>
                    </div>

                </div>
                <div className="w-full sm:w-auto">
                    <FormLabel className="!text-[#788999] !text-xs !font-bold">&nbsp;</FormLabel>
                    <button
                        className="w-full sm:w-16 px-2 !bg-[#dcf6f6] !rounded-sm flex items-center justify-center gap-1 h-9 py-0"
                        onClick={() => {
                            setSelectedCity('');
                            setSelectedListing('');
                        }}
                    >
                        <span className="ladda-label flex items-center font-bold text-[13px] text-[#00b4b4] p-[3px] capitalize">Reset</span>
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 bg-[#F8F9FC] mb-4 py-4 px-3">
                {[
                    { label: 'Rental revenue', value: `${rentalRevenueData?.totalRevenue || 0} MAD`, isLoading: isLoadingRentalRevenue },
                    { label: 'Nights booked', value: bookedNightsData?.totalNights || 0, isLoading: isLoadingBookedNights },
                    { label: 'Occupancy rate', value: `${occupancyRateData?.occupancyRate || 0} %`, isLoading: isLoadingOccupancyRate },
                    { label: 'Average daily rate', value: `${averageDailyRateData?.averageDailyRate || 0} MAD`, isLoading: isLoadingAverageDailyRate },
                    { label: 'Average revenue per stay', value: `${averageRevenuePerStayData?.averageRevenuePerStay || 0} MAD`, isLoading: isLoadingAverageRevenuePerStay },
                ].map((item, index) => (
                    <div key={index} className="">
                        <div className="text-base font-extrabold text-black my-2">{item.label}</div>
                        {item.isLoading ? (
                            <CircularProgress sx={{ color: '#00b4b4' }} size={24} />
                        ) : (
                            <div className="text-3xl font-normal mb-2 text-[#788999]">{item.value}</div>
                        )}
                    </div>
                ))}
            </div>

            <Charts
                reservationStats={reservationStatsData?.data || []}
                channelStats={channelStatsData?.data || []}
                checkinByChannel={checkinByChannelData?.data || []}
                revenueByChannel={revenueByChannelData?.data || []}
                bookedNightsByChannel={bookedNightsByChannelData?.data || []}
                isLoadingReservationStats={isLoadingReservationStats}
                isLoadingChannelStats={isLoadingChannelStats}
                isLoadingCheckinByChannel={isLoadingCheckinByChannel}
                isLoadingRevenueByChannel={isLoadingRevenueByChannel}
                isLoadingBookedNightsByChannel={isLoadingBookedNightsByChannel}
                reservationPerChannelData={reservationPerChannelData?.data}
                isLoadingReservationPerChannel={isLoadingReservationPerChannel}
                rentalRevenuePerReservationData={rentalRevenuePerReservationData?.data}
                isLoadingRentalRevenuePerReservation={isLoadingRentalRevenuePerReservation}
                selectedPeriod={selectedPeriod}
                // onPeriodChange={setSelectedPeriod}
                startDate={startDate}
                endDate={endDate}
                currentPeriodIndex={currentPeriodIndex}
                onPeriodIndexChange={setCurrentPeriodIndex}
                // selectedPeriod={selectedPeriod}
                onPeriodChange={(period) => {
                    setSelectedPeriod(period);
                    setCurrentPeriodIndex(0);
                }}
                active={active}
            />

            <Upcoming
                selectedCityIds={selectedCityIds}
                selectedListingIds={selectedListingIds}
                selectedChannelNames={selectedChannelNames}
                staging={staging}
                active={active} // Now passing array
            />

        </div>
    );
};

export default Analytics;
