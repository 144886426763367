import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { getCountries, getCities, getLanguage } from "../../services/serverApi.listing";
import { FormControl, Select, MenuItem, InputLabel, Autocomplete, TextField, IconButton, Typography, Box, Divider, Grid, FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MapPin, Globe, Navigation, Clock } from 'lucide-react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Tooltip from 'components/TooltipGlobal/Tooltip';

const homeIconSvgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 100"><defs><filter id="shadow"><feDropShadow dx="0" dy="3" stdDeviation="2" flood-color="#000000" flood-opacity="0.3"/></filter></defs><path d="M40 5 C20 5 5 25 5 50 C5 75 40 95 40 95 C40 95 75 75 75 50 C75 25 60 5 40 5 Z" fill="#4285F4" filter="url(#shadow)"/><circle cx="40" cy="42" r="25" fill="white"/><path d="M40 25 L40 60 M28 37 L52 37 M28 47 L52 47" stroke="#4285F4" stroke-width="5" stroke-linecap="round"/></svg>`;

const homeIcon = new L.DivIcon({
  html: `<div style="display: flex; justify-content: center; align-items: center; width: 38px; height: 38px;">${homeIconSvgString}</div>`,
  className: '',
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38]
});

// Map click handler component
const MapClickHandler = ({ formik }) => {
  const map = useMapEvents({
    click: (e) => {
      formik.setFieldValue('lat', e.latlng.lat);
      formik.setFieldValue('lng', e.latlng.lng);
    }
  });
  return null;
};

const SubCategoryHeader = ({ title }) => (
  <Grid item xs={12}>
    <Box sx={{ mt: 1, mb: 0.5 }}>
      <Typography variant="subtitle1" fontWeight="medium" color="text.secondary">{title}</Typography>
      <Divider sx={{ mt: 0.2 }} />
    </Box>
  </Grid>
);

function Address({ formik }) {
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  const UpdateView = () => {
    if (mapRef.current && markerRef.current && formik.values.lat && formik.values.lng) {
      const { lat, lng } = formik.values;
      mapRef.current.setView([lat, lng], 10);
      markerRef.current.setLatLng([lat, lng]);
    }
  }

  useEffect(() => {
    UpdateView()
  }, [formik.values.lat, formik.values.lng]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [citiesResponse, countriesResponse, languagesResponse] = await Promise.all([
          getCities(),
          getCountries(),
          getLanguage()
        ]);
        setCities(citiesResponse.data);
        setCountries(countriesResponse.data);
        setLanguages(languagesResponse.data.filter(lang => lang.name.toLowerCase() !== 'english'));
        const englishLanguage = languagesResponse.data.find(lang => lang.name.toLowerCase() === 'english');
        if (englishLanguage) {
          setDefaultLanguage(englishLanguage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (defaultLanguage && !formik.values.zoneDescription) {
      formik.setFieldValue('zoneDescription', { [defaultLanguage._id]: '' });
    }
  }, [defaultLanguage]);

  const handleZoneDescriptionChange = (languageId, value) => {
    const currentDescriptions = { ...formik.values.zoneDescription };
    currentDescriptions[languageId] = value;
    formik.setFieldValue('zoneDescription', currentDescriptions);
  };

  const getAvailableLanguagesForZoneDescription = () => {
    const usedLanguageIds = Object.keys(formik.values.zoneDescription || {});
    return languages.filter(lang => !usedLanguageIds.includes(lang._id));
  };

  const renderInputField = (label, name, icon, value, type = 'text', isRequired = false, tooltipText = null, isDisabled = false) => (
    <FormControl fullWidth variant="outlined" size="small" error={formik.touched[name] && Boolean(formik.errors[name])} sx={{ mb: 1 }}>
      <Box display="flex" alignItems="center" mb={0.2}>
        <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">{label} {isRequired && <span style={{ color: '#d32f2f' }}>*</span>}</Typography>
        {tooltipText && (
          <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
            <Tooltip text={tooltipText} iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
          </IconButton>
        )}
      </Box>

      <TextField name={name} value={value || ''} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={isDisabled} fullWidth type={type} InputProps={{ startAdornment: icon ? React.cloneElement(icon, { size: 20, className: 'mr-2 text-gray-500' }) : null, classes: { input: '!text-xs' }, className: isDisabled ? 'cursor-not-allowed' : '' }} size="small" variant="outlined" placeholder={`Enter ${label.toLowerCase()}`} sx={{ fontSize: '0.875rem' }} />

      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText error className="!text-xs">{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );

  const renderSelectField = (label, name, options, icon, isRequired = false, tooltipText = null) => (
    <FormControl fullWidth variant="outlined" size="small" error={formik.touched[name] && Boolean(formik.errors[name])} sx={{ mb: 1 }}>
      <Box display="flex" alignItems="center" mb={0.2}>
        <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">{label} {isRequired && <span style={{ color: '#d32f2f' }}>*</span>}</Typography>
        {tooltipText && (
          <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
            <Tooltip text={tooltipText} iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
          </IconButton>
        )}
      </Box>
      
      <Select name={name} value={formik.values[name] || ''} onChange={(e) => {
        if (name === 'country') {
          const selectedCountry = countries?.find(country => country.name === e.target.value);
          formik.setFieldValue('country', e.target.value);
          formik.setFieldValue('countryCode', selectedCountry ? selectedCountry.countryCode : '');
        } else if (name === 'city') {
          const selectedCity = cities?.find(city => city.name === e.target.value);
          formik.setFieldValue('city', e.target.value);
          formik.setFieldValue('cityId', selectedCity ? selectedCity._id : '');
        } else {
          formik.handleChange(e);
        }
      }} onBlur={formik.handleBlur} displayEmpty size="small" className="!text-xs">
        <MenuItem value="" disabled><em className="!text-xs">{`Select ${label}`}</em></MenuItem>
        {options.map((option) => (
          <MenuItem key={option._id} value={option.name} className="!text-xs">{option.name}</MenuItem>
        ))}
      </Select>
      
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText error className="!text-xs">{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );

  return (
    <Box sx={{ p: 4, width: '100%' }}>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>Address</Typography>

      <Grid container spacing={2}>
        <SubCategoryHeader title="Location Info" />

        <Grid item xs={12} sm={4}>{renderSelectField("Country", "country", countries, <Globe />, true, "Select the country where your property is located")}</Grid>
        <Grid item xs={12} sm={4}>{renderInputField("State / Region", "state", <Globe />, formik.values.state, "text", false, "Enter the state or region of your property")}</Grid>
        <Grid item xs={12} sm={4}>{renderSelectField("City", "city", cities, <Globe />, true, "Select the city where your property is located")}</Grid>
        <Grid item xs={12} sm={4}>{renderInputField("Full Address", "address", <MapPin />, formik.values.address, "text", true, "Enter the complete street address of your property")}</Grid>
        <Grid item xs={12} sm={4}>{renderInputField("Zip Code", "zipcode", <MapPin />, formik.values.zipcode, "text", false, "Enter the postal/zip code of your property location")}</Grid>

        <SubCategoryHeader title="Coordinates" />

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ height: '170px', width: '100%', mt: { xs: 1, md: 0 }, mb: 2 }}>
              <MapContainer ref={mapRef} center={[formik.values.lat || 0, formik.values.lng || 0]} zoom={formik.values.lat && formik.values.lng ? 14 : 2} style={{ height: '100%', width: '100%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MapClickHandler formik={formik} />
                {(formik.values.lat && formik.values.lng) ? (
                  <Marker position={[formik.values.lat, formik.values.lng]} icon={homeIcon} ref={markerRef} draggable={true} eventHandlers={{ dragend: (e) => { const marker = e.target; const position = marker.getLatLng(); formik.setFieldValue('lat', position.lat); formik.setFieldValue('lng', position.lng); } }}>
                    <Popup>Property location</Popup>
                  </Marker>
                ) : null}
              </MapContainer>
              <Typography variant="caption" color="text.secondary" className="!text-xs mt-1 block">Click on the map to set location or drag marker to adjust position</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={12}>{renderInputField("Longitude", "lng", <Navigation />, formik.values.lng, "number", true, "The longitude coordinate of your property's location")}</Grid>
              <Grid item xs={12} sm={6} md={12}>{renderInputField("Latitude", "lat", <Navigation />, formik.values.lat, "number", true, "The latitude coordinate of your property's location")}</Grid>
            </Grid>
          </Grid>
        </Grid>

        <SubCategoryHeader title="Timezone" />

        <Grid item xs={12} sm={6}>{renderInputField("Timezone", "timeZoneName", <Clock />, formik.values.timeZoneName, "text", false, "The timezone of your property location (auto-detected)", true)}</Grid>

        <SubCategoryHeader title="Optional Details" />

        <Grid item xs={12} sm={4}>{renderInputField("Zone", "zone", <MapPin />, formik.values.zone, "text", false, "Specific area or zone within the city (e.g., Downtown, Beach Front)")}</Grid>
        <Grid item xs={12} sm={8}></Grid>

        <Grid item xs={12}>
          <Box sx={{ mb: 1, border: '1px solid #e0e0e0', borderRadius: 1, p: 2, backgroundColor: 'white' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-sm">Zone Description</Typography>
                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                  <Tooltip text="Describe the area where your property is located in different languages" iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
                </IconButton>
              </Box>

              <Box width="200px">
                <Autocomplete size="small" options={getAvailableLanguagesForZoneDescription()} getOptionLabel={(option) => option.name} renderInput={(params) => (
                  <TextField {...params} label="Add Language" variant="outlined" size="small" InputProps={{ ...params.InputProps, startAdornment: (<Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}><Globe size={16} className="text-gray-500" /></Box>), classes: { input: '!text-xs' } }} />
                )} onChange={(_, newValue) => { if (newValue) { handleZoneDescriptionChange(newValue._id, ''); } }} />
              </Box>
            </Box>

            <Box sx={{ maxHeight: '350px', overflowY: 'auto', pr: 1, '&::-webkit-scrollbar': { width: '6px' }, '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '10px' }, '&::-webkit-scrollbar-thumb': { background: '#c1c1c1', borderRadius: '10px' } }}>
              {defaultLanguage && (
                <Box sx={{ mb: 2, p: 2, border: '1px solid #e6f0fa', borderLeft: '4px solid #2196f3', borderRadius: 1, backgroundColor: '#f5f9ff' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Box display="flex" alignItems="center">
                        <Box component="span" sx={{ display: 'inline-block', width: 24, height: 24, borderRadius: '50%', backgroundColor: '#2196f3', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1, fontSize: '0.75rem', fontWeight: 'bold' }}>EN</Box>
                        <Typography variant="body2" fontWeight="medium" className="!text-xs">English (Default)</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField fullWidth value={formik.values.zoneDescription?.[defaultLanguage._id] || ''} onChange={(e) => handleZoneDescriptionChange(defaultLanguage._id, e.target.value)} placeholder="Describe the area in English" variant="outlined" size="small" multiline rows={2} InputProps={{ classes: { input: '!text-xs' } }} />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {Object.entries(formik.values.zoneDescription || {}).map(([languageId, description]) => {
                if (languageId === defaultLanguage?._id) return null;

                const langName = languages.find(lang => lang._id === languageId)?.name || '';
                const langCode = langName.substring(0, 2).toUpperCase();

                return (
                  <Box sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderLeft: '4px solid #9e9e9e', borderRadius: 1, backgroundColor: 'white' }} key={languageId}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Box display="flex" alignItems="center">
                            <Box component="span" sx={{ display: 'inline-block', width: 24, height: 24, borderRadius: '50%', backgroundColor: '#9e9e9e', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1, fontSize: '0.75rem', fontWeight: 'bold' }}>{langCode}</Box>
                            <Typography variant="body2" fontWeight="medium" className="!text-xs">{langName}</Typography>
                          </Box>
                          <IconButton onClick={() => { const updatedDescriptions = { ...formik.values.zoneDescription }; delete updatedDescriptions[languageId]; formik.setFieldValue('zoneDescription', updatedDescriptions); }} color="error" size="small" sx={{ p: 0.5 }}><DeleteIcon fontSize="small" /></IconButton>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TextField fullWidth value={description} onChange={(e) => handleZoneDescriptionChange(languageId, e.target.value)} placeholder={`Describe the area in ${langName}`} variant="outlined" size="small" multiline rows={2} InputProps={{ classes: { input: '!text-xs' } }} />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Box>

            {Object.keys(formik.values.zoneDescription || {}).length <= 1 && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', py: 2, opacity: 0.7 }}>
                <Globe size={24} className="mb-2 text-gray-400" />
                <Typography variant="body2" color="text.secondary" className="!text-xs text-center">Add additional languages to provide zone descriptions for international guests</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Address;
