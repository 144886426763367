import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Switch,
  IconButton,
  Modal,
  Typography,
  Snackbar,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { X } from "lucide-react";
import { FlaskConical, Check, EarthLock, CircleAlert } from "lucide-react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ConfiguratorRoot from "components/Configurator/ConfiguratorRoot";
import {
  useMaterialUIController,
  setOpenConfigurator,
  setFixedNavbar,
  setSidenavColor,
} from "context";
import { useTranslation } from "react-i18next";
const Configurator = () => {
  const { i18n, t } = useTranslation("common");
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, fixedNavbar, sidenavColor } = controller;
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [isStaging, setIsStaging] = useState(() => {
    const saved = localStorage.getItem('isStaging');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [openModal, setOpenModal] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);

  const handleStagingToggle = (newState) => {
    if (newState !== isStaging) {
      setOpenModal(true);
    }
  };

  const handleConfirmStaging = () => {
    const newStagingState = !isStaging;
    setIsStaging(newStagingState);
    localStorage.setItem('isStaging', JSON.stringify(newStagingState));
    setSnackbar({ open: true, message: 'Changes saved successfully!' });
    handleCloseConfigurator();
    setOpenModal(false);

    const event = new CustomEvent('stagingToggle', { detail: newStagingState });
    window.dispatchEvent(event);
  };

  const handleCancelStaging = () => {
    setOpenModal(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const languages = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
    { code: "es", label: "Español" },
  ];

  const changeLanguage = (event) => {
    const selectedLang = event.target.value;
    i18n.changeLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang);
  };
  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        sx={{
          color: "white",
        }}
      >
        <MDTypography variant="h6" fontWeight="medium">
          Sojori
        </MDTypography>
        <IconButton
          onClick={handleCloseConfigurator}
          sx={{ color: "white" }}
        >
          <X />
        </IconButton>
      </MDBox>
      <Divider />
      <MDBox p={2}>
        <MDBox mb={3}>
          <MDTypography variant="h6" fontWeight="medium" color="text">
            Sidenav Colors
          </MDTypography>
          <Box display="flex" mt={1}>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                sx={{
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  backgroundColor: color === "dark" ? "#000" : `${color}.main`,
                  marginRight: 1,
                }}
                onClick={() => setSidenavColor(dispatch, color)}
              />
            ))}
          </Box>
        </MDBox>

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="text">
            Navbar Fixed
          </MDTypography>
          <Switch
            checked={fixedNavbar}
            onChange={handleFixedNavbar}
            color="primary"
          />
        </MDBox>
        <Divider />
        <MDTypography variant="h6" fontWeight="medium" color="text">
          dashboard Mode
        </MDTypography>
        <MDBox
          mt={1}
          p={2}
          sx={{
            backgroundColor: "white",
            borderRadius: "4px",
            border: "1px dashed #f0eded"
          }}
          className="overflow-hidden"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

            <Button
              className={isStaging ? "!text-gray-400 bg-white !shadow-md " : "border-1 border-green-600 !text-white !bg-green-600 !shadow-md"}
              startIcon={isStaging ? <EarthLock /> : <Check />}
              onClick={() => handleStagingToggle(false)}
              disabled={!isStaging}
              sx={{ flex: 1, mr: 1 }}
            >

              Production
            </Button>




            <Button
              className={isStaging ? "!text-white !bg-green-600" : "border-1 border-gray-500 !text-gray-400 bg-white hover:!bg-gray-50 hover:!shadow-lg !shadow-md"}
              startIcon={isStaging ? <Check /> : <FlaskConical />}
              onClick={() => handleStagingToggle(true)}
              disabled={isStaging}
              sx={{ flex: 1, ml: 1 }}
            >
              Test
            </Button>



          </Box>
          <div className="flex items-center gap-1 py-2">
            <span className="text-green-500"> <CircleAlert size={14} /></span><span className="text-sm text-green-500">{isStaging ? "Test Mode Active" : "Production Mode Active"}</span>
            <span className="relative flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
            </span>
          </div>
          <div>

            <Select
              labelId="language-select-label"
              id="language-select"
              value={i18n.language}
              label="language"
              onChange={changeLanguage}
            >
              <MenuItem value="">
                <em>Select Language</em>
              </MenuItem>
              {languages.map((lang) => (
                <MenuItem key={lang.code} value={lang.code}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </MDBox>
      </MDBox>

      <Modal
        open={openModal}
        onClose={handleCancelStaging}

      >
        <Box className="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24] bg-[#f8fafb]">
          <Box className="w-full mb-11 p-4">
            <Typography className="!text-[14px] !text-center" id="modal-modal-description">
              Are you sure you want to {isStaging ? "disable" : "enable"} Test Mode ?
            </Typography>
          </Box>
          <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">
            <Box className="flex gap-2">
              <button
                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                onClick={handleCancelStaging}
              >
                Cancel
              </button>
              <button
                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                onClick={handleConfirmStaging}
              >
                Confirm
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </ConfiguratorRoot>
  );
}

export default Configurator;