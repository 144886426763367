import React, { useState, useEffect } from 'react';
import { getStories, deleteStory, getLanguages } from '../services/serverApi.adminConfig';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import StoriesModal from './StoriesModal';
import { toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button } from '@mui/material';


function Stories() {
    const [isLoading, setIsLoading] = useState(true);
    const [stories, setStories] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);
    const [error, setError] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchStories();
        fetchLanguages();
    }, [page, limit]);

    const fetchLanguages = async () => {
        try {
            const response = await getLanguages();
            setLanguages(response);
        } catch (error) {
            console.error('Error fetching languages:', error);
            toast.error('Failed to fetch languages');
        }
    };

    const fetchStories = async () => {
        try {
            const response = await getStories(page, limit, true);
            if (response.storys && Array.isArray(response.storys)) {
                setStories(response.storys);
                setTotal(response.total);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching stories:', error);
            setError('Failed to fetch stories. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenModal = (story = null) => {
        setSelectedStory(story);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedStory(null);
        setModalOpen(false);
    };




    const handleDeleteStory = async (storyId) => {
        if (window.confirm('Are you sure you want to delete this story?')) {
            try {
                await deleteStory(storyId);
                setStories(prevStories => prevStories.filter(story => story._id !== storyId));
                toast.success('Story deleted successfully');
            } catch (error) {
                console.error('Error deleting story:', error);
                toast.error('Failed to delete story');
            }
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const columns = [
        { header: "Name", body: (rowData) => <span>{rowData.name}</span> },
        { header: "City", body: (rowData) => <span>{rowData.city}</span> },
        {
            header: "Description",
            body: (rowData) => (
                <div>
                    {Object.entries(rowData.description).map(([langId, text]) => (
                        <div key={langId}>
                            <strong>{languages.find(lang => lang._id === langId)?.name || 'Unknown'}:</strong>
                            {text.substring(0, 200)}...
                        </div>
                    ))}
                </div>
            )
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModal(rowData)}>
                        <EditOffIcon className="text-white" />
                    </button>
                    <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteStory(rowData._id)}>
                        <DeleteSweepIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];



    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <div className="card p-4 !border-none">
            <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                Stories Management
            </Typography>
            <div className="mb-4">
                <Button onClick={() => handleOpenModal()} className="float-right !bg-medium-aquamarine text-white">Create New Story</Button>
            </div>
            <div>

                <div className="w-full">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <GlobalTable
                            data={stories}
                            columns={columns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            isNextDisabled={page >= Math.ceil(total / limit) - 1}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    )}
                </div>

            </div>
            <StoriesModal
                open={modalOpen}
                onClose={handleCloseModal}
                setStories={setStories}
                story={selectedStory}
            />
        </div>
    );
}

export default Stories;