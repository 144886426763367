import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useCalendar } from '../../hooks/useCalendar';
import TableCalendar from './TableCalendar';
import './Calendar.css';
import { CalendarHeader } from './CalendarHeader';
import FilterCalendar from './FilterCalendar';
import { getTasks } from '../../services/serverApi.task';
import { getStaff } from '../../services/serverApi.task';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

export const Calendar = ({
  groupedListings,
  listingId,
  selectedTaskTypes = [],
  selectedFrenchTasks = [],
  selectedDarijaTasks = [],
  selectedStatuses = ['Pending', 'Confirmed'],
}) => {
  const [listingsData, setListingsData] = useState([]);
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

  const {
    currentDate,
    goToNextPeriod,
    goToPreviousPeriod,
    daysInView,
    setViewType,
    viewType,
    goToToday,
    setCurrentDate,
    scrollBackward,
    scrollForward,
    scrollOffset,
    changeMonthView,
  } = useCalendar(new Date());

  const startDate = moment(daysInView[0]).format('YYYY-MM-DD');
  const endDate = moment(daysInView[daysInView.length - 1]).format(
    'YYYY-MM-DD',
  );
  const formattedDateRange = `${moment(startDate).format('MMM')}`;
  useEffect(() => {
    const fetchData = async () => {
      if (!groupedListings || groupedListings.length === 0) return;

      try {
        const allListings = groupedListings.flatMap((group) => group.listings);
        const listingIds = allListings.map((listing) => listing._id);
        const tasksData = await getTasks({
          startDate: startDate,
          endDate: endDate,
          listingIds,
          staging,
          selectedTaskTypes: selectedTaskTypes,
          selectedFrenchTasks: selectedFrenchTasks,
          selectedDarijaTasks: selectedDarijaTasks,
          selectedStatuses: selectedStatuses,
        });
        const processedListings = groupedListings.map((group) => {
          return {
            zone: group._id,
            properties: group.listings.map((listing) => {
              const listingTasks = tasksData[listing._id] || [];
              return {
                id: listing._id,
                name: listing.name,
                status: listing.status || 'Unknown',
                lastService: listing.lastService || 'N/A',
                cleanNumber: listing.cleanNumber || 'N/A',
                TS_CLEAN: listing.TS_CLEAN || 'N/A',
                TS_INSPECT: listing.TS_RECEPT || 'N/A',
                services: listing.services || [],
                events: listingTasks
                  .filter((task) => task.reservation)
                  .map((task) => ({
                    id: task.reservation._id,
                    propertyId: listing._id,
                    propertyName: listing.name,
                    duration: Math.ceil(
                      (new Date(task.reservation.departureDate) -
                        new Date(task.reservation.arrivalDate)) /
                        (1000 * 60 * 60 * 24),
                    ),
                    adults: task.reservation.adults,
                    children: task.reservation.children,
                    guestName: task.reservation.guestName,
                    reservationNumber: task.reservation.reservationNumber,
                    startDate: new Date(task.reservation.arrivalDate),
                    endDate: new Date(task.reservation.departureDate),
                    checkInTime: task.reservation.checkInTime,
                    checkOutTime: task.reservation.checkOutTime,
                    price: task.reservation.totalPrice,
                    paid: task.reservation.paymentStatus,
                    channel: task.reservation.channelName,
                    numberOfGuests: task.reservation.numberOfGuests,
                    isHalfDay: false,
                    color: '#C8BFE7',
                  })),
                tasks: listingTasks.map((task) => ({
                  id: task._id,
                  propertyId: listing._id,
                  date: new Date(task.startDate),
                  time:
                    task.TS_SEL &&
                    task.TS_SEL.length > 0 &&
                    task.TS_SEL[0].start &&
                    task.TS_SEL[0].end
                      ? `${task.TS_SEL[0].start}h-${task.TS_SEL[0].end}h`
                      : 'N/A',
                  title: task.subType,
                  status: task.status,
                  reservationNumber: task.reservationNumber,
                  assignmentStatus: task.assignmentStatus,
                  subType: task.subType,
                  // status: task.status,
                  type: task.type,
                  staffId: task.staffId,
                  Staff: task.staff,
                  propertyName: listing.name,
                  checkInTime: task.reservation.checkInTime,
                  checkOutTime: task.reservation.checkOutTime,
                  mode: task.mode,
                  TS_SEL: task.TS_SEL,
                  TS: task.TS,
                  TS_VAL: task.TS_VAL,
                  price: task.price,
                  paid: task.paid,
                  paymentMode: task.paymentMode,
                  duration: task.duration,
                  emergency: task.emergency,
                  presence: task.presence,
                  descriptions: task.descriptions,
                  images: task.images,
                  startDate: task.startDate,
                  endDate: task.endDate,
                  listingId: task.listingId,
                  reservationId: task.reservationId,
                  name: task.name,
                  taskStatus: task.taskStatus,
                  propertyUnit: task.propertyUnit,
                  services: task.services || [],
                  listing: {
                    name: listing?.name,
                    services: listing?.services || [],
                  },

                  // staffName: task.staffName,
                })),
              };
            }),
          };
        });
        setListingsData(processedListings);
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
      }
    };
    fetchData();
  }, [
    groupedListings,
    startDate,
    endDate,
    staging,
    selectedTaskTypes,
    selectedFrenchTasks,
    selectedDarijaTasks,
    selectedStatuses,
  ]);

  const handleTaskUpdated = (updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === updatedTask.id ? updatedTask : task,
      ),
    );
    fetchData();
  };

  const fetchStaff = async () => {
    setIsLoading(true);
    try {
      const response = await getStaff();
      console.log('response', response.data.data);
      if (response.data.data && Array.isArray(response.data.data)) {
        setStaff(response.data.data);
      } else {
        setStaff([]);
      }
    } catch (error) {
      console.error('Error fetching staff:', error);
      setStaff([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStaff();
  }, [groupedListings]);

  const handleSaveTask = async (savedTask) => {
    console.log('savedTask', savedTask);
    try {
      setListingsData((prevData) => {
        const isNewTask =
          !savedTask.task._id ||
          !prevData.some((group) =>
            group.properties.some((property) =>
              property.tasks.some((task) => task.id === savedTask.task._id),
            ),
          );

        const updatedData = prevData.map((group) => ({
          ...group,
          properties: group.properties.map((property) => {
            if (property.id === savedTask.task.listingId) {
              const createTaskObject = (task) => ({
                id: task._id,
                propertyId: task.listingId,
                date: new Date(task.startDate),
                name: task.name,
                time:
                  task.TS_SEL && task.TS_SEL.length > 0
                    ? `${task.TS_SEL[0].start}h-${task.TS_SEL[0].end}h`
                    : 'N/A',
                title: task.subType,
                status: task.status,
                reservationNumber: task.reservationNumber,
                assignmentStatus: task.assignmentStatus,
                subType: task.subType,
                type: task.type,
                staffId: task.staffId,
                Staff: task.staff,
                propertyName: property.name,
                checkInTime: task.reservation?.checkInTime,
                checkOutTime: task.reservation?.checkOutTime,
                mode: task.mode,
                TS_SEL: task.TS_SEL,
                TS: task.TS,
                TS_VAL: task.TS_VAL,
                price: task.price,
                paid: task.paid,
                paymentMode: task.paymentMode,
                duration: task.duration,
                emergency: task.emergency,
                presence: task.presence,
                descriptions: task.descriptions,
                images: task.images,
                startDate: task.startDate,
                endDate: task.endDate,
                listingId: task.listingId,
                reservationId: task.reservationId,
                taskCode: task.taskCode,
                openaAiEmergency: task.openaAiEmergency,
                taskStatus: task.taskStatus,
                propertyUnit: task.propertyUnit,
                services: task?.services || [],
              });

              let updatedTasks;
              const existingTaskIndex = property.tasks.findIndex(
                (task) => task.id === savedTask.task._id,
              );

              if (existingTaskIndex !== -1) {
                updatedTasks = property.tasks.map((task, index) =>
                  index === existingTaskIndex
                    ? createTaskObject(savedTask.task)
                    : task,
                );
              } else {
                updatedTasks = [
                  ...property.tasks,
                  createTaskObject(savedTask.task),
                ];
              }

              return {
                ...property,
                tasks: updatedTasks,
              };
            }
            return property;
          }),
        }));

        if (isNewTask) {
          toast.success('Task created successfully!');
        } else {
          toast.success('Task updated successfully!');
        }

        return updatedData;
      });
    } catch (error) {
      console.error('Failed to save task:', error);
      toast.error('Failed to save task. Please try again.');
    }
  };

  return (
    <div className="calendar1">
      <ToastContainer />
      {/* {groupedListings && groupedListings.length > 0 ? ( */}
      <TableCalendar
        groupedProperties={listingsData}
        daysInView={daysInView}
        staffData={staff}
        onTaskUpdated={handleTaskUpdated}
        onSaveTask={handleSaveTask}
        listingId={listingId}
        formattedDateRange={formattedDateRange}
        goToNextPeriod={goToNextPeriod}
        goToToday={goToToday}
        goToPreviousPeriod={goToPreviousPeriod}
        setViewType={setViewType}
        viewType={viewType}
        scrollBackward={scrollBackward}
        scrollForward={scrollForward}
        scrollOffset={scrollOffset}
        setCurrentDate={setCurrentDate}
        changeMonthView={changeMonthView}
        currentDate={currentDate}
      />
      {/* ) : (
                <div className="flex justify-center items-center h-64">
                    <CircularProgress sx={{ color: '#00b4b4' }} />
                </div>
            )} */}
    </div>
  );
};
