import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Checkbox, CircularProgress, Grid,
  Paper, FormControlLabel, Chip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getAmenities } from '../../services/serverApi.listing';
import Tooltip from 'components/TooltipGlobal/Tooltip';

function Amenities({ formik }) {
  const [amenities, setAmenities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAmenities();
  }, []);

  const fetchAmenities = async () => {
    setIsLoading(true);
    try {
      const response = await getAmenities();
      setAmenities(response.data.data);
    } catch (error) {
      console.error('Error fetching amenities:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleCheckboxChange = (event, amenityId) => {
    const { checked } = event.target;
    const currentAmenities = formik.values.listingAmenitiesIds || [];

    if (checked) {
      formik.setFieldValue('listingAmenitiesIds', [...currentAmenities, amenityId]);
    } else {
      formik.setFieldValue(
        'listingAmenitiesIds',
        currentAmenities.filter(id => id !== amenityId)
      );
    }
  };

  return (
    <Box sx={{ p: 3, bgcolor: 'white', borderRadius: 2, boxShadow: '0 2px 8px rgba(0,0,0,0.08)' }}>
      <Box className="flex justify-between">
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h6" fontWeight="bold">
            Amenities
          </Typography>
          <Tooltip
            text="This is displayed on your website and exported to Tripadvisor, 9flats, and Innclusive."
            iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
            position="top"
          >
            <InfoIcon fontSize="small" color="primary" sx={{ ml: 1, cursor: 'pointer' }} />
          </Tooltip>
        </Box>
        {formik.touched.listingAmenitiesIds && formik.errors.listingAmenitiesIds && (
          <span className="text-red-500 text-sm mt-2 float-right">
            {formik.errors.listingAmenitiesIds}
          </span>
        )}
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress style={{ color: '#66cdaa' }} />
        </Box>
      ) : (
        <Grid container spacing={2.5}>
          {amenities.length > 0 ? (
            amenities.map((amenity) => (
              <Grid item xs={12} sm={6} md={4} key={amenity._id}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    border: '1px solid #e0e0e0',
                    borderRadius: 1.5,
                    transition: 'all 0.2s ease',
                    '&:hover': { 
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      borderColor: '#6fd1bd' 
                    }
                  }}
                >
                  <Box 
                    component="img" 
                    src={amenity.iconUrl} 
                    alt={amenity.name} 
                    sx={{ width: 28, height: 28, mr: 2 }} 
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.listingAmenitiesIds?.includes(amenity._id) || false}
                        onChange={(event) => handleCheckboxChange(event, amenity._id)}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 23 },
                          color: '#b0b0b0',
                          '&.Mui-checked': {
                            color: '#6fd1bd',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {amenity.name}
                      </Typography>
                    }
                  />
                  {amenity.topAmentity && (
                    <Chip
                      label="Top"
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        height: 20,
                        fontSize: '0.65rem',
                        bgcolor: '#fff9c4',
                        color: '#7b6c00',
                        fontWeight: 600,
                        border: '1px solid #f9e976'
                      }}
                    />
                  )}
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" color="text.secondary">
                No amenities found.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default Amenities;