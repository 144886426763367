import React, { useState, useEffect } from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getConciergeType,
  updateConciergeType,
  createConciergeType,
  deleteConciergeType,
} from '../services/serverApi.task';
import { getcities } from '../../setting/services/serverApi.adminConfig';

import ModifyConiergeType from '../components/Concierge/edit.Concierge';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import DeleteUserDialog from '../../staff/components/DeleteUserDialog.jsx';

import { Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function ConciergeType() {
  const [conciergeTypeList, setConciergeTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedConciergeType, setSelectedConciergeType] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [headline, setHeadline] = useState('');
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [cities, setCities] = useState([]);

  const columns = [
    {
      field: 'type',
      header: 'Type',
      headerStyle: { width: '100px' },
    },

    {
      field: 'options',
      header: 'options',
      body: (rowData) => (
        <div>
          {(rowData.options || []).map((option, index) => (
            <div key={index} className="p-1">
              {option.pickup}{' '}
              <Tooltip
                title={`(${option.city} - 
                ${option.price})`}
                placement="right"
                key={index}
                className="text-gray-500"
              >
                ({option.city} -{option.price})
              </Tooltip>
            </div>
          ))}
        </div>
      ),
      headerStyle: { width: '200px' },
    },

    {
      field: 'actions',
      header: 'Action',
      body: (rowData) => (
        <>
          <IconButton
            className="!p-[7px] !rounded-lg bg-white shadow-sm"
            onClick={() => handleUpdate(rowData, 'updateType')}
          >
            <ModeEditOutlineOutlinedIcon style={{ color: '#66cdaa' }} />
          </IconButton>
          <IconButton
            className="!p-[7px] !rounded-lg bg-white shadow-sm mx-2"
            onClick={() => handleUpdate(rowData, 'deleteType')}
          >
            <DeleteIcon style={{ color: '#ff6b6b' }} />
          </IconButton>
        </>
      ),
      headerStyle: { width: '100px' },
    },
  ];
  const fetchCities = async () => {
    try {
      const response = await getcities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };
  const fetchConciergeType = async () => {
    setIsLoading(true);
    try {
      const response = await getConciergeType(0, 0, false, searchText);
      if (response.data && Array.isArray(response.data.data)) {
        setConciergeTypeList(response.data.data);
      } else {
        setConciergeTypeList([]);
      }
    } catch (error) {
      console.error('Error fetching Concierge Type:', error);
      setConciergeTypeList([]);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleOpenModifyDialog = () => {
    setOpenUpdateDialog(true);
  };
  const handleUpdate = (adminMember, action) => {
    setSelectedConciergeType(adminMember);
    // setOpenUpdateDialog(true);
    switch (action) {
      case 'deleteType':
        setOpenDeleteDialog(true);
        break;
      case 'updateType':
        setHeadline('Update Concierge Type');
        handleOpenModifyDialog();
        break;
      case 'createType':
        setHeadline('create Concierge Type');
        handleOpenModifyDialog();
        break;

      default:
        break;
    }
  };
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };
  const updateFunction = async (id, body) => {
    try {
      if (id) {
        const { data } = await updateConciergeType(id, body);

        setConciergeTypeList((prevType) =>
          prevType.map((type) => (type._id === id ? data?.data : type)),
        );
        handleCloseUpdateDialog();
        toast.success('updated');
      } else {
        const { data } = await createConciergeType(body);

        setConciergeTypeList((prevType) => [...prevType, data?.data]);
        handleCloseUpdateDialog();
        toast.success('created');
      }
    } catch (err) {
      console.error('Full error details:', err);
      console.error('Error response:', err.response);
      toast.error(
        err.response?.data?.message ||
          (err.response?.data?.errors || [])[0]?.message ||
          'Error updating from Backend',
      );
    }
  };
  const deleteFunction = async () => {
    try {
      const { data } = await deleteConciergeType(selectedConciergeType._id);
      setConciergeTypeList((prevType) =>
        prevType.filter((type) => type._id !== selectedConciergeType._id),
      );
      toast.success(
        `${selectedConciergeType.type} has been deletde successfully`,
      );
      handleCloseDeleteDialog();
    } catch (err) {
      console.error('Error blocking client:', err);
      toast.error('Failed to block client');
    }
  };
  useEffect(() => {
    fetchCities();
  }, []);
  useEffect(() => {
    fetchConciergeType();
  }, [searchText]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="Task">
        <div className="main-content">
          <main className="card">
            <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
              <div className="w-full md:flex-grow md:mr-4">
                <div className="flex w-full bg-white border rounded-md h-8 md:h-10">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search ..."
                    className="p-2 text-xs md:text-xs outline-none rounded-md w-full"
                  />
                  <div className="px-2">
                    <SearchIcon className="!w-3 !h-3 md:!w-4 md:!h-4" />
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => handleUpdate(null, 'createType')}
                className="w-full md:w-auto px-2 md:px-2.5 py-1 md:py-1.5 !text-xs !bg-medium-aquamarine text-white !rounded-md"
              >
                Add Concierge type
              </Button>
            </div>
            <ToastContainer position="top-right" autoClose={3000} />
            <GlobalTable
              data={conciergeTypeList}
              columns={columns}
              page={page}
              onPageChange={setPage}
              isNextDisabled={false}
              hasPagination={false}
              limit={limit}
              onLimitChange={setLimit}
              rowsPerPageOptions={[5, 10, 20, 50]}
            />
            <ModifyConiergeType
              open={openUpdateDialog}
              handleClose={handleCloseUpdateDialog}
              conciergeType={selectedConciergeType}
              functionToExecute={(id, body) => updateFunction(id, body)}
              headline={headline}
              city={cities}
            />
            <DeleteUserDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              functionToExecute={() => deleteFunction()}
              user={selectedConciergeType}
              title={'Delete Concierge Type'}
              message={`are you sure want to delete ${
                selectedConciergeType?.type || ''
              }`}
              btnTxt={'delete'}
              btnClass={'text-white !bg-red-600 !hover:bg-red-600/90'}
            />
          </main>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ConciergeType;
