import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { Select, MenuItem, FormControl } from '@mui/material';

const GlobalFilter = ({
  showCountry = true,
  showCity = true,
  showListing = true,
  showActive = true,
  otherFilters = [],
  selectedCountries = [],
  selectedCities = [],
  selectedListings = [],
  activeStatus = ['true'],
  countries = [],
  cities = [],
  listingOptions = [],
  onCountryChange,
  onCityChange,
  onListingChange,
  onActiveChange,
}) => {
  const statusOptions = [
    { id: 'true', name: 'Active' },
    { id: 'false', name: 'Inactive' },
  ];

  return (
    <div className="flex items-center gap-2">
      {showCountry && (
        <FilterSearch
          selectedItems={selectedCountries}
          options={countries}
          onItemsChange={onCountryChange}
          placeholder="Select Countries"
          idKey="name"
          labelKey="name"
        />
      )}

      {showCity && (
        <FilterSearch
          selectedItems={selectedCities}
          options={cities}
          onItemsChange={onCityChange}
          placeholder="Select Cities"
          idKey="_id"
          labelKey="name"
        />
      )}

      {showListing && (
        <FilterSearch
          selectedItems={selectedListings}
          options={listingOptions}
          onItemsChange={onListingChange}
          placeholder="Select Listings"
          idKey="id"
          labelKey="name"
        />
      )}

      {showActive && (
        <FilterSearch
          selectedItems={activeStatus}
          options={statusOptions}
          onItemsChange={onActiveChange}
          placeholder="Select Status"
          idKey="id"
          labelKey="name"
        />
      )}

      {otherFilters.map((filter, index) => (
        <FilterSearch
          key={index}
          selectedItems={filter.selectedItems}
          options={filter.options}
          onItemsChange={filter.onChange}
          placeholder={filter.placeholder}
          idKey={filter.idKey || 'id'}
          labelKey={filter.labelKey || 'name'}
        />
      ))}
    </div>
  );
};

export default GlobalFilter;
