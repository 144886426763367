import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Clock, Building2 } from 'lucide-react';
import DynamicListingName from '../../utils/DynamicListingName';
import DetailsTask from './DetailsTask';
import { CalendarHeader } from './CalendarHeader';
import { CircularProgress } from '@mui/material';

const TableTask = ({
  staff,
  daysInView,
  formattedDateRange,
  goToNextPeriod,
  goToToday,
  goToPreviousPeriod,
  setViewType,
  viewType,
  setCurrentDate,
  changeMonthView,
  scrollBackward,
  scrollForward,
  currentDate,
}) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    if (staff && staff.length > 0) {
      setIsLoading(false);
    }
  }, [staff]);

  const formatDate = (day) => {
    return {
      dayName: day.toLocaleDateString('en-US', { weekday: 'short' }),
      day: day.toLocaleDateString('en-US', { day: 'numeric' }),
      month: day.toLocaleDateString('en-US', { month: 'short' }),
    };
  };

  const renderTaskCard = (task) => {
    const statusColors = {
      ASSIGNED: '#BFDBFE',
      UNASSIGNED: '#FED7AA',
      ACCEPTED: '#BBF7D0',
      REFUSED: '#FECACA',
    };

    return (
      <div
        // className="p-1 rounded overflow-hidden max-w-full"
        className={`p-1 rounded overflow-hidden max-w-full 
    ${task.emergency === 'Urgent' ? 'bg-red-100' : 'bg-medium-aquamarine'}`}
        style={{
          //   backgroundColor: task.emergency === 'Urgent' ? '#FFE5E5' : '#F5F5F5',
          borderLeft: `4px solid ${
            statusColors[task.assignmentStatus] || 'gray'
          }`,
          color: 'white',
        }}
      >
        <div className="flex items-center gap-1 text-xs truncate">
          <Clock className="w-3 h-3 text-white-500 flex-shrink-0" />
          <span className="truncate">{task.time}</span>
          <span className="font-bold truncate">{task.title}</span>
        </div>
        <div className="flex items-center gap-1 mt-0.5 truncate">
          <Building2 className="w-3 h-3 text-white-400 flex-shrink-0" />
          <DynamicListingName
            listingName={task.ListingName}
            className="truncate max-w-full"
          />
        </div>
      </div>
    );
  };

  const renderStaffCell = (staffMember) => (
    <div className="property-cell truncate max-w-full">
      <div className="flex truncate">
        <span className="property-name truncate">{staffMember.username}</span>
      </div>
    </div>
  );

  const renderTaskCell = (staff, day) => {
    const staffTasks = staff.tasks.filter(
      (task) => task.date.toDateString() === day.toDateString(),
    );

    return (
      <div className="event-container relative">
        <div className="overflow-y-auto max-h-24 scrollbar-thin">
          {staffTasks.map((task, index) => (
            <div
              key={index}
              className="cursor-pointer mt-1"
              onClick={() => {
                setSelectedTask(task);
                setIsModalOpen(true);
              }}
            >
              {renderTaskCard(task)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const columns = [
    {
      field: 'username',
      header: (
        <CalendarHeader
          formattedDateRange={formattedDateRange}
          goToNextPeriod={goToNextPeriod}
          goToToday={goToToday}
          goToPreviousPeriod={goToPreviousPeriod}
          setViewType={setViewType}
          viewType={viewType}
          scrollBackward={scrollBackward}
          scrollForward={scrollForward}
          setCurrentDate={setCurrentDate}
          changeMonthView={changeMonthView}
          currentDate={currentDate}
        />
      ),
      body: renderStaffCell,
      frozen: true,
      style: { width: '250px', zIndex: 2, overflow: 'hidden' },
    },
    ...daysInView.map((day, index) => ({
      field: `day${index}`,
      header: (
        <div className="flex flex-col items-center w-full !text-[12px]">
          <div className="font-light">{formatDate(day).dayName}</div>
          <div className="font-bold">{formatDate(day).day}</div>
          <div className="font-light">{formatDate(day).month}</div>
        </div>
      ),
      body: (rowData) => renderTaskCell(rowData, day),
      style: { width: '120px', position: 'relative', overflow: 'hidden' },
    })),
  ];

  return (
    <div className="table-calendar-container w-full overflow-x-auto">
      <DataTable
        value={staff}
        className="table-calendar"
        scrollable
        scrollHeight="calc(100vh - 250px)"
        showGridlines
        frozenColumns={1}
        // loading={isLoading}
        emptyMessage={
          <div className="text-center p-4 text-gray-500">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <CircularProgress sx={{ color: '#00b4b4' }} />
              </div>
            ) : (
              'No tasks available'
            )}
          </div>
        }
      >
        {columns.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            body={col.body}
            frozen={index === 0}
            style={{
              ...(col.style || {}),
              ...(index === 0
                ? { position: 'sticky', left: 0, zIndex: 2 }
                : {}),
            }}
          />
        ))}
      </DataTable>

      {selectedTask && (
        <DetailsTask
          event={selectedTask}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default TableTask;
