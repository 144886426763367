import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Typography,
    Box,
    IconButton,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    FormHelperText
} from '@mui/material';
import {
    Bed,
    Bath,
    Users,
    HashIcon,
    Plus
} from 'lucide-react';
import BedTypePopup from './modals/BedTypePopup';
import Tooltip from 'components/TooltipGlobal/Tooltip';

const RoomTypeSelect = ({ roomType, types, index, formik }) => {
    const handleChange = (e) => {
        const selectedTypeId = e.target.value;
        const selectedType = types.find(type => type._id === selectedTypeId);

        formik.setFieldValue(`roomTypes.${index}.roomTypeConfigId`, selectedTypeId);
        formik.setFieldValue(`roomTypes.${index}.roomTypeName`, selectedType?.type);
        formik.setFieldValue(`roomTypes.${index}.rooms.${index}.roomName`, selectedType?.type);
    };

    return (
        <FormControl fullWidth>
            <Box display="flex" alignItems="center" mb={0.2}>
                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                    Type <span style={{ color: '#d32f2f' }}>*</span>
                </Typography>
                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                    <Tooltip
                        text="Select the room type category"
                        iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                        position="top"
                    />
                </IconButton>
            </Box>
            <Select
                name={`roomTypes[${index}].roomTypeConfigId`}
                value={roomType.roomTypeConfigId || ''}
                onChange={handleChange}
                error={
                    formik.touched.roomTypes?.[index]?.roomTypeConfigId &&
                    Boolean(formik.errors.roomTypes?.[index]?.roomTypeConfigId)
                }
                renderValue={(selected) => {
                    const selectedTypeObj = types.find(type => type._id === selected);
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={selectedTypeObj?.iconUrl}
                                alt={selectedTypeObj?.type}
                                style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                            <span className="!text-xs">{selectedTypeObj?.type}</span>
                        </Box>
                    );
                }}
            >
                {types.map((type) => (
                    <MenuItem key={type._id} value={type._id}>
                        <ListItemIcon>
                            <img src={type.iconUrl} alt={type.type} style={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        <ListItemText primary={<span className="!text-xs">{type.type}</span>} />
                    </MenuItem>
                ))}
            </Select>
            {formik.touched.roomTypes?.[index]?.roomTypeConfigId &&
                formik.errors.roomTypes?.[index]?.roomTypeConfigId && (
                    <FormHelperText error className="!text-xs">
                        {formik.errors.roomTypes[index].roomTypeConfigId}
                    </FormHelperText>
                )}
        </FormControl>
    );
};

const SubCategoryHeader = ({ title }) => (
    <Grid item xs={12}>
        <Box sx={{ mt: 1, mb: 0.5 }}>
            <Typography variant="subtitle1" fontWeight="medium" color="text.secondary">
                {title}
            </Typography>
            <Divider sx={{ mt: 0.2 }} />
        </Box>
    </Grid>
);

const RoomConfig = ({ formik, types, bedTypes }) => {
    const [isBedTypePopupOpen, setIsBedTypePopupOpen] = useState(false);

    const openBedTypePopup = () => {
        setIsBedTypePopupOpen(true);
    };

    const closeBedTypePopup = () => setIsBedTypePopupOpen(false);

    const handleAddBedType = (bedTypeData) => {
        const newRoomTypes = [...formik.values.roomTypes];
        const currentBedTypes = newRoomTypes[0].bedTypes || [];
        const existingIndex = currentBedTypes.findIndex(bt => bt.bedTypeId === bedTypeData.bedTypeId);

        if (existingIndex !== -1) {
            currentBedTypes[existingIndex].quantity += bedTypeData.quantity;
        } else {
            currentBedTypes.push(bedTypeData);
        }

        newRoomTypes[0] = {
            ...newRoomTypes[0],
            bedTypes: currentBedTypes
        };

        formik.setFieldValue('roomTypes', newRoomTypes);
    };

    const handleRemoveBedType = (bedTypeId) => {
        const newRoomTypes = [...formik.values.roomTypes];
        newRoomTypes[0] = {
            ...newRoomTypes[0],
            bedTypes: newRoomTypes[0].bedTypes.filter(bt => bt.bedTypeId !== bedTypeId)
        };
        formik.setFieldValue('roomTypes', newRoomTypes);
    };

    const renderTextField = (label, field, icon, value, type = 'text', isRequired = false, tooltipText = null) => (
        <FormControl
            fullWidth
            variant="outlined"
            size="small"
            error={formik.touched?.roomTypes?.[0] && Boolean(formik.errors?.roomTypes?.[0]?.[field])}
            sx={{ mb: 1 }}
        >
            <Box display="flex" alignItems="center" mb={0.2}>
                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                    {label} {isRequired && <span style={{ color: '#d32f2f' }}>*</span>}
                </Typography>
                {tooltipText && (
                    <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                        <Tooltip
                            text={tooltipText}
                            iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                            position="top"
                        />
                    </IconButton>
                )}
            </Box>

            <TextField
                error={formik.touched?.roomTypes?.[0] && Boolean(formik.errors?.roomTypes?.[0]?.[field])}
                value={field === 'roomCode'
                    ? formik.values?.roomTypes?.[0]?.rooms?.[0]?.roomCode || ''
                    : value || ''}
                onChange={(e) => {
                    const newRoomTypes = [...(formik.values?.roomTypes || [{
                        rooms: [{}],
                        bedTypes: []
                    }])];

                    if (field === 'roomCode') {
                        newRoomTypes[0] = {
                            ...newRoomTypes[0],
                            rooms: [{
                                ...newRoomTypes[0]?.rooms?.[0],
                                roomCode: e.target.value
                            }]
                        };
                    } else if (field === 'roomNumber') {
                        const newValue = Number(e.target.value) || 0;
                        newRoomTypes[0] = {
                            ...newRoomTypes[0],
                            roomNumber: newValue,
                            rooms: [{
                                ...newRoomTypes[0]?.rooms?.[0],
                                roomNumber: Number(newValue)
                            }]
                        };
                    } else if (field === 'roomTypeName') {
                        newRoomTypes[0] = {
                            ...newRoomTypes[0],
                            roomTypeName: e.target.value,
                            rooms: [{
                                ...newRoomTypes[0]?.rooms?.[0],
                                roomName: e.target.value
                            }]
                        };
                    } else {
                        const newValue = type === 'number' ? Number(e.target.value) || 0 : e.target.value;
                        newRoomTypes[0] = {
                            ...newRoomTypes[0],
                            [field]: newValue
                        };
                    }

                    formik.setFieldValue('roomTypes', newRoomTypes);
                }}
                fullWidth
                type={type}
                InputProps={{
                    startAdornment: icon ? React.cloneElement(icon, { size: 20, className: 'mr-2 text-gray-500' }) : null,
                    inputProps: field === 'roomCode' ? {} : { min: 0 },
                    classes: {
                        input: '!text-xs'
                    }
                }}
                size="small"
                variant="outlined"
                placeholder={`Enter ${label.toLowerCase()}`}
                sx={{ fontSize: '0.875rem' }}
            />

            {formik.touched?.roomTypes?.[0] && formik.errors?.roomTypes?.[0]?.[field] && (
                <FormHelperText error className="!text-xs">
                    {formik.errors.roomTypes[0][field]}
                </FormHelperText>
            )}
        </FormControl>
    );

    return (
        <Box sx={{ p: 4, width: '100%' }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>Room Configuration</Typography>

            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <RoomTypeSelect
                        roomType={formik.values.roomTypes[0]}
                        types={types}
                        index={0}
                        formik={formik}
                    />
                </Grid>

                <SubCategoryHeader title="Room Specs" />

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Room Title",
                        "roomTypeName",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.roomTypeName,
                        "text",
                        true,
                        "Name of the room type displayed to guests and in the system."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Number of Rooms",
                        "roomNumber",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.roomNumber,
                        "number",
                        true,
                        "How many rooms of this type are available."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Person Capacity",
                        "personCapacity",
                        <Users />,
                        formik.values.roomTypes[0]?.personCapacity,
                        "number",
                        true,
                        "Maximum number of guests that can stay in this room."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Number of Bedrooms",
                        "bedroomsNumber",
                        <Bed />,
                        formik.values.roomTypes[0]?.bedroomsNumber,
                        "number",
                        false,
                        "Total number of bedrooms in this room type."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Number of Beds",
                        "bedsNumber",
                        <Bed />,
                        formik.values.roomTypes[0]?.bedsNumber,
                        "number",
                        false,
                        "Total number of beds in this room type."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Number of Bathrooms",
                        "bathroomsNumber",
                        <Bath />,
                        formik.values.roomTypes[0]?.bathroomsNumber,
                        "number",
                        false,
                        "Total number of bathrooms in this room type."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Surface (m²)",
                        "surface",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.surface,
                        "number",
                        false,
                        "Size of the room in square meters."
                    )}
                </Grid>

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Key Code",
                        "roomCode",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.rooms[0]?.roomCode,
                        "text",
                        false,
                        "Unique code to identify this room (for access systems, etc.)"
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2, backgroundColor: 'white', mb: 1 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Bed size={20} className="mr-2 text-gray-500" />
                                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">Bed Types</Typography>
                                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                                    <Tooltip text="Select specific bed types and quantities in this room." iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
                                </IconButton>
                            </Box>

                            <IconButton onClick={openBedTypePopup} sx={{ backgroundColor: '#e6f4f1', color: '#2c7a7b', '&:hover': { backgroundColor: '#c5ebe5' }, border: '1px solid #2c7a7b', p: 0.5 }} size="small">
                                <Plus size={16} />
                            </IconButton>
                        </Box>

                        {formik.values.roomTypes[0]?.bedTypes?.length > 0 ? (
                            <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
                                {formik.values.roomTypes[0]?.bedTypes?.map((bedType) => {
                                    const bedTypeInfo = bedTypes.find(bt => bt._id === bedType.bedTypeId);
                                    return (
                                        <Chip key={bedType.bedTypeId} label={<span className="!text-xs">{`${bedTypeInfo?.name} (${bedType.quantity})`}</span>} onDelete={() => handleRemoveBedType(bedType.bedTypeId)} sx={{ backgroundColor: '#e6f0fa', color: '#1565c0', '& .MuiChip-deleteIcon': { color: '#1565c0', '&:hover': { color: '#d32f2f' } } }} size="small" />
                                    );
                                })}
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', py: 2, opacity: 0.7 }}>
                                <Bed size={24} className="mb-2 text-gray-400" />
                                <Typography variant="body2" color="text.secondary" className="!text-xs text-center">Click the + button to add bed types for this room</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>

                <SubCategoryHeader title="Pricing" />

                <Grid item xs={12} sm={4}>
                    {renderTextField(
                        "Base Price",
                        "basePrice",
                        <span>DH</span>,
                        formik.values.roomTypes[0]?.basePrice,
                        "number",
                        true,
                        "Standard nightly rate for this room type."
                    )}
                </Grid>
            </Grid>

            <BedTypePopup
                isOpen={isBedTypePopupOpen}
                onClose={closeBedTypePopup}
                bedTypes={bedTypes}
                selectedBedTypes={formik.values.roomTypes[0]?.bedTypes || []}
                onAddBedType={handleAddBedType}
            />
        </Box>
    );
};

export default RoomConfig;
