import React, { useState } from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const ListingFilter = ({ listings, selectedListings, setSelectedListings }) => {
  const { t } = useTranslation('common');
  return (
    <FilterSearch
      options={listings}
      selectedItems={selectedListings}
      onItemsChange={setSelectedListings}
      placeholder={t('Select Listings')}
      idKey="id"
      labelKey="name"
    />
  );
};

export default ListingFilter;
