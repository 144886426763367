import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginWorker } from '../../../redux/slices/AuthSlice';
import {
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AuthFormLayout from '../componenets/AuthFormLayout';

const InvitationPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, loading, error: authError } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin/overview');
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    
    if (!emailParam) {
      setError('Invalid invitation link. Email address is missing.');
      return;
    }
    
    setEmail(emailParam);
  }, [location, isAuthenticated, navigate]);

  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    
    setError(null);
    
    try {
      const resultAction = await dispatch(loginWorker({
        email,
        password
      }));
      
      if (loginWorker.fulfilled.match(resultAction)) {
        setSuccess(true);
      }
    } catch (err) {
    }
  };

  return (
    <AuthFormLayout
      variant="split"
      title="Welcome to Sojori"
      subtitle="Your trusted platform for managing all your reservations"
      formTitle="Account Activation"
      formSubtitle="Please set your password to activate your account"
      buttonText="Activate Account"
      loading={loading}
      error={error}
      successMessage={success ? "Your account has been successfully activated! Logging you in..." : null}
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        type="email"
        label="Email"
        variant="outlined"
        value={email}
        disabled
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlinedIcon sx={{ color: '#FF5722' }} />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5722',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5722',
            }
          }
        }}
      />

      <TextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        label="New Password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon sx={{ color: '#FF5722' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5722',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5722',
            }
          }
        }}
      />

      <TextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        label="Confirm Password"
        variant="outlined"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon sx={{ color: '#FF5722' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5722',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5722',
            }
          }
        }}
      />
    </AuthFormLayout>
  );
};

export default InvitationPage;