import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getCalnedarById(listingId, start, end) {
  return axios.get(`${MICROSERVICE_BASE_URL.CALENDAR}/${listingId}/calendar?startDate=${start || ''}&endDate=${end || ''}`);

}

export function updateCalnedar(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.CALENDAR}/update-calendar`, data);
}

export function geListingIds(staging = false) {
  return axios.get(`${MICROSERVICE_BASE_URL.LISTING}?staging=${staging}`);
}
// -------------------------multi--------------------
export function getMultiCalendById(listingId, start, end) {
  return axios.get(`${MICROSERVICE_BASE_URL.CALENDAR}/${listingId}/calendar?startDate=${start || ''}&endDate=${end || ''}`);
}

export function getMultiListing(page, limit, filters, staging = false) {
  const tagsString = filters?.tags ? filters.tags.join(',') : '';
  const baseUrl = `${MICROSERVICE_BASE_URL.LISTING}/?page=${page || 0}&limit=${limit || 20}&paged=true`;
  
  const params = new URLSearchParams();
  
  if (filters?.countryNames && Array.isArray(filters.countryNames)) {
    filters.countryNames.forEach(countryName => {
      if (countryName) {
        params.append('countryNames', countryName);
      }
    });
  }
  
  params.append('useActiveFilter', 'true');
  params.append('active', (filters?.active ?? true).toString());
  
  params.append('tags', tagsString);
  params.append('name', filters?.match || '');
  params.append('staging', staging.toString());
  
  if (filters?.cityIds && Array.isArray(filters.cityIds)) {
    filters.cityIds.forEach(cityId => {
      if (cityId) { 
        params.append('cityId[]', cityId);
      }
    });
  }
  
  return axios.get(`${baseUrl}&${params.toString()}`);
}
// --------------------filters enpoints -------------

export function getcountries(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}?page=${page || 0}&limit=${limit || 10}&paged=false`);
}

export function getcities(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=${page || 0}&limit=${limit || 20}&paged=false`);
}
export function getTags() {
  return axios.get(`${MICROSERVICE_BASE_URL.TAG}?paged=false`);
}


// -------------------------Inventory For Listings--------------------
export function getInventoryForListings(listingIds, startDate, endDate) {
  const listingIdsParams = listingIds.map(id => `listingIds[]=${id}`).join('&');
  
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_CALENDAR}/inventory/get-inventory?${listingIdsParams}&startDate=${startDate || ''}&endDate=${endDate || ''}`
  );
}

export function updateInventoryForListings(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/inventory/update-inventory`, data);
}

// -------------------------Listings----------------------------


export function getListings(staging = false) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.LISTING}/?staging=${staging}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data.data.map((listing) => ({
        id: listing._id,
        name: listing.name,
        checkInTime: listing.checkInTimeStart,
        checkOutTime: listing.checkOutTime,
        propertyUnit: listing.propertyUnit,
        roomTypes: listing.roomTypes,
      }));
    })
    .catch((error) => {
      console.error('Error fetching listings:', error);
      throw error;
    });
}