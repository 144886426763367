import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import Typography from "components/MDTypography";


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      sx={{
        backgroundColor: 'white !important',
        boxShadow: 'none',
        height: '64px',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        marginLeft: '0px',
        marginRight: '0px',
        // marginBottom: '30px',
        width: '100%',
        padding: '0 !important',
        transition: 'all 0.3s ease',
        '& .MuiBox-root': {
          marginRight: '0 !important'
        }
      }}
    >
      <Toolbar
        sx={{
          minHeight: '64px !important',
          padding: '0 24px !important',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <MDBox display="flex" alignItems="center" height="100%">
          <IconButton
            size="small"
            color="inherit"
            sx={{ color: '#333' }}
            onClick={handleMiniSidenav}
          >
            <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
          </IconButton>
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={false}
            sx={{ ml: 2 }}
          />
          {staging && (
            <Chip
              variant="outlined"
              label="Test Mode"
              color="warning"
              size="small"
              sx={{ ml: 2 }}
            />
          )}
        </MDBox>

        {!isMini && (
          <MDBox display="flex" alignItems="center" gap={1}>
            <MDBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                padding: '4px 12px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                },
                cursor: 'pointer'
              }}
            >
              {user?.firstName && (
                <Typography
                  variant="button"
                  fontWeight="regular"
                  sx={{
                    fontSize: '0.875rem',
                    color: '#333'
                  }}
                >
                  {user.firstName}
                </Typography>
              )}
              <Icon sx={{ color: '#333' }}>account_circle</Icon>

            </MDBox>
            <IconButton
              size="small"
              sx={{ color: '#333' }}
              onClick={handleConfiguratorOpen}
            >
              <Icon>settings</Icon>
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: '#333' }}
              onClick={handleOpenMenu}
            >
              <Icon>notifications</Icon>
            </IconButton>
            {renderMenu()}
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
