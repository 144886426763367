import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

// ----------------------Leads----------------

export const getLeads = async (queryString = '') => {
  try {
    const url = `${MICROSERVICE_BASE_URL.SRV_TASK}/lead/get${queryString ? `?${queryString}` : ''}`;
    console.log('Fetching leads with URL:', url);
    
    const response = await axios.get(url);
    console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const createLead = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_TASK}/lead/create`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating lead:', error);
    throw error;
  }
};

export const updateLead = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.SRV_TASK}/lead/update/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating lead:', error);
    throw error;
  }
};

export const deleteLead = async (id) => {
  try {
    const response = await axios.delete(`${MICROSERVICE_BASE_URL.SRV_TASK}/lead/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
};

// ----------------------Lead Details----------------
export const getLeadDetails = async (queryString = '') => {
  try {
    const url = `${MICROSERVICE_BASE_URL.SRV_TASK}/lead_details/get${queryString ? `?${queryString}` : ''}`;
    console.log('Fetching lead details with URL:', url);
    
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead details:', error);
    throw error;
  }
};


export const createLeadDetails = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_TASK}/lead_details/create`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating lead details:', error);
    throw error;
  }
};

export const updateLeadDetails = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.SRV_TASK}/lead_details/update/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating lead details:', error);
    throw error;
  }
};

export const deleteLeadDetails = async (id) => {
  try {
    const response = await axios.delete(`${MICROSERVICE_BASE_URL.SRV_TASK}/lead_details/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting lead details:', error);
    throw error;
  }
};