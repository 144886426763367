import React, { useState, useEffect } from 'react';
import { CalendarReservation } from './CalendarReservation';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import {
  getListings,
  getListingsList,
  getCities,
} from '../../tasks/services/serverApi.task';
import { getCountries } from '../services/serverApi.reservation';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem, FormControl } from '@mui/material';
import GlobalFilter from 'components/GlobalFilter/GlobalFilter';
import { useTranslation } from 'react-i18next';

function ReservationCalendar() {
  const { t } = useTranslation('common');
  const [groupedListings, setGroupedListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listingOptions, setListingOptions] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([
    'Pending',
    'Confirmed',
  ]);
  const { listingId } = useParams();
  const navigate = useNavigate();
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const [useActiveFilter] = useState(true);
  const [activeStatus, setActiveStatus] = useState(['true']);

  const statusOptions = [
    { id: 'Pending', name: 'Pending' },
    { id: 'Confirmed', name: 'Confirmed' },
    { id: 'Completed', name: 'Completed' },
    { id: 'CancelledByAdmin', name: 'CancelledByAdmin' },
    { id: 'CancelledByCustomer', name: 'CancelledByCustomer' },
    { id: 'CancelledByOta', name: 'CancelledByOta' },
    {
      id: 'CancelledAfterFailedPayment',
      name: 'CancelledAfterFailedPayment',
    },
    { id: 'OtherCancellation', name: 'OtherCancellation' },
  ];

  const activeStatusOptions = [
    { id: 'true', name: 'Active' },
    { id: 'false', name: 'Inactive' },
  ];

  const fetchListings = async () => {
    try {
      setIsLoading(true);
      const response = await getListings(
        staging,
        selectedListings,
        selectedCities,
        selectedCountries,
        useActiveFilter,
        activeStatus,
      );
      // console.log('Raw API response:', response);

      if (
        response?.data?.success === false ||
        !response?.data?.data ||
        response.data.data.length === 0
      ) {
        // console.log('No listings found or invalid response');
        setGroupedListings([]);
        // setError("No listings found for the selected criteria.");
        return;
      }

      if (
        response &&
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        // console.log('Original listings data:', response.data.data);

        const normalizedListings = response.data.data.map((group) => ({
          ...group,
          _id: group._id.toLowerCase(),
        }));
        // console.log('Normalized listings:', normalizedListings);

        let filteredListings = normalizedListings;
        if (listingId) {
          // console.log('Filtering by listingId:', listingId);
          filteredListings = normalizedListings
            .map((group) => ({
              ...group,
              listings: group.listings.filter(
                (listing) => listing._id === listingId,
              ),
              count: group.listings.filter(
                (listing) => listing._id === listingId,
              ).length,
            }))
            .filter((group) => group.count > 0);
          // console.log('Filtered listings by ID:', filteredListings);
        }

        const groupedByZone = filteredListings.reduce((acc, group) => {
          const zone = group._id;
          if (!acc[zone]) {
            acc[zone] = { _id: zone, listings: [], count: 0 };
          }
          acc[zone].listings = [...acc[zone].listings, ...group.listings];
          acc[zone].count += group.count;
          return acc;
        }, {});
        // console.log('Grouped by zone:', groupedByZone);

        const regroupedListings = Object.values(groupedByZone);
        // console.log('Final regrouped listings:', regroupedListings);

        setGroupedListings(regroupedListings);
      }
    } catch (err) {
      // console.error('Error fetching listings:', err);
      setGroupedListings([]);
      setError('Failed to fetch listings. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchListingOptions = async () => {
    try {
      const options = await getListingsList(staging);
      setListingOptions(options);
    } catch (err) {
      // console.error('Error fetching listing options:', err);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await getCities();
      setCities(response.data);
    } catch (error) {
      // console.error('Error fetching cities:', error);
      setError('Failed to fetch cities. Please try again.');
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      if (Array.isArray(response.data)) {
        const formattedCountries = response.data.map((country) => ({
          id: country.name,
          name: country.name,
          countryCode: country.countryCode,
          currency: country.currency,
        }));
        setCountries(formattedCountries);
      } else {
        console.error('Unexpected country data format:', response.data);
        setError('Invalid country data format');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setError('Failed to fetch countries');
    }
  };

  useEffect(() => {
    fetchListingOptions();
    fetchCities();
    fetchCountries();
  }, [staging]);

  useEffect(() => {
    fetchListings();
  }, [
    selectedListings,
    selectedCities,
    selectedCountries,
    selectedStatuses,
    staging,
    activeStatus,
  ]);

  const handleListingChange = (selectedIds) => {
    setSelectedListings(selectedIds);
    if (listingId && !selectedIds.includes(listingId)) {
      navigate('/reservation');
    }
  };

  const handleCityChange = (selectedCities) => {
    setSelectedCities(selectedCities);
  };

  const handleCountryChange = (selectedCountries) => {
    setSelectedCountries(selectedCountries);
  };

  const handleStatusChange = (selectedStatuses) => {
    setSelectedStatuses(selectedStatuses);
  };

  const handleActiveStatusChange = (selectedValues) => {
    setActiveStatus(selectedValues.length > 0 ? selectedValues : []);
  };

  const otherFilters = [
    {
      selectedItems: selectedStatuses,
      options: statusOptions,
      onChange: handleStatusChange,
      placeholder: 'Select Status',
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="reservation-container card !p-4">
        <div className="flex items-center mb-2 gap-2">
          <GlobalFilter
            selectedCountries={selectedCountries}
            selectedCities={selectedCities}
            selectedListings={selectedListings}
            activeStatus={activeStatus}
            countries={countries}
            cities={cities}
            listingOptions={listingOptions}
            onCountryChange={handleCountryChange}
            onCityChange={handleCityChange}
            onListingChange={handleListingChange}
            onActiveChange={handleActiveStatusChange}
            otherFilters={otherFilters}
          />
        </div>
        <CalendarReservation
          groupedListings={groupedListings}
          listingId={listingId}
          selectedCountries={selectedCountries}
          selectedStatuses={selectedStatuses}
        />
      </div>
    </DashboardLayout>
  );
}

export default ReservationCalendar;
