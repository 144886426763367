// const _package = require('../../package.json');
// const env = _package.config.env; // 'prod', 'staging', 'localhost'
const env = 'staging'; // 'prod', 'staging', 'localhost'


export const backendServerConfig = {
  appUrl:
    env == 'prod'
      ? ''
      :

      env == 'staging'
        ? 'https://dev.sojori.com'
        : 'http://localhost',
  TIMEOUT: '',
};

export const MICROSERVICE_BASE_URL =
  env == 'localhost'
    ? {
      LISTING_TESTING: `${backendServerConfig.appUrl}:4000/api/v1/listing-test/listings`,
      LISTING: `${backendServerConfig.appUrl}:4000/api/v1/listing/listings`,
      CALENDAR: `${backendServerConfig.appUrl}:4004/api/v1/calendar/calendar`,
      COUNTRY: `${backendServerConfig.appUrl}:4006/api/v1/admin/country`,
      CITY: `${backendServerConfig.appUrl}:4006/api/v1/admin/city`,
      UPLOAD_IMAGE: `${backendServerConfig.appUrl}:4006/api/v1/admin/upload/get_link`,
      CITIESMAPPING: `${backendServerConfig.appUrl}:4006/api/v1/admin/mapping`,
      BLOGS: `${backendServerConfig.appUrl}:4006/api/v1/admin/blog`,
      SLIDES: `${backendServerConfig.appUrl}:4006/api/v1/admin/slide-show`,
      PROPERTYTYPES: `${backendServerConfig.appUrl}:4000/api/v1/listing/propertyTypes`,
      OPENAI: `${backendServerConfig.appUrl}:4006/api/v1/admin/open-ai-init`,
      OPENAICONFIG: `${backendServerConfig.appUrl}:4006/api/v1/admin/open-ai-config`,
      TAG: `${backendServerConfig.appUrl}:4006/api/v1/listing/tag`,
      CHANNEL_MANAGER: `${backendServerConfig.appUrl}:4006/api/v1/admin/channel-manager`,
      CHANNELMANAGER: `${backendServerConfig.appUrl}:4006/api/v1/admin/channel-manager`,


      AMENITIES: `${backendServerConfig.appUrl}:4000/api/v1/listing/amenities`,
      AMENITIESMAPPING: `${backendServerConfig.appUrl}:4000/api/v1/listing/amenities/mapping/amenities`,
      RESERVATION: `${backendServerConfig.appUrl}:4002/api/v1/reservations/reservations`,
      BEDTYPE: `${backendServerConfig.appUrl}:4000/api/v1/listing/bedTypes`,
      TAGS: `${backendServerConfig.appUrl}/api/v1/listing/tag?page=0&limit=20&paged=false&search_text`,
      LANGUAGE: `${backendServerConfig.appUrl}:4006/api/v1/admin/language`,


      SRV_LISTING: `${backendServerConfig.appUrl}:4000/api/v1/listing`,
      SRV_LISTING_TESTING: `${backendServerConfig.appUrl}:4004/api/v1/listing-test/listings`,
      SRV_ADMIN: `${backendServerConfig.appUrl}:4006/api/v1/admin`,
      SRV_RESERVATION: `${backendServerConfig.appUrl}:4002/api/v1/reservations`,
      SRV_CALENDAR: `${backendServerConfig.appUrl}:4004/api/v1/calendar`,
      SRV_CRON: `${backendServerConfig.appUrl}:4009/api/v1/cron`,
      SRV_USER: `${backendServerConfig.appUrl}:4005/api/v1/user`,
      SRV_TASK: `${backendServerConfig.appUrl}:4003/api/v1/task`,

    }
    : {
      // don't use  
      LISTING: `${backendServerConfig.appUrl}/api/v1/listing/listings`,
      AMENITIES: `${backendServerConfig.appUrl}/api/v1/listing/amenities`,
      AMENITIESMAPPING: `${backendServerConfig.appUrl}/api/v1/listing/amenities/mapping/amenities`,
      CALENDAR: `${backendServerConfig.appUrl}/api/v1/calendar/calendar`,
      COUNTRY: `${backendServerConfig.appUrl}/api/v1/admin/country`,
      CITY: `${backendServerConfig.appUrl}/api/v1/admin/city`,
      UPLOAD_IMAGE: `${backendServerConfig.appUrl}/api/v1/admin/upload/get_link`,
      CITIESMAPPING: `${backendServerConfig.appUrl}/api/v1/admin/mapping`,
      BLOGS: `${backendServerConfig.appUrl}/api/v1/admin/blog`,
      SLIDES: `${backendServerConfig.appUrl}/api/v1/admin/slide-show`,
      RESERVATION: `${backendServerConfig.appUrl}/api/v1/reservations/reservations`,
      BEDTYPE: `${backendServerConfig.appUrl}/api/v1/listing/bedTypes`,
      PROPERTYTYPES: `${backendServerConfig.appUrl}/api/v1/listing/propertyTypes`,
      OPENAI: `${backendServerConfig.appUrl}/api/v1/admin/open-ai-init`,
      OPENAICONFIG: `${backendServerConfig.appUrl}/api/v1/admin/open-ai-config`,
      LANGUAGE: `${backendServerConfig.appUrl}/api/v1/admin/language`,
      TAG: `${backendServerConfig.appUrl}/api/v1/listing/tag`,
      CHANNEL_MANAGER: `${backendServerConfig.appUrl}/api/v1/admin/channel-manager`,
      CHANNELMANAGER: `${backendServerConfig.appUrl}/api/v1/admin/channel-manager`,

      // use 
      SRV_LISTING: `${backendServerConfig.appUrl}/api/v1/listing`,
      SRV_LISTING_TESTING: `${backendServerConfig.appUrl}:4004/api/v1/listing-test/listings`,
      SRV_ADMIN: `${backendServerConfig.appUrl}/api/v1/admin`,
      SRV_RESERVATION: `${backendServerConfig.appUrl}/api/v1/reservations`,
      SRV_CALENDAR: `${backendServerConfig.appUrl}/api/v1/calendar`,
      SRV_CRON: `${backendServerConfig.appUrl}/api/v1/cron`,
      SRV_USER: `${backendServerConfig.appUrl}/api/v1/user`,
      SRV_TASK: `${backendServerConfig.appUrl}/api/v1/task`,
      SRV_CHATBOT: `${backendServerConfig.appUrl}/api/v1/ai`,
    };