import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Chip,
    OutlinedInput
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createLeadDetails, updateLeadDetails } from '../../services/serverApi.lead';
import { getLeads } from '../../services/serverApi.lead';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const LeadDetailsModal = ({ open, onClose, setLeadDetails, leadDetail }) => {
    const { t } = useTranslation('common');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [leads, setLeads] = useState([]);
    const [solutions, setSolutions] = useState([
        'PMS',
        'Channel Manager',
        'Dynamic Pricing',
        'Messaging Tool',
        'Revenue Management',
        'Operations Software'
    ]);

    const validationSchema = Yup.object().shape({
        lead_id: Yup.string().required(t('Lead is required')),
        company_name: Yup.string().required(t('Company name is required')),
        timeline: Yup.string().required(t('Timeline is required')),
        current_solutions: Yup.array().min(1, t('At least one solution is required')),
        biggest_challenges: Yup.string().required(t('Biggest challenges are required')),
        planned_growth: Yup.number().required(t('Planned growth is required')).min(0, t('Must be at least 0')),
        expected_outcomes: Yup.string().required(t('Expected outcomes are required')),
        using_dynamic_pricing: Yup.boolean(),
        referral_source: Yup.string().required(t('Referral source is required')),
        promo_code: Yup.string(),
        discovery_notes: Yup.string(),
        client_type: Yup.string().required(t('Client type is required')),
        industry: Yup.string().required(t('Industry is required'))
    });

    useEffect(() => {
        fetchLeads();
    }, []);

    const fetchLeads = async () => {
         try {
              setIsLoading(true);
        
              const queryParams = 'paged=true&limit=100&page=0';
        
              const response = await getLeads(queryParams);
        
              if (response.success && Array.isArray(response.data)) {
                setLeads(response.data);
              } else {
                console.error('Invalid leads response:', response);
                setLeads([]);
              }
            } catch (error) {
              console.error('Error fetching leads:', error);
              setLeads([]);
            } finally {
              setIsLoading(false);
            }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            let response;
            if (leadDetail) {
                response = await updateLeadDetails(leadDetail.id, values);
            } else {
                response = await createLeadDetails(values);
            }

            if (response && response.success) {
                setLeadDetails(prevLeadDetails => {
                    if (!prevLeadDetails) return [response.data];
                    if (leadDetail) {
                        return prevLeadDetails.map(ld => ld.id === leadDetail.id ? response.data : ld);
                    } else {
                        return [...prevLeadDetails, response.data];
                    }
                });
                setSubmitting(false);
                onClose();
                toast.success(leadDetail ? t('Lead details updated successfully') : t('Lead details created successfully'));
            } else {
                throw new Error(t('Unexpected response structure'));
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.message || t('An error occurred while processing your request.'));
            setSubmitting(false);
            toast.error(leadDetail ? t('Error updating lead details') : t('Error creating lead details'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{leadDetail ? t('Update Lead Details') : t('Create New Lead Detail')}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        lead_id: leadDetail ? leadDetail.lead_id : '',
                        company_name: leadDetail ? leadDetail.company_name : '',
                        timeline: leadDetail ? leadDetail.timeline : '',
                        current_solutions: leadDetail ? leadDetail.current_solutions : [],
                        biggest_challenges: leadDetail ? leadDetail.biggest_challenges : '',
                        planned_growth: leadDetail ? leadDetail.planned_growth : 0,
                        expected_outcomes: leadDetail ? leadDetail.expected_outcomes : '',
                        using_dynamic_pricing: leadDetail ? leadDetail.using_dynamic_pricing : false,
                        referral_source: leadDetail ? leadDetail.referral_source : '',
                        promo_code: leadDetail ? leadDetail.promo_code : '',
                        discovery_notes: leadDetail ? leadDetail.discovery_notes : '',
                        client_type: leadDetail ? leadDetail.client_type : 'PMC',
                        industry: leadDetail ? leadDetail.industry : 'Short-Term Rentals'
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <Form className="mt-2">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <FormControl fullWidth error={touched.lead_id && Boolean(errors.lead_id)}>
                                    <InputLabel id="lead-label">{t('Lead')}</InputLabel>
                                    <Select
                                        labelId="lead-label"
                                        name="lead_id"
                                        value={values.lead_id}
                                        onChange={handleChange}
                                        label={t('Lead')}
                                    >
                                        {leads.map(lead => (
                                            <MenuItem key={lead.id} value={lead.id}>
                                                {lead.name} ({lead.email})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.lead_id && errors.lead_id && (
                                        <Typography color="error" variant="caption">{errors.lead_id}</Typography>
                                    )}
                                </FormControl>

                                {/* Row container for side-by-side fields */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <Box sx={{ flex: 1 }}>
                                        <Field name="company_name">
                                            {({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label={t('Company Name')}
                                                    fullWidth
                                                    error={touched.company_name && Boolean(errors.company_name)}
                                                    helperText={touched.company_name && errors.company_name}
                                                />
                                            )}
                                        </Field>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <Field name="timeline">
                                            {({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label={t('Timeline')}
                                                    fullWidth
                                                    error={touched.timeline && Boolean(errors.timeline)}
                                                    helperText={touched.timeline && errors.timeline}
                                                />
                                            )}
                                        </Field>
                                    </Box>
                                </Box>

                                <FormControl fullWidth error={touched.current_solutions && Boolean(errors.current_solutions)}>
                                    <InputLabel id="solutions-label">{t('Current Solutions')}</InputLabel>
                                    <Select
                                        labelId="solutions-label"
                                        multiple
                                        value={values.current_solutions}
                                        onChange={(e) => setFieldValue('current_solutions', e.target.value)}
                                        input={<OutlinedInput label={t('Current Solutions')} />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {solutions.map((solution) => (
                                            <MenuItem key={solution} value={solution}>
                                                {solution}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.current_solutions && errors.current_solutions && (
                                        <Typography color="error" variant="caption">{errors.current_solutions}</Typography>
                                    )}
                                </FormControl>

                                <Field name="biggest_challenges">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('Biggest Challenges')}
                                            fullWidth
                                            multiline
                                            rows={2}
                                            error={touched.biggest_challenges && Boolean(errors.biggest_challenges)}
                                            helperText={touched.biggest_challenges && errors.biggest_challenges}
                                        />
                                    )}
                                </Field>

                                <Field name="planned_growth">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('Planned Growth (properties in next 12 months)')}
                                            type="number"
                                            fullWidth
                                            error={touched.planned_growth && Boolean(errors.planned_growth)}
                                            helperText={touched.planned_growth && errors.planned_growth}
                                        />
                                    )}
                                </Field>

                                <Field name="expected_outcomes">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('Expected Outcomes')}
                                            fullWidth
                                            multiline
                                            rows={2}
                                            error={touched.expected_outcomes && Boolean(errors.expected_outcomes)}
                                            helperText={touched.expected_outcomes && errors.expected_outcomes}
                                        />
                                    )}
                                </Field>

                                <FormControl fullWidth>
                                    <InputLabel id="dynamic-pricing-label">{t('Currently Using Dynamic Pricing')}</InputLabel>
                                    <Select
                                        labelId="dynamic-pricing-label"
                                        name="using_dynamic_pricing"
                                        value={values.using_dynamic_pricing}
                                        onChange={(e) => setFieldValue('using_dynamic_pricing', e.target.value === 'true')}
                                        label={t('Currently Using Dynamic Pricing')}
                                    >
                                        <MenuItem value="true">{t('Yes')}</MenuItem>
                                        <MenuItem value="false">{t('No')}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="referral-source-label">{t('Referral Source')}</InputLabel>
                                    <Select
                                        labelId="referral-source-label"
                                        name="referral_source"
                                        value={values.referral_source}
                                        onChange={handleChange}
                                        label={t('Referral Source')}
                                    >
                                        <MenuItem value="Google Search">{t('Google Search')}</MenuItem>
                                        <MenuItem value="LinkedIn Ad">{t('LinkedIn Ad')}</MenuItem>
                                        <MenuItem value="Facebook Ad">{t('Facebook Ad')}</MenuItem>
                                        <MenuItem value="Referral">{t('Referral')}</MenuItem>
                                        <MenuItem value="Blog">{t('Blog')}</MenuItem>
                                        <MenuItem value="Event">{t('Event')}</MenuItem>
                                        <MenuItem value="Other">{t('Other')}</MenuItem>
                                    </Select>
                                </FormControl>

                                <Field name="promo_code">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('Promo Code (if any)')}
                                            fullWidth
                                            error={touched.promo_code && Boolean(errors.promo_code)}
                                            helperText={touched.promo_code && errors.promo_code}
                                        />
                                    )}
                                </Field>

                                <Field name="discovery_notes">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('Discovery Notes')}
                                            fullWidth
                                            multiline
                                            rows={3}
                                            error={touched.discovery_notes && Boolean(errors.discovery_notes)}
                                            helperText={touched.discovery_notes && errors.discovery_notes}
                                        />
                                    )}
                                </Field>

                                <FormControl fullWidth>
                                    <InputLabel id="client-type-label">{t('Client Type')}</InputLabel>
                                    <Select
                                        labelId="client-type-label"
                                        name="client_type"
                                        value={values.client_type}
                                        onChange={handleChange}
                                        label={t('Client Type')}
                                    >
                                        <MenuItem value="PMC">{t('PMC (Property Management Company)')}</MenuItem>
                                        <MenuItem value="Owner">{t('Owner')}</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="industry-label">{t('Industry')}</InputLabel>
                                    <Select
                                        labelId="industry-label"
                                        name="industry"
                                        value={values.industry}
                                        onChange={handleChange}
                                        label={t('Industry')}
                                    >
                                        <MenuItem value="Short-Term Rentals">{t('Short-Term Rentals')}</MenuItem>
                                        <MenuItem value="Long-Term Rentals">{t('Long-Term Rentals')}</MenuItem>
                                        <MenuItem value="Hotels">{t('Hotels')}</MenuItem>
                                        <MenuItem value="Mixed Portfolio">{t('Mixed Portfolio')}</MenuItem>
                                        <MenuItem value="Other">{t('Other')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} className="!bg-[#df5454] text-white">
                                    {t('Cancel')}
                                </Button>
                                <Button type="submit" className="!bg-medium-aquamarine text-white" disabled={isLoading}>
                                    {leadDetail ? t('Update') : t('Create')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default LeadDetailsModal;