import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const TimelineFilter = ({ timeLine, setTimeLine, showFrench = true }) => {
  const { t } = useTranslation('common');
  const timeLineOptions = [
    { id: 'Coming-Today', name: t('Coming-Today') },
    { id: 'Departure-today', name: t('Departure-today') },
    { id: 'Coming-Tomorrow', name: t('Coming-Tomorrow') },
    { id: 'Departure-tomorrow', name: t('Departure-tomorrow') },
    { id: 'Inside', name: t('Inside') },
    { id: 'Coming-in2days', name: t('Coming-in2days') },
    { id: 'Coming-in3days', name: t('Coming-in3days') },
    { id: 'Normal', name: t('Normal') },
    { id: 'Leave', name: t('Leave') },
    { id: 'One-Night', name: t('One-Night') },
  ];

  return (
    <FilterSearch
      options={timeLineOptions}
      selectedItems={timeLine}
      onItemsChange={setTimeLine}
      placeholder={t('Timeline')}
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default TimelineFilter;
