import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { useState } from "react";
// @mui material components
// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "components/Sidenav/styles/sidenavCollapse";
import List from "@mui/material/List";
import Divider, { dividerClasses } from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React  components
import SidenavCollapse from "components/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";
import { Box } from '@mui/material';


// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Collapse, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  // const [isOpen, setIsOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState(null);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, subRoutes }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === key}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );

    } else if (type === "dropdown") {
      // const isOpen = openMenus.includes(key);
      const isOpen = openMenus === key;

      returnValue = (
        <Box key={key}>
          <ListItemButton
            // onClick={() => setOpen(!open)}
            onClick={() => {
              if (isOpen) {
                // setOpenMenus(openMenus.filter((menu) => menu !== key));
                setOpenMenus(null);
              } else {
                // setOpenMenus([...openMenus, key]);
                setOpenMenus(key);
              }
            }}
            sx={{
              paddingY: "12px",
              paddingX: "24px",
              color: "black !important",
              fontSize: '1rem',
              backgroundColor: "white",
              "& .MuiListItemIcon-root": {
                color: "#F4762A !important"
              },
              "& .MuiListItemText-primary": {
                color: "black !important"
              }
            }}

          >
            <ListItemIcon
              sx={(theme) =>
                collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })
              }

            >

              {icon && icon}
            </ListItemIcon>
            <ListItemText
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                })
              }
              disableTypography

            />
            {isOpen ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </ListItemButton>
          <Collapse in={isOpen} timeout="auto">
            {subRoutes.map(({ key: subKey, name: subName, route: subRoute, icon: iconKey, type: typeKey }) => (
              typeKey === "title" ? (
                <MDTypography
                  key={subKey}
                  display="none"
                >
                  {typeKey}
                </MDTypography>
              ) : (
                <NavLink key={subKey} to={subRoute}>
                  <SidenavCollapse name={subName} icon={iconKey} active={subKey === collapseName} />
                </NavLink>
              )
            ))}
          </Collapse>

        </Box>

      );

    }

    return returnValue;
  });


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'inherit',
          height: '64px',
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '0 24px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
          <MDBox
            width={!brandName && "100%"}
            paddingLeft="0px"
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          height: 'calc(100vh - 64px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#FE9F1A',
            borderRadius: '0 !important'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#FE9F1A'
          }
        }}
      >
        <List>{renderRoutes}</List>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;