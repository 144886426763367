import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { X, User } from 'lucide-react';
import { createAdminV2 } from '../services/serverApi.task';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required').min(6),
    phone: Yup.string().required('Phone is required'),
    whatsapp: Yup.string(),
});

const CreateAdminV2Dialog = ({ open, onClose, onAdminCreated }) => {
    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const response = await createAdminV2({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                phone: values.phone,
                whatsapp: values.whatsapp
            });

            if (response.data) {
                onAdminCreated(response.data);
                onClose();
            }
        } catch (error) {
            console.error('Error creating admin:', error);
            setErrors({ submit: error.response?.data?.message || 'Failed to create admin' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="bg-medium-aquamarine flex justify-between items-center">
                <Typography variant="h6" className="text-white flex items-center gap-2">
                    <User className="w-5 h-5" />
                    Create Admin
                </Typography>
                <IconButton onClick={onClose} className="text-white">
                    <X className="w-5 h-5" />
                </IconButton>
            </DialogTitle>

            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    phone: '',
                    whatsapp: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                    <Form>
                        <DialogContent className="!space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    className="col-span-1"
                                />

                                <TextField
                                    fullWidth
                                    name="lastName"
                                    label="Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    className="col-span-1"
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    className="col-span-1"
                                />

                                <TextField
                                    fullWidth
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                    className="col-span-1"
                                />


                            </div>
                            <div className="grid grid-cols-2 gap-4">

                                <TextField
                                    fullWidth
                                    name="whatsapp"
                                    label="WhatsApp"
                                    value={values.whatsapp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.whatsapp && Boolean(errors.whatsapp)}
                                    helperText={touched.whatsapp && errors.whatsapp}
                                    className="col-span-1"
                                />
                                <TextField
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                            </div>
                        </DialogContent>

                        <DialogActions className="p-4">
                            <Button onClick={onClose} variant="outlined" className="!text-gray-500 !border-gray-500">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                className="!bg-medium-aquamarine !text-white"
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CreateAdminV2Dialog;