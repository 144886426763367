import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const StatusFilter = ({ status, setStatus }) => {
  const { t } = useTranslation('common');
  const statusOptions = [
    { id: 'Pending', name: t('Pending') },
    { id: 'Confirmed', name: t('Confirmed') },
    { id: 'Completed', name: t('Completed') },
    { id: 'CancelledByAdmin', name: t('CancelledByAdmin') },
    { id: 'CancelledByCustomer', name: t('CancelledByCustomer') },
    { id: 'CancelledByOta', name: t('CancelledByOta') },
    {
      id: 'CancelledAfterFailedPayment',
      name: t('CancelledAfterFailedPayment'),
    },
    { id: 'OtherCancellation', name: t('OtherCancellation') },
  ];

  return (
    <FilterSearch
      options={statusOptions}
      selectedItems={status}
      onItemsChange={setStatus}
      placeholder={t('Select Status')}
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default StatusFilter;
