import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Switch } from '@mui/material';
import {
  getStaffPlannig,
  updateStaffPlannig,
} from '../services/serverApi.task';
import ModifyStaffPlanning from './ModifyStaffPlanning';
import { ToastContainer } from 'react-toastify';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import EditOffIcon from '@mui/icons-material/EditOff';
import BlockIcon from '@mui/icons-material/Block';
import { CirclePlus } from 'lucide-react';

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const PublicStaffPlanning = () => {
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStaff();
  }, [page, limit, searchText]);
  useEffect(() => {
    setIsNextDisabled(page + 1 * limit >= totalCount);
  }, [page, limit, totalCount]);
  const fetchStaff = async () => {
    setIsLoading(true);

    getStaffPlannig(page, limit, true, searchText)
      .then(({ data }) => {
        setStaff(data.data);
        setTotalCount(data.total);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdate = (staffMember) => {
    setSelectedStaff(staffMember);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStaff(null);
  };
  const updateAbsent = async (staffId, present, dayName) => {
    const findStaff = staff.find((x) => x._id == staffId);

    if (!findStaff) {
      console.log('Staff not found');
      return;
    }

    const daySchedule = findStaff.staffPlanning?.schedule.find(
      (day) => day.day === dayName,
    );

    if (daySchedule) {
      daySchedule.present = present;

      updateStaffPlannig({
        staffId: findStaff._id,
        schedule: findStaff.staffPlanning?.schedule || [],
      })
        .then(({ data }) => {
          setStaff((prevStaff) =>
            prevStaff.map((s) =>
              s._id == staffId ? { ...s, staffPlanning: data.planning } : s,
            ),
          );
        })
        .catch((err) => console.log(err));
    } else {
      console.log(`No schedule found for ${dayName}`);
    }
  };
  const handleStaffUpdate = (staffId, planning) => {
    setStaff((prevStaff) =>
      prevStaff.map((s) =>
        s._id == staffId ? { ...s, staffPlanning: planning } : s,
      ),
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const columsBody = (rowData, dayName) => {
    const day = rowData?.staffPlanning?.schedule.find(
      (item) => item?.day == dayName,
    );
    return day?.present && day?.timings.length > 0 ? (
      <span>
        <Switch
          checked={day?.present}
          onChange={(event) => {
            console.log(event.target.checked);
            updateAbsent(rowData._id, event.target.checked, dayName);
          }}
          name="present"
          color="primary"
        />
        <span onClick={() => handleUpdate(rowData)}>
          {day?.timings.map((t) => `${t.start}-${t.end}`).join(' / ')}
        </span>
      </span>
    ) : day?.timings.length > 0 ? (
      <span className="inline-flex items-center gap-1 text-red-500">
        <Switch
          checked={day?.present}
          onChange={(event) => {
            console.log(event.target.checked);
            updateAbsent(rowData._id, event.target.checked, dayName);
          }}
          name="present"
          color="primary"
        />
        <span onClick={() => handleUpdate(rowData)}>
          <BlockIcon fontSize="small" />
          absent
        </span>
      </span>
    ) : (
      <span
        className="inline-flex items-center gap-1 text-green-500"
        onClick={() => handleUpdate(rowData)}
      >
        <CirclePlus className="w-5 h-5" />
      </span>
    );
  };
  const columns = [
    { field: 'username', header: 'Username' },
    ...daysOfWeek.map((day) => ({
      field: day,
      header: day,
      body: (rowData) => columsBody(rowData, day),
    })),
  ];

  if (error) {
    return (
      <div className="w-full h-64 flex justify-center items-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="card p-4">
      <Typography
        variant="h4"
        component="h1"
        className="mb-4 flex items-center gap-1"
        gutterBottom
      >
        <CalendarMonthIcon /> Staff Planning
      </Typography>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
        <div className="w-full md:flex-grow md:mr-4">
          <div className="flex w-full bg-white border rounded-md h-8 md:h-10">
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search staff..."
              className="p-2 text-xs md:text-xs outline-none rounded-md w-full"
            />
            <div className="px-2">
              <SearchIcon className="!w-3 !h-3 md:!w-4 md:!h-4" />
            </div>
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center h-64">
            <CircularProgress style={{ color: '#66cdaa' }} />
          </div>
        ) : staff.length > 0 ? (
          <GlobalTable
            data={staff}
            columns={columns}
            page={page}
            hasPagination={true}
            onPageChange={handlePageChange}
            isNextDisabled={isNextDisabled}
            limit={limit}
            onLimitChange={handleLimitChange}
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalCount={totalCount}
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            <CircularProgress sx={{ color: '#00b4b4' }} />
          </div>
        )}
      </div>
      <ModifyStaffPlanning
        open={openModal}
        handleClose={handleCloseModal}
        staff={selectedStaff}
        onStaffUpdate={handleStaffUpdate}
      />
    </div>
  );
};

export default PublicStaffPlanning;
