import React, { useState, useEffect } from 'react';
import {
  getCountries,
  getCities,
} from 'features/reservation/services/serverApi.reservation';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReservationDetails = ({
  editableDetails,
  isEditMode,
  handleInputChange,
}) => {
  const { t } = useTranslation('common');
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getCountries();
        console.log('Countries data:', countriesData.data);
        setCountries(countriesData.data);

        const citiesData = await getCities();
        console.log('Cities data:', citiesData.data);
        setCities(citiesData.data);
      } catch (error) {
        console.error('Error fetching country and city data:', error);
      }
    };

    fetchData();
  }, []);

  const handleBooleanFieldChange = (name, value) => {
    const boolValue = value === '' ? false : value === 'true';
    handleInputChange({
      target: {
        name,
        value: boolValue,
      },
    });
  };

  const renderInputField = (
    label,
    name,
    type = 'text',
    additionalProps = {},
  ) => {
    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <input
          type={type}
          name={name}
          onChange={handleInputChange}
          value={editableDetails[name]}
          readOnly={!isEditMode}
          list={
            name === 'guestCountry'
              ? 'countries'
              : name === 'guestCity'
              ? 'cities'
              : undefined
          }
          autoComplete="off"
          {...additionalProps}
          className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
          style={{
            backgroundColor: !isEditMode ? '#eee' : 'white',
            border: !isEditMode ? '1px solid #eee' : '1px solid #ccc',
            fontSize: 'small',
          }}
        />
        {name === 'guestCountry' && (
          <datalist id="countries" autoComplete="off">
            {countries.map((country) => (
              <option key={country.id} value={country.name} />
            ))}
          </datalist>
        )}
        {name === 'guestCity' && (
          <datalist id="cities" autoComplete="off">
            {cities.map((city) => (
              <option key={city.id} value={city.name} />
            ))}
          </datalist>
        )}
      </div>
    );
  };

  const renderSelectField = (label, name, options, isBoolean = false) => {
    let currentValue;
    if (isBoolean) {
      currentValue =
        editableDetails?.[name] === undefined || editableDetails?.[name] === ''
          ? 'false'
          : String(editableDetails[name]);
    } else {
      currentValue =
        editableDetails?.[name] != null ? String(editableDetails[name]) : '';
    }

    const optionsWithCurrent = [...options];
    if (
      !isBoolean &&
      currentValue &&
      !options.some((opt) => opt.value === currentValue)
    ) {
      optionsWithCurrent.push({ value: currentValue, label: currentValue });
    }

    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <select
          name={name}
          className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
          value={currentValue}
          readOnly={!isEditMode}
          disabled={!isEditMode}
          onChange={(e) => {
            if (isBoolean) {
              handleBooleanFieldChange(name, e.target.value);
            } else {
              handleInputChange(e);
            }
          }}
          style={{
            backgroundColor: isEditMode ? 'white' : '#eee',
            border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
            fontSize: 'small',
          }}
        >
          {isBoolean ? (
            <>
              <option value="true">{t('Yes')}</option>
              <option value="false">{t('No')}</option>
            </>
          ) : (
            <>
              <option value="">
                {t('Select')} {t(label)}
              </option>
              {optionsWithCurrent.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={[
                    'CancelledByAdmin',
                    'CancelledByCustomer',
                    'CancelledByOta',
                    'CancelledAfterFailedPayment',
                    'OtherCancellation',
                  ].includes(option.value)}
                >
                  {t(option.label)}
                </option>
              ))}
            </>
          )}
        </select>
      </div>
    );
  };

  const renderTimeSelectField = (label, name) => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const getValue = () => {
      const time = editableDetails[name];
      if (!time) return '';
      return parseInt(time.split(':')[0]) || 0;
    };
    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <select
          name={name}
          className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
          value={getValue()}
          readOnly={!isEditMode}
          onChange={handleInputChange}
          style={{
            backgroundColor: isEditMode ? 'white' : '#eee',
            border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
            fontSize: 'small',
          }}
        >
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {`${hour.toString().padStart(2, '0')}:00`}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderLocationField = (label, name, options) => {
    return (
      <div className="flex flex-col">
        <label className="font-bold text-[#676a6c] text-sm mb-2">
          {t(label)}
        </label>
        <Autocomplete
          disabled={!isEditMode}
          options={options || []}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option?.name || '';
          }}
          value={editableDetails[name] || null}
          onChange={(event, newValue) => {
            const valueToSave =
              typeof newValue === 'string'
                ? newValue
                : newValue?.name || newValue || '';

            handleInputChange({
              target: {
                name: name,
                value: valueToSave,
              },
            });
          }}
          onInputChange={(event, newInputValue) => {
            if (event?.type === 'change') {
              handleInputChange({
                target: {
                  name: name,
                  value: newInputValue,
                },
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              value={editableDetails[name] || ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: !isEditMode ? '#eee' : 'white',
                  '& fieldset': {
                    borderColor: !isEditMode ? '#eee' : '#ccc',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 'small',
                  color: '#676a6c',
                },
              }}
            />
          )}
          freeSolo
          isOptionEqualToValue={(option, value) => {
            if (!option || !value) return false;
            return option.name === value || option.name === value.name;
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {renderInputField('Check-in date', 'arrivalDate', 'date')}
        {renderInputField('Check-out date', 'departureDate', 'date')}
        {renderTimeSelectField('Check-in time', 'checkInTime')}
        {renderTimeSelectField('Check-out time', 'checkOutTime')}
        {renderSelectField(
          'Confirmed Check-in Time',
          'confirmedCheckInTime',
          [],
          true,
        )}
        {renderSelectField(
          'Confirmed Check-out Time',
          'confirmedCheckOutTime',
          [],
          true,
        )}
        {renderSelectField('Currency', 'currency', [
          { value: 'MAD', label: 'MAD' },
          { value: 'USD', label: 'USD' },
          { value: 'EUR', label: 'EUR' },
          { value: 'GBP', label: 'GBP' },
        ])}
        {renderSelectField('Status', 'status', [
          { value: 'Pending', label: 'Pending' },
          { value: 'Confirmed', label: 'Confirmed' },
          { value: 'Completed', label: 'Completed' },
          { value: 'CancelledByAdmin', label: 'CancelledByAdmin' },
          { value: 'CancelledByCustomer', label: 'CancelledByCustomer' },
          { value: 'CancelledByOta', label: 'CancelledByOta' },
          {
            value: 'CancelledAfterFailedPayment',
            label: 'CancelledAfterFailedPayment',
          },
          { value: 'OtherCancellation', label: 'OtherCancellation' },
        ])}
        {renderSelectField('Payment Status', 'paymentStatus', [
          { value: 'Paid', label: 'Paid' },
          { value: 'UnPaid', label: 'UnPaid' },
        ])}
        {renderSelectField('Time Line', 'timeLine', [
          { value: 'Coming-Today', label: 'Coming-Today' },
          { value: 'Coming-Tomorrow', label: 'Coming-Tomorrow' },
          { value: 'Coming-in2days', label: 'Coming-in2days' },
          { value: 'Coming-in3days', label: 'Coming-in3days' },
          { value: 'Normal', label: 'Normal' },
          { value: 'Departure-Today', label: 'Departure-Today' },
          { value: 'Departure-Tomorrow', label: 'Departure-Tomorrow' },
          { value: 'Inside', label: 'Inside' },
          { value: 'Leave', label: 'Leave' },
          { value: 'One-Night', label: 'One-Night' },
        ])}
        {renderLocationField('Guest Country', 'guestCountry', countries)}
        {renderLocationField('Guest City', 'guestCity', cities)}
      </div>
      {/* <div className="flex justify-end mt-4">
        <button className="px-2 py-1 bg-[#dcf6f6] !rounded-sm flex items-center gap-2">
          <i className="pi pi-download text-[#00b4b4] text-xs"></i>
          <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px]">
            {t('Download invoice')}
          </span>
        </button>
      </div> */}
    </>
  );
};

export default ReservationDetails;
