import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const ChannelNameFilter = ({ channelName, setChannelName }) => {
  const { t } = useTranslation('common');
  const channelOptions = [
    { id: 'sojori', name: 'Sojori' },
    { id: 'BookingCom', name: 'Booking' },
    { id: 'AirBNB', name: 'AirBNB' },
  ];

  return (
    <FilterSearch
      options={channelOptions}
      selectedItems={channelName}
      onItemsChange={setChannelName}
      placeholder={t('Channel')}
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default ChannelNameFilter;
