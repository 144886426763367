import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch as useReduxDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/slices/AuthSlice';
import { hasRouteAccess } from "routes";
import RoutePermissionWrapper from 'components/wrappers/RoutePermissionWrapper';
import NotFoundPage from "components/notFoundPage/NotFoundPage";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// Material Dashboard 2 React example components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import { getRoutes } from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Chip from "@mui/material/Chip";
import {
  Zap,
  ChevronDown
} from 'lucide-react';

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import logoSojori from "assets/images/sojori-logo.png";

export default function Admin() {
  const [controller, controllerDispatch] = useMaterialUIController();
  const reduxDispatch = useReduxDispatch();
  const { user } = useSelector(state => state.auth);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    reduxDispatch(logout());
    navigate('/login');
  };

  const logoTextStyle = {
    color: '#595959',
    fontWeight: 'bold',
    marginLeft: '6px',
    fontSize: '22px',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#000000'
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(controllerDispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutesComponents = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.type === 'dropdown' && route.subRoutes) {
        return getRoutesComponents(route.subRoutes);
      }

      if (route.route) {
        if (route.route === '/404') {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
        
        return (
          <Route 
            exact 
            path={route.route} 
            element={
              <RoutePermissionWrapper>
                {route.component}
              </RoutePermissionWrapper>
            } 
            key={route.key} 
          />
        );
      }

      return null;
    });

  const filteredRoutes = getRoutes(user);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName={
              <div className="relative">
                <div
                  className="flex items-center cursor-pointer justify-between"
                  onClick={handleClick}
                  aria-controls={open ? 'profile-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <div className="flex items-center">
                    <img
                      src={logoSojori}
                      alt="Sojori"
                      style={{ width: '2rem' }}
                    />
                    <span className="text-[#595959] font-bold !ml-[6px] text-[22px] hover:text-black transition-colors duration-300"
                      style={{
                      }}>
                      Sojori
                    </span>

                  </div>
                </div>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'profile-button',
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiMenu-list': {
                        padding: '4px 0',
                      },
                    },
                  }}
                >
                  <MenuItem onClick={() => {
                    handleClose();
                    navigate('/');
                  }}>
                    <div className="flex items-center gap-2">
                      <img src={logoSojori} alt="Sojori" style={{ width: '1.5rem' }} />
                      <span style={{ fontWeight: 'bold' }}>Sojori</span>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <div className="flex items-center gap-2">
                      <ExitToAppIcon />
                      <span>Logout</span>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            }
            routes={filteredRoutes}
          />
          <Configurator />
        </>
      )}
      <Routes>
        {getRoutesComponents(filteredRoutes)}
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}
