import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/AuthSlice";
import uploadImage from "./slices/UploadSlice";
import dateReducer from "./slices/DateSlice";
import formData from "./slices/FormSlice";

const reducer = {
  auth: authReducer,
  uploadData: uploadImage,
  yearDateDta: dateReducer,
  formData: formData
};

export const store = configureStore({
  reducer,
  devTools: true,
});

export default store;