import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, Alert, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Add, Remove, ExpandMore, Edit } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUsefulNumber, updateUsefulNumber } from '../services/serverApi.adminConfig';
import { toast, ToastContainer } from 'react-toastify';

const UsefulNumber = () => {
    const [usefulNumbers, setUsefulNumbers] = useState({});
    const [newCategoryName, setNewCategoryName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editingCategory, setEditingCategory] = useState('');
    const [editedCategoryName, setEditedCategoryName] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        fetchUsefulNumbers();
    }, []);

    const fetchUsefulNumbers = async () => {
        setIsLoading(true);
        try {
            const response = await getUsefulNumber();
            if (response.data.usefulNumber && response.data.usefulNumber.usefulNumber) {
                setUsefulNumbers(response.data.usefulNumber.usefulNumber);
            } else {
                setUsefulNumbers({});
            }
        } catch (error) {
            setUsefulNumbers({});
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateUsefulNumbers = async () => {
        setIsLoading(true);
        try {
            await updateUsefulNumber({ usefulNumberData: usefulNumbers });
            toast.success('Useful Numbers updated successfully');
        } catch (error) {
            toast.error('Error updating Useful Numbers: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddCategory = () => {
        if (!newCategoryName.trim()) {
            setErrorMessage('Please fill in the category name');
            return;
        }
        if (!usefulNumbers[newCategoryName]) {
            setUsefulNumbers({ ...usefulNumbers, [newCategoryName]: [''] });
            setNewCategoryName('');
            setErrorMessage('');
        } else {
            setErrorMessage('Category already exists');
        }
    };

    const handleAddItem = (categoryName) => {
        setUsefulNumbers({
            ...usefulNumbers,
            [categoryName]: [...usefulNumbers[categoryName], '']
        });
    };

    const handleRemoveItem = (categoryName, index) => {
        setItemToDelete({ type: 'item', categoryName, index });
        setDeleteDialogOpen(true);
    };

    const handleChangeItem = (categoryName, index, value) => {
        const newCategory = [...usefulNumbers[categoryName]];
        newCategory[index] = value;
        setUsefulNumbers({
            ...usefulNumbers,
            [categoryName]: newCategory
        });
    };

    const handleRemoveCategory = (categoryName) => {
        setItemToDelete({ type: 'category', categoryName });
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete.type === 'category') {
            const { [itemToDelete.categoryName]: _, ...newUsefulNumbers } = usefulNumbers;
            setUsefulNumbers(newUsefulNumbers);
        } else if (itemToDelete.type === 'item') {
            setUsefulNumbers({
                ...usefulNumbers,
                [itemToDelete.categoryName]: usefulNumbers[itemToDelete.categoryName].filter((_, i) => i !== itemToDelete.index)
            });
        }
        setDeleteDialogOpen(false);
        setItemToDelete(null);
    };

    const startEditingCategory = (categoryName) => {
        setEditingCategory(categoryName);
        setEditedCategoryName(categoryName);
    };

    const handleEditCategoryName = (oldName) => {
        const newName = editedCategoryName.trim();
        if (oldName === newName || !newName) {
            setEditingCategory('');
            setEditedCategoryName('');
            return;
        }
        if (usefulNumbers[newName]) {
            setErrorMessage('Category already exists');
            return;
        }
        const { [oldName]: categoryNumbers, ...rest } = usefulNumbers;
        setUsefulNumbers({
            ...rest,
            [newName]: categoryNumbers
        });
        setEditingCategory('');
        setEditedCategoryName('');
    };

    return (
        <div className="card p-4 !border-none">
            <Typography variant="h6" className="mb-4">Useful Numbers</Typography>

            <ToastContainer />
            <div className="flex mb-4 gap-2">
                <TextField
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder="New Category"
                    variant="outlined"
                    fullWidth
                />
                <Button
                    variant="contained"
                    className="!bg-medium-aquamarine !text-white"
                    onClick={handleAddCategory}
                    disabled={isLoading}
                >
                    <AddCircleIcon />
                </Button>
            </div>
            {errorMessage && <Alert severity="error" className="!mb-4" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
            {Object.entries(usefulNumbers).map(([categoryName, numbers]) => (
                <Accordion key={categoryName} className="mb-4">
                    <AccordionSummary
                        expandIcon={<ExpandMore className="!text-white" />}
                        aria-controls={`${categoryName}-content`}
                        id={`${categoryName}-header`}
                        className="!bg-[#6fd1bd] text-white"
                    >
                        <div className="flex gap-2 items-center w-full">
                            <span className="text-sm ml-2 bg-white text-[#6fd1bd] px-2 py-1 rounded-full">
                                {numbers.length}
                            </span>
                            {editingCategory === categoryName ? (
                                <TextField
                                    value={editedCategoryName}
                                    onChange={(e) => setEditedCategoryName(e.target.value)}
                                    onBlur={() => handleEditCategoryName(categoryName)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleEditCategoryName(categoryName);
                                        }
                                    }}
                                    autoFocus
                                    variant="standard"
                                    className="!text-white"
                                    InputProps={{
                                        style: { color: 'white' }
                                    }}
                                />
                            ) : (
                                <>
                                    <span className="text-md font-semibold">{categoryName}</span>
                                    <IconButton onClick={() => startEditingCategory(categoryName)} size="small" className="!text-white ml-auto">
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </>
                            )}
                        </div>
                        <Button
                            startIcon={<DeleteIcon />}
                            onClick={() => handleRemoveCategory(categoryName)}
                            disabled={isLoading}
                            className="!text-white"
                        >
                        </Button>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <div className="flex justify-between gap-2 my-4">
                                <Button
                                    variant="outlined"
                                    color="success"
                                    startIcon={<Add />}
                                    onClick={() => handleAddItem(categoryName)}
                                    className="!border-medium-aquamarine !text-medium-aquamarine"
                                    disabled={isLoading}
                                >
                                    Add Number
                                </Button>
                            </div>
                            {numbers.map((item, index) => (
                                <div key={index} className="flex items-center mb-2">
                                    <TextField
                                        value={item}
                                        onChange={(e) => handleChangeItem(categoryName, index, e.target.value)}
                                        placeholder={`Number ${index + 1}`}
                                        variant="outlined"
                                        fullWidth
                                        disabled={isLoading}
                                    />
                                    <IconButton
                                        onClick={() => handleRemoveItem(categoryName, index)}
                                        color="error"
                                        disabled={isLoading}
                                    >
                                        <Remove />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            <div className="flex items-center justify-center mt-4">
                {isLoading && <CircularProgress size={24} className="mr-2" />}
                <Button
                    variant="contained"
                    className="!bg-medium-aquamarine !text-white"
                    onClick={handleUpdateUsefulNumbers}
                    disabled={isLoading}
                >
                    {isLoading ? 'Saving Changes...' : 'Save'}
                </Button>
            </div>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {itemToDelete?.type === 'category'
                            ? `Are you sure you want to delete the category "${itemToDelete.categoryName}" and all its numbers?`
                            : `Are you sure you want to delete this number?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UsefulNumber;