import React from 'react';
import { TextField, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import GlobalFilter from 'components/GlobalFilter/GlobalFilter';
import resetSvgIcon from 'assets/images/resetSvgIcon.svg';


const FilterSection = ({
    filters,
    pagination,
    onPaginationChange,
    onFilterChange,
    cities,
    countries,
    listings 
}) => {
    const handleCountryChange = (selectedCountries) => {
        onFilterChange({
            ...filters,
            countryNames: selectedCountries
        });
    };

    const handleCityChange = (selectedCities) => {
        onFilterChange({
            ...filters,
            cityIds: selectedCities
        });
    };

    const handleListingChange = (selectedListings) => {
        onFilterChange({
            ...filters,
            listingId: selectedListings 
        });
    };

    const handleActiveChange = (selectedValues) => {
        onFilterChange({
            ...filters,
            useActiveFilter: selectedValues.length === 1,
            active: selectedValues 
        });
    };

    const handleClearFilters = () => {
        onFilterChange({
            cityIds: [],
            countryNames: [],
            useActiveFilter: true,
            active: ['true'],
            listingId: []
        });
        onPaginationChange({ ...pagination, page: 0 });
    };

    return (
        <div className="flex flex-wrap gap-4 mb-4 sm:flex-row flex-col">

            <div className="flex items-center gap-2 mb-4">
                <GlobalFilter
                    showCountry={true}
                    showCity={true}
                    showListing={true}
                    showActive={true}
                    selectedCountries={filters.countryNames || []}
                    selectedCities={filters.cityIds || []}
                    selectedListings={filters.listingId || []}
                    activeStatus={filters.active}
                    countries={countries.map(country => ({
                        _id: country.name,
                        name: country.name
                    }))}
                    cities={cities}
                    listingOptions={listings.map(listing => ({
                        id: listing._id, 
                        _id: listing._id,
                        name: listing.name || `Listing ${listing._id}` 
                    }))}
                    onCountryChange={handleCountryChange}
                    onCityChange={handleCityChange}
                    onListingChange={handleListingChange}
                    onActiveChange={handleActiveChange}
                    isMultiple={true}
                />

                <Button
                    onClick={handleClearFilters}
                    className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !h-9 !w-9 !justify-center"
                >
                    <img src={resetSvgIcon} alt="reset" className="mr-2" />
                </Button>

            </div>


        </div>
    );
};

export default FilterSection;
