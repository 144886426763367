import React, { useState, useEffect } from 'react';
import { Calendar } from './components/Calendar/Calendar';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import {
  getListings,
  getListingsList,
  getCities,
  getTaskConfigs,
  getCountries,
} from './services/serverApi.task';
import { useParams, useNavigate } from 'react-router-dom';
import GlobalFilter from 'components/GlobalFilter/GlobalFilter';
import CircularProgress from '@mui/material/CircularProgress'; // Add this import

function Task() {
  const [groupedListings, setGroupedListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listingOptions, setListingOptions] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);
  const { listingId } = useParams();
  const navigate = useNavigate();
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
  const [selectedFrenchTasks, setSelectedFrenchTasks] = useState([]);
  const [selectedDarijaTasks, setSelectedDarijaTasks] = useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState([]);
  const [frenchTaskOptions, setFrenchTaskOptions] = useState([]);
  const [darijaTaskOptions, setDarijaTaskOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([
    'Pending',
    'Confirmed',
  ]);
  const [useActiveFilter] = useState(true);
  const [activeStatus, setActiveStatus] = useState(['true']);

  const statusOptions = [
    { id: 'Pending', name: 'Pending' },
    { id: 'Confirmed', name: 'Confirmed' },
    { id: 'Completed', name: 'Completed' },
    { id: 'CancelledByAdmin', name: 'CancelledByAdmin' },
    { id: 'CancelledByCustomer', name: 'CancelledByCustomer' },
    { id: 'CancelledByOta', name: 'CancelledByOta' },
    {
      id: 'CancelledAfterFailedPayment',
      name: 'CancelledAfterFailedPayment',
    },
    { id: 'OtherCancellation', name: 'OtherCancellation' },
  ];

  const fetchListings = async () => {
    try {
      setIsLoading(true);
      const response = await getListings(
        staging,
        selectedListings,
        selectedCities,
        selectedCountries,
        useActiveFilter,
        activeStatus,
      );

      if (
        response?.data?.success === false ||
        !response?.data?.data ||
        response.data.data.length === 0
      ) {
        setGroupedListings([]);
        setError('No listings found for the selected criteria.');
        return;
      }

      if (
        response &&
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        const normalizedListings = response.data.data.map((group) => ({
          ...group,
          _id: group._id.toLowerCase(),
        }));

        let filteredListings = normalizedListings;
        if (listingId) {
          filteredListings = normalizedListings
            .map((group) => ({
              ...group,
              listings: group.listings.filter(
                (listing) => listing._id === listingId,
              ),
              count: group.listings.filter(
                (listing) => listing._id === listingId,
              ).length,
            }))
            .filter((group) => group.count > 0);
        }

        const groupedByZone = filteredListings.reduce((acc, group) => {
          const zone = group._id;
          if (!acc[zone]) {
            acc[zone] = { _id: zone, listings: [], count: 0 };
          }
          acc[zone].listings = [...acc[zone].listings, ...group.listings];
          acc[zone].count += group.count;
          return acc;
        }, {});

        const regroupedListings = Object.values(groupedByZone);

        setGroupedListings(regroupedListings);
      }
    } catch (err) {
      console.error('Error fetching listings:', err);
      setGroupedListings([]);
      setError('Failed to fetch listings. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTaskConfigs = async () => {
    try {
      const response = await getTaskConfigs();
      processTaskConfigs(response);
    } catch (error) {
      console.error('Error fetching task configs:', error);
    }
  };

  const processTaskConfigs = (configs) => {
    const frenchOptions = [];
    const darijaOptions = [];
    const taskTypes = [];

    configs.forEach((taskConfig) => {
      taskTypes.push({
        id: taskConfig.task,
        name: taskConfig.task,
      });

      if (taskConfig.subs && Array.isArray(taskConfig.subs)) {
        taskConfig.subs.forEach((sub) => {
          if (sub.name) {
            frenchOptions.push({
              id: `${sub.name.Francais}-fr`,
              name: sub.name.Francais,
              _id: sub._id,
              type: sub.type,
            });

            darijaOptions.push({
              id: `${sub.name.Darija}-da`,
              name: sub.name.Darija,
              _id: sub._id,
              type: sub.type,
            });
          }
        });
      }
    });

    setTaskTypeOptions(taskTypes);
    setFrenchTaskOptions(frenchOptions);
    setDarijaTaskOptions(darijaOptions);
  };

  useEffect(() => {
    fetchTaskConfigs();
  }, [staging]);

  const fetchListingOptions = async () => {
    try {
      const options = await getListingsList(staging);
      setListingOptions(options);
    } catch (err) {
      console.error('Error fetching listing options:', err);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await getCities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Failed to fetch cities. Please try again.');
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setError('Failed to fetch countries. Please try again.');
    }
  };

  useEffect(() => {
    fetchListingOptions();
    fetchCities();
    fetchCountries();
  }, [staging]);

  useEffect(() => {
    fetchListings();
  }, [
    selectedListings,
    selectedCities,
    selectedCountries,
    staging,
    selectedTaskTypes,
    selectedFrenchTasks,
    selectedDarijaTasks,
    selectedStatuses,
    activeStatus,
  ]);

  const handleTaskTypeChange = (selectedIds) => {
    setSelectedTaskTypes(selectedIds);
  };

  const handleFrenchTaskChange = (selectedIds) => {
    setSelectedFrenchTasks(selectedIds);
  };

  const handleDarijaTaskChange = (selectedIds) => {
    setSelectedDarijaTasks(selectedIds);
  };

  const handleListingChange = (selectedIds) => {
    setSelectedListings(selectedIds);
    if (listingId && !selectedIds.includes(listingId)) {
      navigate('/task');
    }
  };

  const handleCityChange = (selectedCities) => {
    setSelectedCities(selectedCities);
  };

  const handleCountryChange = (selectedCountries) => {
    setSelectedCountries(selectedCountries);
  };

  const handleStatusChange = (selectedStatuses) => {
    setSelectedStatuses(selectedStatuses);
  };

  const handleActiveStatusChange = (selectedValues) => {
    setActiveStatus(selectedValues);
  };

  const taskFilters = [
    {
      selectedItems: selectedTaskTypes,
      options: taskTypeOptions,
      onChange: handleTaskTypeChange,
      placeholder: 'Select Task Types',
    },
    {
      selectedItems: selectedFrenchTasks,
      options: frenchTaskOptions,
      onChange: handleFrenchTaskChange,
      placeholder: 'Sélectionner les tâches',
    },
    {
      selectedItems: selectedDarijaTasks,
      options: darijaTaskOptions,
      onChange: handleDarijaTaskChange,
      placeholder: 'اختر المهام',
    },
    {
      selectedItems: selectedStatuses,
      options: statusOptions,
      onChange: handleStatusChange,
      placeholder: 'Select Status',
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="task-container card !p-4">
        <div className="flex items-center mb-3 gap-2">
          <GlobalFilter
            selectedCountries={selectedCountries}
            selectedCities={selectedCities}
            selectedListings={selectedListings}
            activeStatus={activeStatus}
            countries={countries}
            cities={cities}
            listingOptions={listingOptions}
            onCountryChange={handleCountryChange}
            onCityChange={handleCityChange}
            onListingChange={handleListingChange}
            onActiveChange={handleActiveStatusChange}
            otherFilters={taskFilters}
          />
        </div>
        <Calendar
          groupedListings={groupedListings}
          listingId={listingId}
          selectedTaskTypes={selectedTaskTypes}
          selectedFrenchTasks={selectedFrenchTasks}
          selectedDarijaTasks={selectedDarijaTasks}
          selectedStatuses={selectedStatuses}
        />
      </div>
    </DashboardLayout>
  );
}

export default Task;
