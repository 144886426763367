import React, { useState, useEffect } from 'react';
import { Schedull } from './components/Calendar/Schedull';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getStaff, getTaskConfigs, getTasksByStaff, getListingsList, getCities, getCountries } from './services/serverApi.task';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import GlobalFilter from 'components/GlobalFilter/GlobalFilter';
import './Task.css';

function Scheduling() {
    const [staff, setStaff] = useState([]);
    const [taskConfigs, setTaskConfigs] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [listingOptions, setListingOptions] = useState([]);
    const [selectedListings, setSelectedListings] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [cities, setCities] = useState([]);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    const [selectedFrenchTasks, setSelectedFrenchTasks] = useState([]);
    const [selectedDarijaTasks, setSelectedDarijaTasks] = useState([]);
    const [frenchTaskOptions, setFrenchTaskOptions] = useState([]);
    const [darijaTaskOptions, setDarijaTaskOptions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedStaffIds, setSelectedStaffIds] = useState([]);
    const [activeStatus, setActiveStatus] = useState(false); // Add this new state

    const processTaskConfigs = (configs) => {
        const frenchOptions = [];
        const darijaOptions = [];

        configs.forEach(taskConfig => {
            if (taskConfig.subs && Array.isArray(taskConfig.subs)) {
                taskConfig.subs.forEach(sub => {
                    if (sub.name) {
                        frenchOptions.push({
                            id: `${sub.name.Francais}-fr`,
                            name: sub.name.Francais,
                            _id: sub._id,
                            type: sub.type,
                            originalType: taskConfig.task
                        });

                        darijaOptions.push({
                            id: `${sub.name.Darija}-da`,
                            name: sub.name.Darija,
                            _id: sub._id,
                            type: sub.type,
                            originalType: taskConfig.task
                        });
                    }
                });
            }
        });

        setFrenchTaskOptions(frenchOptions);
        setDarijaTaskOptions(darijaOptions);
    };

    const fetchStaff = async () => {
        setIsLoading(true);
        try {
            const response = await getStaff();
            if (response.data.data && Array.isArray(response.data.data)) {
                setStaff(response.data.data);
            } else {
                setStaff([]);
            }
        } catch (error) {
            console.error('Error fetching staff:', error);
            setStaff([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTaskConfigs = async () => {
        try {
            const response = await getTaskConfigs();
            if (response && Array.isArray(response)) {
                processTaskConfigs(response);
                const taskOptions = response.map(task => ({
                    id: task.task,
                    name: task.task,
                    _id: task.task,
                    type: task.task,
                    subs: task.subs
                }));
                setTaskConfigs(taskOptions);
            } else {
                console.error('Invalid response format from getTaskConfigs');
                setTaskConfigs([]);
            }
        } catch (error) {
            console.error('Error fetching task configs:', error);
            setTaskConfigs([]);
        }
    };

    const fetchListingOptions = async () => {
        try {
            const listings = await getListingsList(staging);
            if (listings && Array.isArray(listings)) {
                // The data is already mapped in getListingsList, just set it directly
                setListingOptions(listings);
            } else {
                console.error('Invalid listings data format');
                setListingOptions([]);
            }
        } catch (err) {
            console.error('Error fetching listing options:', err);
            setError('Failed to fetch listings. Please try again.');
            setListingOptions([]);
        }
    };
    const fetchCities = async () => {
        try {
            const response = await getCities();
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
            setError('Failed to fetch cities. Please try again.');
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await getCountries();
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
            setError('Failed to fetch countries. Please try again.');
        }
    };

    const handleFrenchTaskChange = (selectedIds) => {
        setSelectedFrenchTasks(selectedIds);
    };

    const handleDarijaTaskChange = (selectedIds) => {
        setSelectedDarijaTasks(selectedIds);
    };

    const handleTaskChange = (selectedTaskIds) => {
        setSelectedTasks(selectedTaskIds);
    };

    const handleListingChange = (selectedIds) => {
        setSelectedListings(selectedIds);
    };

    const handleCityChange = (selectedCities) => {
        setSelectedCities(selectedCities);
    };

    const handleCountryChange = (selectedCountries) => {
        setSelectedCountries(selectedCountries);
    };

    const handleStaffChange = (selectedIds) => {
        setSelectedStaffIds(selectedIds);
    };

    const handleActiveChange = (event) => {
        setActiveStatus(event.target.value);
    };

    useEffect(() => {
        fetchStaff();
        fetchTaskConfigs();
        fetchListingOptions();
        fetchCities();
        fetchCountries();
    }, [staging]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="Task card !p-4">
            <div className="flex items-center mb-4 gap-2 pt-4">



                        <GlobalFilter
                            selectedCountries={selectedCountries}
                            selectedCities={selectedCities}
                            selectedListings={selectedListings}
                            activeStatus={activeStatus}
                            countries={countries}
                            cities={cities}
                            listingOptions={listingOptions}
                            onCountryChange={handleCountryChange}
                            onCityChange={handleCityChange}
                            showActive={false}
                            onListingChange={handleListingChange}
                            onActiveChange={handleActiveChange}
                        />

                        <FilterSearch
                            selectedItems={selectedTasks}
                            options={taskConfigs}
                            onItemsChange={handleTaskChange}
                            placeholder="Select Tasks"
                            idKey="id"
                            labelKey="name"
                        />

                        <FilterSearch
                            selectedItems={selectedStaffIds}
                            options={staff.map(s => ({
                                id: s._id,
                                name: `${s.username}`
                            }))}
                            onItemsChange={handleStaffChange}
                            placeholder="Select Staff"
                            idKey="id"
                            labelKey="name"
                        />

                        <FilterSearch
                            selectedItems={selectedFrenchTasks}
                            options={frenchTaskOptions}
                            onItemsChange={handleFrenchTaskChange}
                            placeholder="Sélectionner les tâches"
                            idKey="id"
                            labelKey="name"
                            className="mb-1"
                        />

                        <FilterSearch
                            selectedItems={selectedDarijaTasks}
                            options={darijaTaskOptions}
                            onItemsChange={handleDarijaTaskChange}
                            placeholder="اختر المهام"
                            idKey="id"
                            labelKey="name"
                        />
                </div>

                <div className='hidden md:flex gap-3 absolute top-[5.3rem] left-1/2 transform -translate-x-1/2'>
                    <div className='flex items-center gap-1'><span className='p-1 bg-blue-200 rounded-full '></span><span className='text-xs'>ASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-orange-200 rounded-full'></span><span className='text-xs'>UNASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-green-200 rounded-full'></span><span className='text-xs'>ACCEPTED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-red-200 rounded-full'></span><span className='text-xs'>REFUSED</span></div>
                </div>
                <div className='md:hidden flex flex-wrap justify-center gap-2 p-2 bg-white border-b'>
                    <div className='flex items-center gap-1'><span className='p-1 bg-blue-200 rounded-full '></span><span className='text-xs'>ASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-orange-200 rounded-full'></span><span className='text-xs'>UNASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-green-200 rounded-full'></span><span className='text-xs'>ACCEPTED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-red-200 rounded-full'></span><span className='text-xs'>REFUSED</span></div>
                </div>
                <div className="mx-auto w-full max-w-full md:w-auto">
                    <Schedull
                        staff={selectedStaffIds.length > 0
                            ? staff.filter(s => selectedStaffIds.includes(s._id))
                            : staff}
                        staging={staging}
                        selectedTaskTypes={selectedTasks}
                        selectedListings={selectedListings}
                        selectedCities={selectedCities}
                        selectedCountries={selectedCountries}
                        selectedFrenchTasks={selectedFrenchTasks}
                        selectedDarijaTasks={selectedDarijaTasks}
                    />
                </div>
            </div>
        </DashboardLayout>
    );
}

export default Scheduling;