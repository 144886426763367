import React, { useEffect, useState } from 'react';
import Tooltip from 'components/TooltipGlobal/Tooltip';
import { RenderTimeSelectField } from './helper/Fields/RenderTimeSelectField';
import SelectAutocomplete from 'components/selectAutocomplete/SelectAutocomplete';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';
import RoleBasedRenderer from 'components/wrappers/RoleBasedRenderer';

import {
    Box,
    Card,
    CardContent,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    Divider,
    Grid,
    Paper,
    IconButton,
    FormHelperText
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const instantBookableOptions = [
    { value: 'true', label: "Yes" },
    { value: 'false', label: "No" }
];

const BasicInfo = ({ formik, propertyTypes, currencies }) => {
    const currencyOptions = currencies.map(currency => ({
        label: `${currency.currencyCode} (${currency.currencySymbol})`,
        value: currency.currencyCode
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
    };

    const handleSelectChange = (e, name) => {
        const value = e.target.value;

        if (name === 'propertyType') {
            const selectedOption = propertyTypes.find(option => option.value === value);
            formik.setFieldValue('propertyType', value);
            formik.setFieldValue('propertyTypeId', selectedOption ? selectedOption._id : '');
            formik.setFieldValue('manageRoomType', selectedOption ? selectedOption.manageRoomType : '');
        } else {
            const parsedValue = value === 'true' ? true : value === 'false' ? false : value;
            formik.setFieldValue(name, parsedValue);
        }
    };

    const handleOwnerChange = (selectedOwner) => {
        if (selectedOwner) {
            formik.setFieldValue('ownerId', selectedOwner._id);
            formik.setFieldValue('owner', {
                _id: selectedOwner._id,
                firstName: selectedOwner.firstName,
                lastName: selectedOwner.lastName,
                email: selectedOwner.email
            });
        } else {
            formik.setFieldValue('ownerId', null);
            formik.setFieldValue('owner', null);
        }
    };

    const renderInputField = (name, label, placeholder, isRequired = false, isSelect = false, options = [], tooltipText = null) => (
        <FormControl
            fullWidth
            variant="outlined"
            size="small"
            error={formik.touched[name] && Boolean(formik.errors[name])}
            sx={{ mb: 1 }}
        >
            <Box display="flex" alignItems="center" mb={0.2}>
                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                    {label} {isRequired && <span style={{ color: '#d32f2f' }}>*</span>}
                </Typography>
                {tooltipText && (
                    <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                        <Tooltip text={tooltipText} iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
                    </IconButton>
                )}
            </Box>

            {isSelect ? (
                <Select
                    id={name}
                    name={name}
                    value={formik.values[name] !== undefined ? String(formik.values[name]) : ''}
                    onChange={(e) => handleSelectChange(e, name)}
                    onBlur={formik.handleBlur}
                    displayEmpty
                    placeholder={placeholder}
                    sx={{
                        fontSize: '0.875rem',
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: (formik.touched[name] && formik.errors[name]) ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                        }
                    }}
                >
                    <MenuItem value="" disabled>
                        <em className="!text-xs">{placeholder}</em>
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <span className="!text-xs">{option.label}</span>
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <TextField
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={formik.values[name] || ''}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    size="small"
                    fullWidth
                    variant="outlined"
                    sx={{ fontSize: '0.875rem' }}
                    InputProps={{
                        classes: {
                            input: '!text-xs'
                        }
                    }}
                />
            )}

            {formik.touched[name] && formik.errors[name] && (
                <FormHelperText error className="!text-xs">{formik.errors[name]}</FormHelperText>
            )}
        </FormControl>
    );

    const renderToggle = (name, label, tooltipText = null) => (
        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box display="flex" alignItems="center">
                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                    {label}
                </Typography>
                {tooltipText && (
                    <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                        <Tooltip text={tooltipText} iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
                    </IconButton>
                )}
            </Box>
            <Switch
                id={name}
                name={name}
                checked={formik.values[name] === true}
                onChange={(e) => formik.setFieldValue(name, e.target.checked)}
                color="success"
            />
        </Box>
    );

    const SubCategoryHeader = ({ title }) => (
        <Grid item xs={12}>
            <Box sx={{ mt: 1, mb: 0.5 }}>
                <Typography variant="subtitle1" fontWeight="medium" color="text.secondary">
                    {title}
                </Typography>
                <Divider sx={{ mt: 0.2 }} />
            </Box>
        </Grid>
    );

    return (
        <Box sx={{ p: 4, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h5" fontWeight="bold">Basic Information</Typography>
                <Typography
                    variant="body2"
                    color="primary"
                    className="!text-xs"
                    sx={{
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline' },
                        color: '#65c0ae',
                        fontWeight: 'medium'
                    }}
                >
                    Learn how to Export to Airbnb PRO
                </Typography>
            </Box>

            <CardContent className="!pb-0 !pt-2" sx={{ px: 1, '&:last-child': { pb: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography className="!text-xs !text-black" variant="body1" fontWeight="medium" mr={1}>
                            Activation Status
                        </Typography>
                        <Tooltip
                            text="Enable or disable this listing. Controls whether this property is available for booking."
                            iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                            position="top"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Switch
                            id="active"
                            name="active"
                            checked={formik.values.active === true}
                            onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                            color="success"
                        />
                        <Typography variant="body2" fontWeight="medium" color="text.secondary" ml={1} className="!text-xs">
                            {formik.values.active ? 'Active' : 'Inactive'}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>

            <CardContent className="!pt-0 !pb-1" sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Grid container spacing={1}>
                    <SubCategoryHeader title="General Information" />

                    <Grid className="" item xs={12} md={4}>
                        {renderInputField('name', 'External Listing Name', 'External Listing Name', true, false, [],
                            'The name displayed for this listing to identify it in the system and on booking platforms.')}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('currencyCode', 'Currency', 'Select currency', true, true, currencyOptions,
                            'Currency used for pricing and financial calculations for this property.')}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('propertyType', 'Property Type', 'Select Property type', true, true, propertyTypes,
                            'Category of property (apartment, house, villa, etc.) used for filtering and display.')}
                    </Grid>

                    <SubCategoryHeader className="!pt-0" title="Booking Rules" />

                    <Grid item xs={12} md={4}>
                        {renderInputField('minNights', 'Minimum Nights', 'Minimum nights', true, false, [],
                            'Minimum stay duration required for booking. Default: 1 night.')}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('maxNights', 'Maximum Nights', 'Maximum nights', true, false, [],
                            'Maximum stay duration allowed. Default: 30 nights.')}
                    </Grid>

                    <Grid item xs={12} md={4}></Grid>

                    <SubCategoryHeader title="Check-in Settings" />

                    <Grid item xs={12} md={4}>
                        <Box sx={{ mb: 1 }}>
                            <Box display="flex" alignItems="center" mb={0.2}>
                                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                                    Check-in Time <span style={{ color: '#d32f2f' }}>*</span>
                                </Typography>
                                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                                    <Tooltip
                                        text="When guests can check in. Default: 15:00 (3:00 PM)"
                                        iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                                        position="top"
                                    />
                                </IconButton>
                            </Box>
                            <RenderTimeSelectField
                                formik={formik}
                                name="checkInTimeStart"
                                onChange={(e) => formik.setFieldValue('checkInTimeStart', Number(e.target.value))}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box sx={{ mb: 1 }}>
                            <Box display="flex" alignItems="center" mb={0.2}>
                                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                                    Check-out Time <span style={{ color: '#d32f2f' }}>*</span>
                                </Typography>
                                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                                    <Tooltip
                                        text="When guests must check out. Default: 11:00 (11:00 AM)"
                                        iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                                        position="top"
                                    />
                                </IconButton>
                            </Box>
                            <RenderTimeSelectField
                                formik={formik}
                                name="checkOutTime"
                                onChange={(e) => formik.setFieldValue('checkOutTime', Number(e.target.value))}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('onlineCheckIn', 'Online Check-in', 'Select Online CheckIn', false, true, instantBookableOptions,
                            'Enable online check-in functionality for guests.')}
                    </Grid>

                    <SubCategoryHeader title="Wifi Information" />

                    <Grid item xs={12} md={4}>
                        {renderInputField('wifiUsername', 'Wifi Username', 'Wifi username', false, false, [],
                            'Network username for wifi access, if applicable.')}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('wifiPassword', 'Wifi Password', 'Wifi password', false, false, [],
                            'Network password for wifi access, if applicable.')}
                    </Grid>

                    <SubCategoryHeader title="Other Settings & Synchronization" />

                    <Grid item xs={12} md={4}>
                        {renderInputField('displayInHomeScreen', 'Display In Home Screen', 'Select option', false, true, instantBookableOptions,
                            'Controls visibility on home screen for direct booking sites.')}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('sendTochannex', 'Send To Channex', 'Select option', false, true, instantBookableOptions,
                            'Synchronize this listing with Channex channel manager.')}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {renderInputField('useDynamicPrice', 'Use Dynamic Price', 'Select option', false, true, instantBookableOptions,
                            'Enable dynamic pricing algorithms for this property.')}
                    </Grid>

                    <RoleBasedRenderer adminOnly>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 1 }}>
                                <Box display="flex" alignItems="center" mb={0.2}>
                                    <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                                        Owner <span style={{ color: '#d32f2f' }}>*</span>
                                    </Typography>
                                    <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                                        <Tooltip
                                            text="Property owner assignment (Admin only)"
                                            iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                                            position="top"
                                        />
                                    </IconButton>
                                </Box>
                                <SelectAutocomplete
                                    endpoint={`${MICROSERVICE_BASE_URL.SRV_USER}/user/get-account`}
                                    value={formik.values.owner ? {
                                        id: formik.values.owner._id,
                                        label: `${formik.values.owner.firstName || ''} ${formik.values.owner.lastName || ''}`.trim() || 'Select an owner',
                                        value: formik.values.owner._id,
                                        ...formik.values.owner
                                    } : { id: null, label: 'Select an owner', value: null }}
                                    onChange={handleOwnerChange}
                                    placeholder="Select owner"
                                    label="Owner"
                                    defaultParams={{
                                        page: 0,
                                        limit: 20,
                                        paged: true,
                                        roles: 'Owner'
                                    }}
                                    minInputLength={0}
                                />
                                {formik.touched.ownerId && formik.errors.ownerId && (
                                    <FormHelperText error className="!text-xs">{formik.errors.ownerId}</FormHelperText>
                                )}
                            </Box>
                        </Grid>
                    </RoleBasedRenderer>

                    <SubCategoryHeader title="Description" />

                    <Grid item xs={12}>
                        <Box sx={{ mb: 1 }}>
                            <Box display="flex" alignItems="center" mb={0.2}>
                                <Typography variant="body2" fontWeight="medium" color="text.primary" className="!text-xs">
                                    Description
                                </Typography>
                                <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                                    <Tooltip
                                        text="Detailed property description used for marketing and guest information."
                                        iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
                                        position="top"
                                    />
                                </IconButton>
                            </Box>
                            <TextField
                                id="description"
                                name="description"
                                multiline
                                rows={4}
                                placeholder="Enter detailed property description"
                                value={formik.values.description || ''}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{ fontSize: '0.875rem' }}
                                InputProps={{
                                    classes: {
                                        input: '!text-xs'
                                    }
                                }}
                            />
                            <Box display="flex" justifyContent="flex-end" mt={0.2}>
                                <Typography variant="caption" color="text.secondary" className="!text-xs">
                                    {(formik.values.description || '').length}/2000
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Box>
    );
};

export default BasicInfo;