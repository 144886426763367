import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Typography, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getConfigReservation, updateConfigReservation } from '../services/serverApi.adminConfig';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    beforeCheckinDays: Yup.number()
        .positive('Days must be greater than 0')
        .required('Days are required'),
});

const ConfigReservationComponent = () => {
    const [initialValues, setInitialValues] = useState({ beforeCheckinDays: '' });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchConfig();
    }, []);

    const fetchConfig = async () => {
        try {
            const response = await getConfigReservation();
            setInitialValues({ beforeCheckinDays: response.data.data.beforeCheckinDays });
        } catch (error) {
            console.error('Error fetching configuration', error);
            toast.error('Failed to fetch configuration');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (values) => {
        try {
            await updateConfigReservation({ 
                beforeCheckinDays: Number(values.beforeCheckinDays) 
            });
            toast.success('Reservation configuration updated successfully');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update configuration: ' + error.message);
        }
    };

    return (
        <div className="card p-4 !border-none">
            <ToastContainer />

            <Typography variant="h4" gutterBottom>
                Reservation Configuration
            </Typography>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress style={{ color: '#00b4b4' }} />
                </div>
            ) : (
                <Formik 
                    initialValues={initialValues} 
                    validationSchema={validationSchema} 
                    onSubmit={handleSave} 
                    enableReinitialize={true}
                >
                    {({ setFieldValue, isSubmitting }) => (
                        <Form className="flex gap-4 flex-row p-4 items-center">
                            <span className="text-lg">Before Checkin Days</span>

                            <Field name="beforeCheckinDays">
                                {({ field, form }) => (
                                    <div>
                                        <TextField 
                                            type="number" 
                                            {...field} 
                                            error={Boolean(form.errors.beforeCheckinDays && form.touched.beforeCheckinDays)} 
                                            helperText={<ErrorMessage name="beforeCheckinDays" />} 
                                            inputProps={{ min: 1 }} 
                                            onChange={e => setFieldValue('beforeCheckinDays', e.target.value)} 
                                        />
                                    </div>
                                )}
                            </Field>

                            <IconButton type="submit" className="!bg-medium-aquamarine text-white" disabled={isSubmitting}>
                                <SaveIcon />
                            </IconButton>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default ConfigReservationComponent;