import React from 'react';
import { 
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const SyndicTab = ({ formik, languages }) => {
  const handleAddSyndic = () => {
    const newSyndic = {
      name: '',
      phone: '',
      language: ''
    };
    formik.setFieldValue('syndics', [...(formik.values.syndics || []), newSyndic]);
  };

  const handleRemoveSyndic = (index) => {
    const updatedSyndics = formik.values.syndics.filter((_, idx) => idx !== index);
    formik.setFieldValue('syndics', updatedSyndics);
  };

  const handleSyndicChange = (index, field, value) => {
    const updatedSyndics = formik.values.syndics.map((syndic, idx) => 
      idx === index ? { ...syndic, [field]: value } : syndic
    );
    formik.setFieldValue('syndics', updatedSyndics);
  };

  return (
    <Box>
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h6" className="text-gray-700">
          Syndics Information
        </Typography>
        <button
          type="button"
          onClick={handleAddSyndic}
          className="flex items-center px-1 !text-xs py-1 bg-[#6ad1d1] text-white rounded-md hover:bg-[#5dbebe] transition-colors !rounded-md"
        >
          <AddIcon className="mr-1" />
          Add Syndic
        </button>
      </Box>

      <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
        {formik.values.syndics?.map((syndic, index) => (
          <Card key={index} className="border border-gray-200 shadow-sm">
            <CardContent className="!p-3">
              <Box className="flex justify-between items-start mb-2">
                <Typography variant="subtitle2" className="font-medium text-gray-700 text-xs">
                  Syndic #{index + 1}
                </Typography>
                <IconButton 
                  onClick={() => handleRemoveSyndic(index)}
                  className="text-red-500 hover:text-red-700 !p-1"
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box className="grid grid-cols-1 gap-2">
                <TextField
                  label="Name"
                  value={syndic.name}
                  onChange={(e) => handleSyndicChange(index, 'name', e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="bg-white text-xs"
                  inputProps={{ className: 'text-xs' }}
                />

                <TextField
                  label="Phone"
                  value={syndic.phone}
                  onChange={(e) => handleSyndicChange(index, 'phone', e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                  className="bg-white text-xs"
                  inputProps={{ className: 'text-xs' }}
                  placeholder="e.g., 212612345678"
                />

                <Select
                  value={syndic.language}
                  onChange={(e) => handleSyndicChange(index, 'language', e.target.value)}
                  fullWidth
                  size="small"
                  className="bg-white text-xs"
                  inputProps={{ className: 'text-xs' }}
                  displayEmpty
                >
                  <MenuItem value="" disabled className="text-xs">
                    Select Language
                  </MenuItem>
                  {languages.map((lang) => (
                    <MenuItem key={lang._id} value={lang._id} className="text-xs">
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      {(!formik.values.syndics || formik.values.syndics.length === 0) && (
        <Box className="text-center py-8 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300">
          <Typography variant="body1" className="text-gray-500">
            No syndics added yet. Click the Add Syndic button to add one.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SyndicTab;