import Dashboard from "features/dashboard";
import Listing from "features/listing/pages/listing.page";
import ListingInactivePage from "features/listing/pages/ListingInactive.page";
import ChannexMapping from "features/listing/pages/ChannexMapping.page";
import ListingMapping from "features/listing/pages/ListingMapping.page";
import SignIn from "features/authentication/sign-in";
import SignUp from "features/authentication/sign-up";
import ListingDetails from "features/listing/pages/details.page";
import MonthlyCalendar from "features/calendar/pages/monthlyCalendar.page";
import AmenityConfig from "features/listing/pages/amenity.page";
import BedTypeConfig from "features/listing/pages/bedType.page";
import MultiCalendarV2 from "features/calendar/pages/multiCalendar.v2.page";
import MultiCalendarPage from "features/calendar/pages/MultiCalendarV2.page";
import MetaCalendarPage from "features/calendar/components/metaCalendar/MultiCalendarV2.page";
import RoomTypeCalendar from "features/calendar/components/roomTypeCalendar/RoomTypeCalendar.page";
import ReservationDetails from "features/reservation/pages/reservationDetails";
import ReservationCreate from "features/reservation/pages/reservationCreate";
import ChekinDetails from "features/reservation/pages/ChekinDetails.component";
import ReservationCalendar from "features/reservation/calendarPage/ReservationCalendar";
import LeadsPage from "features/lead/pages/lead.page";


import Reservation from "features/reservation/pages/reservation.page";
import AdminConfig from "features/setting/pages/admin.page";
import MongoDBChartpage from "features/charts/pages/MongoDB.Chart.page";
import OpenAi from "features/setting/pages/openai.page";
import OpenAIConfig from "features/setting/components/OpenAiConfigDisplay";
import ChannelManager from "features/setting/pages/channelManager.page";
import CronConfig from "features/setting/pages/cronConfig.page";
import Currency from "features/setting/pages/currency/Currency.page";
import Synchroniser from "features/setting/pages/synchroniser/Synchroniser";
import StayMore from "features/setting/pages/StayMore";
import Cancellation from "features/setting/pages/cancellation/Cancellation.page";


// import GanttConfig from "features/setting/pages/ganntDemo/Gantt.page";
import Task from "features/tasks/Task.page";
import Schedulling from "features/tasks/Schedulling.page"
import Staff from "features/staff/Staff.page";
import Admin from "features/staff/Admin.page";
import AdminWhatsapp from "features/staff/admin.whatsapp.page";
import ClientWhiteList from "features/staff/Client.white.list.page ";
import UsefulNUmber from "features/setting/pages/useFulNumber/UsefulNumber.page";

import DisplayTask from "features/tasks/DisplayTask.page";
import ChatBox from "features/setting/pages/chatBox.page";
import StandardSojoriPage from "features/listing/pages/standard.page";
import AccessTypePage from "features/listing/pages/Access.types.tab.page";
import RoomTypeConfigPage from "features/listing/pages/roomType.page";
import ChatContainer from "features/chatbox/ChatContainer";
import ChatContainerV2 from "features/chatboxV2/ChatContainer";
import Story from "features/setting/pages/stories/Stories.page";
import WhatsAppConfig from "features/setting/pages/whatsappConfig/whatsAppConfig.page";
import Exchanges from "features/setting/pages/exchange/Exchanges.page";
import ConfigReservation from "features/setting/pages/configReservation/ConfigReservation";
import Minut from "features/minut/pages/minut.page";
import Tickets from "features/tickets/pages/Tickets.page";
import ProjectUnits from "features/projectUnits/pages/projectUnits.page";
import ProjectDetailsPage from "features/projectUnits/components/ProjectDetailsPage";
import UnitDetailsPage from "features/projectUnits/components/UnitDetailsPage";
import Projects from "features/projectUnits/pages/Projects.Page";
import Units from "features/projectUnits/pages/Units.page";
import UnitMapping from "features/projectUnits/pages/UnitMapping";
import Client from "features/staff/Client.page";
import StaffPlanning from "features/staff/Staff.planning.page";
import Template from "features/setting/pages/template/template.page";


// @mui icons/
import Icon from "@mui/material/Icon";
import NewListing from "features/listing/components/NewListing";
import WebSiteBlockConfig from "features/setting/pages/webSiteBlocksConfig";

import Financial from "features/financial/pages/financial.page";
import ReservationsDashboard from "features/financial/pages/ReservationsDashboard";
import UnitFormPage from "features/projectUnits/components/unitForm/UnitFormPage";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import ReferralPage from "features/setting/pages/referral/referral.page";

import {
  Home,
  Calendar,
  Landmark,
  CalendarDays,
  CalendarRange,
  CalendarFold,
  CalendarCheck2,
  ListTodo,
  ClipboardList,
  ChartNoAxesCombined,
  LayoutList,
  ListTree,
  BotMessageSquare,
  MessagesSquare,
  UsersRound,
  Briefcase,
  Rss,
  Settings,
  Headset,
  PanelTop,
  CircleFadingPlus,
  AlarmSmoke,
  ToggleLeft,
  RefreshCcw,
  Ungroup,
  TicketSlash,
  Flame,
  MessageCircle,
  Clock,
  HandCoins,
  FileCheck,
  Bolt,
  CircleDollarSign,
  ChartCandlestick,
  Banknote,
  Handshake,
  Ticket,
  MapPinHouse,
  Milestone,
  Hotel,
  School,
  ListOrdered,
  Logs,
  AlignJustify,
  Star,
  MailCheck,
  CircleUser,
  CircleUserRound,
  Component
} from 'lucide-react';
import OpenAILogo from './helpers/OpenAILogo';
import WhatsAppLogo from './helpers/WhatsAppLogo';
import TaskConfigs from "features/tasks/TaskConfig";
import DynamicPricingComponent from "features/dynamicPricing/pages/DynamicPricing.page";
import ListingsWithRatePlans from "features/dynamicPricing/pages/ListingsWithRatePlans";
import ChannexCall from "features/channex/pages/Channex.page";
import ReviewsContainer from './features/reviews/pages/ReviewsContainer';
import MessageContainer from "features/messages/pages/MessageContainer";
import ConcieregeType from "features/tasks/pages/Concierege.page";
import Owner from "features/staff/Owner.page";
import AdminV2 from "features/staff/AdminV2.page";
import Worker from "features/staff/Worker.page";
import NotFoundPage from "components/notFoundPage/NotFoundPage";


const ROLE_BASED_ROUTES = {
  SuperAdmin: ['*'],
  Admin: ['*'],
  Owner: [
    'admin/overview',
    'admin/calendar/Inventory',
    'admin/calendar/Inventory/:listingId',
    'Reservation/Liste',
    'admin/Reservation/:id/details',
    'admin/Reservation/create',
    'Reservation/ReservationCalendar',
    'admin/Master',
    'admin/Master/:listingId',
    'admin/Schedulling',
    'admin/Tasks',
    'admin/User/client-white-list',
    'admin/User/staff',
    'admin/User/staff-planning',
    'financial/Analytics',
    'admin/DynamicPrice/City',
    'admin/DynamicPrice/ListingsWithRatePlans',
    'admin/Listing/Active',
    'admin/Listing/Inactive',
    'admin/WhatsApp',
    'admin/Reviews',
    'admin/Messages',
    'admin/setting/template',
    'admin/User/worker',

  ],
  Worker: [
    'admin/overview',
    'admin/calendar/Inventory',
    'admin/calendar/Inventory/:listingId',
    'Reservation/Liste',
    'admin/Reservation/:id/details',
    'admin/Reservation/create',
    'Reservation/ReservationCalendar',
    'admin/Master',
    'admin/Master/:listingId',
    'admin/Schedulling',
    'admin/Tasks',
    'admin/User/client-white-list',
    'admin/User/staff',
    'admin/User/staff-planning',
    'financial/Analytics',
    'admin/DynamicPrice/City',
    'admin/DynamicPrice/ListingsWithRatePlans',
    'admin/Listing/Active',
    'admin/Listing/Inactive',
    'admin/WhatsApp',
    'admin/Reviews',
    'admin/Messages',
    'admin/setting/template',
  ]
};
const MODULE_ROUTES = {
  PMS: [
    'admin/overview',
    'admin/calendar/Inventory',
    'admin/calendar/Inventory/:listingId',
    'Reservation/Liste',
    'admin/Reservation/:id/details',
    'admin/Reservation/create',
    'Reservation/ReservationCalendar',
    'admin/Master',
    'admin/Master/:listingId',
    'admin/Schedulling',
    'admin/Tasks',
    'admin/User/staff',
    'admin/User/staff-planning',
    'financial/Analytics',
    'admin/Listing/Active',
    'admin/Listing/Inactive',
    'admin/WhatsApp',
    'admin/User/worker',


  ],
  WhGuest: [
    'admin/User/client-white-list',
  ],
  WhStaff: [

  ],
  WhPMS: [

  ],
  RMS: [
    'admin/DynamicPrice/City',
    'admin/DynamicPrice/ListingsWithRatePlans',

  ],
  MessageAndReview: [
    'admin/Reviews',
    'admin/Messages',
    'admin/setting/template'
  ]
};


export const hasRouteAccess = (user, routePath) => {
  if (!user || !user.role) return false;

  if (user.role === 'SuperAdmin' || user.role === 'Admin') return true;

  const userRoutes = ROLE_BASED_ROUTES[user.role] || [];

  if (userRoutes.includes('*') || userRoutes.includes(routePath)) return true;

  const userModules = user.modules || [];
  return userModules.some(module =>
    MODULE_ROUTES[module] && MODULE_ROUTES[module].includes(routePath)
  );
};

export const getRoutes = (user) => {
  if (!user) return [];

  const { role, permissions = [] } = user;

  const filterRoutesByAccess = (routes) => {
    return routes.map(route => {
      if (role === 'SuperAdmin' || role === 'Admin') {
        return route;
      }

      const roleRoutes = ROLE_BASED_ROUTES[role] || [];

      const hasRoleAccess = roleRoutes.some(path =>
        path === '*' ||
        route.key?.startsWith(path) ||
        route.route?.startsWith(path)
      );

      const hasModuleAccess = permissions.some(permission => {
        const moduleRoutes = MODULE_ROUTES[permission.module] || [];
        return moduleRoutes.some(path =>
          route.key?.startsWith(path) ||
          (route.route && route.route.startsWith(path))
        );
      });

      const hasAccess = hasRoleAccess && hasModuleAccess;

      if (route.type === 'dropdown' && route.subRoutes) {
        const filteredSubRoutes = route.subRoutes
          .map(subRoute => {
            const hasSubRoleAccess = roleRoutes.some(path =>
              path === '*' ||
              subRoute.key?.startsWith(path) ||
              subRoute.route?.startsWith(path)
            );

            const hasSubModuleAccess = permissions.some(permission => {
              const moduleRoutes = MODULE_ROUTES[permission.module] || [];
              return moduleRoutes.some(path =>
                subRoute.key?.startsWith(path) ||
                (subRoute.route && subRoute.route.startsWith(path))
              );
            });

            return (hasSubRoleAccess && hasSubModuleAccess) ? subRoute : null;
          })
          .filter(Boolean);

        return filteredSubRoutes.length > 0
          ? { ...route, subRoutes: filteredSubRoutes }
          : null;
      }

      return hasAccess ? route : null;
    }).filter(Boolean);
  };

  const notFoundRoutes = [
    {
      type: "route",
      name: "Not Found",
      key: "not-found",
      route: "/404",
      component: <NotFoundPage />,
      noCollapse: true,
      hidden: true
    }
  ];

  return [...filterRoutesByAccess(routes), ...notFoundRoutes];
};

const routes = [
  {
    type: "dropdown",
    name:
      // <span style={{ fontWeight: '500', fontSize: '1rem' }}>
      "Overview",
    // </span>,
    key: "admin/dashboard",
    icon: <Home size={20} />,
    //route: "admin/dashboard",
    //component: <Dashboard />,
    subRoutes: [
      {
        key: "admin/overview",
        name: "Overview",
        route: "admin/overview",
        component: <ReservationsDashboard />,
        icon: <Home size={20} />,
      },
      // {
      //   key: "admin/dashboard",
      //   name: "Dashboard",
      //   route: "admin/dashboard",
      //   component: <Dashboard />,
      //   icon: <Icon fontSize="small">dashboard</Icon>,
      // },

    ],


  },
  {
    type: "dropdown",
    name: "Calendar",
    key: "calendar",
    icon: <Calendar size={20} />,

    subRoutes: [
      // {
      //   key: "admin/calendar/multi",
      //   name: "Multi",
      //   route: "admin/calendar/multiV2",
      //   component: <MultiCalendarV2 />,
      //   icon: <Icon fontSize="small">calendar_month</Icon>,


      // },
      // {
      //   key: "admin/calendar/multi",
      //   name: "Multi",
      //   route: "admin/calendar/multiV2",
      //   component: <MetaCalendarPage />,
      //   icon: <CalendarRange size={20} />,


      // },
      {
        key: "admin/calendar/Inventory",
        name: "Inventory",
        route: "admin/calendar/Inventory",
        component: <RoomTypeCalendar />,
        icon: <CalendarDays size={20} />,


      },
      // {
      //   key: "admin/calendar/monthly",
      //   name: "Monthly",
      //   route: "admin/calendar/monthly",
      //   component: <MonthlyCalendar />,
      //   icon: <CalendarDays size={20} />,


      // },


      // {
      //   key: "admin/calendar/multiv2",
      //   name: "MultiV2",
      //   route: "admin/calendar/multiV3",
      //   component: <MultiCalendarPage />,
      //   icon: <Icon fontSize="small">calendar_month</Icon>,


      // },

      // {
      //   key: "admin/calendar/yearly",
      //   name: "Yearly", 
      //   route: "admin/calendar/yearly",
      //   component: <YearlyCalendar />,
      //   icon: <Icon fontSize="small">date_range</Icon>,

      // },

    ],
  },
  {
    type: "dropdown",
    name: "Reservation",
    key: "Reservation",
    icon: <CalendarCheck2 size={20} />,
    subRoutes: [
      {
        key: "Reservation/Liste",
        name: "Liste",
        route: "Reservation/Liste",
        component: <Reservation />,
        icon: <Icon fontSize="small">event_note</Icon>,
      },
      {
        key: "Reservation/ReservationCalendar",
        name: "Calendar",
        route: "Reservation/ReservationCalendar",
        component: <ReservationCalendar />,
        icon: <CalendarCheck2 size={20} />,
      }

    ],
  },
  {
    type: "dropdown",
    name: "Tasks",
    key: "Tasks",
    icon: <ClipboardList size={20} />,
    subRoutes: [
      {
        key: "admin/Master",
        name: "Master",
        route: "admin/Master",
        component: <Task />,
        icon: <CalendarRange size={20} />,
      },

      {
        key: "admin/Schedulling",
        name: "Schedulling",
        route: "admin/Schedulling",
        component: <Schedulling />,
        icon: <CalendarFold size={20} />,
      },
      {
        key: "admin/Tasks",
        name: "Tasks",
        route: "admin/Tasks",
        component: <DisplayTask />,
        icon: <ListTodo size={20} />,
      },
      {
        key: "admin/TaskConfig",
        name: "Task Config",
        route: "admin/TaskConfig",
        component: <TaskConfigs />,
        icon: <FileCheck size={20} />,
      },
      {
        key: "admin/concierge-type",
        name: "Concierge Type",
        route: "admin/concierge-type",
        component: <ConcieregeType />,
        icon: <FileCheck size={20} />,
      }

    ]
  },
  {
    key: "admin/Master/:listingId",
    name: "Task by Listing",
    route: "admin/Master/:listingId",
    component: <Task />,
    icon: <Icon fontSize="small">event_note</Icon>,
  },
  // {
  //   key: "admin/Tasks/:listingId",
  //   name: "Tasks",
  //   route: "admin/Tasks/:listingId",
  //   component: <DisplayTask />,
  //   icon: <Icon fontSize="small">event_note</Icon>,
  // },
  // {
  //   key: "admin/Task/Resservation/:reservationNumber",
  //   name: "Tasks",
  //   route: "admin/Tasks/Reservation/:reservationNumber",
  //   component: <DisplayTask />,
  //   icon: <ListTodo size={20} />,
  // },
  // {
  //   key: "admin/Task",
  //   name: "Tasks",
  //   route: "admin/Tasks?reservationNumber:reservationNumber&listing=:listingId",
  //   component: <DisplayTask />,
  //   icon: <ListTodo size={20} />,
  // },

  {
    key: "admin/calendar/multi/:listingId",
    name: "Multi",
    route: "admin/calendar/multiV2/:listingId",
    component: <MetaCalendarPage />,
    icon: <Icon fontSize="small">calendar_month</Icon>,


  },

  {
    key: "admin/calendar/Inventory/:listingId",
    name: "Inventory",
    route: "admin/calendar/Inventory/:listingId",
    component: <RoomTypeCalendar />,
    icon: <CalendarDays size={20} />,


  },

  {
    type: "dropdown",
    name: "User",
    key: "User",
    icon: <Briefcase size={20} />,
    subRoutes: [
      {
        key: "admin/User/client",
        name: "Client",
        route: "admin/User/client",
        component: <Client />,
        icon: <AdminPanelSettingsIcon size={20} />,
      },
      {
        key: "admin/User/client-white-list",
        name: "Client White List",
        route: "admin/User/client-white-list",
        component: <ClientWhiteList />,
        icon: <AdminPanelSettingsIcon size={20} />,
      },
      // {
      //   key: "admin/User/admin",
      //   name: "Admin",
      //   route: "admin/User/admin",
      //   component: <Admin />,
      //   icon: <AdminPanelSettingsIcon size={20} />,
      // },
      {
        key: "admin/User/admin",
        name: "Admin",
        route: "admin/User/admin",
        component: <AdminV2 />,
        icon: <AdminPanelSettingsIcon size={20} />,
      },
      {
        key: "admin/User/owner",
        name: "Owner",
        route: "admin/User/owner",
        component: <Owner />,
        icon: <AdminPanelSettingsIcon size={20} />,
      },
      {
        key: "admin/User/worker",
        name: "Worker",
        route: "admin/User/worker",
        component: <Worker />,
        icon: <AdminPanelSettingsIcon size={20} />,
      },
      {
        key: "admin/User/admin-whatsapp",
        name: "WaAdmin",
        route: "admin/User/admin-whatsapp",
        component: <AdminWhatsapp />,
        icon: <AdminPanelSettingsIcon size={20} />,
      },
      {
        key: "admin/User/staff",
        name: "Staff",
        route: "admin/User/staff",
        component: <Staff />,
        icon: <UsersRound size={20} />,
      },
      {
        key: "admin/User/staff-planning",
        name: "Staff Planning",
        route: "admin/User/staff-planning",
        component: <StaffPlanning />,
        icon: <CalendarDays size={20} />,
      }
    ]
  },
  {
    type: "dropdown",
    name: "Financial",
    key: "Financial",
    icon: <Landmark size={20} />,
    subRoutes: [
      {
        key: "financial/Analytics",
        name: "Analytics",
        route: "financial/Analytics",
        component: <Financial />,
        icon: <ChartNoAxesCombined size={20} />,
      },
    ]
  },
  {
    type: "dropdown",
    name: "Dynamic Price",
    key: "DynamicPrice",
    icon: <ChartCandlestick size={20} />,
    subRoutes: [
      {
        key: "admin/DynamicPrice/City",
        name: "City",
        route: "admin/DynamicPrice/City",
        component: <DynamicPricingComponent />,
        icon: <CircleDollarSign size={20} />,
      },
      {
        key: "admin/DynamicPrice/ListingsWithRatePlans",
        name: "Listing",
        route: "admin/DynamicPrice/ListingsWithRatePlans",
        component: <ListingsWithRatePlans />,
        icon: <Banknote size={20} />,
      },
    ],
  },
  {
    type: "dropdown",
    name: "Listing",
    key: "ListingOne",
    icon: <LayoutList size={20} />,

    subRoutes: [
      {
        key: "admin/Listing/Active",
        name: "Active",
        route: "admin/Listing/Active",
        component: <Listing />,
        icon: <ListTree size={20} />,
      },
      {
        key: "admin/Listing/Inactive",
        name: "Inactive",
        route: "admin/Listing/Inactive",
        component: <ListingInactivePage />,
        icon: <ListTree size={20} />,
      },
      {
        type: "title",
        key: "admin/Listing/Listing_Details",
        name: "Listing Details",
        route: "admin/Listing/Listing_Details",
        component: <ListingDetails />,
        icon: <Icon fontSize="small">info</Icon>,

      },
      {
        type: "title",
        key: "admin/Listing/new",
        name: "Create Listing",
        route: "admin/Listing/new",
        component: <NewListing />,
        icon: <Icon fontSize="small">info</Icon>,

      },
      {
        type: "title",
        key: "admin/Listing/edit",
        name: "Update Listing",
        route: "admin/Listing/edit/:listingId",
        component: <NewListing />,
      },

      {
        key: "admin/Listing/amenityConfig",
        name: "Amenities",
        route: "admin/Listing/amenityConfig",
        component: <AmenityConfig />,
        icon: <AlarmSmoke size={20} />,
      },
      {
        key: "admin/Listing/StandardSojori",
        name: "Standard Sojori",
        route: "admin/Listing/StandardSojori",
        component: <StandardSojoriPage />,
        icon: <Icon fontSize="small">handshake</Icon>
      },
      {
        key: "admin/Listing/Bed-Type",
        name: "Bed-Type",
        route: "admin/Listing/Bed-Type",
        component: <BedTypeConfig />,
        icon: <Icon fontSize="small">bed</Icon>
      },
      {
        key: "admin/Listing/RoomTypeConfig",
        name: "Room Type Config ",
        route: "admin/Listing/RoomTypeConfig",
        component: <RoomTypeConfigPage />,
        icon: <Bolt size={20} />
      },
      {
        key: "admin/Listing/listingMapping",
        name: "Listing Mapping",
        route: "admin/Listing/listingMapping",
        component: <ListingMapping />,
        icon: <ListTree size={20} />,


      },
      {
        key: "admin/Listing/access-type",
        name: "Access Types",
        route: "admin/Listing/access-type",
        component: <AccessTypePage />,
        icon: <ListTree size={20} />,


      },
      {
        key: "admin/Listing/Channex-Mapping",
        name: "Channex-Mapping",
        route: "admin/Listing/Channex-Mapping",
        component: <ChannexMapping />,
        icon: <ListTree size={20} />,


      },
    ],
  },

  {
    type: "dropdown",
    name: "Immobilier",
    key: "ProjectUnits",
    icon: <MapPinHouse size={20} />,
    subRoutes: [
      {
        key: "admin/ProjectUnits",
        name: "Immobilier",
        route: "admin/ProjectUnits",
        component: <ProjectUnits />,
        icon: <Milestone size={20} />,
      },
      {
        key: "projects",
        name: "Projects",
        route: "projects",
        component: <Projects />,
        icon: <Hotel size={20} />,
      },
      {
        key: "units",
        name: "Units",
        route: "units",
        component: <Units />,
        icon: <School size={20} />,
      },
      {
        key: "admin/UnitMapping",
        name: "Unit Mapping",
        route: "admin/UnitMapping",
        component: <UnitMapping />,
        icon: <ListOrdered size={20} />,
      },
    ],
  },
  {
    route: "admin/units/create",
    component: <UnitFormPage />,
  },
  {
    route: "admin/units/edit/:unitId",
    component: <UnitFormPage />,
  },

  {
    route: "admin/Reservation/:id/details",
    component: <ReservationDetails />,
  },
  {
    route: "admin/projectDetails/:projectId",
    component: <ProjectDetailsPage />,
  },
  {
    route: "admin/projects/:projectId?",
    component: <Projects />,
  },
  {
    route: "admin/units/:unitId",
    component: <UnitDetailsPage />,
  },
  {
    route: "admin/Reservation/ChekinDetails/:id",
    component: <ChekinDetails />,
  },
  {
    route: "admin/Reservation/create",
    component: <ReservationCreate />,
  },
  {
    type: "dropdown",
    name: "ChatBox",
    key: "ChatBox",
    icon: <MessagesSquare size={20} />,
    subRoutes: [
      // {
      //   key: "admin/ChatBox",
      //   name: "ChatBox",
      //   route: "admin/ChatBox",
      //   component: <ChatContainer />,
      //   icon: <MessageCircle size={20} />,
      // },
      {
        key: "admin/WhatsApp",
        name: "WhatsApp",
        route: "admin/WhatsApp",
        component: <ChatContainerV2 />,
        icon: <MessagesSquare size={20} />,
      },
      {
        key: "admin/Reviews",
        name: "Reviews",
        route: "admin/Reviews",
        component: <ReviewsContainer />,
        icon: <Star size={20} />,
      },
      {
        key: "admin/Messages",
        name: "Messages",
        route: "admin/Messages",
        component: <MessageContainer />,
        icon: <MessageCircle size={20} />,
      },
    ],
  },
  {
    type: "dropdown",
    name: "Surveillance",
    key: "Surveillance",
    icon: <Clock size={20} />,
    subRoutes: [
      {
        key: "admin/Surveillance",
        name: "Surveillance",
        route: "admin/Surveillance",
        component: <Minut />,
        icon: <Clock size={20} />,
      },
    ],
  },
  {
    type: "dropdown",
    name: "Partners",
    key: "Partners",
    icon: <Handshake size={20} />,
    subRoutes: [
      {
        key: "admin/Tickets",
        name: "Tickets",
        route: "admin/Tickets",
        component: <Tickets />,
        icon: <Ticket size={20} />,
      },
    ],
  },
  {
    type: "dropdown",
    name: "Leads",
    key: "Leads",
    icon: <CircleUser size={20} />,
    subRoutes: [
      {
        key: "admin/Leads",
        name: "Leads",
        route: "admin/Leads",
        component: <LeadsPage />,
        icon: <CircleUserRound size={20} />,
      },
    ],
  },
  {
    type: "dropdown",
    name: "Logs ",
    key: "logs",
    icon: <Logs size={20} />,

    subRoutes: [

      {
        key: "admin/logs/channex",
        name: "Channex Call",
        route: "admin/logs/channex",
        component: <ChannexCall />,
        icon: <AlignJustify size={20} />,
      },




    ],
  },
  {
    type: "dropdown",
    name: "Setting",
    key: "setting",
    icon: <Settings size={20} />,
    subRoutes: [
      {
        key: "admin/setting/adminConfig",
        name: "Admin Config",
        route: "admin/setting/adminConfig",
        component: <AdminConfig />,
        icon: <Icon fontSize="small">manage_accounts</Icon>,
      },
      {
        key: "admin/setting/referrals",
        name: "Referrals",
        route: "admin/setting/referrals",
        component: <ReferralPage />,
        icon: <Component size={20} />,
      },
      {
        key: "admin/setting/chatBox",
        name: "ChatBox",
        route: "admin/setting/chatBox",
        component: <ChatBox />,
        icon: <BotMessageSquare size={20} />,
      },
      {
        key: "admin/setting/openAi",
        name: "OpenAi",
        route: "admin/setting/openAi",
        component: <OpenAi />,
        icon: <OpenAILogo size={20} />,
      },
      {
        type: "title",
        key: "admin/setting/openAi_Configs",
        name: "Display Config",
        route: "admin/setting/openAi_Config",
        component: <OpenAIConfig />,
      },
      {
        key: "admin/setting/channelManager",
        name: "Channel Manager",
        route: "admin/setting/channelManager",
        component: <ChannelManager />,
        icon: <Rss size={20} />,
      },
      {
        key: "admin/setting/cronConfig",
        name: "Cron Config",
        route: "admin/setting/cronConfig",
        component: <CronConfig />,
        icon: <ToggleLeft size={20} />,
      },
      {
        key: "admin/setting/useFulNumber",
        name: "Use Full Number",
        route: "admin/setting/useFulNumber",
        component: <UsefulNUmber />,
        icon: <Headset size={20} />,
      },
      {
        key: "admin/setting/webSiteBlocksConfig",
        name: "Web Site Blocks Config",
        route: "admin/setting/webSiteBlocksConfig",
        component: <WebSiteBlockConfig />,
        icon: <PanelTop size={20} />,
      },
      {
        key: "admin/setting/currency",
        name: "currency",
        route: "admin/setting/currency",
        component: <Currency />,
        icon: <HandCoins size={20} />,
      },
      {
        key: "admin/setting/stories",
        name: "stories",
        route: "admin/setting/stories",
        component: <Story />,
        icon: <CircleFadingPlus size={20} />,
      },
      {
        key: "admin/setting/whatsappConfig",
        name: "whatsapp Config",
        route: "admin/setting/whatsappConfig",
        component: <WhatsAppConfig />,
        icon: <WhatsAppLogo size={20} />,
      },
      {
        key: "admin/setting/template",
        name: "template",
        route: "admin/setting/template",
        component: <Template />,
        icon: <MailCheck size={20} />,
      },
      {
        key: "admin/setting/exchanges",
        name: "exchanges",
        route: "admin/setting/exchanges",
        component: <Exchanges />,
        icon: <Ungroup size={20} />,
      },
      {
        key: "admin/setting/configReservation",
        name: "configReservation",
        route: "admin/setting/configReservation",
        component: <ConfigReservation />,
        icon: <CircleFadingPlus size={20} />,
      },
      {
        key: "admin/setting/synchroniser",
        name: "synchroniser",
        route: "admin/setting/synchroniser",
        component: <Synchroniser />,
        icon: <RefreshCcw size={20} />,
      },
      {
        key: "admin/setting/stayMore",
        name: "Stay More",
        route: "admin/setting/stayMore",
        component: <StayMore />,
        icon: <Flame size={20} />,
      },
      {
        key: "admin/setting/cancellationPolicy",
        name: "Cancellation Policy",
        route: "admin/setting/cancellationPolicy",
        component: <Cancellation />,
        icon: <TicketSlash size={20} />,
      }

    ],
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  // {
  //   type: "dropdown",
  //   name: "Charts",
  //   key: "charts",
  //   icon: <Icon fontSize="small">Charts</Icon>,
  //   subRoutes: [
  //     {
  //       key: "admin/charts/mongo-Chart",
  //       name: "mongo-Chart",
  //       route: "admin/charts/mongo-Chart",
  //       component: <MongoDBChartpage />,
  //       icon: <Icon fontSize="small">MongoDB Chart</Icon>,


  //     },

  //   ],
  // },



];


export default routes;

