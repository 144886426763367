import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const CountryFilter = ({
  countries = [],
  selectedCountries = [],
  setSelectedCountries,
}) => {
  const { t } = useTranslation('common');
  return (
    <FilterSearch
      options={countries}
      selectedItems={selectedCountries}
      onItemsChange={setSelectedCountries}
      placeholder={t('Select Countries')}
      idKey="code"
      labelKey="name"
    />
  );
};

export default CountryFilter;
