import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

const convertToMultiQueryParam = (paramName, values) =>
  values && values.length
    ? values.map((val) => `${paramName}=${val}`).join('&')
    : '';

const buildUrlParams = (baseParams, arrays) => {
  const params = new URLSearchParams();

  Object.entries(baseParams).forEach(([key, value]) => {
    if (key !== 'useActiveFilter') {
      params.append(key, value);
    }
  });

  if (arrays.active) {
    if (arrays.active.length === 1) {
      params.append('useActiveFilter', 'true');
      params.append('active', arrays.active[0]);
    } else if (arrays.active.length === 0 || arrays.active.length === 2) {
      params.append('useActiveFilter', 'false');
    }
  }

  Object.entries(arrays).forEach(([key, values]) => {
    if (key !== 'active' && Array.isArray(values) && values.length > 0) {
      values.forEach((value) => {
        params.append(key, value);
      });
    }
  });

  return params;
};

export function getBookedNights(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/booked-nights?${params.toString()}`,
  );
}

export function getRentalRevenue(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/rental-revenue?${params.toString()}`,
  );
}

export function getReservationStats(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  period = 'day',
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
      period,
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/reservation-stats?${params.toString()}`,
  );
}

export function getChannelStats(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/channel-stats?${params.toString()}`,
  );
}

export function getoccupancyRate(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_CALENDAR
    }/calendar/occupancy-rate?${params.toString()}`,
  );
}

export function getAverageDailyRate(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/average-daily-rate?${params.toString()}`,
  );
}

export function getAverageRevenuePerStay(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/average-revenue-per-stay?${params.toString()}`,
  );
}

export function getCheckinBychannel(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  period = 'day',
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
      period,
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/checkins-by-channel?${params.toString()}`,
  );
}

export function getRevenueBychannel(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/revenue-per-channel?${params.toString()}`,
  );
}

export function getBookedNightsByChannel(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/booked-nights-per-channel?${params.toString()}`,
  );
}

export function getReservationPercentagePerChannel(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/reservation-percentage-per-channel?${params.toString()}`,
  );
}

export function getRentalRevenuePerReservation(
  startDate,
  endDate,
  cityIds = [],
  listingIds = [],
  channelName = [],
  staging = false,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      staging: String(staging),
    },
    {
      active,
      cityIds,
      listingIds,
      channelName,
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/rental-revenue-per-reservation?${params.toString()}`,
  );
}

export function getcities(page, limit, paged, staging = false) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_ADMIN}/city?page=${page || 0}&limit=${
      limit || 20
    }&paged=false&staging=${staging}`,
  );
}

export function getListingsStats(staging = false) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/stats?staging=${staging}`,
  );
}

export function getListings(staging = false) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_LISTING}/listings?staging=${staging}`,
  );
}

export function getResStats(staging = false) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/stats?staging=${staging}`,
  );
}

export function getRevenuePerLandR(
  startDate,
  endDate,
  type,
  itemIds,
  channelNames = [],
  staging = false,
  dateFilterType,
  active = ['true'],
) {
  const params = buildUrlParams(
    {
      startDate,
      endDate,
      type,
      staging: String(staging),
      dateFilterType,
    },
    {
      active,
      channelNames,
      itemIds: Array.isArray(itemIds) ? itemIds : [itemIds],
    },
  );

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations/revenue-per-l-and-r?${params.toString()}`,
  );
}

export function getListingsPagination(
  page,
  limit,
  listingIds = [],
  cityIds = [],
  countryNames = [],
  staging = false,
  active = ['true'],
) {
  const params = new URLSearchParams();

  params.append('page', page);
  params.append('limit', limit);
  params.append('staging', staging);

  if (Array.isArray(active)) {
    if (active.length === 1) {
      params.append('useActiveFilter', 'true');
      params.append('active', active[0]);
    } else if (active.length === 0 || active.length === 2) {
      params.append('useActiveFilter', 'false');
    }
  }

  listingIds.forEach((id) => params.append('listingId[]', id));
  cityIds.forEach((id) => params.append('cityId[]', id));
  countryNames.forEach((name) => params.append('countryNames', name));

  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/?${params.toString()}`,
  );
}

export const getReservationPagination = (
  page,
  limit,
  filters,
  staging = false,
) => {
  const params = new URLSearchParams();

  params.append('page', page);
  params.append('limit', limit);
  params.append('startDate', filters.from);
  params.append('endDate', filters.to);
  params.append('dateType', filters.checkin);
  params.append('staging', staging);

  if (Array.isArray(filters.active)) {
    if (filters.active.length === 1) {
      params.append('useActiveFilter', 'true');
      params.append('active', filters.active[0]);
    } else if (filters.active.length === 0 || filters.active.length === 2) {
      params.append('useActiveFilter', 'false');
    }
  }

  if (filters.status && Array.isArray(filters.status)) {
    params.append('status', filters.status.join(','));
  }

  filters.listingIds?.forEach((id) => params.append('listingId[]', id));
  filters.cityIds?.forEach((id) => params.append('cityId[]', id));
  filters.countryNames?.forEach((name) => params.append('countryNames', name));
  if (filters.channelNames?.length) {
    params.append('channelName', filters.channelNames.join(','));
  }

  return axios.get(
    `${
      MICROSERVICE_BASE_URL.SRV_RESERVATION
    }/reservations?${params.toString()}`,
  );
};

export function getListingsList(staging = false) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings?staging=${staging}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    });
}

export function getCities(staging = false) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_ADMIN}/city?page=0&limit=100&paged=false&staging=${staging}`,
  );
}

export function getCountries(page, limit, paged) {
  return axios.get(
    `${MICROSERVICE_BASE_URL.COUNTRY}?page=${page || 0}&limit=${
      limit || 10
    }&paged=false`,
  );
}
