import React, { useState, useRef, useEffect } from 'react';
import { 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Typography,
  Box 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const VerificationItems = ({ formik, languages, type }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('0px');

  const arrayConfig = {
    verificationItems: { title: 'Verification List', timeSlots: null },
    verificationItemsCheckin: { title: 'Checkin Verification List', timeSlots: 'TS_CHECKIN' },
    verificationItemsCheckout: { title: 'Checkout Verification List', timeSlots: 'TS_CHECKOUT' }
  };

  const arrayName = type || 'verificationItems';
  const { title, timeSlots } = arrayConfig[arrayName];

  useEffect(() => {
    if (isExpanded) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setContentHeight('0px');
    }
  }, [isExpanded, formik.values[arrayName]]);

  const handleAddLanguageToItem = (itemIndex) => {
    const unusedLanguages = languages.filter(lang => 
      !Object.keys(formik.values[arrayName][itemIndex]).includes(lang._id)
    );
    
    if (unusedLanguages.length > 0) {
      const newItems = [...(formik.values[arrayName] || [])];
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        [unusedLanguages[0]._id]: ''
      };
      formik.setFieldValue(arrayName, newItems);
    }
  };

  const getItemsSummary = () => {
    return `${(formik.values[arrayName] || []).length} items`;
  };

//   const getTimeSlots = () => {
//     if (!timeSlots || !formik.values[timeSlots]) return null;
//     return (
//       <Box sx={{ mt: 1 }}>
//         <Typography variant="caption" color="text.secondary">
//           Time Slots: {formik.values[timeSlots].map(slot => 
//             `${slot.start}:00-${slot.end}:00`
//           ).join(', ')}
//         </Typography>
//       </Box>
//     );
//   };

  return (
    <div className="mt-2">
      <div 
        className="flex flex-col w-full cursor-pointer" 
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center mb-2">
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          <span className="text-sm font-semibold ml-2">{title}</span>
          <span className="text-sm text-gray-400 mx-1">{getItemsSummary()}</span>
          <button
            type="button"
            className="px-2 py-[10px] mx-1 bg-slate-200 !rounded-md"
            onClick={(e) => {
              e.stopPropagation();
              const newItem = {};
              languages.forEach(lang => {
                newItem[lang._id] = '';
              });
              formik.setFieldValue(arrayName, [...(formik.values[arrayName] || []), newItem]);
            }}
          >
            <AddIcon className="!text-sm" />
          </button>
        </div>
        {/* {getTimeSlots()} */}
      </div>
      
      <div
        ref={contentRef}
        style={{ height: contentHeight }}
        className="transition-height duration-300 ease-in-out overflow-hidden"
      >
        <div className="grid grid-cols-2 gap-2">
          {(formik.values[arrayName] || []).map((item, itemIndex) => (
            <div key={itemIndex} className="flex flex-col gap-2 p-2 border border-gray-100 rounded">
              <div className="flex justify-between items-center mb-1">
                <span className="text-xs text-gray-600">List {itemIndex + 1}</span>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="text-green-500 hover:bg-green-50 bg-green-50 px-3 !rounded-full"
                    onClick={() => handleAddLanguageToItem(itemIndex)}
                  >
                    <AddIcon className="!text-xs" />
                  </button>
                  {Object.keys(item).length > 0 && (
                    <button
                      type="button"
                      className="text-red-500 hover:bg-red-50 bg-red-50 px-3 !rounded-full"
                      onClick={() => {
                        const newItems = formik.values[arrayName].filter((_, i) => i !== itemIndex);
                        formik.setFieldValue(arrayName, newItems);
                      }}
                    >
                      <DeleteIcon className="!text-sm" />
                    </button>
                  )}
                </div>
              </div>
              
              {Object.entries(item).map(([langId, value]) => (
                <div key={langId} className="flex gap-1 items-center">
                  <FormControl size="small" className="w-1/3">
                    <InputLabel>Language</InputLabel>
                    <Select
                      value={langId}
                      onChange={(e) => {
                        const newItems = [...formik.values[arrayName]];
                        const currentValue = item[langId];
                        delete newItems[itemIndex][langId];
                        newItems[itemIndex][e.target.value] = currentValue;
                        formik.setFieldValue(arrayName, newItems);
                      }}
                    >
                      {languages.map(lang => (
                        <MenuItem key={lang._id} value={lang._id}>
                          {lang.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  
                  <TextField
                    size="small"
                    className="w-2/3"
                    label="Value"
                    value={value}
                    onChange={(e) => {
                      const newItems = [...formik.values[arrayName]];
                      newItems[itemIndex][langId] = e.target.value;
                      formik.setFieldValue(arrayName, newItems);
                    }}
                  />
                  
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => {
                      const newItems = [...formik.values[arrayName]];
                      delete newItems[itemIndex][langId];
                      if (Object.keys(newItems[itemIndex]).length === 0) {
                        newItems.splice(itemIndex, 1);
                      }
                      formik.setFieldValue(arrayName, newItems);
                    }}
                  >
                    <DeleteIcon className="!text-sm" />
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerificationItems;