import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Button, Typography, Grid, Paper, Divider,
    Box, Chip, Avatar, IconButton
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ApartmentIcon from '@mui/icons-material/Apartment';
import TimerIcon from '@mui/icons-material/Timer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CategoryIcon from '@mui/icons-material/Category';
import SourceIcon from '@mui/icons-material/Source';
import NoteIcon from '@mui/icons-material/Note';

const LeadViewModal = ({ open, onClose, lead }) => {
    const { t } = useTranslation('common');

    if (!lead) return null;

    const formatDate = (dateString) => {
        try {
            return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
        } catch (error) {
            return t('Invalid date');
        }
    };

    const InfoItem = ({ icon, label, value }) => (
        <Box className="!flex !items-start !gap-2 !mb-3 !w-full">
            {icon && (
                <Avatar className="!bg-medium-aquamarine/10 !text-medium-aquamarine !w-5 !h-5 !flex-shrink-0">
                    {React.cloneElement(icon, { fontSize: "inherit", className: "!text-[0.7rem]" })}
                </Avatar>
            )}
            <Box className="!flex-1">
                <Typography variant="subtitle2" className="!text-xs !font-semibold !text-gray-700 !mb-0.5">
                    {label}
                </Typography>
                <Typography variant="body2" className="!text-xs !text-gray-600 !break-words">
                    {value || '-'}
                </Typography>
            </Box>
        </Box>
    );

    const SectionHeader = ({ icon, title }) => (
        <Box className="!flex !items-center !justify-center !mb-4">
            <Avatar className="!bg-medium-aquamarine !mr-2 !w-5 !h-5 !flex-shrink-0">
                {React.cloneElement(icon, { fontSize: "inherit", className: "!text-[0.7rem]" })}
            </Avatar>
            <Typography variant="subtitle1" className="!text-sm !font-semibold !text-gray-800">
                {title}
            </Typography>
        </Box>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            className="!rounded-lg"
            PaperProps={{
                className: "!rounded-lg !shadow-xl"
            }}
        >
            <DialogTitle className="!flex !justify-between !items-center !py-2 !px-4">
                <Typography variant="h6" className="!text-medium-aquamarine !text-base !font-semibold !flex-grow">
                    {t('Lead Details')}
                </Typography>
                <IconButton size="small" onClick={onClose} className="!text-gray-500 !p-1">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>

            <Divider className="!mx-2" />

            <DialogContent className="!p-3 !overflow-y-auto !max-h-[80vh]">
                <Paper elevation={0} className="!p-3 !mb-3 !bg-gray-50/80 !rounded-lg !border !border-gray-100 !shadow-sm">
                    <SectionHeader
                        icon={<BusinessIcon />}
                        title={t('Basic Information')}
                    />

                    <Box className="!flex !flex-wrap !gap-x-4">
                        <Box className="!flex-1 !min-w-[200px]">
                            <InfoItem
                                icon={<BusinessIcon />}
                                label={t('Name')}
                                value={lead.name}
                            />
                        </Box>
                        <Box className="!flex-1 !min-w-[200px]">
                            <InfoItem
                                icon={<EmailIcon />}
                                label={t('Email')}
                                value={lead.email}
                            />
                        </Box>
                        <Box className="!flex-1 !min-w-[200px]">
                            <InfoItem
                                icon={<PhoneIcon />}
                                label={t('Phone')}
                                value={lead.phone_number}
                            />
                        </Box>
                        <Box className="!flex-1 !min-w-[200px]">
                            <InfoItem
                                icon={<ApartmentIcon />}
                                label={t('Properties Number')}
                                value={lead.properties_number}
                            />
                        </Box>
                        <Box className="!flex-1 !min-w-[200px]">
                            <InfoItem
                                icon={<LocationOnIcon />}
                                label={t('Country')}
                                value={lead.country}
                            />
                        </Box>
                        <Box className="!flex-1 !min-w-[200px]">
                            <InfoItem
                                icon={<CalendarTodayIcon />}
                                label={t('Created At')}
                                value={formatDate(lead.created_at)}
                            />
                        </Box>
                    </Box>
                </Paper>

                {lead.details && lead.details.length > 0 && (
                    <Paper elevation={0} className="!p-3 !bg-gray-50/80 !rounded-lg !border !border-gray-100 !shadow-sm">
                        <SectionHeader
                            icon={<NoteIcon />}
                            title={t('Additional Details')}
                        />

                        <Box className="!flex !flex-col !gap-3">
                            <Box className="!flex !flex-wrap !gap-x-4">
                                <Box className="!flex-1 !min-w-[200px]">
                                    <InfoItem
                                        icon={<BusinessIcon />}
                                        label={t('Company Name')}
                                        value={lead.details[0]?.company_name}
                                    />
                                </Box>
                                <Box className="!flex-1 !min-w-[200px]">
                                    <InfoItem
                                        icon={<TimerIcon />}
                                        label={t('Timeline')}
                                        value={lead.details[0]?.timeline}
                                    />
                                </Box>
                                <Box className="!flex-1 !min-w-[200px]">
                                    <InfoItem
                                        icon={<TrendingUpIcon />}
                                        label={t('Planned Growth')}
                                        value={lead.details[0]?.planned_growth}
                                    />
                                </Box>
                            </Box>

                            <Divider className="!my-1" />

                            <Box className="!flex !flex-wrap !gap-x-4">
                                <Box className="!flex-1 !min-w-[250px]">
                                    <InfoItem
                                        label={t('Biggest Challenges')}
                                        value={lead.details[0]?.biggest_challenges}
                                    />
                                </Box>
                                <Box className="!flex-1 !min-w-[250px]">
                                    <InfoItem
                                        label={t('Expected Outcomes')}
                                        value={lead.details[0]?.expected_outcomes}
                                    />
                                </Box>
                            </Box>

                            <Divider className="!my-1" />

                            <Box className="!flex !flex-wrap !gap-x-4">
                                <Box className="!flex-1 !min-w-[200px]">
                                    <InfoItem
                                        icon={<CategoryIcon />}
                                        label={t('Client Type')}
                                        value={lead.details[0]?.client_type}
                                    />
                                </Box>
                                <Box className="!flex-1 !min-w-[200px]">
                                    <InfoItem
                                        label={t('Industry')}
                                        value={lead.details[0]?.industry}
                                    />
                                </Box>
                                <Box className="!flex-1 !min-w-[200px]">
                                    <InfoItem
                                        icon={<SourceIcon />}
                                        label={t('Referral Source')}
                                        value={lead.details[0]?.referral_source}
                                    />
                                </Box>


                            </Box>

                            <Divider className="!my-1" />

                            <Box className="!flex !flex-wrap !gap-x-4">

                            <Box className="!flex-1 !min-w-[200px]">
                                <InfoItem
                                    label={t('Dynamic Pricing')}
                                    value={lead.details[0]?.using_dynamic_pricing ? t('Yes') : t('No')}
                                />
                            </Box>
                            <Box className="!flex-1 !min-w-[200px]">
                                <InfoItem
                                    label={t('Promo Code')}
                                    value={lead.details[0]?.promo_code}
                                />
                            </Box>

                            </Box>

                            <Divider className="!my-1" />


                            {lead.details[0]?.current_solutions && lead.details[0]?.current_solutions.length > 0 && (
                                <Box className="!mt-1">
                                    <Typography variant="subtitle2" className="!text-xs !font-semibold !mb-2 !text-gray-700">
                                        {t('Current Solutions')}
                                    </Typography>
                                    <Box className="!flex !flex-wrap !gap-1">
                                        {lead.details[0].current_solutions.map((solution, index) => (
                                            <Chip
                                                key={index}
                                                label={solution}
                                                size="small"
                                                className="!text-xs !bg-gray-100 !text-gray-700 !px-2 !h-5"
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            )}

                            {lead.details[0]?.discovery_notes && (
                                <Box className="!mt-2 !bg-gray-100 !rounded-lg !p-3">
                                    <Typography variant="subtitle2" className="!text-xs !font-semibold !mb-1 !text-gray-700">
                                        {t('Discovery Notes')}
                                    </Typography>
                                    <Typography variant="body2" className="!text-xs !whitespace-pre-line !text-gray-600">
                                        {lead.details[0]?.discovery_notes}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Paper>
                )}
            </DialogContent>

            <DialogActions className="!justify-center !p-3">
                <Button
                    onClick={onClose}
                    variant="contained"
                    size="small"
                    className="!text-xs !px-6 !py-1 !rounded-full !bg-medium-aquamarine !hover:bg-medium-aquamarine-dark text-white !shadow-sm"
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LeadViewModal;