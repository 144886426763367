/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Margin } from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const sidebarWidth = 270;
  const { transparent, gradients, white, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem, linearGradient } = functions;

  let backgroundValue = "white";

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    display: "none",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      margin: 0,
      height: "100vh",
      backgroundColor: "white",
      color: "black",
      "& .logo-container": { 
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 1200,
        paddingBottom: "16px"
      },
      "& .scrollable-content": { 
        overflowY: "auto",
        height: "calc(100vh - 100px)" 
      },
      "& .MuiListItemIcon-root": {
        color: "#F4762A !important" 
      },
      "& svg": {
        color: "#F4762A !important"
      },
      "& .MuiTypography-root": {
        color: "black"
      },
      "& .Mui-selected, & .active": {
        backgroundColor: "#ff6b00 !important",
        "& .MuiListItemIcon-root": {
          color: "white !important"
        },
        "& svg": {
          color: "white !important"
        },
        "& .MuiTypography-root": {
          color: "white !important"
        },
        "&:hover": {
          backgroundColor: "#ff6b00 !important"
        }
      },
      "& .MuiCollapse-root": {
        backgroundColor: "white",
        "& .MuiListItemIcon-root": {
          color: "#F4762A !important"
        },
        "& .Mui-selected, & .active": {
          backgroundColor: "#ff6b00 !important",
          "& .MuiListItemIcon-root": {
            color: "white !important"
          },
          "& .MuiTypography-root": {
            color: "white !important"
          }
        }
      },
      "& a": {
        color: "black",
        textDecoration: "none",
        "& .MuiListItemIcon-root": {
          color: "#F4762A !important"
        }
      },
      "& .MuiDivider-root": {
        borderColor: "rgba(0, 0, 0, 0.12)"
      },
      "& .logo-divider": {
        margin: "16px 0",
        height: "2px",
        backgroundColor: "rgba(0, 0, 0, 0.2)", 
        border: "none",
        width: "100%",
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)" 
      },
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },

    "& .MuiPaper-root": {
      margin: 0,
      width: ownerState.miniSidenav ? 74 : 270,
      borderRadius: 0,
      backgroundColor: ownerState.transparentSidenav
        ? "transparent"
        : ownerState.darkMode
        ? theme.palette.dark.main
        : theme.palette.white.main,
    },
  };
});
