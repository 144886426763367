import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormControl,
    InputLabel,
    Button,
    TextField,
    Box
} from '@mui/material';
import {
    searchListings,
    getListingsTa,
    getTaskConfigs,
    getCities,
    getCountries
} from '../../services/serverApi.task';
import AddTask from './AddTask';
import AddTaskButton from './AddTaskButton';
import { useLocation, useNavigate } from 'react-router-dom';
import resetSvgIcon from 'assets/images/resetSvgIcon.svg';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const FilterTask = ({
    onFilterChange,
    onSaveTask,
    staff,
    initialFilters = {}
}) => {
    const [filters, setFilters] = useState({
        type: [],
        subType: [],
        status: ["PENDING", "CONFIRMED"],
        assignmentStatus: [],
        listingId: initialFilters.listingId || '',
        reservationNumber: initialFilters.reservationNumber || '',
        searchByGuest: initialFilters.searchByGuest || '',
        cityIds: [],
        countryNames: [],
        useActiveFilter: true,
        active: ['true'], 
        listingIds: []
    });

    const filterRef = useRef(null);
    const [listingOptions, setListingOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
    const [isAddTask, setIsAddTask] = useState(false);
    const [listings, setListings] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

    useEffect(() => {
        let searchParams = new URLSearchParams();

        if (filters.reservationNumber?.trim()) {
            searchParams.set('reservationNumber', filters.reservationNumber);
        }
        if (filters.listingId) {
            searchParams.set('listingId', filters.listingId);
        }
        if (filters.searchByGuest?.trim()) {
            searchParams.set('searchByGuest', filters.searchByGuest);
        }
        if (filters.cityIds?.length > 0) {
            filters.cityIds.forEach(cityId => searchParams.append('cityIds', cityId));
        }
        if (filters.countryNames?.length > 0) {
            filters.countryNames.forEach(country => searchParams.append('countryNames', country));
        }
        if (filters.listingIds?.length > 0) {
            filters.listingIds.forEach(id => searchParams.append('listingIds', id));
        }

        if (Array.isArray(filters.active)) {
            if (filters.active.length === 1) {
                searchParams.append('useActiveFilter', 'true');
                searchParams.append('active', filters.active[0]);
            } else if (filters.active.length === 0 || filters.active.length === 2) {
                searchParams.append('useActiveFilter', 'false');
            }
        }

        const newUrl = `/admin/Tasks?${searchParams.toString()}`;
        if (location.search !== `?${searchParams.toString()}`) {
            navigate(newUrl, { replace: true });
        }

        onFilterChange({
            ...filters,
            useActiveFilter: filters.active.length === 1
        });
    }, [
        filters.type,
        filters.subType,
        filters.status,
        filters.assignmentStatus,
        filters.listingId,
        filters.reservationNumber,
        filters.searchByGuest,
        filters.cityIds,      
        filters.countryNames, 
        filters.listingIds,  
        filters.active   
    ]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const reservationNumber = searchParams.get('reservationNumber');
        const listingId = searchParams.get('listingId');
        const searchByGuest = searchParams.get('searchByGuest');
        const cityIds = searchParams.getAll('cityIds');
        const countryNames = searchParams.getAll('countryNames');
        const listingIds = searchParams.getAll('listingIds');

        setFilters(prevFilters => ({
            ...prevFilters,
            reservationNumber: reservationNumber || prevFilters.reservationNumber,
            listingId: listingId || prevFilters.listingId,
            searchByGuest: searchByGuest || prevFilters.searchByGuest,
            cityIds: cityIds.length > 0 ? cityIds : prevFilters.cityIds,
            countryNames: countryNames.length > 0 ? countryNames : prevFilters.countryNames,
            listingIds: listingIds.length > 0 ? listingIds : prevFilters.listingIds
        }));
    }, [location.search]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchListings();
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, staging]);

    useEffect(() => {
        fetchTaskTypes();
        fetchListingOptions();
        fetchCities();
        fetchCountries();
    }, [staging]);

    const fetchListingOptions = async () => {
        try {
            const listings = await getListingsTa(staging);
            setListings(listings);

            if (filters.listingId) {
                const selectedListing = listings.find(listing => listing._id === filters.listingId);
                if (selectedListing) {
                    setListingOptions([{
                        label: selectedListing.name,
                        value: selectedListing._id
                    }]);
                }
            }
        } catch (error) {
            console.error('Error fetching listing options:', error);
        }
    };

    const fetchTaskTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getTaskConfigs();
            if (response && Array.isArray(response)) {
                setTaskTypes(response);
            } else {
                setTaskTypes([]);
            }
        } catch (error) {
            console.error('Error fetching task config:', error);
            setTaskTypes([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchListings = async () => {
        try {
            const response = await searchListings({
                name: searchTerm,
                limit: 20,
                staging
            });
            if (response.data) {
                setListingOptions(response.data.map(listing => ({
                    label: listing.name,
                    value: listing._id
                })));
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
            setListingOptions([]);
        }
    };

    const fetchCities = async () => {
        try {
            const response = await getCities();
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
            setCities([]);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await getCountries();
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
            setCountries([]);
        }
    };

    const handleFilterChange = (field, value) => {
        if (field === 'active') {
            setFilters(prevFilters => ({
                ...prevFilters,
                [field]: value,
                useActiveFilter: value.length === 1
            }));
        } else {
            setFilters(prevFilters => ({
                ...prevFilters,
                [field]: value
            }));
        }
    };

    const handleClearFilters = () => {
        const clearedFilters = {
            type: [],
            subType: [],
            status: ['PENDING', 'CONFIRMED'],
            assignmentStatus: [],
            listingId: '',
            reservationNumber: '',
            searchByGuest: '',
            cityIds: [],
            countryNames: [],
            useActiveFilter: true,
            active: ['true'], 
            listingIds: []
        };
        setFilters(clearedFilters);
        navigate('/admin/Tasks', { replace: true });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOpenAddTaskModal = () => {
        setOpenAddTaskModal(true);
        setIsAddTask(true);
    };

    const handleCloseAddTaskModal = () => {
        setOpenAddTaskModal(false);
    };

    const renderMultiSelect = (field, label, options) => {
        let selectOptions = [];

        if (field === 'type') {
            selectOptions = options.map(option => ({
                id: option.task,
                name: option.task
            }));
        } else if (field === 'subType') {
            selectOptions = options.flatMap(type =>
                type.subs.map(sub => ({
                    id: sub.type,
                    name: sub.type
                }))
            );
        } else {
            selectOptions = options.map(option => ({
                id: option,
                name: option
            }));
        }

        return (
            <FilterSearch
                options={selectOptions}
                selectedItems={filters[field]}
                onItemsChange={(value) => handleFilterChange(field, value)}
                placeholder={label}
                idKey="id"
                labelKey="name"
                width={250}
            />
        );
    };

    const activeStatusOptions = [
        { id: 'true', name: 'Active' },
        { id: 'false', name: 'Inactive' }
    ];

    return (
        <>
            <div className='flex justify-between items-center px-4 py-2'>
                <div className="flex gap-2 items-center">
                    <Button
                        onClick={() => setShowFilters(!showFilters)}
                        className="!p-button-text !p-1 !rounded !my-1 sm:my-3 !bg-medium-aquamarine !h-9 !px-4 !justify-center"
                    >
                        <span className="!text-white !text-xs !font-medium">
                            Filter {showFilters ? '▼' : '▶'}
                        </span>
                    </Button>
                    {/* <div className="col-span-6 flex items-center justify-end space-x-2 gap-2">
                        <Button
                            onClick={handleClearFilters}
                            variant="outlined"
                            style={{ borderColor: '#66dac3', color: '#66dac3' }}
                            size="small"
                        >
                            Clear Filters
                        </Button>
                    </div> */}

                    <Button
                        onClick={handleClearFilters}
                        className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !h-9 !w-9 !justify-center"
                    >
                        <img src={resetSvgIcon} alt="reset" className="mr-2" />
                    </Button>

                </div>
                <div>
                    <AddTaskButton onClick={handleOpenAddTaskModal} />
                </div>
            </div>

            {showFilters && (
                <div
                    ref={filterRef}
                    className="w-full p-4"
                >
                    <div className="flex flex-wrap gap-2">
                        <div className="">
                            <FormControl fullWidth size="small">
                                <TextField
                                    label="Search by Guest"
                                    value={filters.searchByGuest}
                                    onChange={(e) => handleFilterChange('searchByGuest', e.target.value)}
                                    size="small"
                                    inputProps={{
                                        style: { height: '20px' }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="">
                            <FormControl fullWidth size="small">
                                <TextField
                                    label="Search"
                                    value={filters.reservationNumber}
                                    onChange={(e) => handleFilterChange('reservationNumber', e.target.value)}
                                    size="small"
                                    inputProps={{
                                        style: { height: '20px' }
                                    }}
                                />
                            </FormControl>
                        </div>

                        <div className="">
                            <FilterSearch
                                selectedItems={filters.countryNames}
                                options={countries}
                                onItemsChange={(value) => handleFilterChange('countryNames', value)}
                                placeholder="Select Countries"
                                idKey="name"
                                labelKey="name"
                                width={250}
                            />
                        </div>

                        <div className="">
                            <FilterSearch
                                selectedItems={filters.cityIds}
                                options={cities}
                                onItemsChange={(value) => handleFilterChange('cityIds', value)}
                                placeholder="Select Cities"
                                idKey="_id"
                                labelKey="name"
                                width={250}
                            />
                        </div>

                        <div className="">
                            <FilterSearch
                                selectedItems={filters.listingIds}
                                options={listingOptions}
                                onItemsChange={(value) => handleFilterChange('listingIds', value)}
                                placeholder="Select Listings"
                                idKey="value"
                                labelKey="label"
                                width={250}
                            />
                        </div>

                        <div className="">
                            <FilterSearch
                                selectedItems={filters.active}
                                options={activeStatusOptions}
                                onItemsChange={(value) => handleFilterChange('active', value)}
                                placeholder="Select Status"
                                idKey="id"
                                labelKey="name"
                                width={200}
                            />
                        </div>

                        <div className="">
                            {renderMultiSelect('type', 'Task Types', taskTypes)}
                        </div>
                        <div className="">
                            {renderMultiSelect('status', 'Task Status', [
                                'PENDING',
                                'CONFIRMED',
                                'IN_PROGRESS',
                                'COMPLETED',
                                'CANCELED',
                                'CANCELED-BY-CUST'
                            ])}
                        </div>
                        <div className="">
                            {renderMultiSelect('subType', 'Task Sub-Types', taskTypes)}
                        </div>
                        <div className="">
                            {renderMultiSelect('assignmentStatus', 'Assignment Status', ['ASSIGNED', 'UNASSIGNED', 'ACCEPTED', 'REFUSED'])}
                        </div>

                    </div>
                </div>
            )}

            {openAddTaskModal && (
                <AddTask
                    open={openAddTaskModal}
                    onClose={handleCloseAddTaskModal}
                    onSave={onSaveTask}
                    listing={listings}
                    staff={staff}
                    isAddTask={isAddTask}
                    listingId={filters.listingId}
                />
            )}
        </>
    );
}

export default FilterTask;
