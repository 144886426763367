import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';


export function CreateStaff(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_USER}/user/register`, data);
}
export function deleteStaff(clerkId) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_USER}/user/delete-user/${clerkId}`);
}

export function updateStaff(clerkId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_USER}/user/update/${clerkId}`, data);
}

export function getStaff(params = {}) {
    const { page = 0, limit = 25, search_text = '' } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        search_text
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_TASK}/staff/approved-staff?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getStaff:', error);
            throw error;
        });
}


export function getLanguage() {
    return axios.get(`${MICROSERVICE_BASE_URL.LANGUAGE}?page=0&limit=20&paged=false&search_text`);

}


//----------------------------------Admin--------------------------------------------

export function getAdmins(params = {}) {
    const { page = 0, limit = 25, username = '', deleted = false, banned = false } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        username,
        deleted,
        banned
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/admin/get-admins?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}
export function getClientWhiteList(params = {}) {
    const { page = 0, limit = 25, phone = '', } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        phone,
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_CHATBOT}/client/get-client-white-list?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}
export function blocClientWhiteList(id = {}) {


    return axios.put(`${MICROSERVICE_BASE_URL.SRV_CHATBOT}/client/block-phone/${id}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}
export function updateClientWhiteList(id, body) {


    return axios.put(`${MICROSERVICE_BASE_URL.SRV_CHATBOT}/client/update-phone/${id}`, body)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}
export function getClient(params = {}) {
    const { page = 0, limit = 25, username = '', deleted = false, banned = false } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        username,
        deleted,
        banned
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/client/get-clients?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}



//----------------------------------Reservation--------------------------------------------



export function getReservation(params = {}) {
    const {
        page = 0,
        limit = 10,
        reservationNumber = '',
        staging = false,
        reservationId = []
    } = params;

    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        reservationNumber,
        staging
    });

    reservationId.forEach(id => {
        queryParams.append('reservationId[]', id);
    });

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations?${queryParams.toString()}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getReservation:', error);
            throw error;
        });
}

// -------------------------- strat admin Whatsapp
export function getAdminWhatsapp(params = {}) {
    const { page = 0, limit = 25, search_text = '' } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        search_text
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_TASK}/admin-whatsapp/get?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getStaff:', error);
            throw error;
        });
}
export function CreateAdminWhatsapp(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_TASK}/admin-whatsapp/create`, data);
}
export function updateAdminWhatsapp(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_TASK}/admin-whatsapp/update/${id}`, data);
}
// -------------------------- end admin Whatsapp
// -------------------------- start staff Planning
export function getStaffPlannig(page, limit, paged, searchText) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_TASK}/staff/approved-staff-planning?page=${page}&limit=${limit}&paged=${paged}&search_text=${searchText}`);
}
export function updateStaffPlannig(payload) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_TASK}/staff/update-staff-planning`, payload);
}
// -------------------------- end staff Planning

// -------------------------- owner

export function getOwners(params = {}) {
    const { page = 0, limit = 20, deleted = false, banned = false, search_text = '' } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        roles: 'Owner',
        deleted,
        banned,
        search_text
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_USER}/user/get-account?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getOwners:', error);
            throw error;
        });
}
  
  export function createOwner(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_USER}/auth/register`, {
      ...data,
      role: 'Owner'
    });
  }
  
  export function updateOwner(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_USER}/auth/update-account/${id}`, data);
  }

// -------------------------- adminV2
export function getAdminsV2(params = {}) {
    const { page = 0, limit = 20, deleted = false, banned = false, search_text = '' } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        roles: 'Admin',
        deleted,
        banned,
        search_text
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_USER}/user/get-account?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdminsV2:', error);
            throw error;
        });
}

export function createAdminV2(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_USER}/auth/register`, {
        ...data,
        role: 'Admin' 
    });
}

export function updateAdminV2(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_USER}/auth/update-account/${id}`, data);
}


// -------------------------- worker
export function getWorkers(params = {}) {
    const { page = 0, limit = 20, deleted = false, banned = false, search_text = '' } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        roles: 'Worker',
        deleted,
        banned,
        search_text
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_USER}/user/get-account?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getWorkers:', error);
            throw error;
        });
}
  
export function inviteWorker(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_USER}/auth/invite-woker`, data);
}
  
export function updateWorker(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_USER}/auth/update-account/${id}`, data);
}