import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Tabs,
  Tab,
  Typography,
  TextField,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
} from '@mui/material';

import GlobalTable from '../../../../components/GlobalTable/GlobalTable';
const Services = ({ formik, conciergeType }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleOptionChange = (selectedTypeId, optionId, field, value) => {
    // Find the concierge type
    const selectedConcierge = conciergeType.find(
      (item) => item._id === selectedTypeId,
    );
    if (!selectedConcierge) return;

    // Get the existing service if it exists
    const existingService = (formik.values.services || []).find(
      (service) => service._id === selectedTypeId,
    );

    // Ensure options is an array, even if it doesn't exist yet
    let updatedOptions = existingService?.options
      ? [...existingService.options]
      : [];

    // Find the specific option in the existing services
    const optionIndex = updatedOptions.findIndex((opt) => opt._id === optionId);

    if (optionIndex !== -1) {
      // If the option exists, update only the changed field
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        [field]: value,
      };
    } else {
      // If the option is not in Formik yet, check if it's being activated
      if (field === 'active' && value === true) {
        const newOption = selectedConcierge.options.find(
          (opt) => opt._id === optionId,
        );
        if (newOption) {
          updatedOptions.push({ ...newOption, active: true });
        }
      }
    }

    // Ensure only active options are kept
    updatedOptions = updatedOptions.filter((opt) => opt.active);

    // Prepare updated services array
    const updatedServices = [...(formik.values.services || [])];

    // Find the service index in Formik values
    const serviceIndex = updatedServices.findIndex(
      (service) => service._id === selectedTypeId,
    );

    if (updatedOptions.length > 0) {
      if (serviceIndex !== -1) {
        // Update existing service with new options
        updatedServices[serviceIndex] = {
          ...updatedServices[serviceIndex],
          options: updatedOptions,
        };
      } else {
        // Add new service if it doesn't exist
        console.log('updatedOptions', updatedOptions);

        updatedServices.push({
          type: selectedConcierge.type,
          _id: selectedConcierge._id,
          options: updatedOptions, // Ensure options is correctly set
          // ...updatedOptions,
          // daysBeforeAssignment: 0,
          // notificationHours: 9,
        });
      }
    } else {
      // If no active options remain, remove the service type
      if (serviceIndex !== -1) {
        updatedServices.splice(serviceIndex, 1);
      }
    }

    // Update Formik
    formik.setFieldValue('services', updatedServices);
  };

  const renderTabContent = (selectedTypeId) => {
    const selectedConcierge = conciergeType.find(
      (item) => item._id === selectedTypeId,
    );

    return (
      <Box sx={{ p: 3 }}>
        <OptionsTable
          options={selectedConcierge?.options || []}
          selectedType={selectedTypeId}
          handleOptionChange={handleOptionChange}
          formik={formik}
        />
      </Box>
    );
  };

  return (
    <div className="!border-none">
      <Paper elevation={3}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {conciergeType.map((item, index) => (
            <Tab key={item._id} label={item.type} />
          ))}
        </Tabs>

        {conciergeType.map((item, index) => (
          <Box key={item._id} role="tabpanel" hidden={activeTab !== index}>
            {activeTab === index && renderTabContent(item._id)}
          </Box>
        ))}
      </Paper>
    </div>
  );
};

export default Services;
const CustomRadio = styled(Radio)(() => ({
  padding: '4px',
  '&': {
    color: '#00b4b4',
  },
  '&.Mui-checked': {
    color: '#00b4b4 !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 180, 180, 0.04) !important',
  },
}));
const OptionsTable = ({
  options,
  selectedType,
  handleOptionChange,
  formik,
}) => {
  // const findField = (rowData) =>
  //   (formik.values?.services || [])
  //     .find((item) => item._id == selectedType)
  //     ?.options.find((item) => item._id == rowData._id);
  const findField = (rowData) => {
    const service = (formik.values?.services || []).find(
      (item) => item._id?.toString() === selectedType.toString(), // Ensure correct comparison
    );

    console.log('###########', service);

    // Ensure options is always an array before calling .find()
    if (!service || !Array.isArray(service.options)) {
      return undefined;
    }

    return service.options.find(
      (option) => option._id?.toString() === rowData._id?.toString(),
    );
  };
  const columns = [
    {
      field: 'pickup',
      header: 'Pickup Location',
      width: '200px',
      body: (rowData) => (
        <Typography variant="body2">{rowData.pickup}</Typography>
      ),
    },
    {
      field: 'city',
      header: 'City',
      width: '150px',
      body: (rowData) => (
        <Typography variant="body2">{rowData.city}</Typography>
      ),
    },
    {
      field: 'active',
      header: 'Active',
      width: '150px',
      body: (rowData) => (
        <Switch
          //   checked={rowData.active || false}
          checked={findField(rowData) || false}
          onChange={(event) =>
            handleOptionChange(
              selectedType,
              rowData._id,
              'active',
              event.target.checked,
            )
          }
          name="Active"
          color="primary"
        />
      ),
    },
    {
      field: 'price',
      header: 'Price',
      width: '150px',
      body: (rowData) => (
        <TextField
          size="small"
          type="number"
          // disabled={true}
          disabled={!findField(rowData)}
          value={findField(rowData)?.price || rowData.price}
          onChange={(e) => {
            // handleOptionChangeOtherField(
            handleOptionChange(
              selectedType,
              rowData._id,
              'price',
              parseFloat(e.target.value),
            );
          }}
        />
      ),
    },
    {
      field: 'daysBeforeAssignment',
      header: 'Days Before Assignment',
      width: '200px',
      body: (rowData) => (
        <RadioGroup
          row
          value={findField(rowData)?.daysBeforeAssignment}
          onChange={(e) =>
            handleOptionChange(
              selectedType,
              rowData._id,
              'daysBeforeAssignment',
              parseFloat(e.target.value),
            )
          }
          sx={{ display: 'flex', gap: 0, justifyContent: 'center' }}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7].map((day) => (
            <FormControlLabel
              key={day}
              disabled={!findField(rowData)}
              value={day.toString()}
              control={<CustomRadio size="small" />}
              label={<Typography variant="body2">{`${day}d`}</Typography>}
              labelPlacement="top"
              sx={{
                margin: 0,
                flexDirection: 'column',
                '.MuiFormControlLabel-label': {
                  marginBottom: '4px',
                },
              }}
            />
          ))}
        </RadioGroup>
      ),
    },
    {
      field: 'notificationHours',
      header: 'Notification Hours',
      width: '200px',
      body: (rowData) => (
        <RadioGroup
          row
          value={findField(rowData)?.notificationHours}
          onChange={(e) =>
            handleOptionChange(
              selectedType,
              rowData._id,
              'notificationHours',
              parseFloat(e.target.value),
            )
          }
          sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}
        >
          {[9, 13, 17, 19].map((hour) => (
            <FormControlLabel
              key={hour}
              disabled={!findField(rowData)}
              value={hour.toString()}
              control={<CustomRadio size="small" />}
              label={<Typography variant="body2">{`${hour}h`}</Typography>}
              labelPlacement="top"
              sx={{
                margin: 0,
                flexDirection: 'column',
                '.MuiFormControlLabel-label': {
                  marginBottom: '4px',
                },
              }}
            />
          ))}
        </RadioGroup>
      ),
    },
  ];

  return <GlobalTable data={options} columns={columns} hasPagination={false} />;
};
