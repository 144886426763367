import React from 'react';
import { Button, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const PaginationControls = ({ 
    page, 
    onPageChange, 
    totalItems, 
    itemsPerPage 
}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const isLastPage = page >= totalPages - 1;

    return (
        <div className="flex items-center justify-center mt-6 bg-white mt-2 rounded-lg p-2">
            <Button
                onClick={() => onPageChange(page - 1)}
                disabled={page === 0}
                className="!min-w-0 !p-2"
                aria-label="Previous page"
            >
                <ChevronLeft className={page === 0 ? 'text-gray-300' : 'text-gray-600'} />
            </Button>
            <Typography className="mx-4 text-gray-700 font-medium">
                Page {page + 1} of {totalPages || 1}
                 {/* ({totalItems} items) */}
            </Typography>
            <Button
                onClick={() => onPageChange(page + 1)}
                disabled={isLastPage}
                className="!min-w-0 !p-2"
                aria-label="Next page"
            >
                <ChevronRight className={isLastPage ? 'text-gray-300' : 'text-gray-600'} />
            </Button>
        </div>
    );
};

export default PaginationControls;