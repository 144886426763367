import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createLead, updateLead } from '../../services/serverApi.lead';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const LeadModal = ({ open, onClose, setLeads, lead }) => {
  const { t } = useTranslation('common');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
    phone_number: Yup.string().required(t('Phone number is required')),
    properties_number: Yup.number().required(t('Number of properties is required')).min(0, t('Must be at least 0')),
    country: Yup.string().required(t('Country is required')),
    type: Yup.string().required(t('Type is required'))
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      let response;
      if (lead) {
        response = await updateLead(lead.id, values);
      } else {
        response = await createLead(values);
      }

      if (response && response.success) {
        setLeads(prevLeads => {
          if (!prevLeads) return [response.data];
          if (lead) {
            return prevLeads.map(l => l.id === lead.id ? response.data : l);
          } else {
            return [...prevLeads, response.data];
          }
        });
        setSubmitting(false);
        onClose();
        toast.success(lead ? t('Lead updated successfully') : t('Lead created successfully'));
      } else {
        throw new Error(t('Unexpected response structure'));
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || t('An error occurred while processing your request.'));
      setSubmitting(false);
      toast.error(lead ? t('Error updating lead') : t('Error creating lead'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{lead ? t('Update Lead') : t('Create New Lead')}</DialogTitle>
      <DialogContent>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Formik
          initialValues={{
            name: lead ? lead.name : '',
            email: lead ? lead.email : '',
            phone_number: lead ? lead.phone_number : '',
            properties_number: lead ? lead.properties_number : 0,
            country: lead ? lead.country : '',
            type: lead ? lead.type : 'demo'
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form className="mt-2">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label={t('Name')}
                      fullWidth
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  )}
                </Field>

                <Field name="email">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label={t('Email')}
                      fullWidth
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  )}
                </Field>

                <Field name="phone_number">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label={t('Phone Number')}
                      fullWidth
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  )}
                </Field>

                <Field name="properties_number">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label={t('Number of Properties')}
                      type="number"
                      fullWidth
                      error={touched.properties_number && Boolean(errors.properties_number)}
                      helperText={touched.properties_number && errors.properties_number}
                    />
                  )}
                </Field>

                <Field name="country">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label={t('Country')}
                      fullWidth
                      error={touched.country && Boolean(errors.country)}
                      helperText={touched.country && errors.country}
                    />
                  )}
                </Field>

                <FormControl fullWidth>
                  <InputLabel id="type-label">{t('Type')}</InputLabel>
                  <Select
                    labelId="type-label"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    label={t('Type')}
                  >
                    <MenuItem value="demo">{t('Demo')}</MenuItem>
                    <MenuItem value="pricing">{t('Pricing')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <DialogActions>
                <Button onClick={onClose} className="!bg-[#df5454] text-white">
                  {t('Cancel')}
                </Button>
                <Button type="submit" color="primary" disabled={isLoading} className="!bg-medium-aquamarine text-white">
                  {lead ? t('Update') : t('Create')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default LeadModal;