import React, { useState, useEffect, useMemo } from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ButtonTab from './ButtonTab';
import FormContainer from './forms/FormContainer';
import {
  createListing,
  getOneListing,
  UpdateListing,
  getRoomTypes,
  getPropertyTypes,
  getBedTypes,
} from '../services/serverApi.listing';
import { getConciergeType } from '../../tasks/services/serverApi.task';
import { getCurrencies } from '../../setting/services/serverApi.adminConfig';
import validationSchema from './forms/validator/validationSchema';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { tabOrder, tabIcons } from './forms/helper/TabOrder';
import { getTabsWithErrors } from './forms/helper/errorField/tabFieldMappings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Checkbox,
  Modal,
  FormControlLabel,
  Typography,
  Chip,
} from '@mui/material';
import { cleanRoomTypesData } from './forms/helper/cleanData/cleanRoomTypesData';
import { PropertyTypeSelector } from './forms/helper/PropertyTypeSelector';

const NewListing = () => {
  const [tab, setTab] = useState(tabOrder[0]);
  const { listingId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStaging, setIsStaging] = useState(() => {
    const storedValue = localStorage.getItem('isStaging');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  });

  const [openModal, setOpenModal] = useState(false);
  const [pendingStaging, setPendingStaging] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [conciergeType, setConciergeType] = useState([]);
  const [error, setError] = useState(null);
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  console.log('staging', staging);

  const [types, setRoomTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [bedTypes, setBedTypes] = useState([]);

  const formik = useFormik({
    initialValues: {
      // Basic Info
      name: '',
      // personCapacity: '',
      propertyType: '',
      propertyTypeId: '',
      // roomType: '',
      // bedroomsNumber: '',
      // bedsNumber: '',
      // bathroomsNumber: '',
      // bathroomType: '',
      description: '',
      atSojori: true,
      active: false,
      onlineCheckIn: false,
      displayInHomeScreen: false,
      sendTochannex: true,
      staging: isStaging,
      wifiUsername: '',
      wifiPassword: '',
      propertyUnit: 'Multi',
      currencyCode: 'MAD',
      useDynamicPrice: false,






      //ownerid
      ownerId: null,
      owner: null,

      // AccessInfo
      // access: [{
      //   type: '',
      //   enable: true,
      //   adresse: '',
      //   detail: '',
      //   gpsPosition: { lat: '', lng: '' },
      //   imageUrl: '',
      //   planAccess: '',
      //   code: '',
      // }],
      // appartementAccessCode: '',
      // descriptionAccess: '',
      // preporteyInformation: {},

      // task
      clean: false,
      checkin: false,
      checkout: false,
      syndic: false,
      assit: false,
      taskVerification: false,
      verificationItems: [],
      verificationItemsCheckin: [],
      verificationItemsCheckout: [],

      // AccessDetails
      // accessDetails: {
      //   general: {
      //     type: "",
      //     instructions: [],
      //     enable: false
      //   },
      //   codeAccess: {
      //     type: "",
      //     name: "",
      //     enable: false,
      //     access_code: {
      //       name: "",
      //       code: ""
      //     },
      //     instructions: [],
      //     use_access_code: false,
      //     use_instructions: false
      //   },
      //   parking: {
      //     type: "parking",
      //     enable: false,
      //     gps_access: {
      //       name: "",
      //       url: "",
      //       useUrl: false,
      //       gpsPosition: { lat: '', lng: '' },
      //     },
      //     access_plan: {
      //       name: "",
      //       url: "",
      //       useUrl: false,
      //     },
      //     access_code: {
      //       name: "",
      //       code: ""
      //     },
      //     instructions: [],
      //     use_gps_access: false,
      //     use_access_plan: false,
      //     use_access_code: false,
      //     use_instructions: false
      //   },
      //   building: {
      //     type: "building",
      //     enable: false,
      //     gps_access: {
      //       name: "",
      //       url: "",
      //       useUrl: false,
      //       gpsPosition: { lat: '', lng: '' },
      //     },
      //     access_plan: {
      //       name: "",
      //       url: "",
      //       useUrl: false,
      //     },
      //     access_code: {
      //       name: "",
      //       code: ""
      //     },
      //     instructions: [],
      //     use_gps_access: false,
      //     use_access_plan: false,
      //     use_access_code: false,
      //     use_instructions: false
      //   },
      //   apartment: {
      //     type: "apartment",
      //     enable: false,
      //     gps_access: {
      //       name: "",
      //       url: "",
      //       useUrl: false,
      //       gpsPosition: { lat: '', lng: '' },
      //     },
      //     access_plan: {
      //       name: "",
      //       url: "",
      //       useUrl: false,
      //     },
      //     access_code: {
      //       name: "",
      //       code: ""
      //     },
      //     instructions: [],
      //     use_gps_access: false,
      //     use_access_plan: false,
      //     use_access_code: false,
      //     use_instructions: false
      //   }
      // },
      accessDetails: [],

      // msgTab
      messageCheckin: [],
      messageCheckout: [],

      // Additional Info
      checkInTimeStart: 0,
      checkOutTime: 0,
      minNights: '',
      maxNights: '',
      syndicName: '',
      syndicWhatsappPhone: '',

      //RoomTypes
      roomTypes: [
        {
          roomTypeConfigId: '',
          roomTypeName: 'Standard Room',
          basePrice: '',
          ratePlanIds: [],
          amenitiesIds: [],
          roomTypeImages: [],
          bedTypes: [],
          useAddress: true,
          active: true,
          personCapacity: '',
          bedroomsNumber: '',
          bedsNumber: '',
          bathroomsNumber: '',
          roomNumber: '',
          startCode: '',
          ranking: '',
          surface: '',
          rooms: [
            {
              roomNumber: '',
              roomName: '',
              roomCode: '',
              address: '',
              enabled: true,
            },
          ],
        },
      ],

      // Address
      city: '',
      cityId: '',
      country: '',
      address: '',
      lat: 0,
      lng: 0,
      zipcode: '',
      timeZoneName: 'Africa/Casablanca',
      zone: '',
      zoneDescription: {},

      // BedType
      // listingBedTypes: [],

      // Media
      listingImages: [],

      // Amenities
      // listingAmenities: [],
      listingAmenitiesIds: [],

      // PriceAndFees
      // price: 0,

      // MinutListing
      listingMinutId: '',

      // channel manager
      channelManager: '',

      // StandardSojori
      // listingStandardSojori: [],
      listingStandardSojoriIds: [],

      // Cleaning
      cleanNumber: 0,
      TS_CLEAN: [
        { start: 8, end: 10, type: 'Normal', price: 0, default: false },
        { start: 10, end: 12, type: 'Normal', price: 0, default: false },
        { start: 12, end: 14, type: 'Normal', price: 0, default: false },
        { start: 14, end: 16, type: 'Normal', price: 0, default: false },
        { start: 16, end: 18, type: 'Normal', price: 0, default: false },
      ],
      TS_CHECKOUT: [
        { start: 8, end: 10, type: 'Normal', price: 0, default: false },
        { start: 11, end: 13, type: 'Normal', price: 0, default: false },
        { start: 14, end: 16, type: 'Normal', price: 0, default: false },
        { start: 15, end: 18, type: 'Normal', price: 0, default: false },
      ],
      TS_CHECKIN: [
        { start: 8, end: 10, type: 'Normal', price: 0, default: false },
        { start: 11, end: 13, type: 'Normal', price: 0, default: false },
        { start: 14, end: 16, type: 'Normal', price: 0, default: false },
        { start: 15, end: 18, type: 'Normal', price: 0, default: false },
      ],
      cleanPrice: 0,
      // RECEPT: "Auto",
      cleaningType: 'frequence',
      minDaysForCleaning: 0,
      frequency: [],
      tasks: [
        {
          taskName: 'MidClean',
          taskCreatedAutomatically: null,
          taskAssignedAutomatically: false,
          duration: '',
          daysBetweenCheckInOut: null,
          daysBeforeAssignment: '',
          notificationHours: '',
        },
        {
          taskName: 'Clean',
          taskCreatedAutomatically: false,
          taskAssignedAutomatically: false,
          duration: '',
          daysBetweenCheckInOut: '',
          daysBeforeAssignment: '',
          notificationHours: '',
        },
        {
          taskName: 'Checkin',
          taskCreatedAutomatically: false,
          taskAssignedAutomatically: false,
          duration: '',
          daysBetweenCheckInOut: null,
          daysBeforeAssignment: '',
          notificationHours: '',
        },
        {
          taskName: 'Checkout',
          taskCreatedAutomatically: false,
          taskAssignedAutomatically: false,
          duration: '',
          daysBetweenCheckInOut: null,
          daysBeforeAssignment: '',
          notificationHours: '',
        },
        {
          taskName: 'Assist',
          taskCreatedAutomatically: false,
          taskAssignedAutomatically: false,
          duration: '',
          daysBetweenCheckInOut: '',
          daysBeforeAssignment: '',
          notificationHours: '',
        },
        {
          taskName: 'Syndic',
          taskCreatedAutomatically: null,
          taskAssignedAutomatically: null,
          duration: null,
          daysBetweenCheckInOut: null,
          daysBeforeAssignment: null,
          notificationHours: '',
        },
      ],
      syndics: [],

      //More info
      transport: { name: {}, descriptions: [] },
      center: { name: {}, descriptions: [] },
      parking: { name: {}, descriptions: [] },
      policy: { name: {}, descriptions: [] },
      accessibility: { name: {}, descriptions: [] },
      cancellation: { name: {}, descriptions: [] },
      note: { name: {}, descriptions: [] },
      houseRule: { name: {}, descriptions: [] },
      rating: 0,
      review: 0,
      services: [],
    },

    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setIsSubmitting(true);
      try {
        const listingData = {
          ...values,
          roomTypes: cleanRoomTypesData(values.roomTypes),
        };

        if (listingId) {
          await UpdateListing(listingId, listingData);
          toast.success('Listing updated successfully');
        } else {
          await createListing(listingData);
          toast.success('Listing created successfully');
          resetForm();
        }
      } catch (error) {
        console.error('Error updating/creating Listing:', error);
        toast.error(
          error.response?.data?.message || 'Error fetching listing data',
        );
      }
      setSubmitting(false);
      setIsSubmitting(false);
    },
  });
  console.log('formik', formik);

  useEffect(() => {
    if (formik.values.propertyUnit === 'Single') {
      const newRoomTypes = formik.values.roomTypes.map((roomType) => ({
        ...roomType,
        amenitiesIds: formik.values.listingAmenitiesIds || [],
        roomTypeImages: formik.values.listingImages || [],
        ranking: roomType.ranking || 1,
        startCode: roomType.startCode || 1,
        rooms: roomType.rooms.map((room) => ({
          ...room,
          roomNumber: roomType.roomNumber || '',
          // roomName: roomType.roomTypeName || '',
        })),
      }));

      formik.setFieldValue('roomTypes', newRoomTypes);
    }
  }, [
    formik.values.propertyUnit,
    formik.values.listingAmenitiesIds,
    formik.values.listingImages,
  ]);
  useEffect(() => {
    if (!listingId) {
      formik.resetForm();
    }
  }, [listingId]);

  const fetchCurrencies = async () => {
    try {
      const response = await getCurrencies();
      setCurrencies(response.data);
    } catch (error) {
      console.error('Error fetching currencies:', error);
      setError('Failed to fetch currencies. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  const fetchConciergeTypes = async () => {
    try {
      const response = await getConciergeType(0, 0, false, '');
      // console.log('Cons Type', response.data);

      setConciergeType(response?.data?.data);
    } catch (error) {
      console.error('Error fetching currencies:', error);
      setError('Failed to fetch currencies. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrencies();
    fetchRoomTypes();
    fetchPropertyTypes();
    fetchBedTypes();
    fetchConciergeTypes();
  }, []);

  const fetchRoomTypes = async () => {
    setIsLoading(true);
    try {
      const response = await getRoomTypes();
      if (response.data && Array.isArray(response.data.data)) {
        setRoomTypes(response.data.data);
      } else {
        setRoomTypes([]);
      }
    } catch (error) {
      console.error('Error fetching room types:', error);
      setRoomTypes([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBedTypes = async () => {
    setIsLoading(true);
    try {
      const response = await getBedTypes();
      setBedTypes(response.data.data);
    } catch (error) {
      console.error('Error fetching bed types:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPropertyTypes = async () => {
    try {
      const response = await getPropertyTypes();
      setPropertyTypes(
        response.data.data?.map((type) => ({
          value: type.name,
          label: type.name,
          _id: type._id,
          name: type.name,
          manageRoomType: type.manageRoomType,
        })),
      );
    } catch (error) {
      console.error('Error fetching property types:', error);
    }
  };

  const handleStagingChange = () => {
    setPendingStaging(!isStaging);
    setOpenModal(true);
  };
  const handleCancelStaging = () => {
    setOpenModal(false);
    setPendingStaging(false);
  };

  const handleConfirmStaging = () => {
    setIsStaging(pendingStaging);
    formik.setFieldValue('staging', pendingStaging);
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchListingData = async () => {
      if (listingId) {
        try {
          const listing = await getOneListing(listingId, staging);
          formik.setValues({
            ...formik.values,
            ...listing,
          });
        } catch (error) {
          console.error('Error fetching listing data:', error);
          toast.error('Error fetching listing data');
        }
      }
    };

    fetchListingData();
  }, [listingId, staging]);
  console.log(formik);
  const tabsWithErrors = useMemo(
    () => getTabsWithErrors(formik.errors, formik.touched),
    [formik.errors, formik.touched],
  );

  const currentIndex = tabOrder.indexOf(tab);
  const isFirstTab = currentIndex === 0;
  const isLastTab = currentIndex === tabOrder.length - 1;

  const handleNext = () => !isLastTab && setTab(tabOrder[currentIndex + 1]);
  const handlePrevious = () =>
    !isFirstTab && setTab(tabOrder[currentIndex - 1]);

  const formatTabName = (tabName) =>
    tabName.charAt(0).toUpperCase() +
    tabName
      .slice(1)
      .replace(/([A-Z])/g, ' $1')
      .trim();

  return (
    <DashboardLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <ToastContainer />
          <div
            id="fixed_control_bar"
            className="fixed top-0 w-full lg:w-[calc(100vw-274px)] z-[1000] lg:left-[274px] bg-white border-b-1 border-gray-300"
          >
            <div className="flex justify-between items-center p-4 h-[63px] bg-white">
              {/* Back button */}
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="flex items-center text-[#00b4b4] cursor-pointer"
              >
                <span className="ml-2 text-[14px]">
                  <i className="pi pi-angle-left !text-[15px] font-bold"></i>
                  Listings
                </span>
              </button>

              {/* Center content - Test Mode and Listing Name */}
              <div className="flex items-center gap-4">
                {/* {listingId ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isStaging}
                        onChange={handleStagingChange}
                        name="staging"
                        color="primary"
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 23 },
                          color: '#6fd1bd',
                          '&.Mui-checked': {
                            color: '#6fd1bd',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{
                        fontWeight: 500,
                        color: '#4a4a4a',
                        fontSize: { xs: '12px', sm: '14px' }
                      }}>
                        Test Mode
                      </Typography>
                    }
                  />
                ) : isStaging && (
                  <Chip variant="filled" label="Test Mode" color="error" size="small" />
                )} */}

                <span className="text-center text-sm sm:text-base">
                  {listingId
                    ? `Update listing ${
                        formik.values.name || 'Unnamed Listing'
                      }`
                    : 'Create new listing'}
                </span>
              </div>

              {/* Action buttons */}
              <div className="flex items-center gap-2">
                <button
                  type="submit"
                  className="px-2 py-1 bg-[#00b4b4] !rounded-sm hover:opacity-90 transition-opacity"
                  disabled={isSubmitting}
                >
                  <span className="ladda-label flex items-center text-[13px] text-white p-[3px]">
                    {isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <>
                        <i className="pi pi-save px-1"></i>Save
                      </>
                    )}
                  </span>
                </button>
                {!isFirstTab && (
                  <button
                    type="button"
                    onClick={handlePrevious}
                    className="px-2 py-1 bg-[#dcf6f6] !rounded-sm hover:bg-[#c5efef] transition-colors"
                  >
                    <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px]">
                      Previous
                    </span>
                  </button>
                )}
                {!isLastTab && (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="px-2 py-1 bg-[#dcf6f6] !rounded-sm hover:bg-[#c5efef] transition-colors"
                  >
                    <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px]">
                      Next
                    </span>
                  </button>
                )}
                <button
                  type="button"
                  className="px-2 py-1 hover:bg-[#f5f5f5] rounded-sm transition-colors"
                >
                  <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[2px]">
                    <CalendarMonthIcon />
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="h-[64px]"></div>
        </Box>

        <Box
          sx={{ width: '100%', typography: 'body1', height: '100%' }}
          className="relative"
        >
          <Box className="w-full h-full mt-6">
            <div className="fixed top-[64px] w-full lg:w-[calc(100vw-274px)] z-[999] lg:left-[274px] bg-white px-4 py-3">
              <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
                {tabOrder
                  .filter((tabName) => {
                    if (
                      formik.values.propertyUnit !== 'Multi' &&
                      (tabName === 'roomtype' || tabName === 'rooms')
                    ) {
                      return false;
                    }
                    if (
                      formik.values.propertyUnit !== 'Single' &&
                      tabName === 'configuration'
                    ) {
                      return false;
                    }
                    return true;
                  })
                  .map((tabName) => (
                    <ButtonTab
                      key={tabName}
                      name={formatTabName(tabName)}
                      icon={tabIcons[tabName]}
                      isActive={tab === tabName}
                      onClick={() => setTab(tabName)}
                      hasError={tabsWithErrors.includes(tabName)}
                    />
                  ))}
              </div>
            </div>
            <div className="h-[120px]"></div>

            <FormContainer
              tab={tab}
              formik={formik}
              listingId={listingId}
              types={types}
              propertyTypes={propertyTypes}
              bedTypes={bedTypes}
              currencies={currencies}
              conciergeType={conciergeType}
            />
          </Box>
        </Box>
      </form>
      <PropertyTypeSelector formik={formik} listingId={listingId} />
      <Modal open={openModal} onClose={handleCancelStaging}>
        <Box className="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24] bg-[#f8fafb]">
          <Box className="w-full mb-11 p-4">
            <Typography
              className="!text-[14px] !text-center"
              id="modal-modal-description"
            >
              {pendingStaging
                ? 'Change to Test Mode will affect test data only. Are you sure you want to proceed?'
                : 'Change to Production Mode will affect live data. Please confirm this action.'}
            </Typography>
          </Box>
          <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">
            <Box className="flex gap-2">
              <button
                type="button"
                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                onClick={handleCancelStaging}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                onClick={handleConfirmStaging}
              >
                Confirm
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default NewListing;
