import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageUpload from 'components/CustomUpload/UploadV2';
import { getCountries } from 'features/listing/services/serverApi.listing';
import defaultAvatar from 'assets/images/placeholder.jpg';
import { useTranslation } from 'react-i18next';
import { ta } from 'date-fns/locale';

const GuestForm = ({
  id,
  initialData,
  onSubmit,
  isUpdating,
  showUpdateButton = true,
}) => {
  const { t } = useTranslation('common');
  const [documentType, setDocumentType] = useState(
    initialData.documentType || '',
  );
  const [frontImageUrl, setFrontImageUrl] = useState(
    initialData.document_front_download || '',
  );
  const [backImageUrl, setBackImageUrl] = useState(
    initialData.document_back_download || '',
  );
  const [showFrontImage, setShowFrontImage] = useState(false);
  const [showBackImage, setShowBackImage] = useState(false);
  const [showPassportImage, setShowPassportImage] = useState(false);
  const [countries, setCountries] = useState([]);
  const [imageKey, setImageKey] = useState(Date.now());

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (documentType === 'passport') {
      setBackImageUrl('');
      formik.setFieldValue('document_back_download', '');
    }
  }, [documentType]);

  const fetchData = async () => {
    try {
      const countriesData = await getCountries();
      setCountries(countriesData.data);
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };

  const handleImageUpload = (type, url) => {
    if (documentType === 'passport') {
      formik.setFieldValue('document_front_download', url);
      formik.setFieldValue('document_back_download', '');
      setFrontImageUrl(url);
    } else if (documentType === 'id_card') {
      if (type === 'front') {
        formik.setFieldValue('document_front_download', url);
        setFrontImageUrl(url);
      } else if (type === 'back') {
        formik.setFieldValue('document_back_download', url);
        setBackImageUrl(url);
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required'),
    day: Yup.number()
      .min(1, 'Day must be at least 1')
      .max(31, 'Day cannot be more than 31')
      .required('Day is required')
      .integer('Day must be a whole number'),
    month: Yup.number()
      .min(1, 'Month must be at least 1')
      .max(12, 'Month cannot be more than 12')
      .required('Month is required')
      .integer('Month must be a whole number'),
    year: Yup.number()
      .min(1900, 'Year must be at least 1900')
      .max(
        new Date().getFullYear(),
        `Year cannot be more than ${new Date().getFullYear()}`,
      )
      .required('Year is required')
      .integer('Year must be a whole number'),
    gender: Yup.string().required('Gender is required'),
    nationality: Yup.string().required('Nationality is required'),
    documentType: Yup.string().required('Document Type is required'),
    documentNumber: Yup.string().required('Document Number is required'),
    documentCountry: Yup.string().required('Document Country is required'),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      const updatedValues = {
        ...values,
        documentType: documentType,
      };

      if (documentType === 'passport') {
        updatedValues.document_front_download = values.document_front_download;
        updatedValues.document_back_download = '';
      } else if (documentType === 'id_card') {
        updatedValues.passport = '';
      }

      console.log('Submitting form with values:', updatedValues);
      onSubmit(updatedValues);
    },
  });

  console.log(formik.values);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      id={id}
      className="bg-white"
    >
      <div className="grid grid-cols-4 gap-4 mb-4">
        <TextField
          label={`${t('Name')} *`}
          variant="outlined"
          name="name"
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <TextField
          label={`${t('Last Name')} *`}
          variant="outlined"
          name="surname"
          onBlur={formik.handleBlur}
          error={formik.touched.surname && Boolean(formik.errors.surname)}
          helperText={formik.touched.surname && formik.errors.surname}
          value={formik.values.surname}
          onChange={formik.handleChange}
        />
        <div className="flex gap-2">
          <TextField
            label={`${t('day')} *`}
            variant="outlined"
            name="day"
            type="number"
            value={formik.values.day}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.day && Boolean(formik.errors.day)}
            helperText={formik.touched.day && formik.errors.day}
            className="w-1/3"
          />
          <FormControl
            className="w-1/3"
            required
            error={formik.touched.month && Boolean(formik.errors.month)}
          >
            <InputLabel id="month-select-label">{t('month')}</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              label="Month"
              name="month"
              value={formik.values.month}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {[...Array(12)].map((_, i) => {
                const monthNumber = (i + 1).toString().padStart(2, '0');
                return (
                  <MenuItem key={i} value={monthNumber}>
                    {monthNumber}
                  </MenuItem>
                );
              })}
            </Select>
            {formik.touched.month && formik.errors.month && (
              <FormHelperText>{formik.errors.month}</FormHelperText>
            )}
          </FormControl>
          <TextField
            label={`${t('Year')} *`}
            variant="outlined"
            name="year"
            value={formik.values.year}
            onBlur={formik.handleBlur}
            error={formik.touched.year && Boolean(formik.errors.year)}
            helperText={formik.touched.year && formik.errors.year}
            onChange={formik.handleChange}
            className="w-1/3"
          />
        </div>
        <FormControl
          fullWidth
          required
          error={formik.touched.gender && Boolean(formik.errors.gender)}
        >
          <InputLabel id="gender-select-label">{t('Gender')}</InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender-select"
            label="Gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <MenuItem value="male">{t('Male')}</MenuItem>
            <MenuItem value="female">{t('Female')}</MenuItem>
          </Select>
          {formik.touched.gender && formik.errors.gender && (
            <FormHelperText>{formik.errors.gender}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          required
          error={
            formik.touched.nationality && Boolean(formik.errors.nationality)
          }
        >
          <InputLabel id="nationality-select-label">
            {t('Nationality')}
          </InputLabel>
          <Select
            labelId="nationality-select-label"
            id="nationality-select"
            label="Nationality"
            name="nationality"
            value={formik.values.nationality}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {countries.length > 0 ? (
              countries.map((country) => (
                <MenuItem key={country._id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">{t('No countries available')}</MenuItem>
            )}
          </Select>
          {formik.touched.nationality && formik.errors.nationality && (
            <FormHelperText>{formik.errors.nationality}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          required
          error={
            formik.touched.documentType && Boolean(formik.errors.documentType)
          }
        >
          <InputLabel id="document-type-select-label">
            {t('Document Type')}
          </InputLabel>
          <Select
            labelId="document-type-select-label"
            id="document-type-select"
            label="Document Type"
            name="documentType"
            value={documentType}
            onChange={(event) => {
              setDocumentType(event.target.value);
              formik.handleChange(event);
            }}
            onBlur={formik.handleBlur}
          >
            <MenuItem value="id_card">{t('National ID')}</MenuItem>
            <MenuItem value="passport">{t('Passport')}</MenuItem>
          </Select>
          {formik.touched.documentType && formik.errors.documentType && (
            <FormHelperText>{formik.errors.documentType}</FormHelperText>
          )}
        </FormControl>
        <TextField
          label={`${t('Document Number')} *`}
          variant="outlined"
          name="documentNumber"
          onBlur={formik.handleBlur}
          error={
            formik.touched.documentNumber &&
            Boolean(formik.errors.documentNumber)
          }
          helperText={
            formik.touched.documentNumber && formik.errors.documentNumber
          }
          value={formik.values.documentNumber}
          onChange={formik.handleChange}
        />
        <FormControl
          fullWidth
          required
          error={
            formik.touched.documentCountry &&
            Boolean(formik.errors.documentCountry)
          }
        >
          <InputLabel id="document-country-select-label">
            {t('Document Country')}
          </InputLabel>
          <Select
            labelId="document-country-select-label"
            id="document-country-select"
            label="Document Country"
            name="documentCountry"
            value={formik.values.documentCountry}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {countries.length > 0 ? (
              countries.map((country) => (
                <MenuItem key={country._id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">{t('No countries available')}</MenuItem>
            )}
          </Select>
          {formik.touched.documentCountry && formik.errors.documentCountry && (
            <FormHelperText>{formik.errors.documentCountry}</FormHelperText>
          )}
        </FormControl>
        <TextField
          label={`${t('Email')} ${t('Optional')}`}
          variant="outlined"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <TextField
          label={`${t('phone')} ${t('Optional')}`}
          variant="outlined"
          name="phone"
          placeholder="Enter your phone number"
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
      </div>
      {documentType === 'id_card' && (
        <>
          <Typography
            variant="subtitle1"
            className="mb-2 !text-lg !font-normal"
          >
            {t('National ID Front')}
            <IconButton onClick={() => setShowFrontImage(!showFrontImage)}>
              {showFrontImage ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Typography>

          {showFrontImage && (
            <>
              <div className="flex flex-col items-center">
                <img
                  src={
                    formik.values.document_front_download ||
                    frontImageUrl ||
                    defaultAvatar
                  }
                  alt="Uploaded"
                  className="w-80 h-40"
                />
                <ImageUpload
                  fieldName={'document_front_download'}
                  setFieldValue={formik.setFieldValue}
                  key={`front-${imageKey}`}
                  folder="documents"
                  avatar={false}
                  onSubmit={(url) => handleImageUpload('front', url)}
                />
              </div>
            </>
          )}

          <Typography
            variant="subtitle1"
            className="mb-2 !text-lg !font-normal"
          >
            {ta('National ID Back')}
            <IconButton onClick={() => setShowBackImage(!showBackImage)}>
              {showBackImage ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Typography>
          {showBackImage && (
            <>
              <div className="flex flex-col items-center">
                <img
                  src={
                    formik.values.document_back_download ||
                    backImageUrl ||
                    defaultAvatar
                  }
                  alt="Uploaded"
                  className="w-80 h-40"
                />
                <ImageUpload
                  fieldName={'document_back_download'}
                  setFieldValue={formik.setFieldValue}
                  key={`front-${imageKey}`}
                  folder="documents"
                  onSubmit={(url) => handleImageUpload('back', url)}
                />
              </div>
            </>
          )}
        </>
      )}
      {documentType === 'passport' && (
        <>
          <Typography
            variant="subtitle1"
            className="mb-2 !text-lg !font-normal"
          >
            {t('Passport')}
            <IconButton
              onClick={() => setShowPassportImage(!showPassportImage)}
            >
              {showPassportImage ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Typography>
          {showPassportImage && (
            <>
              <div className="flex flex-col items-center">
                <img
                  src={
                    formik.values.document_front_download ||
                    frontImageUrl ||
                    defaultAvatar
                  }
                  alt="Uploaded"
                  className="w-80 h-40"
                />
                <ImageUpload
                  fieldName={'document_front_download'}
                  setFieldValue={formik.setFieldValue}
                  key={`passport-${imageKey}`}
                  folder="documents"
                  onSubmit={(url) => handleImageUpload('passport', url)}
                />
              </div>
            </>
          )}
        </>
      )}

      {showUpdateButton && (
        <div className="text-center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isUpdating}
            className="text-white !bg-medium-aquamarine mb-4"
          >
            {isUpdating ? `${t('Updating')} ...` : `${t('update')}`}
          </Button>
        </div>
      )}
    </Box>
  );
};

export default GuestForm;
