import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Checkbox,
    FormControlLabel,
    Box,
    Chip,
    OutlinedInput,
    Stack,
    Divider,
    Avatar
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { X, User } from 'lucide-react';
import { updateWorker, getOwners } from '../services/serverApi.task';
import { hasAdminAccess } from 'utils/rbac.utils';
import RoleBasedRenderer from 'components/wrappers/RoleBasedRenderer';

const ALL_MODULES = {
    PMS: { label: 'PMS', price: 100 },
    WhGuest: { label: 'WhatsApp Guest', price: 100 },
    WhStaff: { label: 'WhatsApp Staff', price: 100 },
    WhPMS: { label: 'WhatsApp PMS', price: 100 },
    RMS: { label: 'Dynamic Pricing', price: 100 },
    MessageAndReview: { label: 'Message & Review', price: 100 }
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    whatsapp: Yup.string(),
    ownerId: Yup.string().required('Owner is required'),
    banned: Yup.boolean(),
    deleted: Yup.boolean(),
    selectedModules: Yup.array()
});

const UpdateWorkerDialog = ({ open, onClose, worker, onWorkerUpdated }) => {
    const [owners, setOwners] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableModules, setAvailableModules] = useState({});
    const { user } = useSelector((state) => state.auth);
    const isAdmin = hasAdminAccess(user?.role);
    
    const initialModules = worker?.permissions?.map(perm => perm.module) || [];

    useEffect(() => {
        if (open) {
            if (isAdmin) {
                fetchOwners();
                setAvailableModules(ALL_MODULES);
            } else {
                const ownerModules = {};
                
                if (user?.subscriptionModules && user.subscriptionModules.length > 0) {
                    user.subscriptionModules.forEach(mod => {
                        ownerModules[mod.module] = {
                            label: mod.label || mod.module,
                            price: mod.price || 100
                        };
                    });
                }
                setAvailableModules(ownerModules);
            }
        }
    }, [open, isAdmin, user]);

    const fetchOwners = async () => {
        setIsLoading(true);
        try {
            const response = await getOwners({ limit: 100 });
            if (response && response.data) {
                setOwners(response.data);
            }
        } catch (error) {
            console.error('Error fetching owners:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const permissions = values.selectedModules.map(moduleKey => ({
                module: moduleKey,
                actions: ['get', 'create', 'update', 'delete']
            }));

            const response = await updateWorker(worker._id, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                whatsapp: values.whatsapp,
                ownerId: values.ownerId,
                banned: values.banned,
                deleted: values.deleted,
                permissions: permissions
            });

            if (response.data && response.data.account) {
                onWorkerUpdated(response.data.account);
                onClose();
              }
        } catch (error) {
            console.error('Error updating worker:', error);
            setErrors({ submit: error.response?.data?.message || 'Failed to update worker' });
        } finally {
            setSubmitting(false);
        }
    };

    if (!worker) return null;

    const initialOwnerId = isAdmin ? (worker.owner?._id || '') : user?._id || '';

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="bg-medium-aquamarine flex justify-between items-center">
                <Typography variant="h6" className="text-white flex items-center gap-2">
                    <User className="w-5 h-5" />
                    Update Worker
                </Typography>
                <IconButton onClick={onClose} className="text-white">
                    <X className="w-5 h-5" />
                </IconButton>
            </DialogTitle>

            <Formik
                initialValues={{
                    firstName: worker.firstName || '',
                    lastName: worker.lastName || '',
                    email: worker.email || '',
                    phone: worker.phone || '',
                    whatsapp: worker.whatsapp || '',
                    ownerId: initialOwnerId,
                    banned: worker.banned || false,
                    deleted: worker.deleted || false,
                    selectedModules: initialModules
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
                    <Form>
                        <DialogContent className="!space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    className="col-span-1"
                                />

                                <TextField
                                    fullWidth
                                    name="lastName"
                                    label="Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    className="col-span-1"
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={values.email}
                                    disabled
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    className="col-span-1"
                                />

                                <TextField
                                    fullWidth
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                    className="col-span-1"
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="whatsapp"
                                    label="Whatsapp"
                                    value={values.whatsapp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.whatsapp && Boolean(errors.whatsapp)}
                                    helperText={touched.whatsapp && errors.whatsapp}
                                    className="col-span-1"
                                />
                                
                                <RoleBasedRenderer adminOnly>
                                    <FormControl 
                                        fullWidth 
                                        error={touched.ownerId && Boolean(errors.ownerId)}
                                        className="col-span-1"
                                    >
                                        <InputLabel id="owner-select-label">Owner</InputLabel>
                                        <Select
                                            labelId="owner-select-label"
                                            id="owner-select"
                                            name="ownerId"
                                            value={values.ownerId}
                                            label="Owner"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isLoading}
                                        >
                                            {owners.map((owner) => (
                                                <MenuItem key={owner._id} value={owner._id}>
                                                    {owner.firstName} {owner.lastName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.ownerId && errors.ownerId && (
                                            <FormHelperText>{errors.ownerId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </RoleBasedRenderer>
                                
                                {!isAdmin && (
                                    <input
                                        type="hidden"
                                        name="ownerId"
                                        value={values.ownerId}
                                    />
                                )}
                            </div>

                            <Divider className="my-4" />

                            {/* Permissions section */}
                            <div>
                                <Typography variant="subtitle1" className="font-medium mb-2">
                                    Worker Permissions
                                </Typography>
                                {!isAdmin && Object.keys(availableModules).length === 0 && (
                                    <Typography variant="body2" className="text-gray-500 italic">
                                        You don t have any subscription modules to assign.
                                    </Typography>
                                )}
                                {Object.keys(availableModules).length > 0 && (
                                    <FormControl fullWidth>
                                        <InputLabel id="modules-select-label">Modules</InputLabel>
                                        <Select
                                            labelId="modules-select-label"
                                            id="modules-select"
                                            multiple
                                            value={values.selectedModules}
                                            onChange={(e) => setFieldValue('selectedModules', e.target.value)}
                                            input={<OutlinedInput label="Modules" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip 
                                                            key={value} 
                                                            label={availableModules[value]?.label || value} 
                                                            className="!bg-medium-aquamarine !text-white"
                                                            size="small"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {Object.entries(availableModules).map(([moduleKey, { label }]) => (
                                                <MenuItem key={moduleKey} value={moduleKey}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                
                                {/* Visual representation of permissions */}
                                {values.selectedModules.length > 0 && (
                                    <Box className="mt-4 p-3 bg-gray-50 rounded-md">
                                        <Typography variant="subtitle2" className="font-medium mb-2">
                                            Active Permissions
                                        </Typography>
                                        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: '8px !important' }}>
                                            {values.selectedModules.map((moduleKey) => (
                                                <Box
                                                    key={moduleKey}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        backgroundColor: 'white',
                                                        borderRadius: '8px',
                                                        py: 0.75,
                                                        px: 1.5,
                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                                                        border: '1px solid rgba(0, 180, 180, 0.2)'
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                            fontSize: '0.75rem',
                                                            bgcolor: 'rgba(0, 180, 180, 0.1)',
                                                            color: '#00b4b4',
                                                            fontWeight: 600
                                                        }}
                                                    >
                                                        {availableModules[moduleKey]?.label?.charAt(0) || moduleKey.charAt(0)}
                                                    </Avatar>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '0.75rem',
                                                            fontWeight: 500,
                                                            color: '#2d3748'
                                                        }}
                                                    >
                                                        {availableModules[moduleKey]?.label || moduleKey}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Stack>
                                    </Box>
                                )}
                                
                                {!isAdmin && (
                                    <Box className="mt-2">
                                        <Typography variant="caption" className="text-gray-500 italic">
                                            You can only assign permissions for modules you re subscribed to.
                                        </Typography>
                                    </Box>
                                )}
                            </div>

                            <Divider className="my-4" />

                                <Box className="flex flex-col space-y-2 mt-4">
                                    <Typography variant="subtitle2" className="font-semibold">
                                        Account Status
                                    </Typography>
                                    <div className="flex space-x-4">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.banned}
                                                    onChange={handleChange}
                                                    name="banned"
                                                    color="error"
                                                />
                                            }
                                            label="Banned"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.deleted}
                                                    onChange={handleChange}
                                                    name="deleted"
                                                    color="error"
                                                />
                                            }
                                            label="Deleted"
                                        />
                                    </div>
                                </Box>
                        </DialogContent>

                        <DialogActions className="p-4">
                            <Button onClick={onClose} variant="outlined" className="!text-gray-500 !border-gray-500">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                className="!bg-medium-aquamarine !text-white"
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default UpdateWorkerDialog;