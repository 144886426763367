import React from 'react';
import SellIcon from '@mui/icons-material/Sell';
import DiamondIcon from '@mui/icons-material/Diamond';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';

const ReservationActions = ({ onCreateReservation }) => {
  const { t } = useTranslation('common');
  return (
    <div className="flex items-center gap-2 sm:gap-4 lg:gap-6 flex-wrap">
      {/* <button className="hidden sm:flex bg-[#00b4b4] !rounded min-w-[5rem] sm:w-[7rem] items-center gap-1 justify-center p-[2px]">
      <span className="ladda-label text-[13px] sm:text-[15px] text-white !p-[3px] font-semibold whitespace-nowrap">Coupons</span>
      <SellIcon className="text-white !text-[18px] sm:!text-[20px]" />
    </button>
    <button className="hidden sm:flex bg-[#00b4b4] !rounded min-w-[8rem] sm:w-[13rem] items-center gap-1 justify-center p-[2px]">
      <span className="ladda-label text-[13px] sm:text-[15px] text-white !p-[3px] font-semibold whitespace-nowrap">Manage custom fields</span>
      <DiamondIcon className="text-white !text-[18px] sm:!text-[20px]" />
    </button> */}
      <button
        className="bg-[#00b4b4] !rounded min-w-[5rem] sm:w-[5rem] flex gap-1 items-center justify-center p-[1px]"
        onClick={onCreateReservation}
      >
        <span className="ladda-label text-[13px] sm:text-[15px] text-white !p-[3px] font-semibold">
          {t('Add')}
        </span>
        <AddCircleIcon className="text-white !text-[18px] sm:!text-[20px]" />
      </button>
    </div>
  );
};

export default ReservationActions;
