import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthApi from '../api/AuthApi';
import { getToken, getRefreshToken, setTokens, clearTokens } from '../../utils/auth.utils';

const initialState = {
  user: null,
  token: getToken(),
  refreshToken: getRefreshToken(),
  isAuthenticated: !!getToken(),
  loading: false,
  error: null
};

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const response = await AuthApi.login(credentials);
      setTokens(response.token, response.refreshToken);
      return response;
    } catch (error) {
      return rejectWithValue(error?.message || 'Login failed');
    }
  }
);

export const loginWorker = createAsyncThunk(
  'auth/loginWorker',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const response = await AuthApi.activateWorkerAccount(credentials);
      
      setTokens(response.token, response.refreshToken);
      
      return response;
    } catch (error) {
      // Extract the specific error message from the response data
      const errorMessage = error?.error || error?.message || 'Worker activation failed';
      console.error('Worker activation error:', error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async (_, { rejectWithValue }) => {
    try {
      console.log('Validating token...'); 
      const response = await AuthApi.validateToken();
      if (response.newToken) {
        setTokens(response.newToken, getRefreshToken());
      }
      return {
        newToken: response.newToken,
        user: response.user
      };
    } catch (error) {
      console.error('Token validation failed:', error);
      if (error?.forceLogout) {
        clearTokens();
      }
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      clearTokens();
      AuthApi.logout();
    },
    updateToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      setTokens(action.payload, state.refreshToken);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        state.token = payload.token;
        state.refreshToken = payload.refreshToken;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isAuthenticated = false;
      })
      // Add cases for loginWorker
      .addCase(loginWorker.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginWorker.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        state.token = payload.token;
        state.refreshToken = payload.refreshToken;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(loginWorker.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isAuthenticated = false;
      })
      .addCase(checkAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuth.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.newToken) {
          state.token = payload.newToken;
          state.isAuthenticated = true;
          // Update the token in localStorage
          setTokens(payload.newToken, state.refreshToken);
        }
        if (payload.user) {
          state.user = payload.user;
        }
      })
      .addCase(checkAuth.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.forceLogout) {
          state.user = null;
          state.token = null;
          state.refreshToken = null;
          state.isAuthenticated = false;
          clearTokens();
        }
      });
  }
});

export const { logout, updateToken } = authSlice.actions;
export default authSlice.reducer;