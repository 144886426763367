import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export const AUTH_CONFIG = {
  API_URL: `${MICROSERVICE_BASE_URL.SRV_USER}/auth`,
  TOKEN_KEY: 'sojori_token',
  REFRESH_TOKEN_KEY: 'sojori_refresh_token',
  LOGIN_REDIRECT: '/admin',
  LOGOUT_REDIRECT: '/login',
  INVITATION_PATH: '/invit',
  COOKIE_OPTIONS: {
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'Lax',
    expires: 365 * 10
  }
};