import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Coutries from "../components/countries";
import Cities from "../components/cities";
import CitiesMapping from "../components/CitiesMapping";
import BlogsMapping from "../components/BlogsMapping";
import Blogs from "../components/Blogs";
import Slides from "../components/slides";
import Languages from "../components/Languages";
// import Tags from "../components/Tags";



function AdminConfigPage() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} className="!bg-white py-0">
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabs" sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#00b4b4',
            },
            '& .MuiTab-root': {
              color: 'black',
              fontWeight: 600,
            },
            '& .Mui-selected': {
              color: '#00b4b4 !important',
            },
          }}>
            <Tab label="Countries" value="1" />
            <Tab label="Cities" value="2" />
            <Tab label="Cities Mapping" value="3" />
            <Tab label="Blogs" value="4" />
            <Tab label="blogs Mapping" value="5" />
            <Tab label="Slides" value="6" />
            <Tab label="Languages" value="7" />
            {/* <Tab label="Tags" value="8" /> */}
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ px: '0 !important' }}><Coutries/></TabPanel>
        <TabPanel value="2" sx={{ px: '0 !important' }}><Cities/></TabPanel>
        <TabPanel value="3" sx={{ px: '0 !important' }}><CitiesMapping/></TabPanel>
        <TabPanel value="4" sx={{ px: '0 !important' }}><Blogs/></TabPanel>
        <TabPanel value="5" sx={{ px: '0 !important' }}><BlogsMapping /></TabPanel>
        <TabPanel value="6" sx={{ px: '0 !important' }}><Slides /> </TabPanel>
        <TabPanel value="7" sx={{ px: '0 !important' }}><Languages /> </TabPanel>
        {/* <TabPanel value="8"><Tags /> </TabPanel> */}
        
      </TabContext>
    </Box>      
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminConfigPage;
