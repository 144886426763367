import axios from 'axios';
import { AUTH_CONFIG } from '../../config/auth.config';
import { MICROSERVICE_BASE_URL } from '../../config/backendServer.config';
import { getToken, getRefreshToken, setTokens, clearTokens } from '../../utils/auth.utils';
import { navigationRef } from '../../utils/navigation';

let isValidatingToken = false;
let validationPromise = null;

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
});

axiosInstance.interceptors.request.use(async (config) => {
  if (config.url.includes('/login') || config.url.includes('/valid-token-check')) {
    return config;
  }

  if (isValidatingToken && validationPromise) {
    await validationPromise;
  }

  const token = getToken();
  const refreshToken = getRefreshToken();
  
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (refreshToken) {
    config.headers['x-refresh-token'] = refreshToken;
  }
  
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data?.newToken) {
      const newToken = response.data.newToken;
      setTokens(newToken, getRefreshToken());
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.data?.newToken) {
      const newToken = error.response.data.newToken;
      setTokens(newToken, getRefreshToken());
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    }

    if (
      error.response?.data?.forceLogout ||
      error.response?.data?.error === "Session expired, please login again" ||
      error.response?.data?.error === "Invalid token"
    ) {
      clearTokens();
      navigationRef.current?.navigate(AUTH_CONFIG.LOGOUT_REDIRECT);
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await AuthApi.validateToken();
        if (response.newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${response.newToken}`;
          return axiosInstance(originalRequest);
        } else {
          clearTokens();
          navigationRef.current?.navigate(AUTH_CONFIG.LOGOUT_REDIRECT);
        }
      } catch (refreshError) {
        clearTokens();
        navigationRef.current?.navigate(AUTH_CONFIG.LOGOUT_REDIRECT);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const AuthApi = {
  async login(credentials) {
    try {
      const response = await axios.post(`${AUTH_CONFIG.API_URL}/login`, credentials);
      const { token, refreshToken, user } = response.data;
      setTokens(token, refreshToken);
      return { token, refreshToken, user };
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  async activateWorkerAccount(data) {
    try {
      const response = await axios.post(`${AUTH_CONFIG.API_URL}/init-password`, data);
      const { token, refreshToken, user } = response.data;
      setTokens(token, refreshToken);
      return { token, refreshToken, user };
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  async validateToken() {
    if (isValidatingToken && validationPromise) {
      return validationPromise;
    }

    try {
      isValidatingToken = true;
      validationPromise = (async () => {
        const token = getToken();
        const refreshToken = getRefreshToken();
        
        if (!token || !refreshToken) {
          throw {
            success: false,
            error: "No tokens found",
            forceLogout: true
          };
        }
        
        const response = await axiosInstance.get(`${AUTH_CONFIG.API_URL}/valid-token-check`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-refresh-token': refreshToken
          }
        });
        
        if (!response.data.success) {
          throw {
            success: false,
            error: "Invalid token",
            forceLogout: true
          };
        }

        return {
          newToken: response.data.newToken,
          user: response.data.user
        };
      })();

      const result = await validationPromise;
      return result;
    } catch (error) {
      clearTokens();
      navigationRef.current?.navigate(AUTH_CONFIG.LOGOUT_REDIRECT);
      throw error.response?.data || error;
    } finally {
      isValidatingToken = false;
      validationPromise = null;
    }
  },



  logout() {
    clearTokens();
  }
};

export default AuthApi;