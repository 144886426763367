import { useState } from 'react';
import React from 'react';
import { TextField, FormLabel, CircularProgress } from '@mui/material';
import { getRentalRevenue, getBookedNights, getoccupancyRate, getAverageDailyRate, getAverageRevenuePerStay } from '../services/serverApi.financialConfig';
import moment from 'moment';
import useFetchData from '../services/useFetchData';



const Upcoming = ({ selectedCityIds = [], selectedListingIds = [], selectedChannelNames = [], staging = false, active = ['true'] }) => {
    const currentDate = moment().startOf('day');
    const formattedCurrentDate = currentDate.format('YYYY-MM-DD');
    const formattedSevenDaysLaterDate = moment().add(7, 'days').format('YYYY-MM-DD');
    const [upcomingStartDate, setUpcomingStartDate] = useState(formattedCurrentDate);
    const [upcomingEndDate, setUpcomingEndDate] = useState(formattedSevenDaysLaterDate);

    const { data: upcomingRentalRevenueData, isLoading: isLoadingRentalRevenue } = useFetchData(
        () => getRentalRevenue(upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: upcomingNightsBookedData, isLoading: isLoadingNightsBooked } = useFetchData(
        () => getBookedNights(upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: upcomingOccupancyRateData, isLoading: isLoadingOccupancyRate } = useFetchData(
        () => getoccupancyRate(upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: upcomingAverageDailyRateData, isLoading: isLoadingAverageDailyRate } = useFetchData(
        () => getAverageDailyRate(upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const { data: upcomingAverageRevenuePerStayData, isLoading: isLoadingAverageRevenuePerStay } = useFetchData(
        () => getAverageRevenuePerStay(upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active),
        [upcomingStartDate, upcomingEndDate, selectedCityIds, selectedListingIds, selectedChannelNames, staging, active]
    );

    const handleUpcomingDateChange = (event, dateType) => {
        if (dateType === 'start') {
            setUpcomingStartDate(event.target.value);
        } else {
            setUpcomingEndDate(event.target.value);
        }
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-end mb-4">
                <div>
                    <h5 className="!text-base font-bold !text-[#676a6c]">Upcoming</h5>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full sm:w-auto">
                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">From</FormLabel>
                        <TextField type="date" value={upcomingStartDate} className="w-full" InputProps={{ className: "h-9 !rounded-sm" }} onChange={(e) => handleUpcomingDateChange(e, 'start')} />
                    </div>
                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">To (incl.)</FormLabel>
                        <TextField type="date" value={upcomingEndDate} className="w-full" InputProps={{ className: "h-9 !rounded-sm" }} onChange={(e) => handleUpcomingDateChange(e, 'end')} />
                    </div>
                </div>
                <div className="w-full sm:w-auto">
                    <FormLabel className="!text-[#788999] !text-xs !font-bold">&nbsp;</FormLabel>
                    <button className="w-full sm:w-16 px-2 !bg-[#dcf6f6] !rounded-sm flex items-center justify-center gap-1 h-9 py-0">
                        <span className="ladda-label flex items-center font-bold text-[13px] text-[#00b4b4] p-[3px] capitalize">Reset</span>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 bg-[#F8F9FC] mb-4 py-4 px-3">
                {[
                    { label: 'Rental revenue', value: `${upcomingRentalRevenueData?.totalRevenue || 0} MAD`, isLoading: isLoadingRentalRevenue },
                    { label: 'Nights booked', value: upcomingNightsBookedData?.totalNights || 0, isLoading: isLoadingNightsBooked },
                    { label: 'Occupancy rate', value: `${upcomingOccupancyRateData?.occupancyRate || 0}%`, isLoading: isLoadingOccupancyRate },
                    { label: 'Average daily rate', value: `${upcomingAverageDailyRateData?.averageDailyRate || 0} MAD`, isLoading: isLoadingAverageDailyRate },
                    { label: 'Average revenue per stay', value: `${upcomingAverageRevenuePerStayData?.averageRevenuePerStay || 0} MAD`, isLoading: isLoadingAverageRevenuePerStay },
                ].map((item, index) => (
                    <div key={index} className="">
                        <div className="text-base font-extrabold text-black my-2">{item.label}</div>
                        {item.isLoading ? (
                            <CircularProgress sx={{ color: '#00b4b4' }} size={24} />
                        ) : (
                            <div className="text-3xl font-normal mb-2 text-[#788999]">{item.value}</div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default Upcoming;