import Cookies from 'js-cookie';

const COOKIE_CONFIG = {
  secure: process.env.NODE_ENV === 'production',
  sameSite: 'Lax',
  path: '/',
  expires: 365 * 10
};

export const setCookie = (name, value) => {
  Cookies.set(name, value, COOKIE_CONFIG);
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name, { path: '/' });
};