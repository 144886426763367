
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locales/en";
import fr from "../locales/fr";
import es from "../locales/es";

const resources = { en, fr, es };
const savedLanguage = localStorage.getItem("selectedLanguage") || "fr";

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage,
        fallbackLng: "en",
        interpolation: { escapeValue: false },
    });

export default i18n;

