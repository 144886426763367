import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Typography } from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import { toast } from 'react-toastify';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdminFilter from './AdminFilter';
import CreateAdminV2Dialog from './CreateAdminV2Dialog';
import UpdateAdminV2Dialog from './UpdateAdminV2Dialog';
import TableLoading from 'components/TableLoading/TableLoadign';
import { getAdminsV2 } from '../services/serverApi.task';
import { Chip, Stack, IconButton, Popover } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer } from 'react-toastify';


const PublicAdminV2 = () => {
    const [admins, setAdmins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [deletedFilter, setDeletedFilter] = useState('false');
    const [bannedFilter, setBannedFilter] = useState('false');
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchAdmins();
    }, [page, limit, deletedFilter, bannedFilter, searchText]);

    const fetchAdmins = async () => {
        setIsLoading(true);
        try {
            const response = await getAdminsV2({
                page,
                limit,
                deleted: deletedFilter === 'true',
                banned: bannedFilter === 'true',
                search_text: searchText
            });

            if (response && response.data) {
                setAdmins(response.data);
                setTotalCount(response.total || 0);
                setIsNextDisabled((page + 1) * limit >= response.total);
            }
        } catch (error) {
            console.error('Error fetching admins:', error);
            setAdmins([]);
            setTotalCount(0);
            setIsNextDisabled(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onAdminCreated = async (newAdmin) => {
        toast.success('Admin created successfully');
        await fetchAdmins();
    };

    const onAdminUpdated = (updatedAdmin) => {
        setAdmins(prevAdmins =>
            prevAdmins.map(admin =>
                admin._id === updatedAdmin._id ? {
                    ...updatedAdmin,
                    settings: updatedAdmin.settings,
                    status: updatedAdmin.status,
                    deleted: updatedAdmin.deleted,
                    banned: updatedAdmin.banned,
                    role: updatedAdmin.role,
                    ownerCode: updatedAdmin.ownerCode,
                    permissions: updatedAdmin.permissions,
                    subscriptionModules: updatedAdmin.subscriptionModules,
                    whatsapp: updatedAdmin.whatsapp
                } : admin
            )
        );
        toast.success('Admin updated successfully');
    };

    const handleUpdate = (admin) => {
        setSelectedAdmin(admin);
        setOpenUpdateDialog(true);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const handleStatusClick = (event, rowData) => {
        setStatusAnchorEl(event.currentTarget);
        setSelectedStatus(rowData);
    };

    const handleStatusClose = () => {
        setStatusAnchorEl(null);
        setSelectedStatus(null);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    };

    const columns = [
        {
            field: 'firstName',
            header: 'First Name',
            body: (rowData) => <div className="font-medium">{rowData.firstName}</div>,
        },
        {
            field: 'lastName',
            header: 'Last Name',
            body: (rowData) => <div>{rowData.lastName}</div>,
        },
        {
            field: 'email',
            header: 'Email',
            body: (rowData) => <div>{rowData.email}</div>,
        },
        {
            field: 'phone',
            header: 'Phone',
            body: (rowData) => <div>{rowData.phone}</div>,
        },
        {
            field: 'settings',
            header: 'Settings',
            body: (rowData) => (
                <div className="flex flex-col gap-1">
                    <Chip
                        size="small"
                        label={`Lang: ${rowData.settings?.language || 'N/A'}`}
                        className="!text-xs"
                        variant="outlined"
                    />
                    <Chip
                        size="small"
                        label={`Currency: ${rowData.settings?.currency || 'N/A'}`}
                        className="!text-xs"
                        variant="outlined"
                    />
                </div>
            ),
        },
        {
            field: 'whatsapp',
            header: 'WhatsApp',
            body: (rowData) => <div>{rowData.whatsapp || '-'}</div>,
        },
        {
            field: 'status',
            header: 'Status',
            body: (rowData) => (
                <div className="flex items-center">
                    <IconButton
                        size="small"
                        onClick={(e) => handleStatusClick(e, rowData)}
                        className="!text-medium-aquamarine"
                    >
                        <RemoveRedEye fontSize="small" />
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'action',
            header: 'Action',
            body: (rowData) => (
                <button
                    className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                    onClick={() => handleUpdate(rowData)}
                >
                    <EditOffIcon className="text-white" />
                </button>
            ),
        },
    ];

    return (
        <div className="card p-4">
            <ToastContainer position="top-right" autoClose={3000} />

            <div className="flex items-center justify-between mb-4">
                <Typography
                    variant="h4"
                    component="h1"
                    className="!flex !items-center !gap-1 !md:text-2xl !text-lg"
                >
                    <AdminPanelSettingsIcon className="!md:text-3xl !text-xl" />
                    Admin Management
                </Typography>
                <AdminFilter
                    deletedFilter={deletedFilter}
                    bannedFilter={bannedFilter}
                    onDeletedChange={setDeletedFilter}
                    onBannedChange={setBannedFilter}
                />
            </div>

            <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
                <div className="w-full md:flex-grow md:mr-4">
                    <div className="flex w-full bg-white border rounded-md h-8 md:h-10">
                        <input
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search by name or email..."
                            className="p-2 text-xs md:text-xs outline-none rounded-md w-full"
                        />
                        <div className="px-2 flex items-center">
                            <SearchIcon className="!w-3 !h-3 md:!w-4 md:!h-4" />
                        </div>
                    </div>
                </div>

                <Button
                    variant="contained"
                    onClick={() => setOpenCreateDialog(true)}
                    className="w-full md:w-auto px-2 md:px-2.5 py-1 md:py-1.5 !text-xs !bg-medium-aquamarine text-white !rounded-md"
                >
                    Create Admin
                </Button>
            </div>

            <div className="bg-white">
                {isLoading ? (
                    <TableLoading />
                ) : admins.length > 0 ? (
                    <GlobalTable
                        data={admins}
                        columns={columns}
                        page={page}
                        hasPagination={true}
                        onPageChange={handlePageChange}
                        isNextDisabled={isNextDisabled}
                        limit={limit}
                        onLimitChange={handleLimitChange}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        totalCount={totalCount}
                    />
                ) : (
                    <div className="flex flex-col justify-center items-center h-64">
                        <Typography variant="body1" color="textSecondary">
                            No admins found
                        </Typography>
                    </div>
                )}
            </div>

            <CreateAdminV2Dialog
                open={openCreateDialog}
                onClose={() => setOpenCreateDialog(false)}
                onAdminCreated={onAdminCreated}
            />

            <UpdateAdminV2Dialog
                open={openUpdateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                admin={selectedAdmin}
                onAdminUpdated={onAdminUpdated}
            />

            <Popover
                open={Boolean(statusAnchorEl)}
                anchorEl={statusAnchorEl}
                onClose={handleStatusClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {selectedStatus && (
                    <div className="p-4 space-y-2 !bg-white">
                        <Stack direction="column" spacing={1}>
                            <Chip
                                size="small"
                                label={selectedStatus.banned ? 'Banned' : 'Not Banned'}
                                color={selectedStatus.banned ? 'error' : 'success'}
                                variant="outlined"
                            />
                            <Chip
                                size="small"
                                label={selectedStatus.deleted ? 'Deleted' : 'Not Deleted'}
                                color={selectedStatus.deleted ? 'default' : 'success'}
                                variant="outlined"
                            />
                        </Stack>
                    </div>
                )}
            </Popover>
        </div>
    );
};

export default PublicAdminV2;