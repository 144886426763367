import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createReferral } from '../services/serverApi.adminConfig';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const ReferralDialog = ({ showDialog, onClose, onReferralChange }) => {
    const initialValues = {
        referralCode: 'SO-',
    };

    const validationSchema = Yup.object().shape({
        referralCode: Yup.string()
            .required('Referral code is required')
            .min(5, 'Minimum 5 characters')
            .matches(/^SO-/, 'Must start with SO-'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const response = await createReferral({
                referralCode: values.referralCode
            });
            onReferralChange(response.data);
            toast.success('Referral code created successfully');
            onClose();
        } catch (error) {
            const errorMessage = error.response?.data?.errors?.[0]?.message || 'Error creating referral code';
            toast.error(errorMessage);
        }
        setSubmitting(false);
    };

    return (
        <Dialog open={showDialog} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='bg-medium-aquamarine text-white'>
                Create Referral Code
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="px-4 pt-4">
                                <Field
                                    name="referralCode"
                                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="SO-xxxxx"
                                />
                                <ErrorMessage 
                                    name="referralCode" 
                                    component="div" 
                                    className="text-red-500 text-xs italic mt-1" 
                                />
                            </div>
                            <DialogActions>
                                <Button onClick={onClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="!bg-medium-aquamarine text-white"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Creating...' : 'Create'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default ReferralDialog;