import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import moment from 'moment';

const PendingChangesModal = ({
  open,
  onClose,
  pendingChanges = [],
  onDelete,
  onSave,
  setPendingChanges
}) => {

  useEffect(() => {
    console.log('Modal pendingChanges:', pendingChanges);
  }, [pendingChanges]);

  const handleValueChange = (index, field, newValue) => {
    setPendingChanges(prev => prev.map((change, i) => {
      if (i === index) {
        return {
          ...change,
          [field]: field === 'availableRoom' || field === 'price'
            ? Number(newValue)
            : field === 'stopSell'
              ? Boolean(newValue)
              : newValue
        };
      }
      return change;
    }));
  };

  const renderEditableValue = (change, index) => {
    switch (change.type) {
      case 'availability':
        return (
          <TextField
            type="number"
            value={change.availableRoom}
            onChange={(e) => handleValueChange(index, 'availableRoom', e.target.value)}
            size="small"
            inputProps={{ min: 0 }}
            className="w-20"
          />
        );
      case 'manualPrice':
        return (
          <TextField
            type="number"
            value={change.price}
            onChange={(e) => handleValueChange(index, 'price', e.target.value)}
            size="small"
            inputProps={{ min: 0 }}
            className="w-24"
          />
        );
      case 'stopSell':
        return (
          <select
            value={change.stopSell ? 'true' : 'false'}
            onChange={(e) => handleValueChange(index, 'stopSell', e.target.value === 'true')}
            className="p-1 border rounded"
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        );
      default:
        return String(change[change.type]) || '-';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="flex justify-between items-center">
        <span>Pending Changes ({pendingChanges?.length || 0})</span>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => setPendingChanges([])}
        >
          Clear All
        </Button>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Room Type</TableCell>
                <TableCell>Date Range</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(pendingChanges) && pendingChanges.map((change, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex flex-col">
                        <span className="font-medium text-gray-900">
                          {change.listingName || 'Missing Listing Name'}
                        </span>
                        <span className="text-sm text-gray-500">
                          {change.roomTypeName || 'Missing Room Type Name'}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {`${moment(change.date_from).format('MMM DD')} - ${moment(change.date_to).format('MMM DD')}`}
                      {change.days?.length > 0 && (
                        <div className="text-xs text-gray-500">
                          {change.days.map(day => day.toUpperCase()).join(', ')}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{change.type}</TableCell>
                    <TableCell>{renderEditableValue(change, index)}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => onDelete(index)}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={() => {
            console.log('Saving changes:', pendingChanges);
            onSave();
            onClose();
          }}
          className="!bg-medium-aquamarine !text-white"
        >
          Save All Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PendingChangesModal;