import React, { useState, useEffect } from 'react';
import ReferralDialog from './ReferralDialog';
import { getReferrals } from '../services/serverApi.adminConfig';
import { Box, Button, CircularProgress } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

const Referrals = () => {
    const [referrals, setReferrals] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getReferrals();
            setReferrals(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching referrals:', error.message);
            setReferrals([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateReferral = () => {
        setShowDialog(true);
    };

    const handleReferralChange = (newReferral) => {
        setReferrals(prevReferrals => [...prevReferrals, newReferral]);
        setShowDialog(false);
    };

    return (
        <>
            <ToastContainer />
            <div className="card p-4 !border-none">
                <Box className="text-center mb-4">
                    <Button
                        variant="contained"
                        className="!bg-medium-aquamarine text-white"
                        endIcon={<AddIcon />}
                        onClick={handleCreateReferral}
                    >
                        Add Referral Code
                    </Button>
                </Box>
                {isLoading ? (
                    <div className="flex justify-center items-center h-full">
                        <CircularProgress style={{ color: '#00b4b4' }} />
                    </div>
                ) : (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                        {referrals.map(referral => (
                            <div
                                key={referral._id}
                                className="border rounded p-4 flex justify-center text-black flex-col items-center hover:bg-medium-aquamarine hover:text-white transition-all duration-300"
                            >
                                <h6 className="font-bold m-0 text-sm text-center">{referral.code}</h6>
                                <p className="text-xs opacity-70 mt-1 !mb-0">
                                    {new Date(referral.createdAt).toLocaleDateString()}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
                <ReferralDialog
                    showDialog={showDialog}
                    onClose={() => setShowDialog(false)}
                    onReferralChange={handleReferralChange}
                />
            </div>
        </>
    );
};

export default Referrals;