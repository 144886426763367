import React, { useState, useEffect } from 'react';
import {
  getLeadDetails,
  deleteLeadDetails,
  getLeads
} from '../../services/serverApi.lead';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import LeadDetailsModal from './LeadDetailsModal';
import LeadDetailsFilter from './LeadDetailsFilter';
import { toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button, Chip, Stack, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

function LeadDetails() {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(true);
  const [leadDetails, setLeadDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLeadDetail, setSelectedLeadDetail] = useState(null);
  const [error, setError] = useState(null);
  const [leads, setLeads] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filters, setFilters] = useState({
    lead_id: ''
  });

  useEffect(() => {
    fetchAllLeads();
  }, []);

  useEffect(() => {
    fetchLeadDetails();
  }, [page, limit, filters]);

  const fetchAllLeads = async () => {
    try {
      setIsLoading(true);

      const queryParams = 'paged=true&limit=100&page=0';

      const response = await getLeads(queryParams);

      if (response.success && Array.isArray(response.data)) {
        setLeads(response.data);
      } else {
        console.error('Invalid leads response:', response);
        setLeads([]);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
      setLeads([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLeadDetails = async () => {
    try {
      setIsLoading(true);

      const queryParams = new URLSearchParams({
        paged: 'true',
        limit: String(limit),
        page: String(page),
        ...(filters.lead_id && { lead_id: filters.lead_id }),
      }).toString();

      const response = await getLeadDetails(queryParams);

      if (response.success) {
        setLeadDetails(Array.isArray(response.data) ? response.data : []);
        setTotalRecords(response.total || 0);
      } else {
        setLeadDetails([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error('Error fetching lead details:', error);
      setLeadDetails([]);
      setTotalRecords(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = (leadDetail = null) => {
    setSelectedLeadDetail(leadDetail);
    setModalOpen(true);
  };

  const handleCloseModal = (refreshData = false) => {
    setModalOpen(false);
   
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(Number(newLimit));
    setPage(0);
  };

  const handleFilterApply = (newFilters) => {
    setFilters(newFilters);
    setPage(0);
  };

  const handleDeleteLeadDetail = async (leadDetailId) => {
    if (window.confirm(t('Are you sure you want to delete this lead detail?'))) {
      try {
        await deleteLeadDetails(leadDetailId);
        fetchLeadDetails();
        toast.success(t('Lead detail deleted successfully'));
      } catch (error) {
        console.error('Error deleting lead detail:', error);
        toast.error(t('Failed to delete lead detail'));
      }
    }
  };

  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
    } catch (error) {
      return t('Invalid date');
    }
  };

  const getLeadName = (leadId) => {
    const lead = leads.find(lead => lead.id === leadId || lead._id === leadId);
    return lead ? lead.name : t('Unknown Lead');
  };

  const columns = [
    {
      header: t("Lead"),
      body: (rowData) => (
        <Tooltip title={`ID: ${rowData.lead_id}`} placement="right">
          <span>{getLeadName(rowData.lead_id)}</span>
        </Tooltip>
      )
    },
    {
      header: t("Company"),
      body: (rowData) => (
        <Tooltip title={rowData.company_name} placement="right">
          <span>{rowData.company_name}</span>
        </Tooltip>
      )
    },
    {
      header: t("Timeline"),
      body: (rowData) => <span>{rowData.timeline}</span>
    },
    {
      header: t("Solutions"),
      body: (rowData) => (
        <Tooltip
          title={rowData.current_solutions?.join(', ') || t('None')}
          placement="right"
        >
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            {rowData.current_solutions && rowData.current_solutions.map((solution, index) => (
              <Chip key={index} label={solution} size="small" className="!text-xs" />
            ))}
          </Stack>
        </Tooltip>
      )
    },
    {
      header: t("Growth Plan"),
      body: (rowData) => <span>{rowData.planned_growth} {t('properties')}</span>
    },
    {
      header: t("Challenges"),
      body: (rowData) => (
        <Tooltip
          title={rowData.biggest_challenges || t('None specified')}
          placement="right"
        >
          <span className="cursor-pointer">
            {rowData.biggest_challenges?.length > 30
              ? `${rowData.biggest_challenges.substring(0, 30)}...`
              : rowData.biggest_challenges || '-'}
          </span>
        </Tooltip>
      )
    },
    {
      header: t("Expected Outcomes"),
      body: (rowData) => (
        <Tooltip
          title={rowData.expected_outcomes || t('None specified')}
          placement="right"
        >
          <span className="cursor-pointer">
            {rowData.expected_outcomes?.length > 30
              ? `${rowData.expected_outcomes.substring(0, 30)}...`
              : rowData.expected_outcomes || '-'}
          </span>
        </Tooltip>
      )
    },
    {
      header: t("Client Type"),
      body: (rowData) => <span>{rowData.client_type}</span>
    },
    {
      header: t("Industry"),
      body: (rowData) => <span>{rowData.industry}</span>
    },
    {
      header: t("Action"),
      body: (rowData) => (
        <div className="flex gap-1">
          <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModal(rowData)}>
            <EditOffIcon className="text-white" />
          </button>
          <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteLeadDetail(rowData.id)}>
            <DeleteSweepIcon className="text-white" />
          </button>
        </div>
      ),
    },
  ];

  if (error) {
    return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
  }

  const isNextDisabled = (page + 1) * limit >= totalRecords;

  return (
    <div className="card p-4 !border-none">
      <div className="flex justify-between items-center mb-4">
        <Typography variant="h4" component="h1" className="!text-gray-700" gutterBottom>
          {t('Lead Details Management')}
        </Typography>
        <Button
          onClick={() => handleOpenModal()}
          className="!bg-medium-aquamarine !text-white !text-xs"
        >
          {t('Create New Lead Detail')}
        </Button>
      </div>

      <LeadDetailsFilter
        onFilterApply={handleFilterApply}
        initialFilters={filters}
        leads={leads}
      />

      <div>
        <div className="w-full">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress style={{ color: '#00b4b4' }} />
            </div>
          ) : (
            <GlobalTable
              data={leadDetails}
              columns={columns}
              hasPagination={true}
              page={page}
              onPageChange={handlePageChange}
              limit={limit}
              onLimitChange={handleLimitChange}
              total={totalRecords}
              isNextDisabled={isNextDisabled}
              rowsPerPageOptions={[5, 10, 20, 50]}
            />
          )}
        </div>
      </div>
      <LeadDetailsModal
        open={modalOpen}
        onClose={handleCloseModal}
        setLeadDetails={setLeadDetails}
        leadDetail={selectedLeadDetail}
        leads={leads}
      />
    </div>
  );
}

export default LeadDetails;