import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './TableCalendar.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Tooltip from '@mui/material/Tooltip';
import { CalendarHeader } from '../../tasks/components/Calendar/CalendarHeader';
import EventPopup from './EventPopup';
import { CircularProgress } from '@mui/material';

const TableCalendarReservation = ({
  groupedProperties,
  daysInView,
  listingId,
  formattedDateRange,
  goToNextPeriod,
  goToToday,
  goToPreviousPeriod,
  setViewType,
  viewType,
  scrollBackward,
  scrollForward,
  scrollOffset,
  setCurrentDate,
  changeMonthView,
  currentDate,
  isLoading,
  onReservationUpdate,
}) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [collapsedZones, setCollapsedZones] = useState({});

  const toggleZone = (zone) => {
    setCollapsedZones((prev) => ({
      ...prev,
      [zone]: !prev[zone],
    }));
  };

  const handleReservationUpdate = (updatedReservation) => {
    onReservationUpdate(updatedReservation);
  };

  const formatDate = (day) => {
    return {
      dayName: day.toLocaleDateString('en-US', { weekday: 'short' }),
      day: day.toLocaleDateString('en-US', { day: 'numeric' }),
      month: day.toLocaleDateString('en-US', { month: 'short' }),
    };
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEvent(null);
  };

  const renderZoneHeader = (zone) => {
    const isCollapsed = collapsedZones[zone];
    return (
      <div
        className="group-header-cell cursor-pointer"
        onClick={() => toggleZone(zone)}
      >
        {isCollapsed ? (
          <ArrowRightIcon className="group-header-icon " />
        ) : (
          <ArrowDropDownIcon className="group-header-icon " />
        )}
        <span className="text-white ">{zone}</span>
      </div>
    );
  };

  const isSameDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Confirmed':
        return 'bg-medium-aquamarine';
      case 'Pending':
        return 'bg-medium-aquamarine';
      case 'CancelledByAdmin':
      case 'CancelledByOta':
      case 'CancelledByCustomer':
      case 'CancelledAfterFailedPayment':
      case 'OtherCancellation':
        return 'bg-red-500';
      default:
        return 'bg-medium-aquamarine';
    }
  };

  const renderEventCell = (property, day) => {
    if (!property || !property.reservations) return null;

    const currentDay = new Date(day);
    currentDay.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const calendarStartDate = daysInView[0];

    const reservationsForDay = property.reservations
      .filter((reservation) => {
        const checkIn = new Date(reservation.arrivalDate);
        const checkOut = new Date(reservation.departureDate);
        checkIn.setHours(0, 0, 0, 0);
        checkOut.setHours(0, 0, 0, 0);
        return currentDay >= checkIn && currentDay <= checkOut;
      })
      .map((reservation) => ({
        ...reservation,
        sojoriId: property._id,
        listingName: property.name,
      }));

    return (
      <div className="event-container !h-[40px]">
        <div className="half-day-line"></div>
        {reservationsForDay.map((reservation, index) => {
          const checkIn = new Date(reservation.arrivalDate);
          checkIn.setHours(0, 0, 0, 0);
          const checkOut = new Date(reservation.departureDate);
          checkOut.setHours(0, 0, 0, 0);

          const isFirstDay = isSameDay(currentDay, checkIn);
          const isStartDateBeforeView =
            checkIn < calendarStartDate && isSameDay(day, calendarStartDate);

          const totalDuration = Math.ceil(
            (checkOut - checkIn) / (1000 * 60 * 60 * 24) + 1,
          );

          const remainingDuration = Math.ceil(
            (checkOut - now) / (1000 * 60 * 60 * 24) + 1,
          );

          const displayDuration =
            checkIn < now ? remainingDuration : totalDuration;

          const daysLeft = Math.ceil(
            (checkOut - currentDay) / (1000 * 60 * 60 * 24) + 1,
          );

          const width = Math.min(
            daysLeft,
            daysInView.length - daysInView.indexOf(day),
          );
          const widthCalc = `calc(${width * 100}% + ${(width - 1) * 17}px)`;

          return (
            <div
              key={`${reservation.reservationNumber}-${index}`}
              //   className="event-cell cursor-pointer "
              className={`event-cell cursor-pointer ${getStatusColor(
                reservation.status,
              )}`}
              onClick={() => handleEventClick(reservation)}
              style={{
                width: widthCalc,
                // backgroundColor: getStatusColor(reservation.status),
                zIndex: isFirstDay ? 1 : 0,
                position: 'absolute',
                height: '24px',
                margin: '2px 1px',
                color: 'white',
              }}
            >
              {(isFirstDay || isStartDateBeforeView) && (
                <div className="flex items-center gap-1 px-1">
                  <Tooltip
                    title={`${reservation.guestName} - ${
                      reservation.reservationNumber
                    }
                                        Check-in: ${new Date(
                                          reservation.arrivalDate,
                                        ).toLocaleDateString()}
                                        Check-out: ${new Date(
                                          reservation.departureDate,
                                        ).toLocaleDateString()}
                                        Adults: ${
                                          reservation.adults
                                        } Children: ${reservation.children}
                                        Status: ${reservation.status}`}
                    arrow
                    placement="right"
                  >
                    <span className="event-title">
                      {` ${reservation.guestName} ${reservation.reservationNumber}`}
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  const renderPropertyCell = (property) => {
    return (
      <div className="property-cell">
        <div>
          <span className="property-name">{property.name}</span>
        </div>
      </div>
    );
  };

  const columns = [
    {
      field: 'property',
      header: (
        <div style={{ width: '100%' }}>
          <CalendarHeader
            formattedDateRange={formattedDateRange}
            goToNextPeriod={goToNextPeriod}
            goToToday={goToToday}
            goToPreviousPeriod={goToPreviousPeriod}
            setViewType={setViewType}
            viewType={viewType}
            scrollBackward={scrollBackward}
            scrollForward={scrollForward}
            canScrollForward={scrollOffset > 0}
            setCurrentDate={setCurrentDate}
            changeMonthView={changeMonthView}
            currentDate={currentDate}
          />
        </div>
      ),
      frozen: true,
      style: { width: '300px', zIndex: 2, padding: 0 },
    },
    ...daysInView.map((day, index) => ({
      field: `day${index}`,
      header: (
        <div className="flex flex-col items-center w-full !text-[12px]">
          <div className="font-light">{formatDate(day).dayName}</div>
          <div className="font-bold">{formatDate(day).day}</div>
          <div className="font-light">{formatDate(day).month}</div>
        </div>
      ),
      style: { width: '80px', position: 'relative' },
    })),
  ];

  const data =
    groupedProperties?.flatMap((group) => {
      const zoneRow = {
        id: `zone_${group._id}`,
        property: renderZoneHeader(group._id),
        isZone: true,
      };

      if (collapsedZones[group._id]) {
        return [zoneRow];
      }

      return [
        zoneRow,
        ...group.properties.map((property) => ({
          id: property._id,
          property: renderPropertyCell(property),
          ...daysInView.reduce((acc, day, index) => {
            acc[`day${index}`] = renderEventCell(property, day);
            return acc;
          }, {}),
        })),
      ];
    }) || [];

  return (
    <div className="table-calendar-container">
      <DataTable
        value={data}
        className="table-calendar"
        scrollable
        scrollHeight="calc(100vh - 250px)"
        showGridlines
        frozenColumns={1}
        // loading={isLoading}
        // emptyMessage={
        //     <div className="text-center p-4 text-gray-500 h-[200px] flex items-center justify-center">
        //         <div className="flex justify-center items-center">
        //             No properties available
        //         </div>
        //     </div>
        // }
        rowClassName={(rowData) =>
          rowData.isZone ? 'group-header zone-row' : 'property-row'
        }
      >
        {columns.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            frozen={index === 0}
            style={{
              ...(col.style || {}),
              ...(index === 0
                ? { position: 'sticky', left: 0, zIndex: 2 }
                : {}),
            }}
            body={(rowData) => rowData[col.field]}
          />
        ))}
      </DataTable>
      {selectedEvent && (
        <EventPopup
          event={selectedEvent}
          open={openModal}
          onClose={handleCloseModal}
          onReservationUpdate={handleReservationUpdate}
        />
      )}
    </div>
  );
};

export default TableCalendarReservation;
