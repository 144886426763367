import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import {
  getAllTasks,
  getStaff,
  getCities,
  getCountries,
} from './services/serverApi.task';
import './Task.css';
import FilterTask from './components/Calendar/FilterTask';
import moment from 'moment';
import { UserPlus } from 'lucide-react';
import {
  IconButton,
  Tooltip,
  Snackbar,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import AddTask from './components/Calendar/AddTask';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { ReservationNumber } from './utils/ReservationNumber';

function DisplayTask() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [initialized, setInitialized] = useState(false);

  const [typeFilter, setTypeFilter] = useState([]);
  const [subTypeFilter, setSubTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [assignmentStatusFilter, setAssignmentStatusFilter] = useState([]);
  const [listingIdFilter, setListingIdFilter] = useState('');
  const [reservationNumberFilter, setReservationNumberFilter] = useState('');
  const [searchByGuest, setSearchByGuest] = useState('');
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [staff, setStaff] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

  const filters = useMemo(
    () => ({
      type: typeFilter,
      subType: subTypeFilter,
      status: statusFilter,
      assignmentStatus: assignmentStatusFilter,
      listingId: listingIdFilter,
      reservationNumber: reservationNumberFilter,
      searchByGuest: searchByGuest,
      cityIds: selectedCities,
      countryNames: selectedCountries,
      listingIds: selectedListings,
      useActiveFilter: true,
      active: isActive,
    }),
    [
      typeFilter,
      subTypeFilter,
      statusFilter,
      assignmentStatusFilter,
      listingIdFilter,
      reservationNumberFilter,
      searchByGuest,
      selectedCities,
      selectedCountries,
      selectedListings,
      isActive,
    ],
  );

  useEffect(() => {
    if (!initialized) {
      const searchParams = new URLSearchParams(location.search);
      const reservationNumber = searchParams.get('reservationNumber');
      const searchByGuest = searchParams.get('searchByGuest');
      const listingId = searchParams.get('listingId');
      const cityIds = searchParams.getAll('cityIds');
      const countryNames = searchParams.getAll('countryNames');
      const listingIds = searchParams.getAll('listingIds');

      if (reservationNumber) setReservationNumberFilter(reservationNumber);
      if (searchByGuest) setSearchByGuest(searchByGuest);
      if (listingId) setListingIdFilter(listingId);
      if (cityIds.length > 0) setSelectedCities(cityIds);
      if (countryNames.length > 0) setSelectedCountries(countryNames);
      if (listingIds.length > 0) setSelectedListings(listingIds);

      setInitialized(true);
    }
  }, [location.search, initialized]);

  const handleCopyReservationNumber = (reservationNumber) => {
    navigator.clipboard
      .writeText(reservationNumber)
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Reservation number copied to clipboard!',
        });
      })
      .catch((err) => {
        console.error('Failed to copy reservation number: ', err);
        setSnackbar({
          open: true,
          message: 'Failed to copy reservation number',
        });
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEditTask = (task) => {
    console.log({ task });

    setSelectedTask(task);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedTask(null);
  };

  const handleSaveTask = (response) => {
    const savedTask = response.task;
    setTasks((prevTasks) => {
      const taskIndex = prevTasks.findIndex(
        (task) => task._id === savedTask._id,
      );
      if (taskIndex !== -1) {
        toast.success('Task updated successfully.');
        return prevTasks.map((task) =>
          task._id === savedTask._id
            ? { ...task, ...savedTask, listing: task.listing }
            : task,
        );
      } else {
        toast.success('New task added successfully!');
        const newTask = {
          ...savedTask,
          listing: savedTask.listing || { name: 'Unknown' },
        };
        return [newTask, ...prevTasks];
      }
    });

    handleCloseEditModal();
  };

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const response = await getAllTasks({
        page,
        limit,
        staging,
        ...filters,
      });
      if (response.data && Array.isArray(response.data)) {
        setTasks(response.data);
      } else {
        setTasks([]);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setTasks([]);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStaff = async () => {
    setIsLoading(true);
    try {
      const response = await getStaff();
      if (response.data.data && Array.isArray(response.data.data)) {
        setStaff(response.data.data);
      } else {
        setStaff([]);
      }
    } catch (error) {
      console.error('Error fetching staff:', error);
      setStaff([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  useEffect(() => {
    if (initialized) {
      fetchTasks();
    }
  }, [page, limit, filters, staging, initialized]);

  const handleFilterChange = (newFilters) => {
    setTypeFilter(newFilters.type || []);
    setSubTypeFilter(newFilters.subType || []);
    setStatusFilter(newFilters.status || []);
    setAssignmentStatusFilter(newFilters.assignmentStatus || []);
    setListingIdFilter(newFilters.listingId || '');
    setReservationNumberFilter(newFilters.reservationNumber || '');
    setSearchByGuest(newFilters.searchByGuest || '');
    setSelectedCities(newFilters.cityIds || []);
    setSelectedCountries(newFilters.countryNames || []);
    setSelectedListings(newFilters.listingIds || []);
    setIsActive(newFilters.active);

    const searchParams = new URLSearchParams(location.search); // Preserve existing params

    Object.entries({
      reservationNumber: newFilters.reservationNumber,
      listingId: newFilters.listingId,
      searchByGuest: newFilters.searchByGuest,
      cityIds: newFilters.cityIds,
      countryNames: newFilters.countryNames,
      listingIds: newFilters.listingIds,
    }).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        searchParams.delete(key);
        value.forEach((v) => {
          if (v) searchParams.append(key, v);
        });
      } else if (value) {
        searchParams.set(key, value);
      }
    });

    navigate(`/admin/Tasks?${searchParams.toString()}`, { replace: true });
    setPage(0);
  };

  const formatDate = (date, timeStart) => {
    return moment(date)
      .set({ hour: parseInt(timeStart), minute: 0 })
      .format('MM-DD | HH:mm');
  };

  const columns = [
    {
      field: 'taskCode',
      header: 'Task ID',
      body: (rowData) => (
        <Tooltip title={rowData.taskCode} arrow placement="top">
          <span>{rowData.taskCode}</span>
        </Tooltip>
      ),
    },
    {
      field: 'startDate',
      header: 'Task Date',
      body: (rowData) =>
        formatDate(rowData?.startDate, rowData.TS_SEL[0]?.startDate),
    },
    {
      field: 'name',
      header: 'Task Name',
      body: (rowData) => (
        <Tooltip title={rowData.name} arrow placement="top">
          <span>{rowData.name}</span>
        </Tooltip>
      ),
    },
    {
      field: 'listing.name',
      header: 'Listing Name',
      body: (rowData) => (
        <Tooltip title={rowData.listing.name} arrow placement="top">
          <span>{rowData.listing.name}</span>
        </Tooltip>
      ),
    },
    {
      field: 'reservationNumber',
      header: 'Reservation N°',
      body: (rowData) => (
        <ReservationNumber
          reservationNumber={rowData.reservationNumber}
          onCopy={handleCopyReservationNumber}
        />
      ),
    },
    { field: 'type', header: 'Type' },
    { field: 'subType', header: 'Sub Type' },
    { field: 'mode', header: 'Mode' },
    { field: 'status', header: 'Status' },
    { field: 'assignmentStatus', header: 'Assignment Status' },
    { field: 'price', header: 'Price' },
    {
      field: 'staff',
      header: 'Staff',
      body: (rowData) =>
        rowData.staff && rowData.staff.username ? (
          <Tooltip title={rowData.staff.username} arrow placement="top">
            <span>{rowData.staff.username}</span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      field: 'paymentMode',
      header: 'Payment Mode',
      body: (rowData) => (rowData.paid ? rowData.paymentMode : '-'),
    },
    {
      field: 'action',
      body: (rowData) => (
        <Tooltip title="Assign Task">
          <IconButton
            onClick={() => handleEditTask(rowData)}
            size="small"
            color="primary"
            aria-label="assign task"
          >
            <UserPlus size={20} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <div className="card py-4 overflow-hidden">
        <Typography
          variant="h4"
          component="h1"
          className="mb-2 px-4"
          gutterBottom
        >
          Tasks
        </Typography>
        <FilterTask
          onFilterChange={handleFilterChange}
          onSaveTask={handleSaveTask}
          staff={staff}
          initialFilters={{
            ...filters,
            // Remove redundant props that are already in filters
            cities,
            countries,
          }}
        />
        <div className="p-2">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress sx={{ color: '#00b4b4' }} />
            </div>
          ) : tasks.length > 0 ? (
            <GlobalTable
              data={tasks}
              columns={columns}
              page={page}
              onPageChange={setPage}
              isNextDisabled={tasks.length < limit}
              hasPagination
              limit={limit}
              onLimitChange={setLimit}
              rowsPerPageOptions={[10, 20, 50]}
            />
          ) : (
            <div className="flex justify-center items-center h-64">no data</div>
          )}
        </div>
        {isEditModalOpen && (
          <AddTask
            open={isEditModalOpen}
            onClose={handleCloseEditModal}
            onSave={handleSaveTask}
            existingTask={selectedTask}
            staff={staff}
            listing={selectedTask?.listing ? [selectedTask.listing] : []}
          />
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbar.message}
        />
      </div>
    </DashboardLayout>
  );
}

export default DisplayTask;
