import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  updateAccessType,
  createAccessType,
} from '../services/serverApi.listing';
import { toast } from 'react-toastify';
import ImageUpload from 'components/CustomUpload/UploadV2';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Access Type name is required'),
  iconUrl: Yup.string(),
});

const ModifyAccessType = ({
  open,
  onClose,
  setAccessTypeList,
  accessTypeList,
  index,
  dataAccessType,
  cities,
  isCreating,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const formData = {
      type: values.type,
      iconUrl: values.iconUrl,
    };
    try {
      let response;
      if (isCreating) {
        response = await createAccessType(formData);
      } else {
        response = await updateAccessType(dataAccessType._id, formData);
      }

      if (response.data && response.data.data) {
        if (isCreating) {
          setAccessTypeList((prevList) => [...prevList, response.data.data]);
        } else {
          setAccessTypeList((prevList) =>
            prevList.map((item) =>
              item._id === dataAccessType._id ? response.data.data : item,
            ),
          );
        }
        setSubmitting(false);
        onClose();
        toast.success(
          isCreating
            ? 'Access Type created successfully'
            : 'Modification réussie',
        );
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      setErrorMessage(error.message);
      setSubmitting(false);
      toast.error(
        isCreating
          ? 'Error creating Access Type'
          : 'Error updating Access Type',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-medium-aquamarine text-white">
        <Typography variant="h6" style={{ color: 'white' }} align="center">
          {isCreating ? 'Create Access Type' : 'Modify Access Type'}
        </Typography>
      </DialogTitle>
      <DialogContent className="pt-4">
        {errorMessage && (
          <Box mb={2}>
            <Typography variant="body2" color="error" align="center">
              {errorMessage}
            </Typography>
          </Box>
        )}
        <Formik
          initialValues={{
            type: dataAccessType?.type || '',
            iconUrl: dataAccessType?.iconUrl || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Box mb={2}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  name="type"
                  label="Type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={values.type}
                  onChange={(event) =>
                    setFieldValue('type', event.target.value)
                  }
                />
                <ErrorMessage
                  name="type"
                  component={Typography}
                  variant="body2"
                  color="error"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  name="iconUrl"
                  label="Icon"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={values.iconUrl}
                  onChange={(event) =>
                    setFieldValue('iconUrl', event.target.value)
                  }
                />
                <ErrorMessage
                  name="iconUrl"
                  component={Typography}
                  variant="body2"
                  color="error"
                />
              </Box>

              {/* <Box mb={2}>
                <Typography variant="body1">Icon</Typography>
                <Field name="iconUrl">
                  {({ field, form }) => (
                    <ImageUpload
                      fieldName="iconUrl"
                      setFieldValue={form.setFieldValue}
                      folder="other"
                    />
                  )}
                </Field>
                {values.iconUrl && (
                  <img
                    src={values.iconUrl}
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'contain',
                    }}
                  />
                )}
                <ErrorMessage
                  name="iconUrl"
                  component={Typography}
                  variant="body2"
                  color="error"
                />
              </Box> */}
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <button
                  className="text-white py-1 px-3 bg-medium-aquamarine !rounded-md"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isLoading
                    ? isCreating
                      ? 'Creating...'
                      : 'Updating...'
                    : isCreating
                    ? 'Create'
                    : 'Update'}
                </button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyAccessType;
