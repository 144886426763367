import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const Loading = () => {
  return (
    <Box className="fixed inset-0 flex items-center justify-center bg-white/95 z-50">
      <Box className="flex flex-col items-center space-y-4">
        <CircularProgress sx={{ color: '#00b4b4' }} />
      </Box>
    </Box>
  );
};

export default Loading;