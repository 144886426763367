import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Typography, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getExchangeRates, updateExchangeRate } from '../services/serverApi.adminConfig';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    rate: Yup.number()
        .positive('Rate must be greater than 0')
        .required('Rate is required'),
});

const ExchangeRateComponent = () => {
    const [initialValues, setInitialValues] = useState({ rate: '' });
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchRate();
    }, []);

    const fetchRate = async () => {
        try {
            const response = await getExchangeRates();
            setInitialValues({ rate: response.data.rate });
            setId(response.data._id);
        } catch (error) {
            console.error('Error fetching rate', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (values) => {
        try {
            await updateExchangeRate(id, { rate: values.rate });
            toast.success('Exchange rate updated successfully');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update exchange rate: ' + error.message);
        }
    };

    return (
        <div className="card p-4 !border-none">
            <ToastContainer />

            <Typography variant="h4" gutterBottom>
                Exchange Rate
            </Typography>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress style={{ color: '#00b4b4' }} />
                </div>
            ) : (

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSave} enableReinitialize={true}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form className="flex gap-4 flex-row p-4 items-center">
                        <span className="text-lg">Exchange Rate</span>

                        <Field name="rate">
                            {({ field, form }) => (
                                <div>
                                    <TextField type="number" {...field} error={Boolean(form.errors.rate && form.touched.rate)} helperText={<ErrorMessage name="rate" />} inputProps={{ min: 1 }} onChange={e => setFieldValue('rate', e.target.value)} />
                                </div>
                            )}
                        </Field>

                        <IconButton type="submit" className="!bg-medium-aquamarine text-white" disabled={isSubmitting}>
                            <SaveIcon />
                        </IconButton>
                    </Form>
                )}
            </Formik>

            )}
        </div>
    );
};

export default ExchangeRateComponent;
