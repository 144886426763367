import { AUTH_CONFIG } from '../config/auth.config';
import { setCookie, getCookie, removeCookie } from './cookie.utils';

export const getToken = () => getCookie(AUTH_CONFIG.TOKEN_KEY);
export const getRefreshToken = () => getCookie(AUTH_CONFIG.REFRESH_TOKEN_KEY);

export const setTokens = (token, refreshToken) => {
  setCookie(AUTH_CONFIG.TOKEN_KEY, token);
  setCookie(AUTH_CONFIG.REFRESH_TOKEN_KEY, refreshToken);
};

export const clearTokens = () => {
  removeCookie(AUTH_CONFIG.TOKEN_KEY);
  removeCookie(AUTH_CONFIG.REFRESH_TOKEN_KEY);
};

export const isAuthenticated = () => !!getToken();