import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useTranslation } from 'react-i18next';

const PaymentStatusFilter = ({ paymentStatus, setPaymentStatus }) => {
  const { t } = useTranslation('common');
  const paymentStatusOptions = [
    { id: 'Paid', name: t('Paid') },
    { id: 'UnPaid', name: t('UnPaid') },
  ];

  return (
    <FilterSearch
      options={paymentStatusOptions}
      selectedItems={paymentStatus}
      onItemsChange={setPaymentStatus}
      placeholder={t('Payment Status')}
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default PaymentStatusFilter;
