import React, { useState } from "react";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import Leads from "../components/leads/Leads";
import LeadDetails from "../components/leadsDetails/LeadDetails";
import TabPanel from "@mui/lab/TabPanel";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LeadsPage() {
    const [value, setValue] = useState("1");
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <Box className="!bg-white py-0">
                <TabContext value={value}>
                    <Box className="relative">
                        <TabList 
                            onChange={handleChange} 
                            aria-label="tabs" 
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#00b4b4',
                                },
                                '& .MuiTab-root': {
                                    color: 'black',
                                    fontWeight: 600,
                                },
                                '& .Mui-selected': {
                                    color: '#00b4b4 !important',
                                },
                            }}
                        >
                            <Tab label="Leads" value="1" />
                            <Tab label="Lead Details" value="2" />
                        </TabList>
                        <TabPanel value="1" sx={{ px: '0 !important' }}><Leads /></TabPanel>
                        <TabPanel value="2" sx={{ px: '0 !important' }}><LeadDetails /></TabPanel>
                    </Box>
                </TabContext>
            </Box>
        </DashboardLayout>
    );
}

export default LeadsPage;