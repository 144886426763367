import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getcountries(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}?page=${page || 0}&limit=${limit || 10}&paged=false`);
}

export function createCountry(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.COUNTRY}`, data);
}
export function updateCountry(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.COUNTRY}/${id}`, data);
}

export function removeCountry(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.COUNTRY}/${id}`);
}
export function getCountry(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}/${id}`);
}
// ---------------------------cities ----------------------------
export function getcities(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=${page || 0}&limit=${limit || 1}&paged=false`);
}

export function createCity(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.CITY}`, data);
}


export function updateCity(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.CITY}/${id}`, data);
}

export function removeCity(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.CITY}/${id}`);
}
// ----------------------citiesMapping-----------------

export function getcitiesMappig() {
  return axios.get(`${MICROSERVICE_BASE_URL.CITIESMAPPING}/get-cities-mapping`);
}



export function updateCitiesMapping(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.CITIESMAPPING}/update-cities-mapping`, data);
}
// ----------------------BlogMapping-----------------

export function getBlogsMappig() {
  return axios.get(`${MICROSERVICE_BASE_URL.CITIESMAPPING}/get-blog-mapping`);
}



export function updateBlogsMapping(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.CITIESMAPPING}/update-blog-mapping`, data);
}

// ----------------------Blogs----------------

export function getblogs(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.BLOGS}?page=${page || 0}&limit=${limit || 20}&paged=false`);
}

export function createblog(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.BLOGS}`, data);
}


export function updateblog(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.BLOGS}/${id}`, data);
}

export function removeblog(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.BLOGS}/${id}`);

}



// ----------------------Slides----------------

export function getSlides() {
  return axios.get(`${MICROSERVICE_BASE_URL.SLIDES}/slide-config`);
}

export function createSlide(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.SLIDES}`, data);
}


export function updateSlide(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SLIDES}/${id}`, data);
}


export function deleteSlide(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/slide-show/${id}`);
}




// ----------------------OpenAi----------------

export function getOpenAiInit() {
  return axios.get(`${MICROSERVICE_BASE_URL.OPENAI}?page=${0}&limit=${50}&paged=false&search_text=${''}`);
}
export function createOpenAiInit(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.OPENAI}`, data);
}

export function removeOpenAiInit(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.OPENAI}/${id}`);

}

export function updateOpenAiInig(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.OPENAI}/${id}`, data);
}
// ----------------------OpenAi----------------

export function getOpenAiConfig(page, limit, search) {
  return axios.get(`${MICROSERVICE_BASE_URL.OPENAICONFIG}?page=${page || 0}&limit=${limit || 20}&paged=true&search_text=${search || ''}`);
}
export function createOpenAiConfig(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.OPENAICONFIG}`, data);
}

export function removeOpenAiConfig(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.OPENAICONFIG}/${id}`);

}

export function updateOpenAiConfig(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.OPENAICONFIG}/${id}`, data);
}

export function getOpenAiConfigById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.OPENAICONFIG}/${id}`);
}


// ----------------------Language----------------


export const getLanguages = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.LANGUAGE}?page=0&limit=20&paged=false&search_text=`);
    return response.data;
  } catch (error) {
    console.error('Error fetching languages:', error.message);
    throw new Error('Error fetching languages');
  }
};


export const createLanguage = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.LANGUAGE}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateLanguage = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.LANGUAGE}/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const deleteLanguage = async (id) => {
  try {
    const response = await axios.delete(`${MICROSERVICE_BASE_URL.LANGUAGE}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
// ----------------------Tag----------------




export const getTags = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.TAG}?page=0&limit=20&paged=false&search_text=`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tags:', error.message);
    throw new Error('Error fetching tags');
  }
}


export const createTag = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.TAG}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export const updateTag = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.TAG}/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export const getChannels = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.CHANNELMANAGER}?page=0&limit=20&paged=false&search_text=`);
    return response.data;
  } catch (error) {
    console.error('Error fetching channels:', error.message);
    throw new Error('Error fetching channels');
  }
};

export const createChannel = async (data) => {
  try {
    const response = await axios.post(MICROSERVICE_BASE_URL.CHANNELMANAGER, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateChannel = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.CHANNELMANAGER}/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export const deleteChannel = async (id) => {
  try {
    const response = await axios.delete(`${MICROSERVICE_BASE_URL.CHANNELMANAGER}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};



// ----------------------Cron----------------


export const getCrons = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.SRV_CRON}/setting`);
    return response.data;
  } catch (error) {
    console.error('Error fetching crons:', error.message);
    throw new Error('Error fetching crons');
  }
};


export const createCron = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_CRON}/setting`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


// ----------------------WebSiteBlock----------------

export const getWebSiteBlocks = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/webSite-block`);
    return response.data;
  } catch (error) {
    console.error('Error fetching website blocks:', error.message);
    throw new Error('Error fetching website blocks');
  }
}

export const createWebSiteBlock = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/webSite-block`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

// ----------------------Currency----------------
export function getCurrencies() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/currency?page=0&limit=20&paged=false&search_text=&forTranslate=false`);
}

export const createCurrency = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/currency`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export const updateCurrency = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/currency/${id}`, data);
    if (response && response.data) {
      return response.data;
    } else {
      throw new Error('Unexpected response structure from server');
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('An error occurred while updating the currency');
    }
  }
};

export const deleteCurrency = async (id) => {
  try {
    const response = await axios.delete(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/currency/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);

  }
}

// ----------------------Stories----------------

// export function getStories() {
//   return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/story?page=0&limit=20&paged=true`);
// }
export const getStories = async (page, limit, paged) => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/story?page=${page}&limit=${limit}&paged=${paged}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching stories:', error);
    throw error;
  }
};



export function createStory(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/story`, data)
    .then(response => response.data);
}

export function updateStory(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/story/${id}`, data)
    .then(response => response.data);
}

export function deleteStory(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/story/${id}`);
}


// ----------------------Synchroniser----------------

export function getSyncListings() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/sync-listings`);
}

export function getSyncAmenities() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/amenities/sync-amenities`);
}

export function getSyncPropertyTypes() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/propertyTypes/sync-property-types`);
}

export function getSyncBedTypes() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/BedTypes/sync-bed-types`);
}

export function getSyncAccessToken() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_CRON}/access-token`);
}

export function getSyncCalendar() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/calendar/sync-calendar`);
}

export function getSyncReservation() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/sync-reservations`);
}

export function getSyncMinutHomes() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/fetch-and-save-minut-homes`);
}
// ----------------------ChatInbox----------------


export function getChatInbox(page, limit) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/chat-inbox?page=${page}&limit=${limit}&paged=true`);
}

// ----------------------ExchangeRate----------------

export function getExchangeRates() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/exchange-rate`);
}

export function updateExchangeRate(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/exchange-rate/${id}`, data);
}

// ----------------------Stay More----------------
export const getStayMore = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/stay-more`);
    return response.data;
  } catch (error) {
    console.error('Error fetching stay more:', error.message);
    throw new Error('Error fetching stay more');
  }
}
export const updateStayMore = async (id, data) => {
  try {
    const response = await axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/stay-more/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error update stay more:', error.message);
    throw new Error('Error update stay more');
  }
}
// ----------------------WhatsAppConfig----------------

export function getWhatsappConfig() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/whatsapp-config`);
}



export function updateWhatsappConfig(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/whatsapp-config/${id}`, data);
}


// ----------------------useFulNumber----------------

export function getUsefulNumber() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/useful-number`);
}

export function updateUsefulNumber(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/useful-number`, data);
}



// ----------------------Cancellation Policy----------------

export function getCancellationPolicy() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/cancellation-policy/get`);
}

export function updateCancellationPolicy(policyId, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/cancellation-policy/update`, data);
}




// ----------------------Template----------------



export function getMailTemplate() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/mailTemplate`);
}

export function getReservation(limit = 1, page = 0) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations?page=${page}&limit=${limit}`);
}


export function getMailTemplateById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/mailTemplate/${id}`);
}

export function createMailTemplate(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/mailTemplate`, data);
}

export function updateMailTemplate(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/mailTemplate/${id}`, data);
}

export function deleteMailTemplate(id) {
  return axios.delete(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/mailTemplate/${id}`);
}


export function getConfigReservation() {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/config-reservation/get`);
}


export function updateConfigReservation(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/config-reservation/update`, data);
}


export function translateText(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/ai/translate-txt`, data);
}


export function sendMailTemplate(data) {
  return axios.post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/mailTemplate/send-mail-template`, data);
}



//________________________________________referrals_________________________________


export const getReferrals = async () => {
  try {
    const response = await axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/referral-code/get?paged=true&page=0&limit=50`);
    return response.data.data || []; 
  } catch (error) {
    console.error('Error fetching referrals:', error.message);
    throw new Error(error.response?.data?.message || 'Error fetching referrals');
  }
};

export const createReferral = async (data) => {
  try {
    const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/referral-code/add`, data);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error creating referral');
  }
};