import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WhatsAppChat from './components/componentsInfo/WhatsAppChat';
import Templates from './components/componentsInfo/templates/Templates';
import { useTranslation } from 'react-i18next';

import {
  getReservationsById,
  updateReservation,
} from '../services/serverApi.reservation';
import useReservationStatus from './components/updateReservation/useReservationStatus';

import GuestInfo from './components/componentsInfo/GuestInfo';
import MinutEvent from './components/componentsInfo/MinutEvent';
import Travellers from './components/componentsInfo/Travellers';
import ConfirmationModal from './components/confirmationModal/ConfirmationModal ';
import ButtonTab from './components/ButtonTab';
import Chat from './components/componentsInfo/Chat';
import Reviews from './components/componentsInfo/Reviews';
import useSocketConnection from './useSocketConnection';

export const tabOrder = ['guestInfo', 'travellers', 'messages', 'surveillance'];

export const tabIcons = {
  guestInfo: <PersonIcon className="!text-[18px] !mr-1" />,
  travellers: <GroupIcon className="!text-[18px] !mr-1" />,
  messages: <ChatIcon className="!text-[18px] !mr-1" />,
  surveillance: <NotificationsIcon className="!text-[18px] !mr-1" />,
};

const ReservationDetailsPage = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const navigate = useNavigate();
  const [reservationDetails, setReservationDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('guestInfo');
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedDetails, setUpdatedDetails] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const { socket } = useSocketConnection(id);

  const [messageType, setMessageType] = useState('chat');

  useEffect(() => {
    if (!socket) return;

    const handleMessage = (data) => {
      const message = data.payload?.msgPayload || data.payload || data;

      const newMsg = {
        id: message.id || `temp-${Date.now()}`,
        message: message.message,
        sender: message.sender,
        property_id: message.property_id,
        attachments: message.attachments || [],
        have_attachment: message.have_attachment || false,
        timestamp: new Date().toLocaleTimeString(),
      };

      handleNewMessage(newMsg);
    };

    socket.on('NEW_RECIVED_MSG', handleMessage);
    socket.on('NEW_SENDED_MSG', handleMessage);

    return () => {
      socket.off('NEW_RECIVED_MSG');
      socket.off('NEW_SENDED_MSG');
    };
  }, [socket]);

  useEffect(() => {
    if (reservationDetails?.messages) {
      setChatMessages(reservationDetails.messages);
    }
  }, [reservationDetails]);

  const handleNewMessage = (message) => {
    setChatMessages((prevMessages) => [...prevMessages, message]);
    setReservationDetails((prev) => ({
      ...prev,
      messages: [...(prev.messages || []), message],
    }));
  };

  const handleStatusChange = (newStatus) => {
    setReservationDetails((prev) => ({ ...prev, status: newStatus }));
  };
  const { isLoading, updateReservationStatus } = useReservationStatus(
    reservationDetails,
    handleStatusChange,
  );

  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

  useEffect(() => {
    fetchReservationDetails();
  }, [id, staging]);

  const fetchReservationDetails = async () => {
    try {
      const response = await getReservationsById(id, staging);
      setReservationDetails(response.reservation);
      setUpdatedDetails(response.reservation);
    } catch (error) {
      console.error('Error fetching details:', error);
      toast.error('Failed to fetch reservation details');
    }
  };

  const handleModalOpen = (action) => {
    setModalAction(action);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalConfirm = () => {
    updateReservationStatus(modalAction);
    setModalOpen(false);
  };

  const getStatusColor = (status) => {
    const statusColors = {
      Confirmed: 'bg-green-100 text-green-800',
      Pending: 'bg-yellow-100 text-yellow-800',
      CancelledByAdmin: 'bg-red-100 text-red-800',
      CancelledByCustomer: 'bg-red-100 text-red-800',
      CancelledByOta: 'bg-red-100 text-red-800',
      CancelledAfterFailedPayment: 'bg-red-100 text-red-800',
      OtherCancellation: 'bg-red-100 text-red-800',
      Completed: 'bg-blue-100 text-blue-800',
      CheckedIn: 'bg-purple-100 text-purple-800',
      CheckedOut: 'bg-gray-100 text-gray-800',
    };
    return statusColors[status] || 'bg-gray-100 text-gray-800';
  };

  const formatStatus = (status) => {
    return status
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split('By')[0]
      .trim();
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  const toggleEditMode = () => {
    setIsEditMode((prevMode) => !prevMode);
  };

  const handleDetailsUpdate = (details) => {
    setUpdatedDetails((prevDetails) => ({
      ...prevDetails,
      ...details,
      arrivalDate: formatDate(
        details.arrivalDate || reservationDetails.arrivalDate,
      ),
      departureDate: formatDate(
        details.departureDate || reservationDetails.departureDate,
      ),
      reservationDate: formatDate(
        details.reservationDate || reservationDetails.reservationDate,
      ),
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!reservationDetails._id) {
      console.error('Reservation ID is missing');
      toast.error('Unable to update: Reservation ID is missing');
      return;
    }

    setIsSaving(true);
    try {
      const updatedReservation = await updateReservation(
        reservationDetails._id,
        updatedDetails,
      );

      setReservationDetails((prevDetails) => ({
        ...prevDetails,
        ...updatedDetails,
      }));

      toast.success('Reservation updated successfully');
      setIsEditMode(false);
    } catch (error) {
      console.error('Error updating reservation:', error);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.response?.data?.errors?.[0]?.message ||
        error.message ||
        'Error updating reservation';
      toast.error(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const formattedDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  console.log('reservationDetails', reservationDetails);

  const Messages = ({
    chatMessages,
    reservationId,
    socket,
    addNewMessage,
    reviews,
    fetchReservationDetails,
    messageType,
    setMessageType,
    reservationInfo,
  }) => {
    return (
      <div className="w-full">
        <div className="my-4">
          <select
            value={messageType}
            onChange={(e) => setMessageType(e.target.value)}
            className="w-40 p-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00b4b4]"
          >
            <option value="whatsapp" className="text-sm">
              WhatsApp Chat
            </option>
            <option value="chat" className="text-sm">
              Chat Messages
            </option>
            <option value="reviews" className="text-sm">
              Reviews
            </option>
            <option value="templates" className="text-sm">
              Templates
            </option>
          </select>
        </div>

        {messageType === 'chat' ? (
          <Chat
            messages={chatMessages}
            reservationId={reservationId}
            socket={socket}
            addNewMessage={addNewMessage}
          />
        ) : messageType === 'whatsapp' ? (
          <WhatsAppChat reservationDetails={reservationInfo} />
        ) : messageType === 'templates' ? (
          <Templates reservationInfo={reservationInfo} />
        ) : (
          <Reviews
            reviews={reviews || []}
            onReplySubmitted={() => {
              fetchReservationDetails();
              setMessageType('reviews');
            }}
            reservationInfo={reservationInfo}
          />
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <Box
        sx={{ width: '100%', typography: 'body1' }}
        className="!card !p-2 !bg-white"
      >
        <ToastContainer position="top-right" autoClose={3000} />
        <div id="fixed_control_bar" className="w-full">
          <div className="flex justify-between items-center sm:p-4 p-2">
            {isEditMode ? (
              <h5>
                <div
                  className="flex items-center text-[#00b4b4] cursor-pointer"
                  onClick={toggleEditMode}
                >
                  <span className="sm:ml-2 ml-0 sm:text-[16px] text-[14px]">
                    <i className="pi pi-angle-left sm:!text-[16px] !text-[14px] font-bold"></i>{' '}
                    {t('back')}
                  </span>
                </div>
              </h5>
            ) : (
              <h5>
                <div
                  className="flex items-center text-[#00b4b4] cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <span className="sm:ml-2 ml-0 sm:text-[16px] text-[14px]">
                    <i className="pi pi-angle-left sm:!text-[16px] !text-[14px] font-bold"></i>
                    {t('Reservations')}
                  </span>
                </div>
              </h5>
            )}

            <div className="flex items-center sm:gap-2 gap-1">
              {isEditMode ? (
                <>
                  {isSaving ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <>
                      <button
                        type="button"
                        className="bg-[#00b4b4] !rounded sm:w-[70px] w-[60px]"
                        disabled={isSaving}
                        onClick={handleSave}
                      >
                        <span className="ladda-label sm:text-[15px] text-[13px] text-white sm:p-[2px] p-[1px] font-semibold">
                          {t('Save')}
                        </span>
                      </button>
                      <button
                        type="button"
                        className="bg-[#dcf6f6] !rounded sm:w-[70px] w-[60px]"
                        onClick={toggleEditMode}
                      >
                        <span className="ladda-label sm:text-[15px] text-[13px] text-[#00b4b4] sm:p-[2px] p-[1px] font-semibold">
                          {t('Cancel')}
                        </span>
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {reservationDetails &&
                    ![
                      'CancelledByAdmin',
                      'CancelledByCustomer',
                      'CancelledByOta',
                      'CancelledAfterFailedPayment',
                      'OtherCancellation',
                    ].includes(reservationDetails.status) && (
                      <button
                        type="button"
                        className="sm:px-1 px-[2px] sm:py-1 py-[2px] bg-[#00b4b4] !rounded"
                        onClick={toggleEditMode}
                      >
                        <span className="ladda-label flex items-center sm:text-[13px] text-[12px] text-white sm:p-[3px] p-[2px]">
                          <EditRoadIcon className="sm:!text-[18px] !text-[16px]" />
                        </span>
                      </button>
                    )}
                  <button
                    type="button"
                    className="sm:px-1 px-[2px] sm:py-1 py-[2px] bg-[#dcf6f6] !rounded"
                  >
                    <span className="ladda-label flex items-center sm:text-[13px] text-[12px] text-[#00b4b4] sm:p-[3px] p-[2px]">
                      <CalendarMonthIcon className="sm:!text-[18px] !text-[16px]" />
                    </span>
                  </button>
                  <button
                    type="button"
                    className="sm:px-1 px-[2px] sm:py-1 py-[2px] bg-[#dcf6f6] !rounded"
                  >
                    <span className="ladda-label flex items-center sm:text-[13px] text-[12px] text-[#00b4b4] sm:p-[3px] p-[2px]">
                      <PersonIcon className="sm:!text-[18px] !text-[16px]" />
                    </span>
                  </button>
                  <button
                    type="button"
                    className="sm:px-1 px-[2px] sm:py-1 py-[2px] bg-[#dcf6f6] !rounded"
                  >
                    <span className="ladda-label flex items-center sm:text-[13px] text-[12px] text-[#00b4b4] sm:p-[3px] p-[2px]">
                      <EmailIcon className="sm:!text-[18px] !text-[16px]" />
                    </span>
                  </button>
                  {reservationDetails &&
                    ![
                      'CancelledByAdmin',
                      'CancelledByCustomer',
                      'CancelledByOta',
                      'CancelledAfterFailedPayment',
                      'OtherCancellation',
                    ].includes(reservationDetails.status) && (
                      <button
                        type="button"
                        className="sm:px-2 px-1 sm:py-1 py-[2px] bg-red-500 !rounded"
                        onClick={() => handleModalOpen('cancel')}
                        disabled={isLoading}
                      >
                        <span className="ladda-label flex items-center sm:text-[15px] text-[12px] text-white sm:p-[2px] p-[1px] font-semibold">
                          {isLoading ? (
                            'Processing...'
                          ) : (
                            <span className="sm:block hidden">
                              {t('Cancel Reservation')}
                            </span>
                          )}
                          {isLoading ? (
                            'Processing...'
                          ) : (
                            <span className="sm:hidden block">
                              {t('Cancel')}
                            </span>
                          )}
                        </span>
                      </button>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </Box>

      <Box
        sx={{ width: '100%', typography: 'body1', height: '100%' }}
        className="!card !p-2 !bg-white"
      >
        {reservationDetails && (
          <Box className="grid sm:grid-cols-3 grid-cols-1 sm:gap-4 gap-2 w-full border-b-1 border-gray-300 sm:p-0 p-2">
            <div>
              <span className="text-sm font-semibold">
                {t('Reservation Details')}
              </span>
            </div>
            <div className="sm:text-left text-center">
              <span className="sm:text-md text-sm break-words">
                {reservationDetails.guestName} -{' '}
                {formattedDate(reservationDetails.arrivalDate)}
              </span>
            </div>
            <div className="flex items-center sm:justify-end justify-center sm:mt-0 mt-2">
              <span
                className={`px-3 py-1 rounded-full sm:text-sm text-xs font-semibold ${getStatusColor(
                  reservationDetails.status,
                )}`}
              >
                {formatStatus(t(reservationDetails.status))}
              </span>
            </div>
          </Box>
        )}

        <Box className="w-full h-full sm:mt-6 mt-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-1 sm:gap-2 mt-3 sm:px-0 px-2">
            {tabOrder.map((tabName) => (
              <ButtonTab
                key={tabName}
                // name={t(tabName.replace(/([A-Z])/g, ' $1').trim())}
                name={t(tabName)}
                icon={tabIcons[tabName]}
                isActive={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
                hasError={false}
              />
            ))}
          </div>

          <div className="sm:px-4 px-2">
            {reservationDetails ? (
              <>
                {activeTab === 'guestInfo' || activeTab === 'travellers' ? (
                  <form onSubmit={handleSave}>
                    {activeTab === 'guestInfo' && (
                      <GuestInfo
                        reservationDetails={reservationDetails}
                        isEditMode={isEditMode}
                        onDetailsUpdate={handleDetailsUpdate}
                      />
                    )}
                    {activeTab === 'travellers' && (
                      <Travellers
                        reservationDetails={reservationDetails}
                        isEditMode={isEditMode}
                        onDetailsUpdate={handleDetailsUpdate}
                      />
                    )}
                  </form>
                ) : (
                  // Messages and Surveillance outside form
                  <>
                    {activeTab === 'messages' && (
                      <Messages
                        chatMessages={chatMessages}
                        reservationId={reservationDetails?._id}
                        socket={socket}
                        addNewMessage={handleNewMessage}
                        reviews={reservationDetails?.review}
                        fetchReservationDetails={fetchReservationDetails}
                        messageType={messageType}
                        setMessageType={setMessageType}
                        reservationInfo={reservationDetails}
                      />
                    )}
                    {activeTab === 'surveillance' && (
                      <MinutEvent
                        minutEvent={reservationDetails?.minutEvent || []}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <CircularProgress style={{ color: '#66cdaa' }} />
            )}
          </div>
        </Box>
      </Box>

      <ConfirmationModal
        open={modalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        title={
          modalAction === 'cancel'
            ? 'Cancel Reservation'
            : 'Revert Cancellation'
        }
        message={
          modalAction === 'cancel'
            ? 'Are you sure you want to cancel this reservation? The status will be changed to "CancelledByAdmin".'
            : 'Are you sure you want to revert the cancellation? The status will be changed to "Pending".'
        }
      />
    </DashboardLayout>
  );
};

export default ReservationDetailsPage;
