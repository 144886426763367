import React, { useEffect, useState } from 'react';
import { getLanguages } from 'features/reservation/services/serverApi.reservation';
import { Typography } from '@mui/material';
import GuestCountInput from '../GuestCountInput';
import { useTranslation } from 'react-i18next';

const GuestDetails = ({ editableDetails, isEditMode, handleInputChange }) => {
  const { t } = useTranslation('common');
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    const firstName = editableDetails.guestFirstName || '';
    const lastName = editableDetails.guestLastName || '';
    const fullName = `${firstName} ${lastName}`.trim();
    handleInputChange({ target: { name: 'guestName', value: fullName } });
  }, [editableDetails.guestFirstName, editableDetails.guestLastName]);

  const fetchLanguages = async () => {
    try {
      const languagesData = await getLanguages();
      setLanguages(languagesData);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  };

  const renderInputField = (label, name, type = 'text') => (
    <div className="flex flex-col">
      <label className="font-bold text-[#676a6c] text-sm mb-2">
        {t(label)}
      </label>
      <input
        type={type}
        name={name}
        className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
        value={editableDetails[name]}
        readOnly={!isEditMode}
        onChange={handleInputChange}
        style={{
          backgroundColor: isEditMode ? 'white' : '#eee',
          border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
          fontSize: 'small',
        }}
      />
    </div>
  );

  const renderSelectField = (label, name, options) => (
    <div className="flex flex-col">
      <label className="font-bold text-[#676a6c] text-sm mb-2">
        {t(label)}
      </label>
      <select
        name={name}
        onChange={handleInputChange}
        value={editableDetails[name]}
        disabled={!isEditMode}
        className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
        style={{
          backgroundColor: isEditMode ? 'white' : '#eee',
          border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
          fontSize: 'small',
        }}
      >
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );

  const RegistrationView = ({ registeredGuests, totalGuests, t }) => {
    return (
      <div className="space-y-1">
        <div className="font-bold text-[#676a6c] text-sm mb-2">
          {t('Registered')}
        </div>
        <div className="text-lg border border-gray-200 rounded px-3 py-1 bg-gray-200 text-center">
          {registeredGuests}/{totalGuests}
        </div>
      </div>
    );
  };

  const checkinStatusOptions = [
    { _id: '', name: t('Not Started') },
    { _id: 'START', name: t('START') },
    { _id: 'END', name: t('END') },
  ];

  return (
    <>
      <Typography
        variant="body1"
        className="mb-4 !font-bold !text-lg text-[#676a6c]"
      >
        {editableDetails.reservationNumber
          ? `${t('Reservation Number')}: ${editableDetails.reservationNumber}`
          : 'No reservation number available.'}
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderInputField('First Name', 'guestFirstName')}
        {renderInputField('Last Name', 'guestLastName')}
        {renderInputField('Email', 'guestEmail', 'email')}
        {renderInputField('phone', 'phone')}
        <GuestCountInput
          value={editableDetails.numberOfGuests}
          onChange={handleInputChange}
          isEditMode={isEditMode}
          editableDetails={editableDetails}
          t={t}
        />
        <RegistrationView
          registeredGuests={
            editableDetails.guestRegistration?.nbre_guest_registered
          }
          totalGuests={
            editableDetails.guestRegistration?.nbre_guest_to_register
          }
          t={t}
        />
        {renderSelectField(
          'Checkin Status',
          'checkinStatus',
          checkinStatusOptions,
        )}
        {renderInputField('Guest Language', 'guestLanguage')}
        {/* {renderSelectField('Guest Language', 'guestLanguage', languages)} */}
      </div>
    </>
  );
};

export default GuestDetails;
