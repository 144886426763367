import React, { useState, useRef, useEffect } from 'react';
import {
  Send as SendIcon,
  AttachFile as AttachmentIcon
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  TextField,
  InputAdornment,
  Typography
} from '@mui/material';
import { sendMessage } from 'features/reservation/services/serverApi.reservation';
import { toast } from 'react-toastify';

const Chat = ({
  messages,
  reservationId,
  socket,
  addNewMessage,
  chatTitle = "Reservation Chat"
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [localMessages, setLocalMessages] = useState(messages || []);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    setLocalMessages(messages || []);
  }, [messages]);

  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (data) => {
      console.log('Chat Component - New Message Received:', {
        data,
        reservationId,
        messageDetails: {
          bookingId: data.booking_id,
        }
      });
    };

    socket.on('NEW_RECIVED_MSG', handleNewMessage);
    socket.on('NEW_SENDED_MSG', handleNewMessage);

    return () => {
      socket.off('NEW_RECIVED_MSG', handleNewMessage);
      socket.off('NEW_SENDED_MSG', handleNewMessage);
    };
  }, [socket, reservationId, addNewMessage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || isLoading) return;

    try {
      setIsLoading(true);
      const messageData = {
        message: newMessage,
        reservationId: reservationId
      };

      await sendMessage(messageData);
      setNewMessage('');
      scrollToBottom();
    } catch (error) {
      console.error('Failed to send message:', error);
      const errorMessage = error.response?.data?.message || 'Failed to send message';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };



  const renderMessageBubble = (msg, index) => {
    const isGuest = msg.sender === 'guest';
    const isProperty = msg.sender === 'property';

    return (
      <div key={index} className="w-full clear-both mb-4">
        <div
          className={`max-w-[80%] rounded-lg p-3 my-2 ${isGuest ? 'mr-auto bg-gray-50 float-left' : 'ml-auto  bg-blue-50 float-right'}`}
        >
          <Typography variant="body2" className="whitespace-pre-line mb-2">
            {msg.message}
          </Typography>
          {msg.have_attachment && (
            <Typography variant="caption" color="textSecondary" className="block mb-1">
              Attachment
            </Typography>
          )}
          <Typography variant="caption" color="textSecondary">
            {msg.date && formatDate(msg.date)}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[650px] bg-white shadow-md rounded-lg overflow-hidden mt-4">
      <div className="p-4 bg-[#00b4b4] text-white flex items-center">
        <div className="flex-grow">
          <Typography variant="h6" className="font-bold text-white">
            {chatTitle}
          </Typography>
        </div>
      </div>

      <div
        className="flex-grow overflow-y-auto p-4 space-y-2"
        style={{
          background: 'linear-gradient(to bottom, #f0f0f0 0%, #ffffff 100%)'
        }}
      >
        {localMessages.map(renderMessageBubble)}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 bg-white border-t">
        <div className="flex gap-2">
          <div className="flex-1 relative">
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={isLoading}
              rows={3}
              placeholder="Type a message..."
              className="w-full px-3 py-2 border border-[#00b4b4] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00b4b4] disabled:opacity-50 resize-none"
            />
            <button
              onClick={handleSendMessage}
              disabled={!newMessage.trim() || isLoading}
              className="absolute bottom-2 right-2"
            >
              <SendIcon 
                className={
                  newMessage.trim() && !isLoading
                    ? 'text-[#00b4b4]'
                    : 'text-gray-400'
                }
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;