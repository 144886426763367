import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
const InvoiceTable = ({ roomDetails, reservationDetails, t }) => {
  const rows = [
    { label: 'Total Nightly Rate', value: roomDetails?.totalDailyPrice },
    { label: 'Taxes Fees', value: roomDetails?.totalTaxNotInclusive },
    { label: 'Cleaning Fees', value: roomDetails?.totalServicesNotInclusive },
  ];

  return (
    <TableContainer component={Paper} sx={{ mt: 3, p: 1 }}>
      {/* <h5 className="text-base font-bold mb-2 text-[#676a6c]">
        Invoices & Charges
      </h5> */}

      <Table size="small">
        <TableHead>
          <TableRow>
            {['Description', 'Amount', 'Status'].map((header) => (
              <TableCell
                key={header}
                sx={{ fontWeight: 'bold', color: '#676a6c' }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map(({ label, value }) => (
            <TableRow key={label}>
              <TableCell>{t(label)}</TableCell>
              <TableCell>
                {Number(value).toFixed(2)} {reservationDetails.currency}
              </TableCell>
              <TableCell>
                <strong>{t('PAID')}</strong>
              </TableCell>
            </TableRow>
          ))}

          <TableRow sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
            <TableCell>
              <strong>{t('Total Amount Paid By Customer')}</strong>
            </TableCell>
            <TableCell colSpan={2}>
              {Number(
                roomDetails?.channexAmount?.toString().replace(/./g, ''),
              ) ==
              Number(roomDetails?.sojoriAmount?.toString().replace(/./g, ''))
                ? `${Number(roomDetails?.channexAmount).toFixed(2)} ${
                    reservationDetails.currency
                  }`
                : `${Number(roomDetails?.channexAmount).toFixed(2)} (${Number(
                    roomDetails?.sojoriAmount,
                  ).toFixed(2)}) ${reservationDetails.currency}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default InvoiceTable;
