import React from 'react';
import {
  Card,
  Typography,
  Box,
  Alert,
  Button,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import logoSojori from "assets/images/sojori-logo.png";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1000,
  width: '100%',
  display: 'flex',
  borderRadius: '20px',
  backgroundColor: '#fff',
  boxShadow: '0 8px 40px rgba(0,0,0,0.12)',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

const LeftSection = styled(Box)(({ theme }) => ({
  width: '45%',
  backgroundColor: '#FFF5F1',
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(4)
  }
}));

const RightSection = styled(Box)(({ theme }) => ({
  width: '55%',
  padding: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(4)
  }
}));

const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 700,
  width: '100%',
  borderRadius: '20px',
  backgroundColor: '#fff',
  boxShadow: '0 8px 40px rgba(0,0,0,0.12)',
  overflow: 'hidden',
}));

const CenteredSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4)
  }
}));

const AuthFormLayout = ({
  children,
  variant = 'split',
  title = 'Welcome to Sojori',
  subtitle = 'Your trusted platform for managing all your reservations',
  formTitle = 'Sign in',
  formSubtitle = 'Enter your credentials to continue',
  buttonText = 'Sign in',
  loading = false,
  error = null,
  successMessage = null,
  onSubmit,
}) => {
  if (variant === 'centered') {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
          p: 3
        }}
      >
        <CenteredCard 
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <CenteredSection>
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              style={{ marginBottom: '2rem' }}
            >
              <img src={logoSojori} alt="Sojori" width="120" />
            </motion.div>
            
            <Typography variant="h4" sx={{ color: '#FF5722', fontWeight: 700, mb: 2, textAlign: 'center' }}>
              {title}
            </Typography>
            
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mb: 4 }}>
              {subtitle}
            </Typography>

            {successMessage ? (
              <Alert severity="success" sx={{ borderRadius: 2, mb: 3, width: '100%', maxWidth: 400 }}>
                {successMessage}
              </Alert>
            ) : (
              <form onSubmit={onSubmit} style={{ width: '100%', maxWidth: 400 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {error && (
                    <Alert severity="error" sx={{ borderRadius: 2 }}>
                      {error}
                    </Alert>
                  )}

                  {children}

                  <Button
                    component={motion.button}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    fullWidth
                    sx={{
                      mt: 2,
                      py: 1.8,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      fontWeight: '600',
                      bgcolor: '#FF5722',
                      '&:hover': {
                        bgcolor: '#F4511E'
                      }
                    }}
                  >
                    {loading ? `${buttonText}...` : buttonText}
                  </Button>
                </Box>
              </form>
            )}
          </CenteredSection>
        </CenteredCard>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        p: 3
      }}
    >
      <StyledCard 
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <LeftSection>
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img src={logoSojori} alt="Sojori" width="150" style={{ marginBottom: '2rem' }} />
          </motion.div>
          <Typography variant="h4" sx={{ color: '#FF5722', fontWeight: 700, mb: 2, textAlign: 'center' }}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mb: 4 }}>
            {subtitle}
          </Typography>
          <Box sx={{ width: '100%', maxWidth: 300 }}>
            <motion.div
              animate={{
                y: [0, 10, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            >
            </motion.div>
          </Box>
        </LeftSection>

        <RightSection>
          <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
            <Typography variant="h5" fontWeight="700" sx={{ mb: 1 }}>
              {formTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
              {formSubtitle}
            </Typography>

            <form onSubmit={onSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {error && (
                  <Alert severity="error" sx={{ borderRadius: 2 }}>
                    {error}
                  </Alert>
                )}
                
                {successMessage && (
                  <Alert severity="success" sx={{ borderRadius: 2 }}>
                    {successMessage}
                  </Alert>
                )}

                {children}

                <Button
                  component={motion.button}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={loading}
                  fullWidth
                  sx={{
                    mt: 2,
                    py: 1.8,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    fontWeight: '600',
                    bgcolor: '#FF5722',
                    '&:hover': {
                      bgcolor: '#F4511E'
                    }
                  }}
                >
                  {loading ? `${buttonText}...` : buttonText}
                </Button>
              </Box>
            </form>
          </Box>
        </RightSection>
      </StyledCard>
    </Box>
  );
};

export default AuthFormLayout;