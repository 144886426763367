import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { X, User } from 'lucide-react';
import { inviteWorker, getOwners } from '../services/serverApi.task';
import { hasAdminAccess } from 'utils/rbac.utils';
import RoleBasedRenderer from 'components/wrappers/RoleBasedRenderer';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    whatsapp: Yup.string(),
    ownerId: Yup.string().required('Owner is required'),
});

const CreateWorkerDialog = ({ open, onClose, onWorkerCreated }) => {
    const [owners, setOwners] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const isAdmin = hasAdminAccess(user?.role);

    useEffect(() => {
        if (open && isAdmin) {
            fetchOwners();
        }
    }, [open, isAdmin]);

    const fetchOwners = async () => {
        setIsLoading(true);
        try {
            const response = await getOwners({ limit: 100 });
            if (response && response.data) {
                setOwners(response.data);
            }
        } catch (error) {
            console.error('Error fetching owners:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const response = await inviteWorker({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                whatsapp: values.whatsapp,
                ownerId: values.ownerId
            });

            if (response.data) {
                onWorkerCreated(response.data);
                onClose();
            }
        } catch (error) {
            console.error('Error inviting worker:', error);
            setErrors({ submit: error.response?.data?.message || 'Failed to invite worker' });
        } finally {
            setSubmitting(false);
        }
    };

    // Set initial ownerId based on the user's role
    const initialOwnerId = isAdmin ? '' : user?._id || '';

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="bg-medium-aquamarine flex justify-between items-center">
                <Typography variant="h6" className="text-white flex items-center gap-2">
                    <User className="w-5 h-5" />
                    Invite Worker
                </Typography>
                <IconButton onClick={onClose} className="text-white">
                    <X className="w-5 h-5" />
                </IconButton>
            </DialogTitle>

            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    whatsapp: '',
                    ownerId: initialOwnerId
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                    <Form>
                        <DialogContent className="!space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    className="col-span-1"
                                />

                                <TextField
                                    fullWidth
                                    name="lastName"
                                    label="Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    className="col-span-1"
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    className="col-span-1"
                                />

                                <TextField
                                    fullWidth
                                    name="phone"
                                    label="Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                    className="col-span-1"
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    fullWidth
                                    name="whatsapp"
                                    label="Whatsapp"
                                    value={values.whatsapp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.whatsapp && Boolean(errors.whatsapp)}
                                    helperText={touched.whatsapp && errors.whatsapp}
                                    className="col-span-1"
                                />
                                
                                <RoleBasedRenderer adminOnly>
                                    <FormControl 
                                        fullWidth 
                                        error={touched.ownerId && Boolean(errors.ownerId)}
                                        className="col-span-1"
                                    >
                                        <InputLabel id="owner-select-label">Owner</InputLabel>
                                        <Select
                                            labelId="owner-select-label"
                                            id="owner-select"
                                            name="ownerId"
                                            value={values.ownerId}
                                            label="Owner"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isLoading}
                                        >
                                            {owners.map((owner) => (
                                                <MenuItem key={owner._id} value={owner._id}>
                                                    {owner.firstName} {owner.lastName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.ownerId && errors.ownerId && (
                                            <FormHelperText>{errors.ownerId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </RoleBasedRenderer>

                                {!isAdmin && (
                                    <input
                                        type="hidden"
                                        name="ownerId"
                                        value={values.ownerId}
                                    />
                                )}
                            </div>
                        </DialogContent>

                        <DialogActions className="p-4">
                            <Button onClick={onClose} variant="outlined" className="!text-gray-500 !border-gray-500">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                className="!bg-medium-aquamarine !text-white"
                            >
                                Invite
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CreateWorkerDialog;