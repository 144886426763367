import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Select,
} from '@mui/material';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { User, X, Plus, Trash2 } from 'lucide-react';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  options: Yup.array().of(
    Yup.object().shape({
      pickup: Yup.string().required('Pickup is required'),
      city: Yup.string().required('City is required'),
      price: Yup.number()
        .typeError('Price must be a number')
        .required('Price is required'),
    }),
  ),
});

const ModifyClientWhiteList = ({
  open,
  handleClose,
  conciergeType,
  functionToExecute,
  city,
  headline,
}) => {
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await functionToExecute(conciergeType?._id, values);
      handleClose();
    } catch (error) {
      console.error('Error updating conciergeType:', error);
      if (error.response?.data?.errors) {
        const serverErrors = {};
        error.response.data.errors.forEach((err) => {
          serverErrors[err.path[0]] = err.message;
        });
        setErrors(serverErrors);
        toast.error(serverErrors[Object.keys(serverErrors)[0]]);
      } else {
        toast.error('Error updating Client');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle className="bg-medium-aquamarine flex justify-between items-center">
        <Typography variant="h6" className="text-white flex items-center gap-2">
          <User className="w-5 h-5" />
          {headline}
        </Typography>
        <IconButton onClick={handleClose} className="text-white">
          <X className="w-5 h-5" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="pt-6">
        <Formik
          initialValues={{
            type: conciergeType?.type || '',
            options: conciergeType?.options || [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="space-y-4">
              <Box className="flex flex-col gap-4 mt-4">
                <Field
                  as={TextField}
                  fullWidth
                  name="type"
                  label="Type"
                  variant="outlined"
                />
                <ErrorMessage
                  name="type"
                  component={Typography}
                  className="text-red-500 !text-xs"
                />

                <FieldArray name="options">
                  {({ push, remove }) => (
                    <Box className="space-y-4">
                      {values.options.map((option, index) => (
                        <Box
                          key={index}
                          className="flex gap-2 items-center border-b pb-2"
                        >
                          <Field
                            as={TextField}
                            name={`options.${index}.pickup`}
                            label="Pickup"
                            variant="outlined"
                            fullWidth
                          />
                          <ErrorMessage
                            name={`options.${index}.pickup`}
                            component="div"
                            className="text-red-500 text-xs"
                          />

                          <Field name={`options.${index}.city`}>
                            {({ field }) => (
                              <Select
                                {...field}
                                fullWidth
                                variant="outlined"
                                displayEmpty
                                onChange={(e) =>
                                  setFieldValue(
                                    `options.${index}.city`,
                                    e.target.value,
                                  )
                                }
                              >
                                <MenuItem value="" disabled>
                                  Select City
                                </MenuItem>
                                {(city || []).map((c) => (
                                  <MenuItem key={c._id} value={c.name}>
                                    {c.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </Field>
                          <ErrorMessage
                            name={`options.${index}.city`}
                            component="div"
                            className="text-red-500 text-xs"
                          />

                          <Field
                            as={TextField}
                            name={`options.${index}.price`}
                            label="Price"
                            variant="outlined"
                            fullWidth
                            type="number"
                          />
                          <ErrorMessage
                            name={`options.${index}.price`}
                            component="div"
                            className="text-red-500 text-xs"
                          />

                          <IconButton
                            onClick={() => remove(index)}
                            className="text-red-500"
                          >
                            <Trash2 className="w-5 h-5" />
                          </IconButton>
                        </Box>
                      ))}

                      <Button
                        onClick={() => push({ pickup: '', city: '', price: 0 })}
                        className="text-white !bg-medium-aquamarine !hover:bg-medium-aquamarine/90"
                        startIcon={<Plus />}
                      >
                        Add Option
                      </Button>
                    </Box>
                  )}
                </FieldArray>
              </Box>

              <DialogActions>
                <Button
                  className="!text-red-500"
                  onClick={handleClose}
                  variant="outlined"
                  color="error"
                  startIcon={<X className="w-4 h-4" />}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="text-white !bg-medium-aquamarine !hover:bg-medium-aquamarine/90"
                  variant="contained"
                  startIcon={<User className="w-4 h-4" />}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyClientWhiteList;
