import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import VerificationItems from '../VerificationItems';
import { getLanguage } from 'features/listing/services/serverApi.listing';

const CleaningTab = ({ formik }) => {
    const [editingRows, setEditingRows] = useState({ frequency: null });
    const [expandedTables, setExpandedTables] = useState({ frequency: true });
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        fetchLanguages();
    }, []);

    const fetchLanguages = async () => {
        try {
            const response = await getLanguage();
            setLanguages(response.data);
        } catch (error) {
            console.error('Error while fetching languages:', error);
        }
    };

    const handleAddSlot = () => {
        const newSlot = { 
            id: formik.values.frequency?.length || 0, 
            startDay: '', 
            endDay: '', 
            numberOfCleaning: 0, 
            isNew: true 
        };
        const updatedSlots = [...(formik.values.frequency || []), newSlot];
        formik.setFieldValue('frequency', updatedSlots);
        setEditingRows(prev => ({ ...prev, frequency: newSlot }));
        setExpandedTables(prev => ({ ...prev, frequency: true }));
    };

    const handleStartEditing = (rowData) => {
        setEditingRows(prev => ({ ...prev, frequency: { ...rowData } }));
    };

    const handleCancelEditing = () => {
        if (editingRows.frequency?.isNew) {
            const updatedSlots = formik.values.frequency.filter(
                (_, index) => index !== editingRows.frequency.id
            );
            formik.setFieldValue('frequency', updatedSlots);
        }
        setEditingRows(prev => ({ ...prev, frequency: null }));
    };

    const handleSaveNewSlot = () => {
        const updatedSlot = {
            startDay: Number(editingRows.frequency.startDay),
            endDay: Number(editingRows.frequency.endDay),
            numberOfCleaning: Number(editingRows.frequency.numberOfCleaning)
        };
        const updatedSlots = formik.values.frequency.map((slot, index) =>
            index === editingRows.frequency.id ? updatedSlot : slot
        );
        formik.setFieldValue('frequency', updatedSlots);
        setEditingRows(prev => ({ ...prev, frequency: null }));
    };

    const handleUpdateRow = () => {
        const updatedSlot = {
            ...editingRows.frequency,
            startDay: Number(editingRows.frequency.startDay),
            endDay: Number(editingRows.frequency.endDay),
            numberOfCleaning: Number(editingRows.frequency.numberOfCleaning)
        };
        const updatedSlots = formik.values.frequency.map((slot, index) =>
            index === editingRows.frequency.id ? updatedSlot : slot
        );
        formik.setFieldValue('frequency', updatedSlots);
        setEditingRows(prev => ({ ...prev, frequency: null }));
    };

    const handleDelete = () => {
        const updatedSlots = formik.values.frequency.filter(
            (_, index) => index !== selectedSlot.id
        );
        formik.setFieldValue('frequency', updatedSlots);
        setSelectedSlot(null);
    };

    const handleEditingInputChange = (e) => {
        const { name, value } = e.target;
        setEditingRows(prev => ({ 
            ...prev, 
            frequency: { ...prev.frequency, [name]: value } 
        }));
        formik.setFieldValue(
            `frequency.${editingRows.frequency.id}.${name}`, 
            value
        );
        formik.validateField(`frequency.${editingRows.frequency.id}.${name}`);
    };

    const toggleTable = () => {
        setExpandedTables(prev => ({ 
            ...prev, 
            frequency: !prev.frequency 
        }));
    };

    const columns = [
        {
            header: "Start Day",
            body: (rowData) => (
                editingRows.frequency?.id === rowData.id ? (
                    <TextField
                        type="number"
                        name="startDay"
                        value={editingRows.frequency.startDay}
                        onChange={handleEditingInputChange}
                        fullWidth
                        error={Boolean(formik.errors.frequency?.[rowData.id]?.startDay)}
                        helperText={formik.errors.frequency?.[rowData.id]?.startDay}
                    />
                ) : (
                    <span onDoubleClick={() => handleStartEditing(rowData)}>
                        {rowData.startDay}
                    </span>
                )
            ),
        },
        {
            header: "End Day",
            body: (rowData) => (
                editingRows.frequency?.id === rowData.id ? (
                    <TextField
                        type="number"
                        name="endDay"
                        value={editingRows.frequency.endDay}
                        onChange={handleEditingInputChange}
                        fullWidth
                        error={Boolean(formik.errors.frequency?.[rowData.id]?.endDay)}
                        helperText={formik.errors.frequency?.[rowData.id]?.endDay}
                    />
                ) : (
                    <span onDoubleClick={() => handleStartEditing(rowData)}>
                        {rowData.endDay}
                    </span>
                )
            ),
        },
        {
            header: "Number of Cleaning",
            body: (rowData) => (
                editingRows.frequency?.id === rowData.id ? (
                    <TextField
                        type="number"
                        name="numberOfCleaning"
                        value={editingRows.frequency.numberOfCleaning}
                        onChange={handleEditingInputChange}
                        fullWidth
                        error={Boolean(formik.errors.frequency?.[rowData.id]?.numberOfCleaning)}
                        helperText={formik.errors.frequency?.[rowData.id]?.numberOfCleaning}
                    />
                ) : (
                    <span onDoubleClick={() => handleStartEditing(rowData)}>
                        {rowData.numberOfCleaning}
                    </span>
                )
            ),
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    {editingRows.frequency?.id === rowData.id ? (
                        <>
                            {rowData.isNew ? (
                                <button 
                                    type='button' 
                                    className="px-2 py-1 bg-[#6ad1d1] rounded-sm"
                                    onClick={handleSaveNewSlot}
                                >
                                    <span className="text-[13px] text-white">Save</span>
                                </button>
                            ) : (
                                <button 
                                    type='button' 
                                    className="px-2 py-1 bg-[#6ad1d1] rounded-sm"
                                    onClick={handleUpdateRow}
                                >
                                    <span className="text-[13px] text-white">Save</span>
                                </button>
                            )}
                            <button 
                                type='button' 
                                className="px-2 py-1 bg-gray-400 rounded-sm"
                                onClick={handleCancelEditing}
                            >
                                <span className="text-[13px] text-white">Cancel</span>
                            </button>
                        </>
                    ) : (
                        <>
                            <button 
                                type='button' 
                                className="px-2 py-1 bg-[#6ad1d1] rounded-sm"
                                onClick={() => handleStartEditing(rowData)}
                            >
                                <span className="text-[13px] text-white">Update</span>
                            </button>
                            <button 
                                type='button' 
                                className="px-2 py-1 bg-[#df5454] rounded-sm"
                                onClick={() => setSelectedSlot(rowData)}
                            >
                                <span className="text-[13px] text-white">Delete</span>
                            </button>
                        </>
                    )}
                </div>
            ),
        },
    ];

    return (
        <Box sx={{ width: '100%' }}>
            <VerificationItems formik={formik} languages={languages} />
            
            <div className="mb-8">
                <div className="flex items-center my-2 w-full">
                    {expandedTables.frequency ? 
                        <KeyboardArrowUpIcon /> : 
                        <KeyboardArrowDownIcon />
                    }
                    <span 
                        className="text-sm font-semibold ml-2 cursor-pointer" 
                        onClick={toggleTable}
                    >
                        Cleaning
                    </span>
                    <button 
                        type="button" 
                        className='px-2 py-[10px] mx-1 bg-slate-200 !rounded-md'
                        onClick={handleAddSlot}
                    >
                        <AddIcon className='!text-sm' />
                    </button>
                </div>

                <div
                    id="frequency-table"
                    className={`${expandedTables.frequency ? 'block' : 'hidden'}`}
                >
                    {formik.values.frequency?.length > 0 ? (
                        <div className="w-full">
                            <GlobalTable
                                data={formik.values.frequency.map((slot, index) => ({ 
                                    ...slot, 
                                    id: index 
                                }))}
                                columns={columns}
                                hasPagination={false}
                            />
                        </div>
                    ) : (
                        <div className="w-full h-48 flex justify-center items-center">
                            <span className="text-gray-500 text-[20px]">
                                No frequency data added yet
                            </span>
                        </div>
                    )}
                </div>
            </div>

            {selectedSlot && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999]">
                    <div className="bg-white p-4 rounded-sm w-[550px]">
                        <Typography className="!text-[14px] !text-center mb-4">
                            Are you sure you want to delete this frequency slot?
                        </Typography>
                        <div className="flex justify-end gap-2">
                            <button
                                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm"
                                onClick={() => setSelectedSlot(null)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-3 py-1 bg-red-500 text-white !rounded-sm"
                                onClick={handleDelete}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Box>
    );
};

export default CleaningTab;