import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getRatePlan(cityId, staging = false) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan?cityId=${cityId}&staging=${staging}`);
}


export function getCities() {
    return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=0&limit=10&search_text&paged=false`);
}

export function getCountries() {
    return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}?page=0&limit=10&search_text&paged=false`);
}


export function updateRatePlan(ratePlanId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/update/${ratePlanId}`, data);
}


export function createRatePlan(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/create`, data);
}



export function getListingsWithRatePlans(params = {}) {
    const queryParams = new URLSearchParams(params).toString();
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/listings-with-rate-plans?${queryParams}`);
}



// export function deleteRatePlan(ratePlanId) {
//     return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/delete/${ratePlanId}`);
// }



export function updateUseDynamicPrice(listingId, useDynamicPrice) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/update-use-dynamic-price/${listingId}`, {
        useDynamicPrice
    });
}

export function updateUseDynamicPriceByCity(cityId, useDynamicPrice) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/update-use-dynamic-price-by-city`, {
        cityId,
        useDynamicPrice
    });
}


export function getListingsList(staging = false) {
    return axios
        .get(`${MICROSERVICE_BASE_URL.LISTING}/?staging=${staging}`)
        .then((response) => {
            if (!response.data.success) {
                throw new Error(response.data.message);
            }
            return response.data.data.map((listing) => ({
                id: listing._id, // Add this as a unique identifier for the key prop
                _id: listing._id,  
                name: listing.name,
                // Ensure name is not null or undefined
                displayName: listing.name || `Listing ${listing._id}`,
                checkInTime: listing.checkInTimeStart,
                checkOutTime: listing.checkOutTime,
                propertyUnit: listing.propertyUnit,
                roomTypes: listing.roomTypes
            }));
        });
}
