import React, { useState, useEffect } from 'react';
import { getCurrencies, getLanguages, updateCurrency, deleteCurrency } from '../services/serverApi.adminConfig';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import CurrencyModal from './CurrencyModal';
import { toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Typography, CircularProgress, Button, Switch } from '@mui/material';

function Currencies() {
  const [isLoading, setIsLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCurrencies();
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getLanguages();
      setLanguages(response);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await getCurrencies();
      setCurrencies(response.data);
    } catch (error) {
      console.error('Error fetching currencies:', error);
      setError('Failed to fetch currencies. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = (currency = null) => {
    setSelectedCurrency(currency);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCurrency(null);
    setModalOpen(false);
  };

  const handleDeleteCurrency = async (currencyId) => {
    if (window.confirm('Are you sure you want to delete this currency?')) {
      try {
        await deleteCurrency(currencyId);
        setCurrencies(currencies.filter(currency => currency._id !== currencyId));
        toast.success('Currency deleted successfully');
      } catch (error) {
        console.error('Error deleting currency:', error);
        toast.error('Failed to delete currency');
      }
    }
  };

  const handleSwitchChange = async (rowData, field, checked) => {
    try {
      const updatedCurrency = {
        ...rowData,
        [field]: checked
      };
      const response = await updateCurrency(rowData._id, updatedCurrency);
      if (response && response.currency) {
        setCurrencies(prevCurrencies =>
          prevCurrencies.map(currency =>
            currency._id === rowData._id ? response.currency : currency
          )
        );
        toast.success(response.message || 'Currency updated successfully');
      } else {
        console.error('Unexpected response structure:', response);
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error updating currency:', error);
      toast.error(`Error updating currency: ${error.message}`);
    }
  };

  const columns = [
    { header: "Name", body: (rowData) => <span>{rowData.currencyName}</span> },
    { header: "Code", body: (rowData) => <span>{rowData.currencyCode}</span> },
    { header: "Symbol", body: (rowData) => <span>{rowData.currencySymbol}</span> },
    { header: "Min", body: (rowData) => <span>{rowData.min}</span> },
    { header: "Max", body: (rowData) => <span>{rowData.max}</span> },
    {
      header: "Languages",
      body: (rowData) => (
        <span>
          {Array.isArray(rowData.languageId) && rowData.languageId.length > 0
            ? rowData.languageId.map(id => {
              const language = languages.find(lang => lang._id === id);
              return language ? language.name : id;
            }).join(', ')
            : 'No languages'}
        </span>
      ),
    },
    {
      header: "Translate",
      body: (rowData) => (
        <Switch
          checked={rowData.useInTranslate}
          onChange={(event) => handleSwitchChange(rowData, 'useInTranslate', event.target.checked)}
        />
      ),
    },
    {
      header: "Default",
      body: (rowData) => (
        <Switch
          checked={rowData.defaultCurrency}
          onChange={(event) => handleSwitchChange(rowData, 'defaultCurrency', event.target.checked)}
        />
      ),
    },
    {
      header: "Action",
      body: (rowData) => (
        <div className="flex gap-1">
          <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModal(rowData)}>
            <EditOffIcon className="text-white" />
          </button>
          <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteCurrency(rowData._id)}>
            <DeleteSweepIcon className="text-white" />
          </button>
        </div>
      ),
    },
  ];

  if (error) {
    return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
  }

  return (
    <div className="card p-4 !border-none">
      <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
        Currency Management
      </Typography>
      <div className="mb-4">
        <Button onClick={() => handleOpenModal()} className="float-right !bg-medium-aquamarine text-white">Create New Currency</Button>
      </div>
      <div>
        <div className="w-full">
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress style={{ color: '#00b4b4' }} />
            </div>
          ) : (
            <GlobalTable data={currencies} columns={columns} hasPagination={false} />
          )}
        </div>
      </div>
      <CurrencyModal
        open={modalOpen}
        onClose={handleCloseModal}
        setCurrencies={setCurrencies}
        currency={selectedCurrency}
        languages={languages}
      />
    </div>
  );
}

export default Currencies;
