import React, { useState } from 'react';
import {
    Select, MenuItem, FormControl, InputLabel, Button,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const LeadDetailsFilter = ({ onFilterApply, initialFilters, leads }) => {
    const { t } = useTranslation('common');
    const [selectedLeadId, setSelectedLeadId] = useState(initialFilters?.lead_id || '');

    const handleLeadChange = (event) => {
        const value = event.target.value;
        setSelectedLeadId(value);
        onFilterApply({ lead_id: value });
    };

    const handleReset = () => {
        setSelectedLeadId('');
        onFilterApply({ lead_id: '' });
    };

    return (
        <div className="w-full flex flex-col gap-4 mb-4 sm:flex-row sm:flex-wrap sm:items-center">
            <div className="w-full sm:w-64">
                <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel>{t('Filter by Lead')}</InputLabel>
                    <Select
                        value={selectedLeadId}
                        onChange={handleLeadChange}
                        label={t('Filter by Lead')}
                        MenuProps={{
                            PaperProps: {
                                style: { maxHeight: 224 },
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}
                    >
                        <MenuItem value="">{t('All Leads')}</MenuItem>
                        {leads && leads.map((lead) => (
                            <MenuItem key={lead.id || lead._id} value={lead.id || lead._id}>
                                {lead.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="w-full sm:w-auto">
                <div className="flex gap-2 w-full">
                    <Button
                        onClick={handleReset}
                        className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !justify-center">
                        <RotateLeftIcon className="!text-white !h-7 !w-7" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LeadDetailsFilter;